import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import PrivateRoute from '../components/PrivateRoute';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Spacer } from '../../utils/components/Spacer';
import { StyledDiv, StyledTypograpy, NewsDateTypograpy, SelectTitleTypograpy } from '../styles/style';
import { getCurrentUserToken } from '../../utils/functions';
import SnackBar from '../../utils/components/SnackBar';
import InformationDialog from '../../utils/components/InformationDialog';


// 現在の年から3年前までの4年間を取得
const years: string[] = [];

for(let i = 0; i < 4; i++) {
  const year = (new Date().getFullYear() - i).toString();
  years.push(year);
}

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export function Information() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [res, setRes] = useState([]);
    const [targetYear, setTargetYear] = useState((new Date().getFullYear()).toString());
    const [infoId, setInfoId] = useState('');
    const [infoText, setInfoText] = useState('');
    const [infoTitle, setInfoTitle] = useState('');
    const [infoDate, setInfoDate] = useState('');
    const [infoAttach, setInfoAttach] = useState('');
    const [page, setPage] = useState<number>(1);
    // ファイルダウンロード
    const [downloadStart, setDownloadStart] = useState<boolean>(false);
    const [downloadFailed, setDownloadFailed] = useState<boolean>(false);
    
    const auth = useAuth();
    
    // 1ページに表示するデータ数
    const itemsPerPage: number = Number(process.env.REACT_APP_INFORMATION_ITEMS_PER_PAGE) || 20;
    
    const handleOpen = (info_id: string, title: string, date: string) => {
        setInfoId(info_id);
        setInfoTitle(title);
        setInfoDate(date);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    
    const handleChangeTargetYear = (event: SelectChangeEvent) => {
        setTargetYear(event.target.value as string);
    };
    
    // S3からPDF取得　
    const getInfoPdf = async (event: any) => {
        event.preventDefault();
        const currentUserToken = await getCurrentUserToken();
        // axios.request({
        //     url: baseURL + "/login-after-pdf",
        //     method: "post",
        //     headers: {
        //         Accept: "application/pdf",
        //         Authorization: `${currentUserToken}`,
        //     },
        //     data: {
        //         'information_id': infoId,
        //         'pdf_file_name': infoAttach
        //     }
        // })
        // .then((response) => {
        //     const blob: Blob = toBlobPdf(response.data, "application/pdf");
        //     const url = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     console.log(url);
        //     link.setAttribute('download', infoAttach);
        //     document.body.appendChild(link);
        //     link.click();
        // })
        // .catch((error) => {
        //     console.log(error);
        // });
        try {
            setDownloadStart(true);
            
            const response = await axios.request({
                url: baseURL + "/login-after-pdf",
                method: "post",
                headers: {
                    Accept: "application/pdf",
                    Authorization: `${currentUserToken}`,
                },
                data: {
                    'information_id': infoId,
                    'pdf_file_name': infoAttach
                }
            })
            
            const s3_url = response.data.url;
            
            const content = await axios({
                method: 'get',
                url: s3_url,
                responseType: 'blob'
            });
            
            // const blob: Blob = infoAttach.endsWith('.pdf')? toBlobPdf(content.data, "application/pdf") : toBlobZip(content.data);
            const url = window.URL.createObjectURL(content.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', infoAttach);
            document.body.appendChild(link);
            link.click();
            
            setDownloadStart(false);
            
        } catch (error) {
            setDownloadFailed(true);
            console.log(error);
        }
    };
    
    // お知らせの一覧を取得する
    const getInfo = async () => {
        const currentUserToken = await getCurrentUserToken();
        const userId = typeof(auth.customerUserId) !== "undefined"? auth.customerUserId: auth.username;
        axios.request({
            url: baseURL + "/topinfo",
            method: "post",
            headers: {
                Accept: "application/json",
                Authorization: `${currentUserToken}`,
            },
            data: {
                'user_id': userId,
                'get_type': "list",
                'search_year': targetYear
            }
        })
        .then((response) => {
            setIsLoading(false);
            for(var i=0;i<response.data.length; i++){
                let tmp = response.data[i]['publicate_period_start_datetime'];
                tmp = tmp.split(' ')[0];
                response.data[i]['publicate_period_start_datetime'] = tmp;
            }
            setRes(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    // お知らせがクリックされた際に詳細情報を取得する
    const getInfoDetail = async (id: string) => {
        const currentUserToken = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/topinfo/" + id,
            method: "post",
            headers: {
                Accept: "application/json",
                Authorization: `${currentUserToken}`,
            },
        })
        .then((response) => {
            setInfoText(response.data[0]['information_text']['S']);
            setInfoAttach(response.data[0]['information_attached_file']['S']);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        getInfo();
        setPage(1);
    }, [targetYear]);
    
    useEffect(() => {
        const getData = async() => {
            try {
                const _ = await getInfo();
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
    }, [])
    const pagenation = (curPage: number) => {
        setPage(curPage);
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={50} />
                
                <div style={{ display: "flex" }}>
                    <StyledTypograpy variant="h4">お知らせ</StyledTypograpy>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                        <SelectTitleTypograpy>対象年</SelectTitleTypograpy>
                        <FormControl size="small" sx={{ width: "150px" }}>
                            <Select
                                defaultValue={targetYear}
                                value={targetYear}
                                onChange={handleChangeTargetYear}
                            >
                            {years.map((year) => (
                                <MenuItem value={year}>{year}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {
                    !isLoading?
                    <List sx={{ width: "100%" }}>
                    {res.slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((item) => (
                        <ListItem sx={{ borderBottom: "1px solid #E0E0E0", display: "flex", alignItems: "center" }} >
                            <NewsDateTypograpy>{item['publicate_period_start_datetime']}</NewsDateTypograpy>
                            <Link 
                                component="button" 
                                underline="hover"
                                sx={{ padding: "15px 0", fontWeight: 500, textAlign: "left", color: "#333333" }}
                                onClick={() => {
                                    handleOpen(
                                        item['information_id'],
                                        item['information_title'],
                                        item['publicate_period_start_datetime'],
                                    );
                                    getInfoDetail(item['information_id'])
                                }}
                            >{item['information_title']}</Link>
                            <InformationDialog 
                                open={open}
                                onClose={handleClose}
                                infoDate={infoDate}
                                infoTitle={infoTitle}
                                infoText={infoText}
                                infoAttach={infoAttach}
                                onDownload={getInfoPdf}
                            />
                        </ListItem>
                    ))}
                    </List>
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
                
                
                <Spacer size={50} />
                
                <ThemeProvider theme={pagenationTheme}>
                    <Pagination 
                        count={(res.length % itemsPerPage === 0) ? (Math.floor((res.length / itemsPerPage) + 1)-1) : (Math.floor((res.length / itemsPerPage) + 1))}
                        variant="outlined" 
                        shape="rounded" 
                        onChange={(e, page) => pagenation(page)} 
                        page={page}
                    />
                </ThemeProvider>
            </StyledDiv>
            <SnackBar
                successMessage="ダウンロードを開始します"
                openSuccess={downloadStart}
                setOpenSuccess={setDownloadStart}
                failedMessage="ダウンロードに失敗しました"
                openFailed={downloadFailed}
                setOpenFailed={setDownloadFailed}/>
        </PrivateRoute>
    );
}