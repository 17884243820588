import { useState, useEffect } from "react";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { ManagePersonAccountRecordInterface, SearchCondition } from '../interfaces/ManagePersonAccount'
 
type useManagePersonAccountRet<T> = {
  page: number,
  searchedData: T[],
  curPageData: T[],
  getManagePersonAccountData:  () => Promise<T[]>;
  setSearchConditionRequestName: (requestName?: string[]) => void;
  requestNameList: string[];
  setSearchConditionRequestUserId: (requestUserId?: string[]) => void;
  requestUserIdList: string[];
  setSearchConditionAuthority: (customer?: boolean, info?: boolean, loginInfo?: boolean, account?: boolean, changePassword?: boolean, billingRelease?: boolean) => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  searchCondition: SearchCondition; // for dubug
};

// 検索条件の初期値
const initSearchAuthority = {
  customer: true,
  info: true,
  loginInfo: true,
  account: true,
  changePassword: true,
  billingRelease: true
};
const initSearchCondition = {
  requestName: [],
  requestUserId: [],
  SearchAuthority: initSearchAuthority,
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 5;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useManagePersonAccount(): useManagePersonAccountRet<ManagePersonAccountRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<ManagePersonAccountRecordInterface[]>([]);
  const [searchedData, setSearchedData] = useState<ManagePersonAccountRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<ManagePersonAccountRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSearchCondition});
  const [requestNameList, setRequestNameList] = useState<string[]>([]);
  const [requestUserIdList, setRequestUserIdList] = useState<string[]>([]);
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  
  useEffect(() => {
    getDropdownSearchList();
  }, [defaultData]);
  
  useEffect(() => {
    pagenation(1);
    // getDropdownSearchList();
  }, [searchedData]); 
  
  useEffect(() => {
    searchData();
  }, [searchCondition]);
  
  const setSearchConditionRequestName = (requestName: string[] = []) => {
    if(requestName.length !== 0){
      setSearchCondition({...searchCondition, requestName: [...requestName]});
    }
    else{
      setSearchCondition({...searchCondition, requestName: []});
    }
  }
  
  const setSearchConditionRequestUserId = (requestUserId: string[] = []) => {
    if(requestUserId.length !== 0){
      setSearchCondition({...searchCondition, requestUserId: [...requestUserId]});
    }
    else{
      setSearchCondition({...searchCondition, requestUserId: []});
    }
  }
  
  const setSearchConditionAuthority = (customer: boolean = false, info: boolean = false, loginInfo: boolean = false, account: boolean = false, changePassword: boolean = false, billingRelease: boolean = false) => {
    const targetAuthority = {
      customer,
      info,
      loginInfo,
      account,
      changePassword,
      billingRelease
    };
    setSearchCondition({...searchCondition, SearchAuthority: targetAuthority});
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: ManagePersonAccountRecordInterface[] = [];
    let searchedData2: ManagePersonAccountRecordInterface[] = [];
    
    searchedData1 = defaultData;
  
    /*名称による検索処理*/
    if(searchCondition.requestName.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.requestName.indexOf(item.name) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*アカウントIDによる検索処理*/
    if(searchCondition.requestUserId.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.requestUserId.indexOf(item.user_id) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];

    /* 権限による検索処理 */
    searchedData1.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.SearchAuthority.customer && item.authority[0] === "1"){
        pushFlag = true;
      }
      else if(searchCondition.SearchAuthority.info && item.authority[1] === "1"){
        pushFlag = true;
      }
      else if(searchCondition.SearchAuthority.loginInfo && item.authority[2] === "1"){
        pushFlag = true;
      }
      else if(searchCondition.SearchAuthority.account && item.authority[3] === "1"){
        pushFlag = true;
      }
      else if(searchCondition.SearchAuthority.changePassword && item.authority[4] === "1"){
        pushFlag = true;
      }
      else if(searchCondition.SearchAuthority.billingRelease && item.authority[5] === "1"){
        pushFlag = true;
      }
      if (pushFlag) searchedData2.push(searchedData1[index]);
    });
    
    setSearchedData([...searchedData2]);
  }

  // 検索ボックスのプルダウンに表示するリストを作成する
  const getDropdownSearchList = () => {
    // setterは非同期のため一時配列を用意する
    let tmpRequestNameList: string[] = [];
    let tmpRequestUserIdList: string[] = [];
    
    defaultData.forEach((obj: any, i: Number) => {
      const tmpRequestName: string = obj.name;
      if(tmpRequestNameList.indexOf(tmpRequestName) == -1){
        tmpRequestNameList.push(tmpRequestName);
      }
      const tmprequestUserId: string = obj.user_id;
      if(tmpRequestUserIdList.indexOf(tmprequestUserId) == -1){
        tmpRequestUserIdList.push(tmprequestUserId);
      }
    });
    
    
    // 更新処理は最後に一括で代入する
    setRequestNameList(tmpRequestNameList);
    setRequestUserIdList(tmpRequestUserIdList);
  }
  
  const resetData = () => {
    setSearchedData([...defaultData]);
    setSearchCondition({...searchCondition, ...initSearchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: ManagePersonAccountRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }

  // 使用電力アラームを取得する　
  const getManagePersonAccountData: () => Promise<ManagePersonAccountRecordInterface[]> = async () => {
    const token = await getCurrentUserToken();
    const response = axios.request({
      url: baseURL + "/get-manage-account",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params: {
        name: "",
        user_id: ""
      }
    })
    .then((response) => {
      setDefaultData(response.data.data);
      setSearchedData(response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    curPageData,
    getManagePersonAccountData,
    setSearchConditionRequestName,
    requestNameList,
    setSearchConditionRequestUserId,
    requestUserIdList,
    setSearchConditionAuthority,
    resetState,
    resetData,
    clearResetSatate,
    pagenation, 
    searchCondition
  };
}
