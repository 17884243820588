import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import PrivateRoute from '../components/PrivateRoute';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
// components
import FiscalYear from '../components/FiscalYear';
import { Spacer } from '../../utils/components/Spacer';
import AchievementDownloadTab from '../components/Tab/AchievementDownloadTab';
import SearchBoxAchievementDownloadDayAndNight from '../components/DownloadDayAndNight/SearchBoxAchievementDownloadDayAndNight';
import UsingAchievementDownloadDayAndNightTable from '../components/DownloadDayAndNight/UsingAchievementDownloadDayAndNightTable';
// custom hooks
import { useAchievementDonwloadDayAndNight } from "../hooks/useAchievementDonwloadDayAndNight";
// style
import { StyledDiv, StyledTypograpy, StyledFooter, StyledToolbar } from '../styles/style';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const downloadFileLength = 150;

interface CsvFile {
  filename: string;
  blob: Blob;
}

export function UsingAchievementDownloadDayAndNight() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  
  const { 
    page,
    searchedData,
    getDownloadList,
    targetDateList,
    setSearchConditionTargetDate,
    setSearchConditionName,
    nameList,
    setSearchConditionContractId,
    contractIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionContractTypeName,
    contractTypeNameList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck,
    initFiscalYear,
    searchCondition
  } = useAchievementDonwloadDayAndNight();
  
  useEffect(() => {
    setIsLoading(true);
    const getData = async() => {
      try {
        const _ = await getDownloadList();
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    if (searchCondition.fiscalYear !== "") getData();
  }, [searchCondition.fiscalYear]);
  
  useEffect(() => {
    initFiscalYear();
  }, []);
  
  const onLambdaCsvButtonClick = async () => {
    setIsDownload(true);
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    // ダウンロード対象のファイル名を抽出
    const downloadFiles: any[] = [];
    let filePathItems: Array<string> = [];
    let fileNameItems: Array<string> = []; 
    searchedData.forEach((obj) => {
      if(obj.checked) {
        let downloadFile: {downloadFilePaths: string, downloadFileNames: string} = {
          "downloadFilePaths" : obj.system_file_name,
          "downloadFileNames": obj.download_file_name
        };
        filePathItems.push(obj.system_file_name);
        const id = typeof(obj.request_point_id) === "undefined"? obj.contract_id : obj.request_point_id;
        fileNameItems.push(`${obj.target_ym}_昼間・夜間電力量_${id}.csv`);
        downloadFiles.push(downloadFile);
      }
    });
      
    if(downloadFiles.length === 0){
      window.alert("ダウンロード対象のファイルが存在しません")
      setIsDownload(false);
    }
    // 単一のcsvファイルをダウンロード
    else if(downloadFiles.length === 1){
      axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/csv",
            Authorization: token,
          },
          data:{
            "downloadFiles": filePathItems
          }
      })
      .then((response) => {
        const blob: Blob = toBlobCsv(response.data, "application/csv",);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileNameItems[0]}`);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownload(false);
      });
    }
    // zip形式のCsvファイル群をダウンロード
    else{
      
      if (downloadFiles.length <= downloadFileLength) {
        axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/zip",
            Authorization: token,
          },
            data:{
              "downloadFiles": downloadFiles
            }
        })
        .then((response) => {
          const blob: Blob = toBlobZip(response.data);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          if (typeof(auth.requestPointID) === "undefined"){
            link.setAttribute('download', `${searchCondition.fiscalYear}_昼間・夜間電力量_${auth.contractID}_${auth.name}.zip`);
          }else {
            link.setAttribute('download', `${searchCondition.fiscalYear}_昼間・夜間電力量_${auth.requestPointID}${auth.name}.zip`);
          }
          document.body.appendChild(link);
          link.click();
          setIsDownload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsDownload(false);
        });
      } else {
        // 1つのAPIが対処するファイル数の決定
        const segmentCount = downloadFiles.length / downloadFileLength;
        
        // ダウンロードするファイル群をsegmentCountの数だけ等分する
        const chunkSize = Math.ceil(downloadFiles.length / segmentCount);
        const fileChunks = [];
        for (let i = 0; i < downloadFiles.length; i += chunkSize) {
          fileChunks.push(downloadFiles.slice(i, i + chunkSize));
        }
        
        // 新しいzipインスタンスを作成
        const finalZip = new JSZip();
        
        // 各チャンクごとにAPIリクエストを行い、結果を結合する
        try {
          const zipParts = await Promise.all(fileChunks.map(async (chunk, index) => {
            const response = await axios.request({
              url: baseURL + "/billing-inquery-invoice-download",
              method: "post",
              headers: { 
                Accept: "application/zip",
                Authorization: token,
              },
                data:{
                  "downloadFiles": chunk
                }
            });
            
            // base64エンコードされたzipデータをBlobに変換
            const blob: Blob = toBlobZip(response.data);
      
            const zip = new JSZip();
            await zip.loadAsync(blob);
      
            const csvFiles: Promise<CsvFile>[] = [];
            zip.forEach((relativePath, file) => {
              if (relativePath.endsWith('.csv')) {
                csvFiles.push(file.async('blob').then((blob) => ({
                  filename: relativePath,
                  blob: blob
                })));
              }
            });
            
            return await Promise.all(csvFiles);
          }));
          
          zipParts.flat().forEach(({ filename, blob }) => {
            finalZip.file(filename, blob);
          });
        
          // 最終的なzipファイルを生成してダウンロード
          finalZip.generateAsync({ type: 'blob' }).then(function (content) {
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            if (typeof(auth.requestPointID) === "undefined"){
              link.setAttribute('download', `${searchCondition.fiscalYear}_昼間・夜間電力量_${auth.contractID}_${auth.name}.zip`);
            }else {
              link.setAttribute('download', `${searchCondition.fiscalYear}_昼間・夜間電力量_${auth.requestPointID}${auth.name}.zip`);
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          
        } catch (error) {
          console.error(error);
        } finally {
          setIsDownload(false);
        }
      }
    }
  };
  
  function toBlobCsv(base64: string, mime_ctype: string): Blob {
    const byteArray = new Uint8Array(atob(base64).split('').map(function (char) {
      return char.charCodeAt(0);
    }));

    var bin = atob(base64);
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([byteArray], {
        type: mime_ctype,
    });
    return blob;
  };
  
  // base64エンコードされたzip形式のファイルをblob型に変換する
  function toBlobZip(base64: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'application/zip'
    });
    return blob;
  };
  
  return (
    <PrivateRoute>
      <StyledDiv>
        <Spacer size={50} />
        <StyledTypograpy variant="h5">データダウンロード</StyledTypograpy>
        <Spacer size={20} />
        <AchievementDownloadTab initPage={"day-and-night"} />
        <Spacer size={20} />
        <FiscalYear searchData={setSearchConditionTargetDate} targetDateList={targetDateList} resetState={resetState} clearResetSatate={clearResetSatate} />
        <Spacer size={20} />
        {( typeof(auth.requestPointID) !== "undefined" ? 
            <p style={{textAlign: 'center'}}>対象なし</p> :
          !isLoading ?
            <>
              <SearchBoxAchievementDownloadDayAndNight 
                setSearchConditionName={setSearchConditionName}
                nameList={nameList}
                setSearchConditionContractId={setSearchConditionContractId}
                contractIdList={contractIdList}
                setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
                supplyPointNoList={supplyPointNoList}
                setSearchConditionAddress={setSearchConditionAddress}
                addressList={addressList}
                setSearchConditionContractTypeName={setSearchConditionContractTypeName}
                contractTypeNameList={contractTypeNameList}
                setSearchConditionVoltageClass={setSearchConditionVoltageClass}
                setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                resetData={resetData} />
              <Spacer size={30} />
              <UsingAchievementDownloadDayAndNightTable
                allCheck={allCheck}
                singleCheck={singleCheck}
                searchedData={searchedData}
                page={page} 
                pagenation={pagenation}/>
            </>
            :
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
      </StyledDiv>
      <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
        <StyledToolbar>
          <StyledButton
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onLambdaCsvButtonClick}>CSVダウンロード</StyledButton>
          {isDownload && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </StyledToolbar>
      </StyledFooter>
    </PrivateRoute>
  );
}

/* Styles */
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  color: '#0098CB',
  fontWeight: 'bold',
  margin: 10,
  padding: 10,
  '&:hover': {
    backgroundColor: "#FFFFFF"
  }
}));
