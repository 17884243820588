import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import PrivateRoute from '../components/PrivateRoute';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Spacer } from '../../utils/components/Spacer';
import { LocationInterface } from '../interfaces/LocationInterface';
import { LocationState } from '../interfaces/UsingAchievement';
import SelectDemandLocation from '../components/SelectDemandLocation';
import UsingAchievementTab from '../components/Tab/UsingAchievementTab';
import UsingAchievementDailyTable from '../components/UsingAchievementDaily/UsingAchievementDailyTable';
import UsingAchievementDailyChart from '../components/UsingAchievementDaily/UsingAchievementDailyChart';
import { UsingAchievementDiv, StyledTypograpy } from '../styles/style';
import { getCurrentUserToken } from '../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

function createPowerData(
  time: string,
  daytime_use_power_kWh: number,
  nighttime_use_power_kWh: number,
  exceed: number,
  flag: boolean
) {
  return { time, daytime_use_power_kWh, nighttime_use_power_kWh, exceed, flag };
}

interface DailyData {
    time: string;
    daytime_use_power_kWh: number;
    nighttime_use_power_kWh: number;
    exceed: number;
    flag: boolean;
};

export function UsingAchievementDaily() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation() as LocationState;
    const [res, setRes] = useState<DailyData[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [targetDay, setTargetDay] = useState<Dayjs | null>(dayjs(`${new Date()}`));
    const [pointID, setPointID] = useState('');
    const [supplyStartDate, setSupplyStartDate] = useState('');
    const [contractEndDate, setContractEndDate] = useState('');
    const [locationData, setLocationData] = useState<LocationInterface[]>([]);
    const [totalData, setTotalData] = useState<{total: string, daytime: string, nighttime: string}>({total: "0", daytime: "0", nighttime: "0"});
    
    const [threshold, setThreshold] = useState(0);
    
    // 需要場所データの取得
    const getDemandLocation = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/demand-location",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'contract_id': auth.contractID,
            }
        })
        .then((response) => {
            const pointsSorted = response.data["data"].sort((a: LocationInterface, b: LocationInterface) => a.request_point_id.localeCompare(b.request_point_id));
            setPointID(pointsSorted[0]["request_point_id"]);
            setInputValue(pointsSorted[0]["request_point_name"])
            setLocationData(pointsSorted);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        if (typeof(auth.requestPointID) === "undefined") {
            // 使用実績画面が初めて開かれた場合、需要場所を取得する
            if (typeof(location.state?.locationData) === "undefined") {
                getDemandLocation();
            } else {
            // タブでの遷移だった場合、そのデータを引き継ぐ
                setPointID(location.state?.pointID);
                setInputValue(location.state?.inputValue);
                setLocationData(location.state?.locationData);
            }
        } else {
            setPointID(auth.requestPointID);
        }
    }, []);
    
    // 日別の使用実績データの取得
    const getUsingAchievementDailyData = async (targetDate: string | null) => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/volume-usage/day",
            method: "post",
            headers: { 
                Authorization: `${token}`,
        },
            data : {
                "date": targetDate,
                "request_point_id": pointID,
            }
        })
        .then((response) => {
            if (typeof(response.data["notExistVolumeUsage"]) === "undefined"){
                let resThreshold = 0;
                if(Number(response.data["data"]["use_power_alarm_threshold"]) === -1) {
                    resThreshold = 0
                } else {
                    resThreshold = response.data["data"]["use_power_alarm_threshold"];
                }
                console.log(resThreshold);
                const dailyData = []
                // テーブルやグラフに表示できる形に成形   
                for(let i = 0; i < 48; i++){
                    const start = `${String(Math.floor(i / 2)).padStart(2, '0')}:${i % 2 === 0 ? '00' : '30'}`;
                    const end = `${String(Math.floor((i + 1) / 2)).padStart(2, '0')}:${i % 2 === 0 ? '30' : '00'}`;
                    const volume = response.data["data"]["data"]["min30_power_volume"][i];
                    let flag = true
                    if (volume === "") flag = false;
                    
                    // 閾値より超過しているものを分ける
                    if (15 < i && i < 44){
                        if (volume < resThreshold || resThreshold === 0){
                            dailyData.push(createPowerData(`${start}~${end}`, volume, 0, 0, flag));
                        } else {
                            dailyData.push(createPowerData(`${start}~${end}`, resThreshold, 0, volume - resThreshold, flag));
                        }
                    } else if (i < 16 || 43 < i) {
                        if (volume < resThreshold || resThreshold === 0){
                            dailyData.push(createPowerData(`${start}~${end}`, 0, volume, 0, flag));
                        } else {
                            dailyData.push(createPowerData(`${start}~${end}`, 0, resThreshold, volume - resThreshold, flag));
                        }
                    }
                }
                setRes(dailyData);
                setThreshold(resThreshold);
                setTotalData({
                    total: response.data["data"]["data"]["total_use_power_kWh"], 
                    daytime: response.data["data"]["data"]["daytime_use_power_kWh"], 
                    nighttime: response.data["data"]["data"]["nighttime_use_power_kWh"]
                });
                if (targetDate === "") {
                    setTargetDay(dayjs(response.data["data"]["cur_date"]));   
                }
                setSupplyStartDate(response.data["data"]["supply_start_date"]);
                setContractEndDate(response.data["data"]["contract_end_date"]);
            } else {
                setRes([])
            }
        })
        .catch((error) => {
            console.log(error);
            setRes([])
            setTotalData({total: "0", daytime: "0", nighttime: "0"});
        });
    }
    
    useEffect(() => {
        if (pointID !== "") getUsingAchievementDailyData("");
    }, [pointID]);
    
    const callGetDailyData = (newDate: Dayjs | null) => {
        getUsingAchievementDailyData(newDate !== null? newDate.format("YYYYMMDD"): null);
    };
    
    const handlePrevDay = () => {
        setTargetDay(targetDay !== null ? targetDay.add(-1, 'day') : null);
        callGetDailyData(targetDay !== null ? targetDay.add(-1, 'day') : null);
    };
    
    const handleNextDay = () => {
        setTargetDay(targetDay !== null ? targetDay.add(1, 'day') : null);
        callGetDailyData(targetDay !== null ? targetDay.add(1, 'day') : null);
    };
    
    return (
        <PrivateRoute>
            <UsingAchievementDiv>
                <Spacer size={50} />
                <StyledTypograpy variant="h5">実績データ</StyledTypograpy>
                <Spacer size={20} />
                {typeof(auth.requestPointID) === "undefined" ? (
                    <>
                        <SelectDemandLocation location={locationData} inputValue={inputValue} setInputValue={setInputValue} setPointID={setPointID}/>
                        <Spacer size={20} />
                    </>
                    ) : <div />
                }
                <UsingAchievementTab initPage={"daily"} pointID={pointID} inputValue={inputValue} locationData={locationData}/>
                <Spacer size={70} />
                
                <div style={{ display: 'flex' }}>
                    <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                <DateTimePicker
                                    value={targetDay}
                                    onChange={(newValue: Dayjs | null) => {
                                        setTargetDay(newValue);
                                        callGetDailyData(newValue);
                                    }}
                                    format="YYYY/MM/DD"
                                    slotProps={{ textField: { size: 'small'}}}
                                    sx={{ width: "150px", height: "40px" }}
                                    views={['year', 'month', 'day']}
                                />
                            </LocalizationProvider>
                            <Button variant="text" startIcon={<ArrowBackIosIcon />} onClick={() => handlePrevDay()}>
                                前日
                            </Button>
                            <Button variant="text" endIcon={<ArrowForwardIosIcon />} onClick={() => handleNextDay()}>
                                翌日
                            </Button>
                        </Stack>
                        
                        <Spacer size={20} />
                        
                        <Box sx={{ width: 395, display: 'flex', justifyContent: "space-between"}}>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>合計(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{Number(totalData.total).toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>昼間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{Number(totalData.daytime).toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>夜間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{Number(totalData.nighttime).toLocaleString()}</Typography>
                            </div>
                        </Box>
                        
                        <Spacer size={20} />
                        
                        <UsingAchievementDailyTable data={res} supplyStartDate={supplyStartDate} contractEndDate={contractEndDate} targetDay={targetDay}/>
                    </div>
                    
                    <Box sx={{ minWidth: "725px", width: "1250px", height: "530px" }}>
                        <UsingAchievementDailyChart data={res} threshold={threshold} supplyStartDate={supplyStartDate} contractEndDate={contractEndDate} targetDay={targetDay}/>
                    </Box>
                </div>
                
                <Spacer size={40} />
                
                <div style={{ display: 'flex' }}>
                    <Typography>※</Typography>
                    <Typography>スマートメーターや通信機器の状態などで、「使用実績」に誤差が生まれる場合がございます。　実際の使用電力については、検針票・計算書にてご確認ください。</Typography>
                </div>
                {auth.userAuthority[14] === "1"? 
                    <div style={{ display: 'flex' }}>
                        <Typography>※</Typography>
                        <Typography>しきい値の変更は<span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0098CB' }} onClick={() => navigate("/user/setting/electricity-alarm")}>設定＞使用電力アラーム</span>から可能です。</Typography>
                    </div> : <div />
                }
            </UsingAchievementDiv>
        </PrivateRoute>
    );
}