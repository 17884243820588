import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import PrivateRoute from '../components/PrivateRoute';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Spacer } from '../../utils/components/Spacer';
import { LocationInterface } from '../interfaces/LocationInterface';
import { LocationState } from '../interfaces/UsingAchievement';
import SelectDemandLocation from '../components/SelectDemandLocation';
import UsingAchievementTab from '../components/Tab/UsingAchievementTab';
import UsingAchievementWeekdayTable from '../components/UsingAchievementWeekday/UsingAchievementWeekdayTable';
import UsingAchievementWeekdayChart from '../components/UsingAchievementWeekday/UsingAchievementWeekdayChart';
import { UsingAchievementDiv, StyledTypograpy, ErrorTypography } from '../styles/style';
import { ERROR_MESSAGE_09, ERROR_MESSAGE_10 } from '../../utils/errorMessages';
import { getCurrentUserToken } from '../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

interface WeekdayData {
    aggregate_time: string;
    daytime_use_power_kWh: string;
    nighttime_use_power_kWh: string;
    total_use_power_kWh: string;
    week_of_day: string;
};

const sumPowerData = (powerData: WeekdayData[]): {daytime_use_power_kWh: number, nighttime_use_power_kWh: number} => {
  const daytime_use_power_kWh = powerData.reduce((acc, item) => acc + Number(item.daytime_use_power_kWh), 0);
  const nighttime_use_power_kWh = powerData.reduce((acc, item) => acc + Number(item.nighttime_use_power_kWh), 0);
  return { daytime_use_power_kWh, nighttime_use_power_kWh };
};


export function UsingAchievementWeekday() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation() as LocationState;
    const [res, setRes] = useState<WeekdayData[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [startDay, setStartDay] = useState<Dayjs | null>(dayjs());
    const [endDay, setEndDay] = useState<Dayjs | null>(dayjs());
    const [pointID, setPointID] = useState('');
    const [locationData, setLocationData] = useState<LocationInterface[]>([]);
    const [totalData, setTotalData] = useState<{daytime_use_power_kWh: number, nighttime_use_power_kWh: number}>({daytime_use_power_kWh: 0, nighttime_use_power_kWh: 0});
    const [error, setError] = useState('');
    
    // 需要場所データの取得
    const getDemandLocation = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/demand-location",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'contract_id': auth.contractID
            }
        })
        .then((response) => {
            const pointsSorted = response.data["data"].sort((a: LocationInterface, b: LocationInterface) => a.request_point_id.localeCompare(b.request_point_id));
            setPointID(pointsSorted[0]["request_point_id"]);
            setInputValue(pointsSorted[0]["request_point_name"])
            setLocationData(pointsSorted);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        if (typeof(auth.requestPointID) === "undefined") {
            // 使用実績画面が初めて開かれた場合、需要場所を取得する
            if (typeof(location.state?.locationData) === "undefined") {
                getDemandLocation();
            } else {
            // タブでの遷移だった場合、そのデータを引き継ぐ
                setPointID(location.state?.pointID);
                setInputValue(location.state?.inputValue);
                setLocationData(location.state?.locationData);
            }
        } else {
            setPointID(auth.requestPointID);
        }
    }, []);
    
    // 曜日別の使用実績データの取得
    const getUsingAchievementWeekdayData = async (start: string | null, end: string | null) => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/volume-usage/weekday",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                "request_point_id": pointID,
                "date_from": start,
                "date_to": end
            }
        })
        .then((response) => {
            if (typeof(response.data["notExistVolumeUsage"]) === "undefined"){
                setRes(response.data["data"]["data"]);
                if (start === "" && end === "") {
                    setStartDay(dayjs(response.data["data"]["date_from"]));
                    setEndDay(dayjs(response.data["data"]["date_to"]));
                }
            } else {
                setRes([])
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        if (pointID !== "") getUsingAchievementWeekdayData("", "");
    }, [pointID]);
    
    useEffect(() => {
        setTotalData(sumPowerData(res));
    }, [res]);
    
    const callGetWeekdayData = (start: Dayjs | null, end: Dayjs | null, flag: string) => {
        if(flag === 'start' && start !== null && start.diff(end) > 0) {
            setError(ERROR_MESSAGE_09)
            return;
        };
        if(flag === 'end' && end !== null && end.diff(start) < 0) {
            setError(ERROR_MESSAGE_10)
            return;
        };
        getUsingAchievementWeekdayData(
            start !== null? start.format("YYYYMMDD"): null,
            end !== null? end.format("YYYYMMDD"): null,
        );
    };
    
    const clearErrorMsg = () => {
        setError('');
    };
    
    return (
        <PrivateRoute>
            <UsingAchievementDiv>
                <Spacer size={50} />
                <StyledTypograpy variant="h5">実績データ</StyledTypograpy>
                <Spacer size={20} />
                {typeof(auth.requestPointID) === "undefined" ? (
                    <>
                        <SelectDemandLocation location={locationData} inputValue={inputValue} setInputValue={setInputValue} setPointID={setPointID}/>
                        <Spacer size={20} />
                    </>
                    ) : <div />
                }
                <UsingAchievementTab initPage={"weekday"} pointID={pointID} inputValue={inputValue} locationData={locationData}/>
                <Spacer size={70} />
                
                <div style={{ display: 'flex' }}>
                    <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                <DateTimePicker
                                 value={startDay}
                                 onChange={(newValue: Dayjs | null) => {
                                    clearErrorMsg();
                                    setStartDay(newValue);
                                    callGetWeekdayData(newValue, endDay, "start");
                                 }}
                                 format="YYYY/MM/DD"
                                 slotProps={{ textField: { size: 'small'}}}
                                 sx={{ width: "150px", height: "40px" }}
                                 views={['year', 'month', 'day']}
                                />
                            </LocalizationProvider>
                            <Typography variant="h5">~</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                <DateTimePicker
                                 value={endDay}
                                 onChange={(newValue: Dayjs | null) => {
                                    clearErrorMsg();
                                    setEndDay(newValue);
                                    callGetWeekdayData(startDay, newValue, "end");
                                 }}
                                 format="YYYY/MM/DD"
                                 slotProps={{ textField: { size: 'small'}}}
                                 sx={{ width: "150px", height: "40px" }}
                                 views={['year', 'month', 'day']}
                                />
                            </LocalizationProvider>
                        </Stack>
                        
                        <ErrorTypography variant="caption">{error !== ""? error : ""}</ErrorTypography>
                        
                        <Spacer size={20} />
                        
                        <Box sx={{ width: 395, display: 'flex', justifyContent: "space-between"}}>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>合計(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{(totalData.daytime_use_power_kWh + totalData.nighttime_use_power_kWh).toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>昼間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{totalData.daytime_use_power_kWh.toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>夜間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{totalData.nighttime_use_power_kWh.toLocaleString()}</Typography>
                            </div>
                        </Box>
                        
                        <Spacer size={20} />
                        
                        <UsingAchievementWeekdayTable data={res}/>
                    </div>
                    
                    <Box sx={{ minWidth: "725px", width: "1250px", height: "530px" }}>
                        <UsingAchievementWeekdayChart data={res}/>
                    </Box>
                </div>
                
                <Spacer size={40} />
                
                <div style={{ display: 'flex' }}>
                    <Typography>※</Typography>
                    <Typography>スマートメーターや通信機器の状態などで、「使用実績」に誤差が生まれる場合がございます。　実際の使用電力については、検針票・計算書にてご確認ください。</Typography>
                </div>
                {auth.userAuthority[14] === "1"? 
                    <div style={{ display: 'flex' }}>
                        <Typography>※</Typography>
                        <Typography>しきい値の変更は<span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0098CB' }} onClick={() => navigate("/user/setting/electricity-alarm")}>設定＞使用電力アラーム</span>から可能です。</Typography>
                    </div> : <div />
                }
            </UsingAchievementDiv>
        </PrivateRoute>
    );
}