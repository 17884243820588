import React, { useState }  from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import TextField from '@mui/material/TextField';
import { 
  ERROR_MESSAGE_03, 
  ERROR_MESSAGE_04, 
  ERROR_MESSAGE_05, 
  ERROR_MESSAGE_06, 
  ERROR_MESSAGE_07,
  ERROR_MESSAGE_08,} from "../../utils/errorMessages";
import PrivateRoute from '../components/PrivateRoute';
import SnackBar from '../../utils/components/SnackBar';
import { Spacer } from "../../utils/components/Spacer";
import { StyledDiv, StyledContainer, StyledTypography, StyledButton, ErrorTypography, PasswordBox, PasswordTextBox } from '../styles/kanriStyle';

export function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [changeFailed, setChangeFailed] = useState(false);
  const [errors, setErrors] = useState({
    "currentPasswordError": '',
    "newPasswordError": '',
    "reNewPasswordError": '',
    "passwordError": ''
  });
  const auth = useAuth();
  
  const clearMsg = () => {
    setErrors({
      "currentPasswordError": '',
      "newPasswordError": '',
      "reNewPasswordError": '',
      "passwordError": ''
    });
  }
  
  const onRegistrationButtonClick = async () => {
    // パスワードの入力チェック
    if(currentPassword === '' && newPassword === '' && reNewPassword === '') {
      setErrors({
        ...errors, 
        currentPasswordError: ERROR_MESSAGE_03, 
        newPasswordError: ERROR_MESSAGE_04, 
        reNewPasswordError: ERROR_MESSAGE_05
      });
      return;
    }
    if(currentPassword === '' && newPassword === '') {
      setErrors({
        ...errors, 
        currentPasswordError: ERROR_MESSAGE_03,
        newPasswordError: ERROR_MESSAGE_04, 
      });
      return;
    }
    if(currentPassword === '' && reNewPassword === '') {
      setErrors({
        ...errors, 
        currentPasswordError: ERROR_MESSAGE_03,
        reNewPasswordError: ERROR_MESSAGE_05, 
      });
      return;
    }
    if(newPassword === '' && reNewPassword === '') {
      setErrors({
        ...errors, 
        newPasswordError: ERROR_MESSAGE_04,
        reNewPasswordError: ERROR_MESSAGE_05, 
      });
      return;
    }
    if(currentPassword === '') {
      setErrors({...errors, currentPasswordError: ERROR_MESSAGE_03});
      return;
    }
    if(newPassword === '') {
      setErrors({...errors, newPasswordError: ERROR_MESSAGE_04});
      return;
    }
    if(reNewPassword === '') {
      setErrors({...errors, reNewPasswordError: ERROR_MESSAGE_05});
      return;
    }
    if(newPassword !== reNewPassword) {
      setErrors({...errors, passwordError: ERROR_MESSAGE_06});
      return;
    }
    if(currentPassword === newPassword) {
      setErrors({...errors, passwordError: ERROR_MESSAGE_07});
      return;
    }
    
    if(auth.username === newPassword) {
      setErrors({...errors, passwordError: ERROR_MESSAGE_08});
      return;
    }
    
    const result = await auth.changePassword(currentPassword, newPassword);
    if (result.success) {
      setChangeSuccess(true);
      setCurrentPassword('');
      setNewPassword('');
      setReNewPassword('');
    } else {
      setChangeFailed(true);
      alert(result.message);
    }
  }
  
  const onClearButtonClick = () => {
    setCurrentPassword('');
    setNewPassword('');
    setReNewPassword('');
  }
  
  return (
    <PrivateRoute>
      <StyledContainer>
        <StyledDiv>
          <Spacer size={50} />
          <StyledTypography variant="h5">パスワード変更</StyledTypography>
          <Spacer size={40} />
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <PasswordBox>
              <StyledTypography>現在のパスワード</StyledTypography>
            </PasswordBox>
            
            <PasswordTextBox>
              <TextField
                type="password"
                value={currentPassword} 
                onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    clearMsg();
                }}
                size="small"
                style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                
                <ErrorTypography variant="caption">{errors.currentPasswordError !== ""? errors.currentPasswordError : ""}</ErrorTypography>
            </PasswordTextBox>
          </div>
        
          <Spacer size={20}/>
        
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        
            <PasswordBox style={{ height: '140px' }}>
              <StyledTypography>新しいパスワード</StyledTypography>
            </PasswordBox>
            
            <PasswordTextBox style={{ height: '140px' }}>
              <TextField
                  type="password"
                  value={newPassword} 
                  onChange={(e) => {
                      setNewPassword(e.target.value);
                      clearMsg();
                  }}
                  size="small"
                  style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
              <ErrorTypography variant="caption">{errors.passwordError !== ""? errors.passwordError : ""}</ErrorTypography>
              <Spacer size={10}/>
              <TextField
                  type="password"
                  value={reNewPassword} 
                  onChange={(e) => {
                      setReNewPassword(e.target.value);
                      clearMsg();
                  }}
                  size="small"
                  style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
              
              <ErrorTypography variant="caption">{errors.reNewPasswordError !== ""? errors.reNewPasswordError : ""}</ErrorTypography>
              <ErrorTypography variant="caption">{errors.passwordError !== ""? errors.passwordError : ""}</ErrorTypography>
            </PasswordTextBox>
          </div>
          
          <Spacer size={20}/>
          <div style={{ textAlign: 'center' }}>
            <StyledButton
                onClick={onRegistrationButtonClick}>更新</StyledButton>
            <StyledButton
                onClick={onClearButtonClick}>クリア</StyledButton>
          </div>
        </StyledDiv>
      </StyledContainer>
      
      <SnackBar
          successMessage="パスワードの更新が完了しました"
          openSuccess={changeSuccess}
          setOpenSuccess={setChangeSuccess}
          failedMessage="パスワードの更新が失敗しました"
          openFailed={changeFailed}
          setOpenFailed={setChangeFailed}/>
    </PrivateRoute>
  );
}