import React, { useState, useEffect } from 'react';
import PrivateRoute from '../components/PrivateRoute';
import { useAuth } from '../../utils/hooks/use-auth';
import { Auth } from 'aws-amplify';
import axios from "axios";
import TextField from '@mui/material/TextField';
import { Spacer } from '../../utils/components/Spacer';
import SettingTab from '../components/Tab/SettingTab';
import SnackBar from '../../utils/components/SnackBar';
import { StyledDiv, StyledTypograpy, SaveButton, SettingMailBox, SettingMailTextBox, ErrorTypography } from '../styles/style';
import { ERROR_MESSAGE_11, ERROR_MESSAGE_17 } from '../../utils/errorMessages';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export function SettingMail() {
    const auth = useAuth();
    const userId = typeof(auth.customerUserId) !== "undefined"? auth.customerUserId: auth.username;
    const [mailAddress, setMailAddress] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFailed, setOpenFailed] = useState(false);
    const [mailAddressError, setMailAddressError] = useState('');
    
    // 登録しているメールアドレスの取得
    const getMailAddress = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/get-setting-email",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
            params: {
                'user_id': userId
            }
        })
        .then((response) => {
            console.log(response.data)
            setMailAddress(response.data["data"][0]["mail_address"])
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        getMailAddress();
    }, []);
    
    const updateMailAddress = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/update-setting-email",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data: {
                'user_id': userId,
                'mail_address': mailAddress
            }
        })
        .then((response) => {
            console.log(response.data)
            setOpenSuccess(true);
        })
        .catch((error) => {
            console.log(error);
            setOpenFailed(true);
        });
    }
    
    // メールアドレスの入力チェック
    const checkUpdateMailAddress = (): boolean => {
        if (mailAddress === "") return setMailAddressError(ERROR_MESSAGE_11), false;
        if (mailAddress.match(/[^\x01-\x7E]/g) !== null) return setMailAddressError(ERROR_MESSAGE_17), false;
        return true;
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={50} />
                
                <StyledTypograpy variant="h5">設定</StyledTypograpy>
                
                <Spacer size={20} />
                
                <SettingTab initPage={"mail"} />
                
                <Spacer size={50} />
                
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <SettingMailBox>
                        <StyledTypograpy>登録済み</StyledTypograpy>
                        <StyledTypograpy>メールアドレス</StyledTypograpy>
                    </SettingMailBox>
                    
                    <SettingMailTextBox>
                        <TextField 
                            value={mailAddress} 
                            onChange={(e) => {
                                setMailAddress(e.target.value);
                                setMailAddressError('');
                            }}
                            size="small"
                            style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                        
                        <ErrorTypography variant="caption">{mailAddressError !== ""? mailAddressError : ""}</ErrorTypography>
                    </SettingMailTextBox>
                    
                    <SaveButton
                        onClick={() => {
                            const chkMailAddress = checkUpdateMailAddress();
                            if ( !chkMailAddress ) return;
                            updateMailAddress();
                        }}>保存</SaveButton>
                </div>
                
                <SnackBar
                    successMessage="メールアドレスを更新しました"
                    openSuccess={openSuccess}
                    setOpenSuccess={setOpenSuccess}
                    failedMessage="メールアドレスを更新に失敗しました"
                    openFailed={openFailed}
                    setOpenFailed={setOpenFailed}/>
                
            </StyledDiv>
        </PrivateRoute>
    );
}