import * as React from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// custom hooks
import { useViewInvoicePDF } from "../../hooks/useViewInvoicePDF";
// custom styled component
import { StyledTableSortLabel } from '../../styles/style';
// interface
import { InvoiceRecordInterface } from '../../interfaces/Invoice';

interface Order {
  target_ym: 'asc' | 'desc';
  document_id: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface invoiceTable {
  searchedData: InvoiceRecordInterface[];
  page: number;
  pagenation: (page: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, name: string) => void;
}

const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function InvoiceTable(props: invoiceTable) {
  const {searchedData, singleCheck, allCheck, page, pagenation} = props;
  const [order, setOrder] = React.useState<Order>({ target_ym: 'desc', document_id: 'asc' });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('target_ym');
  const [checkboxBulk, setCheckboxBulk] = React.useState<boolean>(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  const {ViewInvoicePDF} = useViewInvoicePDF();
  
  const onViewPDFButtonClick = async (targetDate: string) => {
    ViewInvoicePDF(targetDate, searchedData);
  }
  
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxBulk(event.target.checked);
    allCheck(event.target.checked);
  };

  const compare = (a: InvoiceRecordInterface, b: InvoiceRecordInterface): number => {
    if (orderBy === 'target_ym') {
      if (a.target_ym < b.target_ym) {
        return order.target_ym === 'asc' ? -1 : 1;
      }
      if (a.target_ym > b.target_ym) {
        return order.target_ym === 'asc' ? 1 : -1;
      }
      if (a.target_ym === b.target_ym) {
        if (a.contents[0]?.document_id < b.contents[0]?.document_id) {
          return order?.document_id === 'asc' ? -1 : 1;
        }
        if (a.contents[0]?.document_id > b.contents[0]?.document_id) {
          return order?.document_id === 'asc' ? 1 : -1;
        }
        return 0;
      }
    } else if (orderBy === 'document_id') {
      if (a.contents[0]?.document_id < b.contents[0]?.document_id) {
        return order?.document_id === 'asc' ? -1 : 1;
      }
      if (a.contents[0]?.document_id > b.contents[0]?.document_id) {
        return order?.document_id === 'asc' ? 1 : -1;
      }
      if (a.contents[0]?.document_id === b.contents[0]?.document_id) {
        if (a.target_ym < b.target_ym) {
          return order.target_ym === 'asc' ? -1 : 1;
        }
        if (a.target_ym > b.target_ym) {
          return order.target_ym === 'asc' ? 1 : -1;
        }
        return 0;
      }
    }
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page)*itemsPerPage < searchedData.length? (page)*itemsPerPage : searchedData.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={checkboxBulk} onChange={handleChangeCheckbox} />
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'target_ym'}
                  direction={order.target_ym}
                  onClick={() => handleRequestSort('target_ym')}
                >
                  対象年月
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'document_id'}
                  direction={order?.document_id}
                  onClick={() => handleRequestSort('document_id')}
                >
                  請求書番号
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>ダウンロード対象ファイル</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#ABE1FA' : '#ffffff' }}
              >
                <TableCell>
                  <Checkbox
                    onChange={() => singleCheck(row.target_ym, row.contents[0]["document_id"])}
                    checked={row.checked} />
                </TableCell>
                <TableCell>
                  <Button variant="text" onClick={() => onViewPDFButtonClick(row.target_ym)}>
                    {row.target_ym}
                  </Button>
                </TableCell>
                <TableCell>{row.contents.filter((item) => item.file_type === "請求書").length > 0 ? row.contents.filter((item) => item.file_type === "請求書")[0].document_id : row.contents[0].document_id}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    {row.contents.map((file) => (
                      file.file_type !== "清算金額内訳"
                      ? file.file_type === "明細一覧" 
                      ? <Chip label="明細一覧CSV" variant="outlined" /> : <Chip label="請求書PDF" variant="outlined" />
                      : <Chip label="清算金額内訳Excel" variant="outlined" />))}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}