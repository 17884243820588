import { useState, useEffect } from "react";
import axios from "axios";
import * as pako from 'pako';
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import dayjs, { Dayjs } from 'dayjs';
import { CustomerManagementRecordInterface, SearchCondition } from '../interfaces/CustomerManagement'
 
type useCustomerManagementRet<T> = {
  page: number,
  searchedData: T[],
  curPageData: T[],
  getCustomerManagementData:  () => Promise<T[]>;
  setSearchConditionUserId: (userId?: string) => void;
  setSearchConditionContractId: (contractId?: string) => void;
  setSearchConditionContractName: (contractName?: string) => void;
  setSearchConditionRequestPointId: (requestPointId?: string) => void;
  setSearchConditionRequestPointName: (requestPointName?: string) => void;
  setSearchConditionSupplyDate: (startHead: Dayjs | null, startTail: Dayjs | null, EndHead: Dayjs | null, EndTail: Dayjs | null) => void;
  setSearchConditionUnit: (contractUnit?: boolean, requestPointUnit?: boolean) => void;
  setSearchConditionMailAddress: (mailAddressRegister?: boolean, noMailAddressRegister?: boolean) => void;
  setSearchConditionIdStatus: (idStatNotified?: boolean, idStatUnnotified?: boolean) => void;
  setSearchConditionSendMail: (mailNecessary?: boolean, mailUnnecessary?: boolean) => void;
  searchData: () => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  searchCondition: SearchCondition; // for dubug
};

const initSearchCondition = {
  userId: "",
  contractId: "",
  contractName: "",
  requestPointId: "",
  requestPointName: "",
  supplyStartDate: {head: null, tail: null},
  supplyEndDate: {head: dayjs(), tail: null},
  searchUnit: {contractUnit: true, requestPointUnit: true},
  searchMailAddress: {mailAddressRegister: true, noMailAddressRegister: true},
  searchIdStatus: {idStatNotified: true, idStatUnnotified: true},
  searchSendMail: {mailNecessary: true, mailUnnecessary: true},
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = 20;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useCustomerManagement(): useCustomerManagementRet<CustomerManagementRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<Uint8Array>(new Uint8Array());
  const [searchedData, setSearchedData] = useState<CustomerManagementRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<CustomerManagementRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSearchCondition});
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  // データを取得できた場合、searchData関数を呼び出し初期検索を行ったデータをsearchedDataに格納する
  useEffect(() => {
    if(defaultData.buffer.byteLength > 0) searchData();
  }, [defaultData]);
  
  useEffect(() => {
    pagenation(1);
  }, [searchedData]);
  
  // リセットした際に検索条件が終わった後に、searchData()が動くように制御
  useEffect(() => {
    if (resetState) {
      searchData();
      setResetState(false);
    }
  }, [searchCondition, resetState]);
  
  const setSearchConditionUserId = (userId: string = "") => {
    if(userId !== "") setSearchCondition({...searchCondition, userId: userId});
    else setSearchCondition({...searchCondition, userId: ""});
  }
  
  const setSearchConditionContractId = (contractId: string = "") => {
    if(contractId !== "") setSearchCondition({...searchCondition, contractId: contractId});
    else setSearchCondition({...searchCondition, contractId: ""});
  }
  
  const setSearchConditionContractName = (contractName: string = "") => {
    if(contractName !== "") setSearchCondition({...searchCondition, contractName: contractName});
    else setSearchCondition({...searchCondition, contractName: ""});
  }
  
  const setSearchConditionRequestPointId = (requestPointId: string = "") => {
    if(requestPointId != "") setSearchCondition({...searchCondition, requestPointId: requestPointId});
    else setSearchCondition({...searchCondition, requestPointId: ""});
  }
  
  const setSearchConditionRequestPointName = (requestPointName: string = "") => {
    if(requestPointName !== "") setSearchCondition({...searchCondition, requestPointName: requestPointName});
    else setSearchCondition({...searchCondition, requestPointName: ""});
  }
  
  const setSearchConditionSupplyDate = (startHead: Dayjs|null = null, startTail: Dayjs|null = null, EndHead: Dayjs|null = null, EndTail: Dayjs|null = null ) => {
    setSearchCondition({...searchCondition, supplyStartDate: {head: startHead, tail: startTail}, supplyEndDate: {head: EndHead, tail: EndTail}});

    if(startHead !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, head: startHead}});
    }
    if(startTail !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, tail: startTail}});
    }
    if(EndHead !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, head: EndHead}});
    }
    if(EndTail !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, tail: EndTail}});
    }
  }
  
  const setSearchConditionUnit = (contractUnit: boolean = false, requestPointUnit: boolean = false) => {
    const targetUnit = { contractUnit, requestPointUnit };
    setSearchCondition({...searchCondition, searchUnit: targetUnit});
  }
  
  const setSearchConditionMailAddress = (mailAddressRegister: boolean = false, noMailAddressRegister: boolean = false) => {
    const targetMailAddress = { mailAddressRegister, noMailAddressRegister };
    setSearchCondition({...searchCondition, searchMailAddress: targetMailAddress});
  }
  
  const setSearchConditionIdStatus = (idStatNotified: boolean = false, idStatUnnotified: boolean = false) => {
    const targetIdStatus = { idStatNotified, idStatUnnotified };
    setSearchCondition({...searchCondition, searchIdStatus: targetIdStatus});
  }
  
  const setSearchConditionSendMail = (mailNecessary: boolean = false, mailUnnecessary: boolean = false) => {
    const targetSendMail = { mailNecessary, mailUnnecessary };
    setSearchCondition({...searchCondition, searchSendMail: targetSendMail});
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: CustomerManagementRecordInterface[] = [];
    let searchedData2: CustomerManagementRecordInterface[] = [];
    
    // 圧縮データの解凍
    const decompressed = pako.inflate(defaultData);
    
    // Uint8Arrayを文字列に変換
    const textDecoder = new TextDecoder();
    const decodedJsonString = textDecoder.decode(decompressed);
    
    // JSON形式の文字列を配列に変換
    searchedData1 = JSON.parse(decodedJsonString);
    
    /*ユーザIDによる検索処理*/
    if(searchCondition.userId !== ""){
      searchedData1.forEach((item, index) => {
        if(searchCondition.userId === item.user_id){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*契約IDによる検索処理*/
    if(searchCondition.contractId !== ""){
      searchedData2.forEach((item, index) => {
        if(searchCondition.contractId === item.contract_id){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
  
    /*契約名による検索処理*/
    if(searchCondition.contractName !== ""){
      searchedData1.forEach((item, index) => {
        if(item.contract_name && item.contract_name.includes(searchCondition.contractName)){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*需要場所IDによる検索処理*/
    if(searchCondition.requestPointId !== ""){
      searchedData2.forEach((item, index) => {
        if(searchCondition.requestPointId === item.request_point_id){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
  
    /*需要場所による検索処理*/
    if(searchCondition.requestPointName !== ""){
      searchedData1.forEach((item, index) => {
        if(item.request_point_name && item.request_point_name.includes(searchCondition.requestPointName)){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /* ユーザ種別による検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchUnit.contractUnit && item.user_type_code === "01") pushFlag = true;
      else if(searchCondition.searchUnit.requestPointUnit && item.user_type_code === "02") pushFlag = true;
      
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    searchedData2 = [];
    
    
    /* メールアドレスによる検索処理 */
    searchedData1.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchMailAddress.mailAddressRegister && item.mail_address !== "") pushFlag = true;
      else if(searchCondition.searchMailAddress.noMailAddressRegister && item.mail_address === "") pushFlag = true;
      
      if (pushFlag) searchedData2.push(searchedData1[index]);
    });
    searchedData1 = [];
    
    /* ID通知ステータスによる検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchIdStatus.idStatNotified && item.id_notice_status_code === "1") pushFlag = true;
      else if(searchCondition.searchIdStatus.idStatUnnotified && item.id_notice_status_code === "0") pushFlag = true;
      
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    searchedData2 = [];
    
    /* ユーザ種別による検索処理 */
    searchedData1.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchSendMail.mailNecessary && item.mail_send_necessity_code === "1") pushFlag = true;
      else if(searchCondition.searchSendMail.mailUnnecessary && item.mail_send_necessity_code === "0") pushFlag = true;
      
      if (pushFlag) searchedData2.push(searchedData1[index]);
    });
    
    // 契約開始日による検索処理関数
    const searchSupplyStartDate = (s: Dayjs | null, e: Dayjs | null, searched: CustomerManagementRecordInterface[]): CustomerManagementRecordInterface[] => {
      let tmpData: CustomerManagementRecordInterface[] = [];
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD") && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約終了日による検索処理関数
    const searchSupplyEndDate = (s: Dayjs | null, e: Dayjs | null, searched: CustomerManagementRecordInterface[]): CustomerManagementRecordInterface[] => {
      let tmpData: CustomerManagementRecordInterface[] = [];
      // nullチェック
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD") && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約開始日による検索処理
    searchedData2 = searchSupplyStartDate(searchCondition.supplyStartDate.head, searchCondition.supplyStartDate.tail, searchedData2);
    
    // 契約終了日による検索処理
    searchedData2 = searchSupplyEndDate(searchCondition.supplyEndDate.head, searchCondition.supplyEndDate.tail, searchedData2);
    
    setSearchedData([...searchedData2]);
  }
  
  const resetData = () => {
    setSearchCondition({...searchCondition, ...initSearchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: CustomerManagementRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }

  // お客さま一覧を取得する　
  const getCustomerManagementData: () => Promise<CustomerManagementRecordInterface[]> = async () => {
    const token = await getCurrentUserToken();
    const response = axios.request({
      url: baseURL + "/get-customer-management",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
    })
    .then((response) => {
      response.data.data = response.data.data.map((item: CustomerManagementRecordInterface) => {
        if (item.user_type_code === "01") {
          return {
            ...item,
            request_point_id: item.min_request_point_id,
            request_point_name: item.min_request_point_name,
            supply_point_no: item.min_supply_point_no,
            fee_menu_name_printing: item.min_fee_menu_name_printing,
            supply_start_date: item.min_supply_start_date,
            contract_end_date: item.max_contract_end_date
          };
        }
        return item;
      });
      console.log(response);
      // 配列をJSON形式の文字列に変換
      const jsonString = JSON.stringify(response.data.data);
      // データをUint8Arrayに変換
      const textEncoder = new TextEncoder();
      const uint8Array = textEncoder.encode(jsonString);
      // データを圧縮
      const compressed = pako.deflate(uint8Array);
      
      setDefaultData(compressed);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    curPageData,
    getCustomerManagementData,
    setSearchConditionUserId,
    setSearchConditionContractId,
    setSearchConditionContractName,
    setSearchConditionRequestPointId,
    setSearchConditionRequestPointName,
    setSearchConditionSupplyDate,
    setSearchConditionUnit,
    setSearchConditionMailAddress,
    setSearchConditionIdStatus,
    setSearchConditionSendMail,
    searchData,
    resetState,
    resetData,
    clearResetSatate,
    pagenation, 
    searchCondition
  };
}
