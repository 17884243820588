import React, { useState } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import { ERROR_MESSAGE_0201_01, ERROR_MESSAGE_0201_02, ERROR_MESSAGE_0201_03, ERROR_MESSAGE_0201_04 } from "../../utils/errorMessages";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { StyledDiv, LoginBox, StyledTypography, StyledTextField, LoginButton } from '../styles/kanriStyle';
import { Spacer } from '../../utils/components/Spacer';
import { getCurrentUserToken } from '../../utils/functions';
import EneresIcon from '../../assets/img/common/Eneres_Primary_Logo.png'

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export default function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    "usernameError": '',
    "passwordError": '',
    "signInError": ''
  });
  
  function clearMsg() {
    setErrors({
      "usernameError": '',
      "passwordError": '',
      "signInError": ''
    });
  }
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  // 管理者が登録されているかのチェックを行い、登録されている場合ログイン最終日時を更新し、管理者メニューに遷移する
  const executeSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsDisabled(true);
    
    if(userId === '' && password === '') {
      setIsDisabled(false);
      setErrors({...errors, usernameError: ERROR_MESSAGE_0201_01, passwordError: ERROR_MESSAGE_0201_02});
      return;
    }
    if(userId === '') {
      setIsDisabled(false);
      setErrors({...errors, usernameError: ERROR_MESSAGE_0201_01})
      return;
    }
    if(password === '') {
      setIsDisabled(false);
      setErrors({...errors, passwordError: ERROR_MESSAGE_0201_02})
      return;
    }
    
    axios.request({
      url: baseURL + "/kanri-login-check",
      method: "post",
      data: {
        'user_id': userId
      }
    })
    .then((response) => {
      if (response.status === 200) {
        axios.request({
          url: baseURL + "/update-login-time",
          method: "post",
          data: {
              'table_name': 'kanri',
              'user_id': userId
          }
        })
        .then((response) => {
          if(response.data.success) {
            auth.signIn(userId, password).then((resp) => {
              if(resp.success) {
                navigate({ pathname: '/kanri/menu' });
              } else {
                setIsDisabled(false);
                if(resp.message == '認証に失敗しました。') {
                  setErrors({...errors, signInError: ERROR_MESSAGE_0201_03})
                } else {
                  setErrors({...errors, signInError: ERROR_MESSAGE_0201_04})
                }
              }
            }).catch((error) => {
              setIsDisabled(false);
              console.log(error)
            })
          }
        })
        .catch((error) => {
          setIsDisabled(false);
          console.log(error);
        });
      } else {
        setIsDisabled(false);
        setErrors({...errors, signInError: ERROR_MESSAGE_0201_03})
      }
    })
    .catch((error) => {
      setIsDisabled(false);
      setErrors({...errors, signInError: ERROR_MESSAGE_0201_03})
      console.log(error);
    });
  };

  return (
    <StyledDiv>
      <Spacer size={30} />
      
      <div style={{ textAlign: "center" }}>
          <img src={EneresIcon} style={{ width: '240px'}} />
          <StyledTypography>管理者サイト</StyledTypography>
      </div>
    
      <Spacer size={20} />
      
      <LoginBox>
          <div style={{ width: "320px", margin: "0 auto"}}>
            <form noValidate onSubmit={executeSignIn}>
              <StyledTypography>ユーザ名</StyledTypography>
              <StyledTextField
                  value={userId}
                  size="small"
                  onChange={(e) => {setUserId(e.target.value); clearMsg()}}
              />
              {errors.usernameError !== ""? <div>{errors.usernameError}</div>: ""}
              
              <Spacer size={20} />
              
              <StyledTypography>パスワード</StyledTypography>
              <StyledTextField
                  value={password}
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => {setPassword(e.target.value); clearMsg()}}
                  InputProps={{ 
                      endAdornment: <InputAdornment position="end">
                      <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                      >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                      </InputAdornment>
                  }}
              />
              {errors.passwordError !== ""? <div>{errors.passwordError}</div>: ""}
              
              <Spacer size={20} />
              
              <Box sx={{ m: 1, position: 'relative' }}>
                {!isDisabled?
                  <LoginButton type="submit" disabled={isDisabled}>ログイン</LoginButton>
                : <LoginButton disabled={isDisabled}>ログイン中</LoginButton>
                }
                {isDisabled && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "#FFFFFF",
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              
              <Spacer size={20} />
                        
              <StyledTypography style={{ textAlign: "center" }}>{errors.signInError !== ""? errors.signInError: ""}</StyledTypography>
            
            </form>
          </div>
      </LoginBox>
    </StyledDiv>
  );
}