import { useState, useEffect } from "react";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { ElectricityAlarmRecordInterface, SearchCondition } from '../interfaces/SettingElectricityAlarm'
 
type useElectricityAlarmSearchRet<T> = {
  page: number,
  searchedData: T[],
  curPageData: T[],
  getSettingElecticityAlarmData:  () => Promise<T[]>;
  setSearchConditionRequestPointName: (requestPointName?: string[]) => void;
  requestPointNameList: string[];
  setSearchConditionRequestPointId: (requestPointId?: string[]) => void;
  requestPointIdList: string[];
  setSearchConditionSupplyPointNo: (supplyPointNo?: string[]) => void;
  supplyPointNoList: string[];
  setSearchConditionAddress: (address?: string[]) => void;
  addressList: string[];
  setSearchConditionFeeMenuNamePrinting: (feeMenuNamePrinting?: string[]) => void;
  feeMenuNamePrintingList: string[];
  setSearchConditionVoltageClass: (low?: boolean, high?: boolean, exHigh?: boolean) => void;
  setSearchConditionSupplyDate: (startHead: Dayjs | null, startTail: Dayjs | null, EndHead: Dayjs | null, EndTail: Dayjs | null) => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  searchCondition: SearchCondition; // for dubug
};

// 検索条件の初期値
const initSerchVoltageClass = {
  exHigh: true,
  high: true,
  low: true
};

const initSerchCondition = {
  requestPointName: [],
  requestPointId: [],
  supplyPointNo: [],
  feeMenuNamePrinting: [],
  address: [],
  SearchVoltageClass: initSerchVoltageClass,
  supplyStartDate: {head: null, tail: null},
  supplyEndDate: {head: dayjs(), tail: null},
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = 20;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useSettingElectricityAlarm(): useElectricityAlarmSearchRet<ElectricityAlarmRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<ElectricityAlarmRecordInterface[]>([]);
  const [searchedData, setSearchedData] = useState<ElectricityAlarmRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<ElectricityAlarmRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSerchCondition});
  const [requestPointNameList, setRequestPointNameList] = useState<string[]>([]);
  const [requestPointIdList, setRequestPointIdList] = useState<string[]>([]);
  const [supplyPointNoList, setSupplyPointNoList] = useState<string[]>([]);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [feeMenuNamePrintingList, setFeeMenuNamePrintingList] = useState<string[]>([]);
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  
  useEffect(() => {
    if(defaultData.length > 0) searchData();
    getDropdownSearchList();
  }, [defaultData]);
  
  useEffect(() => {
    pagenation(1);
    // getDropdownSearchList();
  }, [searchedData]); 
  
  useEffect(() => {
    searchData();
  }, [searchCondition]);
  
  const setSearchConditionRequestPointName = (requestPointName: string[] = []) => {
    if(requestPointName.length !== 0){
      setSearchCondition({...searchCondition, requestPointName: [...requestPointName]});
    }
    else{
      setSearchCondition({...searchCondition, requestPointName: []});
    }
  }
  
  const setSearchConditionRequestPointId = (requestPointId: string[] = []) => {
    if(requestPointId.length !== 0){
      setSearchCondition({...searchCondition, requestPointId: [...requestPointId]});
    }
    else{
      setSearchCondition({...searchCondition, requestPointId: []});
    }
  }

  const setSearchConditionSupplyPointNo = (supplyPointNo: string[] = []) => {
    if(supplyPointNo.length !== 0){
      setSearchCondition({...searchCondition, supplyPointNo: [...supplyPointNo]});
    }
    else{
      setSearchCondition({...searchCondition, supplyPointNo: []});
    }
  }
  
  const setSearchConditionAddress = (address: string[] = []) => {
    if(address.length !== 0){
      setSearchCondition({...searchCondition, address: [...address]});
    }
    else{
      setSearchCondition({...searchCondition, address: []});
    }
  }
  
  const setSearchConditionFeeMenuNamePrinting = (feeMenuNamePrinting: string[] = []) => {
    if(feeMenuNamePrinting.length !== 0){
      setSearchCondition({...searchCondition, feeMenuNamePrinting: [...feeMenuNamePrinting]});
    }
    else{
      setSearchCondition({...searchCondition, feeMenuNamePrinting: []});
    }
  }
  
  const setSearchConditionVoltageClass = (exHigh: boolean = false, high: boolean = false, low: boolean = false) => {
    const targetVoltageClass = {
      exHigh,
      high,
      low
    };
    setSearchCondition({...searchCondition, SearchVoltageClass: targetVoltageClass});
  }
  
  const setSearchConditionSupplyDate = (startHead: Dayjs|null = null, startTail: Dayjs|null = null, EndHead: Dayjs|null = null, EndTail: Dayjs|null = null ) => {
    setSearchCondition({...searchCondition, supplyStartDate: {head: startHead, tail: startTail}, supplyEndDate: {head: EndHead, tail: EndTail}});

    if(startHead !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, head: startHead}});
    }
    if(startTail !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, tail: startTail}});
    }
    if(EndHead !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, head: EndHead}});
    }
    if(EndTail !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, tail: EndTail}});
    }
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: ElectricityAlarmRecordInterface[] = [];
    let searchedData2: ElectricityAlarmRecordInterface[] = [];
    
    searchedData1 = defaultData;
  
    /*需要場所による検索処理*/
    if(searchCondition.requestPointName.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.requestPointName.indexOf(item.request_point_name) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*需要場所IDによる検索処理*/
    if(searchCondition.requestPointId.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.requestPointId.indexOf(item.request_point_id) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*住所による検索処理*/
    if(searchCondition.address.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.address.indexOf(item.address) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*供給地点番号による検索処理*/
    if(searchCondition.supplyPointNo.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.supplyPointNo.indexOf(item.supply_point_no) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*契約種別による検索処理*/
    if(searchCondition.feeMenuNamePrinting.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.feeMenuNamePrinting.indexOf(item.fee_menu_name_printing) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];


    /* ダウンロード対象ファイルによる検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.SearchVoltageClass.exHigh && item.voltage_class === "特高"){
        pushFlag = true;
      }
      else if(searchCondition.SearchVoltageClass.high && item.voltage_class === "高圧"){
        pushFlag = true;
      }
      else if(searchCondition.SearchVoltageClass.low && item.voltage_class === "低圧"){
        pushFlag = true;
      }
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    
    // 契約開始日による検索処理関数
    const searchSupplyStartDate = (s: Dayjs | null, e: Dayjs | null, searched: ElectricityAlarmRecordInterface[]): ElectricityAlarmRecordInterface[] => {
      let tmpData: ElectricityAlarmRecordInterface[] = [];
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD") && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約終了日による検索処理関数
    const searchSupplyEndDate = (s: Dayjs | null, e: Dayjs | null, searched: ElectricityAlarmRecordInterface[]): ElectricityAlarmRecordInterface[] => {
      let tmpData: ElectricityAlarmRecordInterface[] = [];
      // nullチェック
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD") && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約開始日による検索処理
    searchedData1 = searchSupplyStartDate(searchCondition.supplyStartDate.head, searchCondition.supplyStartDate.tail, searchedData1);
    
    // 契約終了日による検索処理
    searchedData1 = searchSupplyEndDate(searchCondition.supplyEndDate.head, searchCondition.supplyEndDate.tail, searchedData1);
    
    setSearchedData([...searchedData1]);
  }

  // 検索ボックスのプルダウンに表示するリストを作成する
  const getDropdownSearchList = () => {
    // setterは非同期のため一時配列を用意する
    let tmpRequestPointNameList: string[] = [];
    let tmpRequestPointIdList: string[] = [];
    let tmpSupplyPointNoList: string[] = [];
    let tmpAddressList: string[] = [];
    let tmpFeeMenuNamePrintingList: string[] = [];
    
    defaultData.forEach((obj: any, i: Number) => {
      const tmpRequestPointName: string = obj.request_point_name;
      if(tmpRequestPointNameList.indexOf(tmpRequestPointName) == -1){
        tmpRequestPointNameList.push(tmpRequestPointName);
      }
      const tmprequestPointId: string = obj.request_point_id;
      if(tmpRequestPointIdList.indexOf(tmprequestPointId) == -1){
        tmpRequestPointIdList.push(tmprequestPointId);
      }
      const tmpSupplyPointNo: string = obj.supply_point_no;
      if(tmpSupplyPointNoList.indexOf(tmpSupplyPointNo) == -1){
        tmpSupplyPointNoList.push(tmpSupplyPointNo);
      }
      const tmpAddress: string = obj.address;
      if(tmpAddressList.indexOf(tmpAddress) == -1){
        tmpAddressList.push(tmpAddress);
      }
      const tmpFeeMenuNamePrinting: string = obj.fee_menu_name_printing;
      if(tmpFeeMenuNamePrintingList.indexOf(tmpFeeMenuNamePrinting) == -1){
        tmpFeeMenuNamePrintingList.push(tmpFeeMenuNamePrinting);
      }
    });
    
    
    // 更新処理は最後に一括で代入する
    setRequestPointNameList(tmpRequestPointNameList);
    setRequestPointIdList(tmpRequestPointIdList);
    setSupplyPointNoList(tmpSupplyPointNoList);
    setAddressList(tmpAddressList);
    setFeeMenuNamePrintingList(tmpFeeMenuNamePrintingList);
  }
  
  const resetData = () => {
    setSearchedData([...defaultData]);
    setSearchCondition({...searchCondition, ...initSerchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: ElectricityAlarmRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }

  // 使用電力アラームを取得する　
  const getSettingElecticityAlarmData: () => Promise<ElectricityAlarmRecordInterface[]> = async () => {
    const token = await getCurrentUserToken();
    const params: {[prop: string]: string} = {
      "contractId" : auth.contractID
    }
    if(typeof(auth.requestPointID) !== "undefined") params["requestPointId"] = auth.requestPointID;
    
    const response = axios.request({
      url: baseURL + "/get-setting-alarm",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params : params
    })
    .then((response) => {
      setDefaultData(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    curPageData,
    getSettingElecticityAlarmData,
    setSearchConditionRequestPointName,
    requestPointNameList,
    setSearchConditionRequestPointId,
    requestPointIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionFeeMenuNamePrinting,
    feeMenuNamePrintingList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation, 
    searchCondition
  };
}
