import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs, { Dayjs } from 'dayjs';
import { UsingAchievementTableCell } from '../../styles/style';

interface Props {
    data: DailyData[];
    supplyStartDate: string;
    contractEndDate: string;
    targetDay: Dayjs | null;
};

interface DailyData {
    time: string;
    daytime_use_power_kWh: number;
    nighttime_use_power_kWh: number;
    exceed: number;
    flag: boolean;
};


export default function UsingAcievementTable(props: Props) {
  const { data, supplyStartDate, contractEndDate, targetDay } = props;
  const withinContractPeriod = dayjs(targetDay).isAfter(dayjs(supplyStartDate).subtract(1, 'day')) &&
                              dayjs(targetDay).isBefore(dayjs(contractEndDate).add(1, 'day'));
    
  return (
    <TableContainer component={Paper} elevation={0} sx={{ width: 395, height: 378, border: '1px solid', borderColor: '#DFDFED' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>時刻</TableCell>
            <UsingAchievementTableCell padding="none">全体(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">昼間(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">夜間(kWh)</UsingAchievementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: index % 2 === 1 ? '#CBE8FA' : '#FFFFFF' }}
              
            >
              <TableCell>{row.time}</TableCell>
              <TableCell align="right">
                {(!withinContractPeriod || !row.flag) ? "-" : ((Number(row.daytime_use_power_kWh) !== -1 ? Number(row.daytime_use_power_kWh) : 0) 
                + (Number(row.nighttime_use_power_kWh) !== -1 ? Number(row.nighttime_use_power_kWh) : 0) 
                + row.exceed).toLocaleString()}
              </TableCell>
              <TableCell align="right">{(!withinContractPeriod || !row.flag) ? "-" : Number(row.daytime_use_power_kWh) !== -1 ? Number(row.daytime_use_power_kWh) === 0 ? Number(row.daytime_use_power_kWh): (row.daytime_use_power_kWh + row.exceed).toLocaleString() : "欠損"}</TableCell>
              <TableCell align="right">{(!withinContractPeriod || !row.flag) ? "-" : Number(row.nighttime_use_power_kWh) !== -1 ? Number(row.nighttime_use_power_kWh) === 0 ? Number(row.nighttime_use_power_kWh) : (row.nighttime_use_power_kWh + row.exceed).toLocaleString() : "欠損"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}