import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../../utils/hooks/use-auth';
import { InvoiceRecordInterface, SearchCondition } from '../interfaces/Invoice';
import { getCurrentUserToken } from '../../utils/functions';
 
type useInvoiceSearchRet<T> = {
  searchedData: T[],
  curPageData: T[],
  setCurPageData: (data: T[]) => void;
  getInvoiceData:  (startDate: string | null, endDate: string | null) => Promise<T[]>;
  page: number,
  targetDateList: string[],
  invoiceNoList: string[],
  setSearchConditionTargetDate: (startDate?: string, endDate?: string) => void;
  setSearchConditionInvoiceNo: (invoiceNo?: string[]) => void;
  setSearchConditionFileType: (pdf?: boolean, csv?: boolean, xlsx?: boolean) => void;
  resetState: boolean,
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, documentId: string) => void;
};

const initTargetFile = {
  pdf: true,
  csv: true,
  xlsx: true
};

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 20;

// メインフック
export function useInvoice(): useInvoiceSearchRet<InvoiceRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<InvoiceRecordInterface[]>([]);
  const [searchedData, setSearchedData] = useState<InvoiceRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<InvoiceRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({startDate: "1000/1", endDate: "9998/12", invoiceNo: [], targetFile: initTargetFile});
　const [targetDateList, setTargetDateList] = useState<string[]>([]);  // 対象年月
  const [invoiceNoList, setInvoiceNoList] = useState<string[]>([]);
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  useEffect(() => {
    if (defaultData.length > 0) searchData();
  }, [defaultData]);
  
  useEffect(() => {
    pagenation(1);
    searchData();
  }, [searchCondition]);
  
  const setSearchConditionTargetDate = (startDate: string = "", endDate: string = "") => {
    if(startDate === "" && endDate === ""){
      setSearchCondition({...searchCondition, startDate: "1000/1", endDate: "9998/12"});
    }
    // 期末のみ指定されている場合
    else if(startDate === "" && endDate !== ""){
      setSearchCondition({...searchCondition, endDate: endDate});
    }
    // 期初のみ指定されている場合
    else if (startDate !== "" && endDate === ""){
      setSearchCondition({...searchCondition, startDate: startDate});
    }
    // 両方指定されている場合
    else{
      setSearchCondition({...searchCondition, startDate: startDate, endDate: endDate});
    }
  }
  
  const setSearchConditionInvoiceNo = (invoiceNo: string[] = []) => {
    if(invoiceNo.length !== 0){
      setSearchCondition({...searchCondition, invoiceNo: [...invoiceNo]});
    }
    else{
      setSearchCondition({...searchCondition, invoiceNo: []});
    }
  }
  
  const setSearchConditionFileType = (pdf: boolean = true, csv: boolean = true, xlsx: boolean = true) => {
    const targetFileType = {
      pdf,
      csv,
      xlsx
    };
    setSearchCondition({...searchCondition, targetFile: targetFileType});
  }
  
  const searchData = () => {
    let dateSearchedData: InvoiceRecordInterface[] = [];
    let filetypeSearchedData: InvoiceRecordInterface[] = [];
    let returnData: InvoiceRecordInterface[] = [];
  
    /* 対象年月による検索 */
    const splitedStartDate = searchCondition.startDate.split("/");
    const sy: number = Number(splitedStartDate[0]);
    const sm: number = Number(splitedStartDate[1]);
    const startDateObj: Date = new Date(sy, sm, 15, 0, 0, 0);
    const splitedEndDate = searchCondition.endDate.split("/");
    const ey: number = Number(splitedEndDate[0]);
    const em: number = Number(splitedEndDate[1]);
    const endDateObj: Date = new Date(ey, em, 15, 0, 0, 0);
    dateSearchedData = setResultData(startDateObj, endDateObj);
    
    // 対象年月検索のメインロジック関数
    function setResultData(s: Date, e: Date){
      let tmpData: InvoiceRecordInterface[] = [];
      defaultData.forEach((obj, i) => {
        const y: number = Number(obj.target_ym.slice(0, 4));
        const m: number = Number(obj.target_ym.slice(4, 6));
        const tmpDateObj: Date = new Date(y, m, 15, 0, 0, 0);
        if(tmpDateObj >= s && tmpDateObj <= e) tmpData.push(defaultData[i]);
      })
      return tmpData
    }
    
    /* ダウンロード対象ファイルによる検索処理 */
    dateSearchedData.forEach((item, index) => {
      let pushFlag = false;
      item.contents.forEach((childItem:any) => {
        if(searchCondition.targetFile.pdf && childItem.file_type === "請求書"){
          pushFlag = true;
        }
        if(searchCondition.targetFile.csv && childItem.file_type === "明細一覧"){
          pushFlag = true;
        }
        if(searchCondition.targetFile.xlsx  && childItem.file_type === "清算金額内訳"){
          pushFlag = true;
        }
      });
      if (pushFlag) filetypeSearchedData.push(dateSearchedData[index]);
    });
    
    /*請求書番号による検索処理*/
    if(searchCondition.invoiceNo.length !== 0){
      filetypeSearchedData.forEach((item, index) => {
        item.contents.forEach((childItem:any) => {
          if (childItem.file_type === "請求書"){
            if(searchCondition.invoiceNo.indexOf(childItem.document_id) !== -1){
              returnData.push(filetypeSearchedData[index]);
            }
          }
        });
      });
    }
    else{
      returnData = [...filetypeSearchedData];
    }
    
    setSearchedData(returnData);
  }

  const getInvoiceList = (responseData: InvoiceRecordInterface[]) => {
    // setterは非同期のため一時配列を用意する
    let tmpTargetDateList: string[] = [];
    let TargetDateListForCompare: Date[] = [];
    let tmpInvoiceNoList: string[] = [];
    responseData.forEach((obj: any, i: Number) => {
      const tmpTargetDate: string = obj.target_ym;
      const sy: number = Number(tmpTargetDate.slice(0, 4));
      const sm: number = Number(tmpTargetDate.slice(4, 6));
      const DateObj: Date = new Date(sy, sm-1, 15, 0, 0, 0);  // JavaScriptにおけるDate型の月は0始まりであるため実際の月-1する
      
      if(TargetDateListForCompare.indexOf(DateObj) == -1){
        TargetDateListForCompare.push(DateObj);
      }
      
      obj.contents.forEach((childObj: any, j: Number) => {
        if (childObj.file_type === "請求書"){
          const tmpInvoiceNo: string = childObj.document_id;
          if(tmpInvoiceNoList.indexOf(tmpInvoiceNo) == -1){
            tmpInvoiceNoList.push(tmpInvoiceNo);
          }
        }
      })
    })
    
    const maxDate = new Date(Math.max.apply(null, TargetDateListForCompare.map(date => date.getTime())));
    const minDate = new Date(Math.min.apply(null, TargetDateListForCompare.map(date => date.getTime())));
    let currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      tmpTargetDateList.push(`${year}` + '/' + `${month}`.padStart(2,"0"));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    tmpTargetDateList = tmpTargetDateList.reverse();
    
    // 更新処理は最後に一括で代入する
    setTargetDateList([...tmpTargetDateList]);
    setInvoiceNoList(tmpInvoiceNoList);
  }
  
  const resetData = () => {
    setSearchedData([...defaultData]);
    setSearchCondition({...searchCondition, startDate: "1000/1", endDate: "9998/12", invoiceNo: [], targetFile: initTargetFile});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: InvoiceRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }
  
  const allCheck = (state: boolean) => {
    const updateAllCheck = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: true }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj.contents[0]["document_id"] === sObj.contents[0]["document_id"]);
      return searchedObj ? { ...obj, checked: true } : obj;
    });
    
    const updateAllCancel = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: false }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj.contents[0]["document_id"] === sObj.contents[0]["document_id"]);
      return searchedObj ? { ...obj, checked: false } : obj;
    });
    
    if (state === true){
      setDefaultData(updateAllCheck);
    }
    else if (state === false){
      setDefaultData(updateAllCancel);
    }
  }
  
  const singleCheck = (target_ym: string, document_id: string) => {
    const updatedSingleCheck = defaultData.map((obj) => {
      if (obj["target_ym"] === target_ym && obj.contents[0]["document_id"] === document_id) {
        return { ...obj, checked: !obj["checked"]};
      } else {
        return obj;
      }
    });
    setDefaultData(updatedSingleCheck);
  }
  
  // DBの請求書を表示　
  const getInvoiceData: (startDate: string | null, endDate: string | null) => Promise<InvoiceRecordInterface[]> = async (start, end) => {
    const token = await getCurrentUserToken();
    const response = axios.request({
      url: baseURL + "/billing-inquery-invoice",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params : {
        "contractId" : auth.contractID,
        "startDate": start,
        "endDate": end
      }
    })
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i]["checked"] = false;
      }
      setDefaultData(response.data);
      getInvoiceList(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response;
  };
  
  return {
    searchedData,
    curPageData,
    setCurPageData,
    getInvoiceData,
    page,
    targetDateList,
    invoiceNoList,
    setSearchConditionTargetDate,
    setSearchConditionInvoiceNo,
    setSearchConditionFileType,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck
  };
}
