import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SettingTab from '../components/Tab/SettingTab';
import SnackBar from '../../utils/components/SnackBar';
import SettingElectricityAlarmTable from '../components/SettingElectricityAlarm/SettingElectricityAlarmTable';
import SearchBoxElectricityAlarm from '../components/SettingElectricityAlarm/SearchBoxSettingElectricityArarm';
import SettingElectricityAlarmDialog from '../components/SettingElectricityAlarm/SettingElectricityAlarmDialog';
import { useSettingElectricityAlarm } from "../hooks/useSettingElectricityAlarm";
import { useMultipleChecked } from "../../utils/hooks/useMultipleChecked"
import { ElectricityAlarmDialogInterface } from '../interfaces/SettingElectricityAlarm';
import { StyledDiv, StyledTypograpy, StyledFooter, FooterButton, StyledToolbar } from '../styles/style';
import { ERROR_MESSAGE_18 } from '../../utils/errorMessages';

const itemsPerPage: number = 20;

export function SettingElectricityAlarm() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState<ElectricityAlarmDialogInterface[]>([]);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFailed, setOpenFailed] = useState(false);
    
    const {
        checked,
        toggleChecked,
        allCheck,
        clearCheck 
    } = useMultipleChecked<number>([...Array(itemsPerPage)].map((_, i) => i));
    
    const { 
        page,
        searchedData,
        curPageData,
        getSettingElecticityAlarmData,
        setSearchConditionRequestPointName,
        requestPointNameList,
        setSearchConditionRequestPointId,
        requestPointIdList,
        setSearchConditionSupplyPointNo,
        supplyPointNoList,
        setSearchConditionAddress,
        addressList,
        setSearchConditionFeeMenuNamePrinting,
        feeMenuNamePrintingList,
        setSearchConditionVoltageClass,
        setSearchConditionSupplyDate,
        resetData,
        pagenation,
    } = useSettingElectricityAlarm();
      
    useEffect(() => {
        const getData = async() => {
            try {
                getSettingElecticityAlarmData().then(response => {
                    setIsLoading(false);
                });
            }
            catch(error){
                console.log(error);
            }
        }
        getData();
    }, []);
    
    const handleOpen = () => {
        const records: ElectricityAlarmDialogInterface[] = [];
        checked.forEach((index) => {
            if(curPageData.length > index) records.push({
                userId: curPageData[index].user_id,
                requestPointId: curPageData[index].request_point_id,
                requestPointName: curPageData[index].request_point_name,
                supplyPointNo: curPageData[index].supply_point_no,
                contractPowerUsually: curPageData[index].contract_power_usually,
                feeMenuBaseFeeConfirmValue: curPageData[index].fee_menu_base_fee_confirm_value,
                capacityUnit: curPageData[index].capacity_unit,
                usePowerAlarmValidFlag: "1",
                usePowerAlarmNoticeType: "2",
                usePowerAlarmMailAddress: "",
                usePowerAlarmThreshold: curPageData[index].use_power_alarm_threshold,
            });
        });
        setUserData(records);
        if (checked.length > 0) {
            setOpen(true);
        } else {
            alert(ERROR_MESSAGE_18);
        }
    };
  
    const handleClose = () => setOpen(false);
    
    return (
        <PrivateRoute>
            <div>
                <StyledDiv>
                    <Spacer size={50} />
                    
                    <StyledTypograpy variant="h5">設定</StyledTypograpy>
                    
                    <Spacer size={20} />
                    
                    <SettingTab initPage={"electricity-alarm"} />
                    
                    <Spacer size={20} />
                    
                    <SearchBoxElectricityAlarm 
                        setSearchConditionRequestPointName={setSearchConditionRequestPointName}
                        requestPointNameList={requestPointNameList}
                        setSearchConditionRequestPointId={setSearchConditionRequestPointId}
                        requestPointIdList={requestPointIdList}
                        setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
                        supplyPointNoList={supplyPointNoList}
                        setSearchConditionAddress={setSearchConditionAddress}
                        addressList={addressList}
                        setSearchConditionFeeMenuNamePrinting={setSearchConditionFeeMenuNamePrinting}
                        feeMenuNamePrintingList={feeMenuNamePrintingList}
                        setSearchConditionVoltageClass={setSearchConditionVoltageClass}
                        setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                        resetData={resetData} />
                        
                    <Spacer size={30} />
                    {
                        !isLoading?
                        <SettingElectricityAlarmTable
                            data={curPageData}
                            toggleChecked={toggleChecked}
                            checkState={checked}
                            allCheck={allCheck}
                            clearCheck={clearCheck}
                            searchedData={searchedData}
                            totalDataNum={searchedData.length} 
                            page={page}
                            pagenation={pagenation}
                            getSettingElecticityAlarmData={getSettingElecticityAlarmData}
                            setOpenSuccess={setOpenSuccess}
                            setOpenFailed={setOpenFailed}/>
                        :
                        <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                            <CircularProgress />
                        </div>
                    }
                    
                    
                </StyledDiv>
                <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
                    <StyledToolbar>
                        <FooterButton
                            variant="outlined"
                            onClick={() => handleOpen()}
                            >一括設定</FooterButton>
                            
                            <SettingElectricityAlarmDialog 
                                open={open} 
                                isDisabled={true} 
                                getSettingElecticityAlarmData={getSettingElecticityAlarmData}
                                handleClose={handleClose} 
                                userData={userData}
                                setOpenSuccess={setOpenSuccess}
                                setOpenFailed={setOpenFailed}/>
                    </StyledToolbar>
                </StyledFooter>
                
                <SnackBar
                    successMessage="更新が成功しました"
                    openSuccess={openSuccess}
                    setOpenSuccess={setOpenSuccess}
                    failedMessage="更新が失敗しました"
                    openFailed={openFailed}
                    setOpenFailed={setOpenFailed}/>
            </div>
        </PrivateRoute>
    );
}