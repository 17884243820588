import React, { useState, useEffect, useRef } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AutoComplete from '../../../utils/components/AutoComplete';
import { Spacer } from '../../../utils/components/Spacer';

type Props = {
  setSearchConditionRequestName: (requestName?: string[]) => void;
  requestNameList: string[];
  setSearchConditionRequestUserId: (requestUserId?: string[]) => void;
  requestUserIdList: string[];
  setSearchConditionAuthority: (customer?: boolean, info?: boolean, loginInfo?: boolean, account?: boolean, changePassword?: boolean, billingRelease?: boolean) => void;
  resetData: () => void;
}

export default function SearchBoxManagePersonAccount(props: Props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string[]>([]);
  const [userId, setUserId] = useState<string[]>([]);
  // チェックボックス3つ分
  const [checkedCustomer, setCheckedCustomer] = useState(true);
  const [checkedInfo, setCheckedInfo] = useState(true);
  const [checkedLoginInfo, setCheckedLoginInfo] = useState(true);
  const [checkedAccount, setCheckedAccount] = useState(true);
  const [checkedChangePassword, setCheckedChangePassword] = useState(true);
  const [checkedBillingRelease, setCheckedBillingRelease] = useState(true);
  
  const {
    setSearchConditionRequestName,
    requestNameList, 
    setSearchConditionRequestUserId,
    requestUserIdList,
    setSearchConditionAuthority,
    resetData
  } = props;

  const handleClick = () => {
    setOpen(!open);
  };
  
  useEffect(() => {
    setSearchConditionAuthority(checkedCustomer, checkedInfo, checkedLoginInfo, checkedAccount, checkedChangePassword, checkedBillingRelease);
  }, [checkedCustomer, checkedInfo, checkedLoginInfo, checkedAccount, checkedChangePassword, checkedBillingRelease])
  
  const resetConditionOnClick = () => {
    setName([]);
    setUserId([]);
    setCheckedCustomer(true);
    setCheckedInfo(true);
    setCheckedLoginInfo(true);
    setCheckedAccount(true);
    setCheckedChangePassword(true);
    setCheckedBillingRelease(true);
    resetData();
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#172B65', color: '#FFFFFF', '&:hover': { backgroundColor: '#172B65' } }} onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary="絞り込み" />
      </ListItemButton>
      {open &&
        <Paper sx={{ backgroundColor: "#C9CACA26", height: 220, padding: "0 40px" }} variant="outlined" square >
          <Spacer size={10} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="body1">アカウントID</Typography>
              <AutoComplete
                value={userId}
                setValue={setUserId}
                requestList={requestUserIdList}
                setSearchConditionRequest={setSearchConditionRequestUserId}/>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">名称</Typography>
              <AutoComplete
                value={name}
                setValue={setName}
                requestList={requestNameList}
                setSearchConditionRequest={setSearchConditionRequestName}/>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">権限</Typography>
              <Stack direction="row" spacing={1}>
                <FormControlLabel control={<Checkbox checked={checkedCustomer} onChange={(event) => setCheckedCustomer(event.target.checked)} />} label="お客さま管理" />
                <FormControlLabel control={<Checkbox checked={checkedInfo} onChange={(event) => setCheckedInfo(event.target.checked)} />} label="お知らせ管理" />
                <FormControlLabel control={<Checkbox checked={checkedLoginInfo} onChange={(event) => setCheckedLoginInfo(event.target.checked)} />} label="ログインお知らせ管理" />
                <FormControlLabel control={<Checkbox checked={checkedAccount} onChange={(event) => setCheckedAccount(event.target.checked)} />} label="アカウント管理" />
                <FormControlLabel control={<Checkbox checked={checkedChangePassword} onChange={(event) => setCheckedChangePassword(event.target.checked)} />} label="パスワード変更" />
                <FormControlLabel control={<Checkbox checked={checkedBillingRelease} onChange={(event) => setCheckedBillingRelease(event.target.checked)} />} label="請求公開" />
              </Stack>
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={resetConditionOnClick}>検索条件をクリア</Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      }
    </div>
  );
}