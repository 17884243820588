import { useAuth } from "../../utils/hooks/use-auth";
import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { SettingBillingPasswordRecordInterface } from "../interfaces/SettingBillingPassword";

type useSettingBillingPassowrdSet<T> = {
    page: number,
    data: T[],
    curPageData: T[],
    setData: (data:T[]) => void,
    pagenation: (curPage: number) => void,
    getSettingBillingPassword:  () => Promise<T[]>
    totalDataNum: number,
    isLoading: boolean,
    isProcessing: boolean,
    idPassCheck: (userId: string, mailAddress: string) => void,
    sendIdNotificationMail: (userId: string) => void
    mailSuccess: boolean,
    mailFailed: boolean,
    setMailSuccess: (mailSuccess: boolean) => void;
    setMailFailed: (mailFailed: boolean) => void;
}
// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = 20;
// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

export function useSettingBillingPassword(): useSettingBillingPassowrdSet<SettingBillingPasswordRecordInterface> {
    const auth = useAuth();
    const [mailSuccess, setMailSuccess] = useState(false);
    const [mailFailed, setMailFailed] = useState(false);
    // ローディング
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    // ページ番号を保持する変数
    const [page, setPage] = useState<number>(1);
    const [data, setData] = useState<SettingBillingPasswordRecordInterface[]>([]);
  　const [curPageData, setCurPageData] = useState<SettingBillingPasswordRecordInterface[]>([]);
  　const [totalDataNum, setTotalDataNum] = useState<number>(0);
  　
  　const pagenation = (curPage: number) => {
      const head = (curPage - 1) * itemsPerPage;
      const targetPage: SettingBillingPasswordRecordInterface[] = data.slice(head, (head + itemsPerPage));
      setCurPageData([...targetPage]);
      setPage(curPage);
    }
    
    useEffect(() => {
      setTotalDataNum(data.length);
      pagenation(1);
    },[data]);
    
    useEffect(() => {
      getSettingBillingPassword();
    }, []);
    
    const getSettingBillingPassword = async() => {
      const token = await getCurrentUserToken();
      const response = axios.request({
        url: baseURL + "/get-setting-demand-location",
        method: "get",
        headers: { 
          Authorization: `${token}`,
        },
        params: {
          'contract_id': auth.contractID
        }
      })
      .then((response) => {
        let result = response.data.data;
        setData(result);
        setIsLoading(false);
        return result
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
      return response;
    }
    // IDPassチェック
    const idPassCheck = async (userId: string, mailAddress: string) => {
      setIsProcessing(true);
      const token = await getCurrentUserToken();
      axios.request({
        url: baseURL + "/id-pass-check",
        method: "post",
        headers: {
          Authorization: `${token}`,
        },
        data: {
          "user": typeof(auth.customerUserId) === "undefined"? auth.username : auth.customerUserId,
          "user_id": userId,
          "mail_address": mailAddress
        }
      }).then(async (response) => {
        await sendIdNotificationMail(userId);
      }).catch((error) => {
        setIsProcessing(false);
        setMailFailed(true);
      })
    }
    // ID通知メール送信
    const sendIdNotificationMail = async (userId: string) => {
      setIsProcessing(true);
      const token = await getCurrentUserToken();
      // ID通知メールの送信を行う
      axios.request({
        url: baseURL + "/send-mail",
        method: "post",
        headers: {
          Authorization: `${token}`,
        },
        data: {
          "insUpdKbn": "1",
          "userId": userId,
          "caseNo": "4"
        }
      })
      .then((response) => {
        axios.request({
          url: baseURL + "/send-mail",
          method: "post",
          headers: {
              Authorization: `${token}`,
          },
          data: {
              "mail_send_manage_no": response.data.data.mail_send_manage_no,
              "caseNo": "2"
          }
        })
        .then((response) => {
          console.log(response);
          curPageData.map((item, index) => {
            if (item["user_id"] == userId) {
              item["id_notice_status_code"] = "1";
              setIsProcessing(false);
              setMailSuccess(true);
            }
          });
        })
        .catch((error) => {
          console.log(error);
          setIsProcessing(false);
          setMailFailed(true);
        })
      })
      .catch((error) => {
        console.log(error);
        setIsProcessing(false);
        setMailFailed(true);
      })
    
    }
    return {
        page,
        data,
        curPageData,
        setData,
        pagenation,
        getSettingBillingPassword,
        totalDataNum,
        isLoading,
        isProcessing,
        sendIdNotificationMail,
        idPassCheck,
        mailSuccess,
        mailFailed,
        setMailSuccess,
        setMailFailed
    }
}