import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(AppBar)(({ theme }) => ({
    height: 52,
    position: 'absolute',
    top: 'auto',
    bottom: 0,
    width: "100%",
    backgroundColor: '#0098CB',
    color: '#FFFFFF',
    boxShadow: 'none',
}));

const FooterContent = styled('div')(({ theme }) => ({
    maxWidth: '1660px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: "0 auto",
    padding: '0 30px',
}));

export default function Footer() {
    return (
        <StyledFooter>
            <FooterContent>
                <Typography variant="caption">Copyright © 2023 ENERES Co.,Ltd. All rights reserved.</Typography>
            </FooterContent>        
        </StyledFooter>
    );
}