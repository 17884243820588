import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TableSortLabel, { TableSortLabelProps } from '@mui/material/TableSortLabel';

export const StyledDiv = styled('div')(({ theme }) => ({
    width: "100%",
    maxWidth: "1660px",
    margin: "0 auto",
    padding: "0 30px 0 30px",
}));

export const StyledTypograpy = styled(Typography)(({ theme }) => ({
    color: "#333333",
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root': {
        backgroundColor: "#CCCCDB",
        color: "#0099CC",
        fontFamily: "NotoSansJP-Bold",
        fontWeight: 700,
    },
    '&.Mui-selected': {
        backgroundColor: "#005491",
        color: "#FFFFFF",
        fontFamily: "NotoSansJP-Bold",
        fontWeight: 700,
    }
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
    textAlign: "center",
    color: "#FFFFFF",
    backgroundColor: "#0098CB",
    width: "220px",
    height: "40px",
    marginLeft: "auto",
    '&:hover': {
        backgroundColor: "#008AB8"
    }
}));

export const StyledHeader = styled(AppBar)(({ theme }) => ({
  height: 70,
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#0098CB',
  color: '#FFF',
  boxShadow: 'none',
}));

export const StyledFooter = styled(AppBar)(({ theme }) => ({
  height: 80,
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(0, 80, 108, 0.9)',
  opacity: 0.9,
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: 'auto',
}));

interface StyledTableSortLabelProps extends TableSortLabelProps {
  hideSortIcon?: boolean;
}

export const StyledTableSortLabel = styled(TableSortLabel)<StyledTableSortLabelProps>(({ theme, hideSortIcon }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: hideSortIcon ? 0 : 1,
    color: "#DADADA",
  },
}));

export const FooterButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#0098CB',
  fontWeight: 'bold',
  minWidth: "150px",
  margin: 10,
  padding: 10,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    width: "100%",
}));

export const ErrorTypography = styled(Typography)(({ theme }) => ({
    color: "#E2421F",
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    height: 70,
    position: "relative",
    padding: "0 20px",
    '&:hover': {
        color: "#0098CB",
        backgroundColor: "#F3F3F7",
    }
}));

export const HeaderPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: "#F3F3F7",
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: 1,
    padding: "20px",
}));

export const LogoutButton = styled(Button)(({ theme }) => ({
    color: '#333333',
    backgroundColor: '#FAC031',
    '&:hover': {
        backgroundColor: "#E1AD2C"
    }
}));

export const LoginButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        width: "100%",
        maxWidth: "320px",
        height: "50px",
        backgroundColor: "#00506B",
        fontFamily: "NotoSansJP-Bold",
        fontWeight: 700,
        textaAlign: "center",
        color: "#FFFFFF",
    },
    '&.Mui-focusVisible': {
        backgroundColor: "#00506B",
    },
}));

export const LoginLink = styled(Link)(({ theme }) => ({
    fontWeight: 400,
    color: "#00506B",
}));

export const LoginBox = styled(Box)(({ theme }) => ({
    width: "40%",
    backgroundColor: "#CCCCDB",
    minWidth: "480px",
    padding: "52px 0",
    marginRight: "40px"
}));

export const LoginInformation = styled(Box)(({ theme }) => ({
    width: "60%",
    minWidth: "600px",
    border: "1px solid #DFDFED",
    height: "370px",
    overflowY: "auto",
    padding: "0 40px",
}));

export const InformationTypograpy = styled(Typography)(({ theme }) => ({
    fontFamily: "NotoSansJP-Medium",
    fontWeight: 500,
    textAlign: "left",
}));

export const NewsDateTypograpy = styled(Typography)(({ theme }) => ({
    fontFamily: "Arial",
    fontWeight: 400,
    marginRight: "30px",
    textAlign: "left",
    color: "#808080",
}));

export const NewsModalBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "480px",
    padding: "20px 40px",
    textAlign: "left",
    border: "1px solid #DFDFED",
    overflow: "hidden",
    overflowY: "auto"
}));

export const MenuDiv = styled('div')(({ theme }) => ({
    width: "100%",
    maxWidth: "1180px",
    margin: "0 auto",
    padding: "50px 30px",
}));

export const MenuItemPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: "174px",
    '&:hover': {
        backgroundColor: "#F7F7F7"
    },
    cursor: "pointer"
}));

export const MenuItemIcon = styled('img')(({ theme }) => ({
    display: "block",
    width: "60px",
    height: "60px",
    margin: "0 auto",
}));

export const MenuItemTypography = styled(Typography)(({ theme }) => ({
    display: "block",
    width: "100%",
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
    textAlign: "center",
    color: "#00506B",
    marginTop: "15px",
}));

export const SelectTitleTypograpy = styled(Typography)(({ theme }) => ({
    color: "#333333",
    fontWeight: 700,
    marginRight: "10px",
}));

export const UsingAchievementDiv = styled('div')(({ theme }) => ({
    width: "100%",
    maxWidth: "1660px",
    margin: "0 auto",
    padding: "0 30px 0 30px",
}));

export const UsingAchievementDialogBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#F2F2F2",
    maxHeight: "518px",
    padding: "20px 40px",
    alignItems: "center",
}));

export const UsingAchievementTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: "nowrap",
    fontWeight: "bold",
}));

export const ElectricityAlarmTableButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: '#0098CB',
    fontWeight: 'bold',
    margin: 10,
    padding: 10,
    '&:hover': {
        backgroundColor: "#E6E6E6"
    },
}));

export const ElectricityAlarmCancelButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    backgroundColor: '#FFFFFF',
    color: '#0098CB',
    width: "160px",
    height: "44px",
    '&:hover': {
        backgroundColor: "#E6E6E6"
    },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    backgroundColor: '#FFFFFF',
    color: '#172B65',
    width: "160px",
    height: "44px",
    '&:hover': {
        backgroundColor: "#E6E6E6"
    },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    backgroundColor: '#0098CB',
    color: '#FFFFFF',
    width: "160px",
    height: "44px",
    marginLeft: "20px",
    '&:hover': {
        backgroundColor: "#008AB8"
    },
}));

export const ElectricityAlarmModalBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "430px",
    padding: "20px 40px",
    textAlign: "left",
    border: "1px solid #DFDFED",
    overflow: "hidden",
    overflowY: "auto"
}));

export const SettingMailBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "70px",
    width: "180px",
    textAlign: "center",
    justifyContent: "center",
    border: "1px solid #DFDFED",
    backgroundColor: "#CBE8FA",
}));

export const SettingMailTextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "70px",
    width: "640px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DFDFED",
}));

export const ForgotPasswordForm = styled('form')(({ theme }) => ({
    float: "left",
    height: "300px",
    backgroundColor: "white"
}))

export const ForgotPasswordMain = styled('div')(({ theme }) => ({
    marginBottom: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

export const ForgetPasswordFooter = styled('div')(({ theme }) => ({
    textAlign: "center",
    paddingBottom: "40px"
}))

export const SettingBillingPasswordButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: '#005491',
    fontWeight: 'bold',
    margin: 10,
    padding: 10,
    '&:hover': {
        backgroundColor: "#E6E6E6"
    },
}));

export const SettingIDNotificationSaveButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    backgroundColor: '#0098CB',
    color: '#FFFFFF',
    margin: 10,
    padding: 10,
    '&:hover': {
        backgroundColor: "#008AB8"
    },
}));