import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomerManagementDialog from './CustomerManagementDialog';
import { Spacer } from '../../../utils/components/Spacer';
import { CustomerManagementRecordInterface, CustomerManagementDialogInterface } from '../../interfaces/CustomerManagement';
import { ManagePersonAccountTableButton, StyledTableSortLabel } from '../../styles/kanriStyle';

interface Order {
  user_id: 'asc' | 'desc';
  user_type_code: 'asc' | 'desc';
  contract_name: 'asc' | 'desc';
  request_point_name: 'asc' | 'desc';
  supply_start_date: 'asc' | 'desc';
  contract_id: 'asc' | 'desc';
  request_point_id: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface CustomerManagementTable {
  data: CustomerManagementRecordInterface[];
  searchedData: CustomerManagementRecordInterface[];
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  getCustomerManagementData: () => void;
  setIsLoading: (isLoading: boolean) => void;
  setEditSuccess: (editSuccess: boolean) => void;
  setEditFailed: (editFailed: boolean) => void;
  setMailSuccess: (mailSuccess: boolean) => void;
  setMailFailed: (mailFailed: boolean) => void;
}

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function CustomerManagementTable(props: CustomerManagementTable) {
  const { data, searchedData, page, pagenation, getCustomerManagementData, setIsLoading, setEditSuccess, setEditFailed, setMailSuccess, setMailFailed } = props;
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState<Order>({
    user_id: 'asc',
    user_type_code: 'asc',
    contract_name: 'asc',
    request_point_name: 'asc',
    supply_start_date: 'asc',
    contract_id: 'asc',
    request_point_id: 'asc'
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('contract_id');
  const [userData, setUserData] = useState<CustomerManagementDialogInterface>({
    userId: "",
    userType: "",
    contractId: "",
    contractName: "",
    requestPointId: "",
    requestPointName: "",
    supplyPointNo: "",
    feeMenuNamePrinting: "",
    contractCapacity: "",
    supplyStartDate: "",
    contractEndDate: "",
    mailAddress: "",
    mailSendNecessityCode: "",
    idNoticeStatusCode: "",
    validPeriodDate: "",
    lastLoginDatetime: "",
    authority: ""
  });
  
  const handleOpen = (userId: string, userType: string, contractId: string, contractName: string, requestPointId: string, requestPointName: string, supplyPointNo: string, 
                      feeMenu: string, contractCapacity: string, start: string, end: string, mail: string, mailSendCode: string, idStatus: string, periodDate: string, lastLoginDatetime: string, authority: string) => {
    const records = {
      userId: userId,
      userType: userType,
      contractId: contractId,
      contractName: contractName,
      requestPointId: requestPointId,
      requestPointName: requestPointName,
      supplyPointNo: supplyPointNo,
      feeMenuNamePrinting: feeMenu,
      contractCapacity: contractCapacity,
      supplyStartDate: start,
      contractEndDate: end,
      mailAddress: mail,
      mailSendNecessityCode: mailSendCode,
      idNoticeStatusCode: idStatus,
      validPeriodDate: periodDate,
      lastLoginDatetime: lastLoginDatetime,
      authority: authority
    };
    setUserData(records);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  // カラムごとによる行の並び替え
  const compare = (a: CustomerManagementRecordInterface, b: CustomerManagementRecordInterface): number => {
    if (orderBy === 'user_id') {
      if (a.user_id < b.user_id) {
        return order.user_id === 'asc' ? -1 : 1;
      }
      if (a.user_id > b.user_id) {
        return order.user_id === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'user_type_code') {
      if (a.user_type_code < b.user_type_code) {
        return order.user_type_code === 'asc' ? -1 : 1;
      }
      if (a.user_type_code > b.user_type_code) {
        return order.user_type_code === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_name') {
      if (a.contract_name < b.contract_name) {
        return order.contract_name === 'asc' ? -1 : 1;
      }
      if (a.contract_name > b.contract_name) {
        return order.contract_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'request_point_name') {
      if (a.request_point_name < b.request_point_name) {
        return order.request_point_name === 'asc' ? -1 : 1;
      }
      if (a.request_point_name > b.request_point_name) {
        return order.request_point_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_start_date') {
      if (a.supply_start_date < b.supply_start_date) {
        return order.supply_start_date === 'asc' ? -1 : 1;
      }
      if (a.supply_start_date > b.supply_start_date) {
        return order.supply_start_date === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_id') {
      if (a.contract_id < b.contract_id) {
        return order.contract_id === 'asc' ? -1 : 1;
      }
      if (a.contract_id > b.contract_id) {
        return order.contract_id === 'asc' ? 1 : -1;
      }
      if (a.contract_id === b.contract_id) {
        if (a.user_type_code < b.user_type_code) {
          return order.user_type_code === 'asc' ? -1 : 1;
        }
        if (a.user_type_code > b.user_type_code) {
          return order.user_type_code === 'asc' ? 1 : -1;
        }
      }
    }
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+data.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'user_id'}
                  direction={order.user_id}
                  onClick={() => handleRequestSort('user_id')}>ユーザID</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'user_type_code'}
                  direction={order.user_type_code}
                  onClick={() => handleRequestSort('user_type_code')}>ユーザ種別</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_name'}
                  direction={order.contract_name}
                  onClick={() => handleRequestSort('contract_name')}>契約名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_name'}
                  direction={order.request_point_name}
                  onClick={() => handleRequestSort('request_point_name')}>需要場所名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_start_date'}
                  direction={order.supply_start_date}
                  onClick={() => handleRequestSort('supply_start_date')}>供給期間</StyledTableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#D9E1FB' : '#FFFFFF' }}
              >
                <TableCell>{row.user_id}</TableCell>
                <TableCell>{row.user_type_code === "01"? "契約単位" : "需要場所単位"}</TableCell>
                <TableCell>{row.contract_name}</TableCell>
                <TableCell>{row.request_point_name}</TableCell>
                <TableCell>{`${row.supply_start_date === null? "": row.supply_start_date}~${row.user_type_code === "01"? "": row.contract_end_date === "2099/12/31"? "": row.contract_end_date}`}</TableCell>
                <TableCell>
                  <ManagePersonAccountTableButton
                    variant="outlined"
                    onClick={() => handleOpen(
                      row.user_id,
                      row.user_type_code === "01"? "契約単位" : "需要場所単位",
                      row.contract_id,
                      row.contract_name,
                      row.request_point_id,
                      row.request_point_name,
                      row.supply_point_no,
                      row.fee_menu_name_printing,
                      row.contract_power_usually !== ""? Math.floor(parseFloat(row.contract_power_usually)).toString() + row.capacity_unit
                      : row.fee_menu_base_fee_confirm_value !== ""? Math.floor(parseFloat(row.fee_menu_base_fee_confirm_value)).toString() + row.capacity_unit : "",
                      row.supply_start_date,
                      row.contract_end_date,
                      row.mail_address,
                      row.mail_send_necessity_code,
                      row.id_notice_status_code,
                      row.valid_period_date,
                      row.last_login_datetime,
                      row.authority
                    )}
                    >設定</ManagePersonAccountTableButton>
                    
                  <CustomerManagementDialog
                    open={open}
                    getCustomerManagementData={getCustomerManagementData}
                    handleClose={handleClose}
                    userData={userData}
                    setIsLoading={setIsLoading}
                    setEditSuccess={setEditSuccess}
                    setEditFailed={setEditFailed}
                    setMailSuccess={setMailSuccess}
                    setMailFailed={setMailFailed}/> 
                    
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (data.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      
      <Spacer size={20} />
      
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}