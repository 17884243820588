import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ManagePersonAccountDialog from './ManagePersonAccountDialog';
import { ManagePersonAccountRecordInterface, ManagePersonAccountDialogInterface } from '../../interfaces/ManagePersonAccount';
import { ManagePersonAccountTableButton, StyledTableSortLabel } from '../../styles/kanriStyle';
import dayjs from 'dayjs';

interface Order {
  user_id: 'asc' | 'desc';
  name: 'asc' | 'desc';
  customer: 'asc' | 'desc';
  info: 'asc' | 'desc';
  loginInfo: 'asc' | 'desc';
  account: 'asc' | 'desc';
  changePassword: 'asc' | 'desc';
  billingRelease: 'asc' | 'desc';
  login_last_datetime: 'asc' | 'desc';
  valid_period_start_date: 'asc' | 'desc';
  valid_period_end_date: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface ManagePersonAccountTableProps {
  data: ManagePersonAccountRecordInterface[];
  searchedData: ManagePersonAccountRecordInterface[];
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  getManagePersonAccountData: () => void;
  setOpenSuccess: (openSuccess: boolean) => void;
  setOpenFailed: (openFailed: boolean) => void;
}

const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 5;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function ManagePersonAccountTable(props: ManagePersonAccountTableProps) {
  const { data, searchedData, page, pagenation, getManagePersonAccountData, setOpenSuccess, setOpenFailed } = props;
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState<Order>({
    user_id: 'asc',
    name: 'asc',
    customer: 'asc',
    info: 'asc',
    loginInfo: 'asc',
    account: 'asc',
    changePassword: 'asc',
    billingRelease: 'asc',
    login_last_datetime: 'asc',
    valid_period_start_date: 'asc',
    valid_period_end_date: 'asc'
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('valid_period_start_date');
  const [userData, setUserData] = useState<ManagePersonAccountDialogInterface[]>([]);
  
  const handleOpen = (userId: string, name: string, mail: string, authority: string, start: string, end: string, remarks: string) => {
    const records = [{
      userId: userId,
      name: name,
      mailAddress: mail,
      authority: authority,
      validPeriodStartDate: start,
      validPeriodEndDate: end,
      remarks: remarks
    }];
    setUserData(records);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  // カラムごとによる行の並び替え
  const compare = (a: ManagePersonAccountRecordInterface, b: ManagePersonAccountRecordInterface): number => {
    if (orderBy === 'user_id') {
      if (a.user_id < b.user_id) return order.user_id === 'asc' ? -1 : 1;
      if (a.user_id > b.user_id) return order.user_id === 'asc' ? 1 : -1;
    } else if (orderBy === 'name') {
      if (a.name < b.name) return order.name === 'asc' ? -1 : 1;
      if (a.name > b.name) return order.name === 'asc' ? 1 : -1;
    } else if (orderBy === 'customer') {
      if (a.authority[0] < b.authority[0]) return order.customer === 'asc' ? -1 : 1;
      if (a.authority[0] > b.authority[0]) return order.customer === 'asc' ? 1 : -1;
    } else if (orderBy === 'info') {
      if (a.authority[1] < b.authority[1]) return order.info === 'asc' ? -1 : 1;
      if (a.authority[1] > b.authority[1]) return order.info === 'asc' ? 1 : -1;
    } else if (orderBy === 'loginInfo') {
      if (a.authority[2] < b.authority[2]) return order.loginInfo === 'asc' ? -1 : 1;
      if (a.authority[2] > b.authority[2]) return order.loginInfo === 'asc' ? 1 : -1;
    } else if (orderBy === 'account') {
      if (a.authority[3] < b.authority[3]) return order.account === 'asc' ? -1 : 1;
      if (a.authority[3] > b.authority[3]) return order.account === 'asc' ? 1 : -1;
    } else if (orderBy === 'changePassword') {
      if (a.authority[4] < b.authority[4]) return order.changePassword === 'asc' ? -1 : 1;
      if (a.authority[4] > b.authority[4]) return order.changePassword === 'asc' ? 1 : -1;
    } else if (orderBy === 'billingRelease') {
      if (a.authority[5] < b.authority[5]) return order.billingRelease === 'asc' ? -1 : 1;
      if (a.authority[5] > b.authority[5]) return order.billingRelease === 'asc' ? 1 : -1;
    } else if (orderBy === 'login_last_datetime') {
      if (a.login_last_datetime < b.login_last_datetime) return order.login_last_datetime === 'asc' ? -1 : 1;
      if (a.login_last_datetime > b.login_last_datetime) return order.login_last_datetime === 'asc' ? 1 : -1;
    } else if (orderBy === 'valid_period_start_date') {
      if (a.valid_period_start_date < b.valid_period_start_date) return order.valid_period_start_date === 'asc' ? -1 : 1;
      if (a.valid_period_start_date > b.valid_period_start_date) return order.valid_period_start_date === 'asc' ? 1 : -1;
      if (a.valid_period_start_date === b.valid_period_start_date) {
        if (a.valid_period_end_date < b.valid_period_end_date) return order.valid_period_end_date === 'asc' ? -1 : 1;
        if (a.valid_period_end_date > b.valid_period_end_date) return order.valid_period_end_date === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+data.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'user_id'}
                  direction={order.user_id}
                  onClick={() => handleRequestSort('user_id')}>アカウントID</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'name'}
                  direction={order.name}
                  onClick={() => handleRequestSort('name')}>名称</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'customer'}
                  direction={order.customer}
                  onClick={() => handleRequestSort('customer')}>
                   <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>お客さま管理</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'info'}
                  direction={order.info}
                  onClick={() => handleRequestSort('info')}>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>お知らせ管理</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'loginInfo'}
                  direction={order.loginInfo}
                  onClick={() => handleRequestSort('loginInfo')}>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>ログイン</Typography>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>お知らせ管理</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'account'}
                  direction={order.account}
                  onClick={() => handleRequestSort('account')}>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>管理者</Typography>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>アカウント管理</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'changePassword'}
                  direction={order.changePassword}
                  onClick={() => handleRequestSort('changePassword')}>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>パスワード変更</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'billingRelease'}
                  direction={order.billingRelease}
                  onClick={() => handleRequestSort('billingRelease')}>
                    <Typography sx={{writingMode: "vertical-rl", textOrientation: "upright"}}>請求公開</Typography>
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'login_last_datetime'}
                  direction={order.login_last_datetime}
                  onClick={() => handleRequestSort('login_last_datetime')}>最終ログイン日時</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'valid_period_start_date'}
                  direction={order.valid_period_start_date}
                  onClick={() => handleRequestSort('valid_period_start_date')}>有効期限（開始日～終了日）</StyledTableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#D9E1FB' : '#FFFFFF' }}
              >
                <TableCell>{row.user_id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.authority[0] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.authority[1] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.authority[2] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.authority[3] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.authority[4] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.authority[5] === "1"? "〇": "-"}</TableCell>
                <TableCell>{row.login_last_datetime}</TableCell>
                <TableCell>{`${dayjs(row.valid_period_start_date).format("YYYY/MM/DD")}~${dayjs(row.valid_period_end_date).format("YYYY/MM/DD") !== "2099/12/31"? dayjs(row.valid_period_end_date).format("YYYY/MM/DD") : ""}`}</TableCell>
                <TableCell>
                  <ManagePersonAccountTableButton
                    variant="outlined"
                    onClick={() => handleOpen(
                      row.user_id,
                      row.name,
                      row.mail_address,
                      row.authority,
                      row.valid_period_start_date,
                      row.valid_period_end_date,
                      row.remarks)}
                    >編集</ManagePersonAccountTableButton>
                  
                  <ManagePersonAccountDialog
                      open={open}
                      getManagePersonAccountData={getManagePersonAccountData}
                      handleClose={handleClose}
                      userData={userData}
                      setOpenSuccess={setOpenSuccess}
                      setOpenFailed={setOpenFailed}/>  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (data.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}