import { useState, useEffect } from "react";
import axios from "axios";
import * as pako from 'pako';
import dayjs, { Dayjs } from 'dayjs';
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { InformationRegistrationRecordInterface, SearchCondition } from '../interfaces/InformationRegistration'
 
type useInformationRegistrationRet<T> = {
  page: number,
  defaultData: T[],
  searchedData: T[],
  curPageData: T[],
  checkedData: T[],
  getInformationRegistrationData:  (userIds: {user_id: string}[]) => Promise<T[]>;
  setSearchConditionUserId: (userId?: string) => void;
  setSearchConditionContractId: (contractId?: string) => void;
  setSearchConditionContractName: (contractName?: string) => void;
  setSearchConditionRequestPointId: (requestPointId?: string) => void;
  setSearchConditionRequestPointName: (requestPointName?: string) => void;
  setSearchConditionSupplyDate: (startHead: Dayjs | null, startTail: Dayjs | null, EndHead: Dayjs | null, EndTail: Dayjs | null) => void;
  setSearchConditionUnit: (contractUnit?: boolean, requestPointUnit?: boolean) => void;
  searchData: () => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (contract_id: string, request_point_id: string, user_type_code: string) => void;
};

const initSearchCondition = {
  userId: "",
  contractId: "",
  contractName: "",
  requestPointId: "",
  requestPointName: "",
  supplyStartDate: {head: null, tail: null},
  supplyEndDate: {head: dayjs(), tail: null},
  searchUnit: {contractUnit: true, requestPointUnit: true}
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = 20;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useInformationRegistration(): useInformationRegistrationRet<InformationRegistrationRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<InformationRegistrationRecordInterface[]>([]); // useState<Uint8Array>(new Uint8Array());
  const [searchedData, setSearchedData] = useState<InformationRegistrationRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<InformationRegistrationRecordInterface[]>([]);
  const [checkedData, setCheckedData] = useState<InformationRegistrationRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSearchCondition});
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  useEffect(() => {
    if (defaultData.length > 0) searchData();
    setCheckedData(defaultData.filter((obj) => obj.checked === true));
  }, [defaultData]);
  
  // リセットした際に検索条件が終わった後に、searchData()が動くように制御
  useEffect(() => {
    if (resetState) {
      searchData();
      setResetState(false);
    }
    pagenation(1);
  }, [searchCondition, resetState]);
  
  const setSearchConditionUserId = (userId: string = "") => {
    if(userId !== "") setSearchCondition({...searchCondition, userId: userId});
    else setSearchCondition({...searchCondition, userId: ""});
  }
  
  const setSearchConditionContractId = (contractId: string = "") => {
    if(contractId !== "") setSearchCondition({...searchCondition, contractId: contractId});
    else setSearchCondition({...searchCondition, contractId: ""});
  }
  
  const setSearchConditionContractName = (contractName: string = "") => {
    if(contractName !== "") setSearchCondition({...searchCondition, contractName: contractName});
    else setSearchCondition({...searchCondition, contractName: ""});
  }
  
  const setSearchConditionRequestPointId = (requestPointId: string = "") => {
    if(requestPointId != "") setSearchCondition({...searchCondition, requestPointId: requestPointId});
    else setSearchCondition({...searchCondition, requestPointId: ""});
  }
  
  const setSearchConditionRequestPointName = (requestPointName: string = "") => {
    if(requestPointName !== "") setSearchCondition({...searchCondition, requestPointName: requestPointName});
    else setSearchCondition({...searchCondition, requestPointName: ""});
  }
  
  const setSearchConditionSupplyDate = (startHead: Dayjs|null = null, startTail: Dayjs|null = null, EndHead: Dayjs|null = null, EndTail: Dayjs|null = null ) => {
    setSearchCondition({...searchCondition, supplyStartDate: {head: startHead, tail: startTail}, supplyEndDate: {head: EndHead, tail: EndTail}});

    if(startHead !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, head: startHead}});
    }
    if(startTail !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, tail: startTail}});
    }
    if(EndHead !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, head: EndHead}});
    }
    if(EndTail !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, tail: EndTail}});
    }
  }
  
  const setSearchConditionUnit = (contractUnit: boolean = false, requestPointUnit: boolean = false) => {
    const targetUnit = { contractUnit, requestPointUnit };
    setSearchCondition({...searchCondition, searchUnit: targetUnit});
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: InformationRegistrationRecordInterface[] = [];
    let searchedData2: InformationRegistrationRecordInterface[] = [];
    
    searchedData1 = defaultData
    
    /*ユーザIDによる検索処理*/
    if(searchCondition.userId !== ""){
      searchedData1.forEach((item, index) => {
        if(searchCondition.userId === item.user_id){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*契約IDによる検索処理*/
    if(searchCondition.contractId !== ""){
      searchedData2.forEach((item, index) => {
        if(searchCondition.contractId === item.contract_id){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
  
    /*契約名による検索処理*/
    if(searchCondition.contractName !== ""){
      searchedData1.forEach((item, index) => {
        if(item.contract_name && item.contract_name.includes(searchCondition.contractName)){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*需要場所IDによる検索処理*/
    if(searchCondition.requestPointId !== ""){
      searchedData2.forEach((item, index) => {
        if(searchCondition.requestPointId === item.request_point_id){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
  
    /*需要場所による検索処理*/
    if(searchCondition.requestPointName !== ""){
      searchedData1.forEach((item, index) => {
        if(item.request_point_name && item.request_point_name.includes(searchCondition.requestPointName)){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /* ユーザ種別による検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchUnit.contractUnit && item.user_type_code === "01") pushFlag = true;
      else if(searchCondition.searchUnit.requestPointUnit && item.user_type_code === "02") pushFlag = true;
      
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    
    // 契約開始日による検索処理関数
    const searchSupplyStartDate = (s: Dayjs | null, e: Dayjs | null, searched: InformationRegistrationRecordInterface[]): InformationRegistrationRecordInterface[] => {
      let tmpData: InformationRegistrationRecordInterface[] = [];
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD") && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.supply_start_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.supply_start_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約終了日による検索処理関数
    const searchSupplyEndDate = (s: Dayjs | null, e: Dayjs | null, searched: InformationRegistrationRecordInterface[]): InformationRegistrationRecordInterface[] => {
      let tmpData: InformationRegistrationRecordInterface[] = [];
      // nullチェック
      if(s !== null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD") && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null && obj.contract_end_date >= dayjs(s).format("YYYY/MM/DD")) tmpData.push(obj);
        })
      } else if(s === null) {
        searched.forEach((obj) => {
          if(e !== null && obj.contract_end_date <= dayjs(e).format("YYYY/MM/DD")) tmpData.push(obj);
          else if(e === null) tmpData.push(obj);
        })
      }
      return tmpData
    }
    
    // 契約開始日による検索処理
    searchedData1 = searchSupplyStartDate(searchCondition.supplyStartDate.head, searchCondition.supplyStartDate.tail, searchedData1);
    
    // 契約終了日による検索処理
    searchedData1 = searchSupplyEndDate(searchCondition.supplyEndDate.head, searchCondition.supplyEndDate.tail, searchedData1);
    
    setSearchedData([...searchedData1]);
  }
  
  const resetData = () => {
    setSearchCondition({...searchCondition, ...initSearchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const allCheck = (state: boolean) => {
    const updateAllCheck = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: true }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["user_type_code"] === sObj["user_type_code"] && obj["contract_id"] === sObj["contract_id"] && obj["request_point_id"] === sObj["request_point_id"]);
      return searchedObj ? { ...obj, checked: true } : obj;
    });
    
    const updateAllCancel = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: false }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["user_type_code"] === sObj["user_type_code"] && obj["contract_id"] === sObj["contract_id"] && obj["request_point_id"] === sObj["request_point_id"]);
      return searchedObj ? { ...obj, checked: false } : obj;
    });
    
    if (state === true){
      setDefaultData(updateAllCheck);
    }
    else if (state === false){
      setDefaultData(updateAllCancel);
    }
  }
  
  const singleCheck = (contract_id: string, request_point_id: string, user_type_code: string) => {
    const updatedSingleCheck = defaultData.map((obj) => {
      if (obj["user_type_code"] === user_type_code && obj["contract_id"] === contract_id  && obj["request_point_id"] === request_point_id) {
        return { ...obj, checked: !obj["checked"]};
      } else {
        return obj;
      }
    });
    setDefaultData(updatedSingleCheck);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: InformationRegistrationRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }

  // API経由で全アカウントの情報を取得する関数
  const getInformationRegistrationData: (userIds: {user_id : string}[]) => Promise<InformationRegistrationRecordInterface[]> = async (userIds: {user_id : string}[]) => {
    const token = await getCurrentUserToken();
    const response = axios.request({
      url: baseURL + "/get-customer-management",
      method: "get",
      headers: {
        Authorization: `${token}`,
      },
    })
    .then((response) => {
      console.log(userIds)
      response.data.data = response.data.data.map((item: InformationRegistrationRecordInterface) => {
        const userId = item.user_id;
        if (item.user_type_code === "01") {
          return {
            ...item,
            request_point_id: item.min_request_point_id,
            request_point_name: item.min_request_point_name,
            supply_point_no: item.min_supply_point_no,
            fee_menu_name_printing: item.min_fee_menu_name_printing,
            supply_start_date: item.min_supply_start_date,
            contract_end_date: item.max_contract_end_date,
            checked: userIds.some((user) => user.user_id === userId)
          };
        }
        return {
          ...item,
          checked: userIds.some((user) => user.user_id === userId)
        };
      });
      
      console.log(response)
      setDefaultData(response.data.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    defaultData,
    searchedData,
    curPageData,
    checkedData,
    getInformationRegistrationData,
    setSearchConditionUserId,
    setSearchConditionContractId,
    setSearchConditionContractName,
    setSearchConditionRequestPointId,
    setSearchConditionRequestPointName,
    setSearchConditionSupplyDate,
    setSearchConditionUnit,
    searchData,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck,
  };
}
