import React, { useState, useEffect } from 'react';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import SearchBox from '../components/LoginInformationManagement/SearchBoxLoginInformationManagement';
import LoginInformationManagementTable from '../components/LoginInformationManagement/LoginInformationManagementTable';
import RegistrationDialog from '../components/LoginInformationManagement/RegistrationDialog';
import PrivateRoute from '../components/PrivateRoute';
import dayjs from 'dayjs';
import { Spacer } from '../../utils/components/Spacer';
import SnackBar from '../../utils/components/SnackBar';
import { SearchItemsInterface, LoginInformationManagementRecordInterface } from '../interfaces/LoginInformationManagement';
import { StyledDiv, StyledTypography, StyledFooter, StyledToolbar, FooterButton } from '../styles/kanriStyle';
import { getCurrentUserToken } from '../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export function LoginInformationManagement() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [searchedData, setSearchedData] = useState<LoginInformationManagementRecordInterface[]>([]);
    const [curPageData, setCurPageData] = useState<LoginInformationManagementRecordInterface[]>([]);
    const [page, setPage] = useState<number>(1);
    const [editSuccess, setEditSuccess] = useState(false);
    const [editFailed, setEditFailed] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailed, setRegistrationFailed] = useState(false)
    const [searchItems, setSearchItems] = useState<SearchItemsInterface>({
        checkedImportant: true,
        checkedNormal: true,
        title: "",
        startDateHead: null,
        startDateTail: dayjs(),
        endDateHead: dayjs(),
        endDateTail: null,
    });
    
    const itemsPerPage: number = 20;
    
    const pagenation = (curPage: number) => {
        const head = (curPage - 1) * itemsPerPage;
        const targetPage: LoginInformationManagementRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
        setCurPageData([...targetPage]);
        setPage(curPage);
    }
    
    useEffect(() => {
        pagenation(1);
    }, [searchedData]);
    
    useEffect(() => {
        const getData = async() => {
            try {
                const _ = await getLoginInformationManagement();
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
    }, [])
    
    // 検索条件に合致するログインお知らせデータの取得
    const getLoginInformationManagement = async () => {
        const token = await getCurrentUserToken();
        // 分類をAPIに合った形に整形
        let informationType: string[] = [];
        if(searchItems.checkedNormal) informationType.push("0");
        if(searchItems.checkedImportant) informationType.push("1");
        console.log(informationType)
        
        axios.request({
            url: baseURL + "/get-manage-login-before-info",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
            params : {
                'type': informationType,
                'title': searchItems.title,
                'start_datetime': [searchItems.startDateHead !== null? dayjs(searchItems.startDateHead).format("YYYY/MM/DD HH:mm") : dayjs("1900/01/01 00:00").format("YYYY/MM/DD HH:mm"),
                                    searchItems.startDateTail !== null? dayjs(searchItems.startDateTail).format("YYYY/MM/DD HH:mm") : dayjs("2099/12/31 23:59").format("YYYY/MM/DD HH:mm")],
                'end_datetime': [searchItems.endDateHead !== null? dayjs(searchItems.endDateHead).format("YYYY/MM/DD HH:mm") : dayjs("1900/01/01 00:00").format("YYYY/MM/DD HH:mm"),
                                searchItems.endDateTail !== null? dayjs(searchItems.endDateTail).format("YYYY/MM/DD HH:mm") : dayjs("2099/12/31 23:59").format("YYYY/MM/DD HH:mm")],
            },
            paramsSerializer: {
               indexes: null
            }
        })
        .then((response) => {
            console.log(response);
            setSearchedData(response.data.data);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }
    
    const handleClose = () => setOpen(false);
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={70} />
                <StyledTypography variant="h5">お知らせ管理（ログイン）</StyledTypography>
                
                <Spacer size={20} />
                
                <SearchBox 
                    setSearchItems={setSearchItems}
                    getLoginInformationManagement={getLoginInformationManagement}/>
                
                <Spacer size={20} />
                
                { !isLoading? 
                    <LoginInformationManagementTable 
                        searchedData={searchedData}
                        page={page}
                        pagenation={pagenation}
                        getLoginInformationManagement={getLoginInformationManagement}
                        setEditSuccess={setEditSuccess}
                        setEditFailed={setEditFailed}
                    />
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
                
            </StyledDiv>
            
            <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
                <StyledToolbar>
                    <FooterButton
                    variant="outlined"
                    onClick={() => setOpen(true)}
                    >新規登録</FooterButton>
                    
                    <RegistrationDialog
                        open={open}
                        handleClose={handleClose}
                        setRegistrationSuccess={setRegistrationSuccess}
                        setRegistrationFailed={setRegistrationFailed}/>
                </StyledToolbar>
            </StyledFooter>
            
            <SnackBar
                successMessage="更新が成功しました"
                openSuccess={editSuccess}
                setOpenSuccess={setEditSuccess}
                failedMessage="更新が失敗しました"
                openFailed={editFailed}
                setOpenFailed={setEditFailed}/>
            
            <SnackBar
                successMessage="登録が成功しました"
                openSuccess={registrationSuccess}
                setOpenSuccess={setRegistrationSuccess}
                failedMessage="登録が失敗しました"
                openFailed={registrationFailed}
                setOpenFailed={setRegistrationFailed}/>
        </PrivateRoute>
    );
}