import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';

const userPoolId: string = process.env.REACT_APP_USER_POOL_ID || "";
const clientId: string = process.env.REACT_APP_USER_WEB_CLIENT_ID || '';
const region: string = process.env.REACT_APP_REGION || '';

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
  Region: region
};

const userPool = new CognitoUserPool(poolData);

export function SignUp() {
  const auth = useAuth();
  const navigate = useNavigate(); // useNavigateフックを使う
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const executeSignUp = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const attributeList = [];
    const dataEmail = {
      Name: 'email',
      Value: email
    };
    attributeList.push(new CognitoUserAttribute(dataEmail));

    userPool.signUp(username, password, attributeList, [], (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      navigate('/Verification'); // /Verificationに飛ばす
    });
  };

  return (
    <div className="signup-formContainer">
      <form noValidate onSubmit={executeSignUp}>
        <div>
          <label htmlFor="username">ユーザ名: </label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">メールアドレス: </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">パスワード: </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="signup-button">登録</button>
      </form>
    </div>
  );
}