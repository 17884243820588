import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import { Auth } from 'aws-amplify';
import { useAuth } from "../../utils/hooks/use-auth";
import { Spacer } from '../../utils/components/Spacer';
import { StyledDiv, StyledTypograpy, StyledTextField, ForgotPasswordForm, ForgotPasswordMain, LoginButton } from '../styles/style';
import { toBlobPdf, getCustomerManualPdf } from '../../utils/functions';
import { ERROR_MESSAGE_17 } from '../../utils/errorMessages';

export function ForgetPw(){
  const auth = useAuth();
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const errMsg01 = "ユーザー名を入力してください。";
  const errMsg02 = "メールアドレスを入力してください。";
  const errMsg03 = "入力されたユーザは登録されていません。";
  const [errors, setErrors] = useState({
      "emailError"   : '',
      "usernameError": '',
      "resetError"  : ''
  });
  
  function clearMsg() {
      setErrors({
          "emailError"   : '',
          "usernameError": '',
          "resetError"  : ''
      });
  }
  const backToLogin = () => {
    navigate("/signin");
  }
  
  const validEmail = () => {
    clearMsg();
  }
  
  const executeReset = async () => {
    //validation
    if(username == '') {
      setErrors({...errors, usernameError: errMsg01});
      return;
    }
    if(email == '') {
      setErrors({...errors, emailError: errMsg02});
      return;
    }
    // 全角のチェック
    if(username.match(/[^\x01-\x7E]/g) !== null){
      setErrors({...errors, usernameError: ERROR_MESSAGE_17});
      return;
    }
    // 全角のチェック
    if(email.match(/[^\x01-\x7E]/g) !== null){
      setErrors({...errors, emailError: ERROR_MESSAGE_17});
      return;
    }
    axios.request({
      url: baseURL + "/password-forgot-check",
      method: "get",
      params: {
          'user_id': username
      }
    })
    .then((response) => {
      console.log(response.data);
      let flg = false;
      if(response.data.mail_address.indexOf(';') == -1) {
        if(response.data.mail_address.trim() == email) {
          flg = true;
        }
      } else {
        var mail_list = response.data.mail_address.split(';');
        for(let i=0;i<mail_list.length;i++) {
          if(mail_list[i].trim() == email) {
            flg = true;
          }
        }
      }
      if(flg) {
        axios.request({
        url: baseURL + "/send-mail",
        method: "post",
        data: {
            'insUpdKbn': '2',
            'userId': username,
            'caseNo': '4',
            'sendMailAddress': email
        }
        }).then((response) => {}).catch((error) => {
          setErrors({...errors, resetError: 'メール送信失敗しました'});
        });
        navigate('/user/password-forgot-success');
        
      } else {
        setErrors({...errors, resetError: errMsg03});
      }
    }).catch((error) => {});
  };
  
  return (
    <StyledDiv>
      <Spacer size={30} />
         <ForgotPasswordMain style= {{ marginTop: "140px" }}>
            <ForgotPasswordForm>
                <div className="err-msg">
                {errors.resetError != ""? <div>{errors.resetError}</div>: ""}
                </div>
                <div>
                  <StyledTypograpy>ユーザー名</StyledTypograpy>
                  <StyledTextField
                    id="username"
                    type="username"
                    value={username}
                    style={{ width: "320px"}}
                    onChange={(e) => {setUsername(e.target.value); clearMsg()}}
                  />
                  <Spacer size={5} />
                  <div className="err-msg">
                  {errors.usernameError != ""? <div>{errors.usernameError}</div>: ""}
                  </div>
                  <StyledTypograpy>メールアドレス</StyledTypograpy>
                  <StyledTextField
                    id="email"
                    type="email"
                    value={email}
                    style={{ width: "320px"}}
                    onChange={(e) => {setEmail(e.target.value); clearMsg()}}
                  />
                  <Spacer size={5} />
                  <div className="err-msg">
                  {errors.emailError != ""? <div>{errors.emailError}</div>: ""}
                  </div>
                  <Spacer size={40} />
                </div>
                <LoginButton onClick={backToLogin}>ログイン画面に戻る</LoginButton>
                <Spacer size={10} />
                <LoginButton onClick={executeReset}>メールを送信する</LoginButton>
            </ForgotPasswordForm>
        </ForgotPasswordMain>
        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
              <Link component="button" onClick={(e: any) => (getCustomerManualPdf('エナリスお客さまサイト_操作マニュアル.pdf'))} sx={{ color: "#333333" }} underline="hover">ログイン操作マニュアル</Link>
              <FilterNoneOutlinedIcon sx={{ marginLeft: "5px", marginRight: "40px", color: "#333333", fontSize: 15 }} />
              <div>
                <Typography sx={{ color: "#333333", fontWeight: 'Bold' }}>当サイトに関するお問合せ<br/></Typography>
                <Typography sx={{ color: "#333333", marginLeft: '10px' }}>株式会社エナリス お客さまサイト窓口<br/>mail:ec_support@eneres.co.jp</Typography>
              </div>
        </div>
    </StyledDiv>
  );
}