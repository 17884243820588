import * as React from 'react';
import { useAuth } from '../../../utils/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { TabProps } from '../../../utils/interfaces/TabProps';
import { StyledTab } from '../../styles/style';

export default function BillingDownloadTab(props: TabProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(props.initPage);
  // tabの横の長さをタブの表示数で変更する
  const tabWidth = 40 - 20 * auth.userAuthority.substring(11, 13).split('').filter(char => char === '0').length;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    switch (newValue) {
        case "pdf-download":
            navigate('/user/billing-document/pdf-download');
            break;
        case "specificationlist-download":
            navigate('/user/billing-document/specificationlist-download');
            break;
        default:
            break;
    }
  };

  return (
    <Box sx={{ width: '100%', borderBottom: 2, borderColor: '#0098CB' }}>
      <Box sx={{ width: tabWidth.toString() + "%" }}>
        <Tabs 
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="tab"
            sx={{ '& .MuiTab-root:not(:last-child)': { mr: 1 } }}
        >
          {auth.userAuthority[11] === "1"? <StyledTab label="請求書・明細書一括ダウンロード" value="pdf-download"/> : <div />}
          {auth.userAuthority[12] === "1"? <StyledTab label="明細一覧ダウンロード" value="specificationlist-download"/> : <div />}
        </Tabs>
      </Box>
    </Box>
  );
}