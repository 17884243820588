import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface Props {
    data: WeekdayData[];
};

interface WeekdayData {
    aggregate_time: string;
    daytime_use_power_kWh: string;
    nighttime_use_power_kWh: string;
    total_use_power_kWh: string;
    week_of_day: string;
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string, value: number }[];
  label?: string;
}

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Box className="custom-tooltip" sx={{ padding: 1, borderRadius: 1, color: "#FFFFFF", backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
        <Typography variant="h6" style={{ display: "flex", justifyContent: "center" }}>{`${label}`}</Typography>
        <div style={{ display: 'flex', alignItems: "center" }}>
          <Box sx={{backgroundColor: '#FCD983', width: '10px', height: '10px', mr: 1}}></Box>
          <Typography>{`${payload[0].name} : ${Number(payload[0].value).toLocaleString()} kWh`}</Typography>
        </div>
        <div style={{ display: 'flex', alignItems: "center"}}>
          <Box sx={{backgroundColor: '#A5BCE8', width: '10px', height: '10px', mr: 1}}></Box>
          <Typography>{`${payload[1].name} : ${Number(payload[1].value).toLocaleString()} kWh`}</Typography>
        </div>
      </Box>
    );
  }

  return null;
};

const day_of_week_ja = ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"];

export default function UsingAchievementWeekdayChart(props: Props) {
    const { data } = props;
  
    return (
      <ResponsiveContainer width="100%" height="95%">
        <BarChart
          width={500}
          height={300}
          data={data.map((entry) => ({
            ...entry, 
            week_of_day: day_of_week_ja[Number(entry.week_of_day)],
          }))}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="0" />
          <XAxis dataKey="week_of_day">
            <Label value="曜日" offset={-15} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: '電力(kWh)', angle: -90, position: 'insideLeft' }}/>
          <Tooltip content={<CustomTooltip /> } cursor={false}/>
          <Legend 
            verticalAlign="top" 
            align="center" 
            height={36} 
            formatter={(value) => <span style={{ color: "#000000" }}>{value}</span>}/>
          <Bar name="昼間" dataKey="daytime_use_power_kWh" stackId="a" fill="#FCD983" />
          <Bar name="夜間"dataKey="nighttime_use_power_kWh" stackId="a" fill="#A5BCE8" />
        </BarChart>
      </ResponsiveContainer>
    );
}
