import { useState, useEffect } from "react";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
 
type useViewSpecificationPDFRet = {
  ViewSpecificationPDF: (checked: string, systemFileName: string) => void;
};


const baseURL = process.env.REACT_APP_API_ENDPOINT;
const baseS3Path = "s3://p3-cust-dev-pdf/esb_receive/";

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useViewSpecificationPDF(): useViewSpecificationPDFRet {
  
  const ViewSpecificationPDF = async (targetDate: string, systemFileName: string) => {
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    axios.request({
        url: baseURL + "/billing-inquery-invoice-download",
        method: "post",
        headers: { 
          Accept: "application/pdf",
          Authorization: token,
        },
        data:{
          "downloadFiles": [systemFileName]
        }
    })
    .then((response) => {
      const blob: Blob = toBlobPdf(response.data, "application/pdf");
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank")  // pdfを別タブで表示
    })
    .catch((error) => {
      console.log(error);
    });
  };
  
  function toBlobPdf(base64: string, mime_ctype: string): Blob {
    // 日本語の文字化けに対処するためBOMを作成する。
    var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([bom, buffer.buffer], {
        type: mime_ctype,
    });
    return blob;
  };
  
  return {
    ViewSpecificationPDF
  };
}
