import * as React from 'react';
import { useAuth } from '../../../utils/hooks/use-auth';
// MUI components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
// custom components
import { Spacer } from '../../../utils/components/Spacer';
import { getCustomerManualPdf } from '../../../utils/functions';

export default function ContractInformationLinkBox() {
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);


  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#0099CC', color: '#ffffff' }} onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary="約款等関連事項" />
      </ListItemButton>
      {open &&
        <Paper variant="outlined" square >
          <Spacer size={15} />
          <Grid container sx={{marginX:7, marginY:4}}>
            {auth.userAuthority[18] === "1"?
              <Grid sx={{marginRight:5}}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <Link
                    color="inherit"
                    underline="hover"
                    sx={{
                      marginRight:"2px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      window.open("https://eneres-pm.co.jp/profile/#sect-03")
                    }}
                  >
                    各種約款
                  </Link>
                  <FilterNoneIcon fontSize="small" />
                </div>
              </Grid> : <div />
            }
            {auth.userAuthority[21] === "1"?
              <Grid sx={{marginRight:5}}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <Link
                    color="inherit"
                    underline="hover"
                    sx={{
                      marginRight:"2px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      window.open("https://eneres-pm.co.jp/fuel_cost_index/")
                    }}
                  >
                    燃料費等調整単価
                  </Link>
                  <FilterNoneIcon fontSize="small" />
                </div>
              </Grid> : <div />
            }
            {auth.userAuthority[22] === "1"?
              <Grid sx={{marginRight:5}}>
                <div style={{display:"flex", alignItems:"center"}}>
                  <Link
                    color="inherit"
                    underline="hover"
                    sx={{
                      marginRight:"2px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      getCustomerManualPdf("電源連動型再エネメニュー(特高・高圧)電気料金単価表.pdf");
                    }}
                  >
                    電源連動型再エネメニュー電気料金
                  </Link>
                  <FilterNoneIcon fontSize="small" />
                </div>
              </Grid> : <div />
            }
          </Grid>
        </Paper>
      }
    </div>
  );
}