import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/HelpOutline';
import SettingElectricityAlarmDialog from '../../components/SettingElectricityAlarm/SettingElectricityAlarmDialog';
import { ElectricityAlarmRecordInterface, ElectricityAlarmDialogInterface } from '../../interfaces/SettingElectricityAlarm';
import { ElectricityAlarmTableButton, StyledTableSortLabel } from '../../styles/style';

interface Order {
  use_power_alarm_valid_flag: 'asc' | 'desc';
  request_point_name: 'asc' | 'desc';
  supply_point_no: 'asc' | 'desc';
  voltage_class: 'asc' | 'desc';
  use_power_alarm_threshold: 'asc' | 'desc';
  use_power_alarm_notice_type: 'asc' | 'desc';
  use_power_alarm_mail_address: 'asc' | 'desc';
  contract_id: 'asc' | 'desc';
  request_point_id: 'asc' | 'desc';
  contract_capacity: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface SettingElecticityAlarmTable {
  data: ElectricityAlarmRecordInterface[];
  toggleChecked: (index: number) => void;
  searchedData: ElectricityAlarmRecordInterface[];
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  checkState: number[];
  allCheck: () => void;
  clearCheck: () => void;
  getSettingElecticityAlarmData: () => void;
  setOpenSuccess: (openSuccess: boolean) => void;
  setOpenFailed: (openFailed: boolean) => void;
}

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(0, 80, 108, 0.9)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 180,
    backgroundColor: 'rgba(0, 80, 108, 0.9)',
    opacity: 0.9,
  },
});

export default function SettingElectricityAlarmTable(props: SettingElecticityAlarmTable) {
  const { data, searchedData, toggleChecked, checkState, allCheck, clearCheck, page, pagenation, getSettingElecticityAlarmData, setOpenSuccess, setOpenFailed } = props;
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState<Order>({
    use_power_alarm_valid_flag: 'asc',
    request_point_name: 'asc',
    supply_point_no: 'asc',
    voltage_class: 'asc',
    use_power_alarm_threshold: 'asc',
    use_power_alarm_notice_type: 'asc',
    use_power_alarm_mail_address: 'asc',
    contract_id: 'asc',
    request_point_id: 'asc',
    contract_capacity: 'asc'
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('contract_id');
  const [checkboxBulk, setCheckboxBulk] = React.useState<boolean>(false);
  const [userData, setUserData] = useState<ElectricityAlarmDialogInterface[]>([]);
  
  const handleOpen = (name: string, flag: string, threshold: string, type: string, mail: string, pointNo: string, pointId: string, id: string, contractPower: string, feeMenuBaseFeeConfirmValue: string, capacityUnit: string) => {
    const records = [{
      userId: id,
      requestPointId: pointId,
      requestPointName: name,
      supplyPointNo: pointNo,
      contractPowerUsually: contractPower,
      feeMenuBaseFeeConfirmValue: feeMenuBaseFeeConfirmValue,
      capacityUnit: capacityUnit,
      usePowerAlarmValidFlag: flag,
      usePowerAlarmNoticeType: type,
      usePowerAlarmMailAddress: mail,
      usePowerAlarmThreshold: threshold,
    }];
    setUserData(records);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxBulk(event.target.checked);
    if(event.target.checked){
      allCheck();
    }
    else{
      clearCheck();
    }
  };
  
  interface VoltageMap {
    [key: string]: number;
  }
  
  const voltageMap: VoltageMap = {
    '低圧': 0,
    '高圧': 1,
    '特高': 2
  };
  
  // カラムごとによる行の並び替え
  const compare = (a: ElectricityAlarmRecordInterface, b: ElectricityAlarmRecordInterface): number => {
    if (orderBy === 'use_power_alarm_valid_flag') {
      if (a.use_power_alarm_valid_flag < b.use_power_alarm_valid_flag) {
        return order.use_power_alarm_valid_flag === 'asc' ? -1 : 1;
      }
      if (a.use_power_alarm_valid_flag > b.use_power_alarm_valid_flag) {
        return order.use_power_alarm_valid_flag === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'request_point_name') {
      if (a.request_point_name < b.request_point_name) {
        return order.request_point_name === 'asc' ? -1 : 1;
      }
      if (a.request_point_name > b.request_point_name) {
        return order.request_point_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_point_no') {
      if (a.supply_point_no < b.supply_point_no) {
        return order.supply_point_no === 'asc' ? -1 : 1;
      }
      if (a.supply_point_no > b.supply_point_no) {
        return order.supply_point_no === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'voltage_class') {
      if (voltageMap[a.voltage_class] < voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? -1 : 1;
      }
      if (voltageMap[a.voltage_class] > voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_capacity') {
      const contract_power_a = a.contract_power_usually !== ""? a.contract_power_usually + a.capacity_unit : a.fee_menu_base_fee_confirm_value !== ""? a.fee_menu_base_fee_confirm_value + a.capacity_unit : "";
      const contract_power_b = b.contract_power_usually !== ""? b.contract_power_usually + b.capacity_unit : b.fee_menu_base_fee_confirm_value !== ""? b.fee_menu_base_fee_confirm_value + b.capacity_unit : "";
      
      if (contract_power_a < contract_power_b) {
        return order.contract_capacity === 'asc' ? -1 : 1;
      }
      if (contract_power_a > contract_power_b) {
        return order.contract_capacity === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'use_power_alarm_threshold') {
      if (a.use_power_alarm_threshold < b.use_power_alarm_threshold) {
        return order.use_power_alarm_threshold === 'asc' ? -1 : 1;
      }
      if (a.use_power_alarm_threshold > b.use_power_alarm_threshold) {
        return order.use_power_alarm_threshold === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'use_power_alarm_notice_type') {
      if (a.use_power_alarm_notice_type < b.use_power_alarm_notice_type) {
        return order.use_power_alarm_notice_type === 'asc' ? -1 : 1;
      }
      if (a.use_power_alarm_notice_type > b.use_power_alarm_notice_type) {
        return order.use_power_alarm_notice_type === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'use_power_alarm_mail_address') {
      if (a.use_power_alarm_mail_address < b.use_power_alarm_mail_address) {
        return order.use_power_alarm_mail_address === 'asc' ? -1 : 1;
      }
      if (a.use_power_alarm_mail_address > b.use_power_alarm_mail_address) {
        return order.use_power_alarm_mail_address === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_id') {
      if (a.contract_id < b.contract_id) {
        return order.contract_id === 'asc' ? -1 : 1;
      }
      if (a.contract_id > b.contract_id) {
        return order.contract_id === 'asc' ? 1 : -1;
      }
      if (a.contract_id === b.contract_id) {
        if (a.request_point_id < b.request_point_id) {
          return order.request_point_id === 'asc' ? -1 : 1;
        }
        if (a.request_point_id > b.request_point_id) {
          return order.request_point_id === 'asc' ? 1 : -1;
        }
      }
    }
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+data.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={checkboxBulk} onChange={handleChangeCheckbox} />
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomTooltip title="アラートメールのON/OFFの設定状態です" placement="top" arrow>
                    <HelpIcon sx={{ color: "#0099CC", marginRight: "5px" }}/>
                  </CustomTooltip>
                  <StyledTableSortLabel
                    active={orderBy === 'use_power_alarm_valid_flag'}
                    direction={order.use_power_alarm_valid_flag}
                    onClick={() => handleRequestSort('use_power_alarm_valid_flag')}>有効</StyledTableSortLabel>
                </div>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_name'}
                  direction={order.request_point_name}
                  onClick={() => handleRequestSort('request_point_name')}>需要場所名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_point_no'}
                  direction={order.supply_point_no}
                  onClick={() => handleRequestSort('supply_point_no')}>供給地点特定番号</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'voltage_class'}
                  direction={order.voltage_class}
                  onClick={() => handleRequestSort('voltage_class')}>電圧区分</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_capacity'}
                  direction={order.contract_capacity}
                  onClick={() => handleRequestSort('contract_capacity')}>契約容量</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomTooltip title="実績値が本設定を超えるとアラートメールが配信されます" placement="top" arrow>
                    <HelpIcon sx={{ color: "#0099CC", marginRight: "5px" }}/>
                  </CustomTooltip>
                  <StyledTableSortLabel
                    active={orderBy === 'use_power_alarm_threshold'}
                    direction={order.use_power_alarm_threshold}
                    onClick={() => handleRequestSort('use_power_alarm_threshold')}>しきい値(kW)</StyledTableSortLabel>
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomTooltip title="毎回（連続）か初回（連続した場合は初回のみ）を選択します" placement="top" arrow>
                    <HelpIcon sx={{ color: "#0099CC", marginRight: "5px" }}/>
                  </CustomTooltip>
                  <StyledTableSortLabel
                    active={orderBy === 'use_power_alarm_notice_type'}
                    direction={order.use_power_alarm_notice_type}
                    onClick={() => handleRequestSort('use_power_alarm_notice_type')}>通知パターン</StyledTableSortLabel>
                </div>
              </TableCell>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomTooltip title="アラートメールの配信先です" placement="top" arrow>
                    <HelpIcon sx={{ color: "#0099CC", marginRight: "5px" }}/>
                  </CustomTooltip>
                  <StyledTableSortLabel
                    active={orderBy === 'use_power_alarm_mail_address'}
                    direction={order.use_power_alarm_mail_address}
                    onClick={() => handleRequestSort('use_power_alarm_mail_address')}>通知先</StyledTableSortLabel>
                </div>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#ABE1FA' : '#ffffff' }}
              >
                <TableCell>
                  <Checkbox
                    onChange={() => toggleChecked(index)}
                    checked={checkState.includes(index)} />
                </TableCell>
                <TableCell>{row.use_power_alarm_valid_flag !== "0"? "〇": ""}</TableCell>
                <TableCell>{row.request_point_name}</TableCell>
                <TableCell>{row.supply_point_no}</TableCell>
                <TableCell>{row.voltage_class}</TableCell>
                <TableCell>{row.contract_power_usually !== ""? Math.floor(parseFloat(row.contract_power_usually)).toString() + row.capacity_unit
                : row.fee_menu_base_fee_confirm_value !== ""? Math.floor(parseFloat(row.fee_menu_base_fee_confirm_value)).toString() + row.capacity_unit : ""}</TableCell>
                <TableCell>{row.use_power_alarm_threshold}</TableCell>
                <TableCell>{row.use_power_alarm_notice_type !== "1"? "毎回": "初回"}</TableCell>
                <TableCell>{row.use_power_alarm_mail_address}</TableCell>
                <TableCell>
                  <ElectricityAlarmTableButton
                    variant="outlined"
                    onClick={() => handleOpen(
                      row.request_point_name,
                      row.use_power_alarm_valid_flag, 
                      row.use_power_alarm_threshold, 
                      row.use_power_alarm_notice_type,
                      row.use_power_alarm_mail_address,
                      row.supply_point_no,
                      row.request_point_id,
                      row.user_id,
                      row.contract_power_usually,
                      row.fee_menu_base_fee_confirm_value,
                      row.capacity_unit)}
                    >設定</ElectricityAlarmTableButton>
                    
                    <SettingElectricityAlarmDialog
                      open={open}
                      isDisabled={false}
                      getSettingElecticityAlarmData={getSettingElecticityAlarmData}
                      handleClose={handleClose}
                      userData={userData}
                      setOpenSuccess={setOpenSuccess}
                      setOpenFailed={setOpenFailed}/>  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (data.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              clearCheck();
              setCheckboxBulk(false);
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}