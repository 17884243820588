import React from 'react';
import { StyledDiv, LoginButton } from '../styles/style';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../../utils/components/Spacer';

export function PasswordResetSuccess(props: any) {
    const navigate = useNavigate();
    const navigateToLogin = function () {
        navigate('/signin');
    }
    return(
        <StyledDiv>
          <div style={{ width: "400px", margin: "30px auto 0 auto", fontSize: "20px", color: "#00506B" }}>
              <h3>パスワードセット完了</h3>
              新しいパスワードを使用してログインしてください
              <Spacer size={20} />
              <div><LoginButton onClick={navigateToLogin}>ログイン画面へ</LoginButton></div>
          </div>
        </StyledDiv>
    )
}