import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Spacer } from '../../../utils/components/Spacer';
// interfaces
import { InformationRegistrationRecordInterface } from '../../interfaces/InformationRegistration'
import { StyledTableSortLabel } from '../../styles/kanriStyle';

interface Order {
  user_id: 'asc' | 'desc';
  contract_name: 'asc' | 'desc';
  request_point_name: 'asc' | 'desc';
  supply_start_date: 'asc' | 'desc';
  contract_id: 'asc' | 'desc';
  request_point_id: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface InformationRegistrationTable {
  data: InformationRegistrationRecordInterface[];
  searchedData: InformationRegistrationRecordInterface[];
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (contract_id: string, request_point_id: string, user_type_code: string) => void;
}

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function InformationRegistrationTable(props: InformationRegistrationTable) {
  const { data, searchedData, page, pagenation, allCheck, singleCheck } = props;
  const [checkboxBulk, setCheckboxBulk] = React.useState<boolean>(false);
  const [order, setOrder] = React.useState<Order>({
    user_id: 'asc',
    contract_id: 'asc',
    contract_name: 'asc',
    request_point_id: 'asc',
    request_point_name: 'asc',
    supply_start_date: 'asc',
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('contract_id');
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxBulk(event.target.checked);
    allCheck(event.target.checked);
  };
  
  const toggleChecked = (i: number) =>{
    console.log("")
  }
  
  // カラムごとによる行の並び替え
  const compare = (a: InformationRegistrationRecordInterface, b: InformationRegistrationRecordInterface): number => {
    if (orderBy === 'user_id') {
      if (a.user_id < b.user_id) {
        return order.user_id === 'asc' ? -1 : 1;
      }
      if (a.user_id > b.user_id) {
        return order.user_id === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_id') {
      if (a.contract_id < b.contract_id) {
        return order.contract_id === 'asc' ? -1 : 1;
      }
      if (a.contract_id > b.contract_id) {
        return order.contract_id === 'asc' ? 1 : -1;
      }
      if (a.contract_id === b.contract_id) {
        if (a.request_point_id < b.request_point_id) {
          return order.request_point_id === 'asc' ? -1 : 1;
        }
        if (a.request_point_id > b.request_point_id) {
          return order.request_point_id === 'asc' ? 1 : -1;
        }
      }
    } else if (orderBy === 'contract_name') {
      if (a.contract_name < b.contract_name) {
        return order.contract_name === 'asc' ? -1 : 1;
      }
      if (a.contract_name > b.contract_name) {
        return order.contract_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'request_point_id') {
      if (a.request_point_id < b.request_point_id) {
        return order.request_point_id === 'asc' ? -1 : 1;
      }
      if (a.request_point_id > b.request_point_id) {
        return order.request_point_id === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'request_point_name') {
      if (a.request_point_name < b.request_point_name) {
        return order.request_point_name === 'asc' ? -1 : 1;
      }
      if (a.request_point_name > b.request_point_name) {
        return order.request_point_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_start_date') {
      if (a.supply_start_date < b.supply_start_date) {
        return order.supply_start_date === 'asc' ? -1 : 1;
      }
      if (a.supply_start_date > b.supply_start_date) {
        return order.supply_start_date === 'asc' ? 1 : -1;
      }
    } 
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+data.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={checkboxBulk} onChange={handleChangeCheckbox} />
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'user_id'}
                  direction={order.user_id}
                  onClick={() => handleRequestSort('user_id')}>ユーザID</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_id'}
                  direction={order.contract_id}
                  onClick={() => handleRequestSort('contract_id')}>契約ID</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_name'}
                  direction={order.contract_name}
                  onClick={() => handleRequestSort('contract_name')}>契約名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_id'}
                  direction={order.request_point_id}
                  onClick={() => handleRequestSort('request_point_id')}>需要場所ID</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_name'}
                  direction={order.request_point_name}
                  onClick={() => handleRequestSort('request_point_name')}>需要場所名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_start_date'}
                  direction={order.supply_start_date}
                  onClick={() => handleRequestSort('supply_start_date')}>契約期間</StyledTableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#D9E1FB' : '#FFFFFF' }}
              >
                <TableCell>
                  <Checkbox
                    onChange={() => singleCheck(row.contract_id, row.request_point_id, row.user_type_code)}
                    checked={row.checked} />
                </TableCell>
                <TableCell>{row.user_id}</TableCell>
                <TableCell>{row.contract_id}</TableCell>
                <TableCell>{row.contract_name}</TableCell>
                <TableCell>{row.request_point_id}</TableCell>
                <TableCell>{row.request_point_name}</TableCell>
                <TableCell>{`${row.supply_start_date}~${row.user_type_code === "01"? "-": row.contract_end_date === "2099/12/31"? "": row.contract_end_date}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (searchedData.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      
      <Spacer size={20} />
      
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}