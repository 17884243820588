import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useAuth } from '../../../utils/hooks/use-auth';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../../utils/components/Spacer';
import { StyledTypography, CancelButton, SaveButton, ErrorTypography, StyledButton } from '../../styles/kanriStyle';
import { CustomerManagementDialogInterface } from '../../interfaces/CustomerManagement';
import { ERROR_MESSAGE_15, ERROR_MESSAGE_16, ERROR_MESSAGE_17 } from '../../../utils/errorMessages';
import { getCurrentUserToken } from '../../../utils/functions';
import { CheckboxGroup } from './CheckBoxGroup';
  
const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
interface Props {
    open: boolean;
    getCustomerManagementData: () => void;
    handleClose: () => void;
    userData: CustomerManagementDialogInterface;
    setIsLoading: (isLoading: boolean) => void;
    setEditSuccess: (editSuccess: boolean) => void;
    setEditFailed: (editFailed: boolean) => void;
    setMailSuccess: (mailSuccess: boolean) => void;
    setMailFailed: (mailFailed: boolean) => void;
};

export default function CustomerManagementDialog(props: Props) {
    const { open, handleClose, userData, getCustomerManagementData, setIsLoading, setEditSuccess, setEditFailed, setMailSuccess, setMailFailed } = props;
    const auth = useAuth();
    const [sendIdDialogOpen, setSendIdDialogOpen] = useState(false);
    const [openCheckBox, setOpenCheckBox] = useState(false);
    const [mailAddress, setMailAddress] = useState('');
    const [mailSendCode, setMailSendCode] = useState('');
    const [featuresInfoCheckState, setFeaturesInfoCheckState] = useState<boolean[]>([]);
    const [featuresUsingReferCheckState, setFeaturesUsingReferCheckState] = useState<boolean[]>([]);
    const [featuresUsingDownloadCheckState, setFeaturesUsingDownloadCheckState] = useState<boolean[]>([]);
    const [featuresBillingCheckState, setFeaturesBillingCheckState] = useState<boolean[]>([]);
    const [featuresContractCheckState, setFeaturesContractCheckState] = useState<boolean[]>([]);
    const [featuresSettingCheckState, setFeaturesSettingCheckState] = useState<boolean[]>([]);
    const [featuresHelpCheckState, setFeaturesHelpCheckState] = useState<boolean[]>([]);
    const [mailAddressError, setMailAddressError] = useState('');
    const [validPeriodDate, setValidPeriodDate] = useState<Dayjs | null>(dayjs());
    
    const featuresInfo = [
        { name: 'お知らせ' },
    ];
    
    const featuresUsingRefer = [
        { name: '使用実績照会（年間実績）' },
        { name: '使用実績照会（月間実績）' },
        { name: '使用実績照会（週間実績）' },
        { name: '使用実績照会（日別実績）' },
        { name: '使用実績照会（曜日別実績）' }
    ];
    
    const featuresUsingDownload = userData.userType === "契約単位"? [
        { name: '使用実績照会（月次レポート）' },
        { name: '使用実績照会（昼夜間電力量）' },
        { name: '使用実績照会（月別30分単位実績）' },
    ] : [
        { name: '使用実績照会（月次レポート）' },
        { name: '使用実績照会（月別30分単位実績）' },
    ];
    
    const featuresBilling = userData.userType === "契約単位"? [
        { name: '請求関連書類照会（請求書）' },
        { name: '請求関連書類照会（明細書）' },
        { name: '請求関連書類照会（PDF一括ダウンロード）' },
        { name: '請求関連書類照会（明細一覧ダウンロード）' },
    ] : [
        { name: '請求関連書類照会（明細書）' },
        { name: '請求関連書類照会（PDF一括ダウンロード）' },
    ];
    
    const featuresContract = [
        { name: '（リンク）各種約款' },
        { name: '（リンク）燃料費等調整単価' },
        { name: '（リンク）電源連動型再エネメニュー電気料金' },
        { name: '契約情報一覧' },
    ];
    
    const featuresSetting = userData.userType === "契約単位"? [
        { name: '設定照会（使用電力アラーム）' },
        { name: '設定照会（メール）' },
        { name: '設定照会（パスワード）' },
        { name: '設定照会（需要場所ID通知）' },
    ] : [
        { name: '設定照会（使用電力アラーム）' },
        { name: '設定照会（メール）' },
        { name: '設定照会（パスワード）' },
    ];
    
    const featuresHelp = [
        { name: 'ヘルプ 操作マニュアル' },
        { name: 'ヘルプ EAサービスご利用の手引き' },
        { name: 'ヘルプ お問い合わせ' },
    ];
    
    // Dialogの初期値を各stateに代入
    useEffect(() => {
        if (userData.userId !== "") {
            // 権限を管理する文字列をboolean(true: 1, false: 0)の配列に変換
            const authorityArray: boolean[] = userData.authority.split('').map(char => char === '1');
            setMailAddress(userData.mailAddress);
            setMailSendCode(userData.mailSendNecessityCode)
            setMailAddressError('');
            setValidPeriodDate(userData.validPeriodDate !== null? dayjs(userData.validPeriodDate): dayjs());
            // 各チェックボックスを管理するStateに権限を代入
            // ※契約単位か需要場所単位かで配列数が異なることに注意
            setFeaturesInfoCheckState([authorityArray[0]]);
            setFeaturesUsingReferCheckState([authorityArray[1], authorityArray[2], authorityArray[3], authorityArray[4], authorityArray[5]]);
            setFeaturesUsingDownloadCheckState(userData.userType === "契約単位" ? [authorityArray[6], authorityArray[7], authorityArray[8]] : [authorityArray[6], authorityArray[8]]);
            setFeaturesBillingCheckState(userData.userType === "契約単位"? [authorityArray[9], authorityArray[10], authorityArray[11], authorityArray[12]] : [authorityArray[10], authorityArray[11]]);
            setFeaturesContractCheckState([authorityArray[18], authorityArray[21], authorityArray[22], authorityArray[13]]);
            setFeaturesSettingCheckState(userData.userType === "契約単位"? [authorityArray[14], authorityArray[15], authorityArray[16], authorityArray[17]] : [authorityArray[14], authorityArray[15], authorityArray[16]]);
            setFeaturesHelpCheckState([authorityArray[23], authorityArray[24], authorityArray[25]]);
        }
    }, [userData]);
    
    // boolean型の各チェックボックスの値を40桁の権限を管理する文字列に変換する関数
    // ※配列の20, 21番目は欠番のためfalse
    const booleanArrayToString = (): string => {
        const booleanArray = userData.userType === "契約単位"?
                    [featuresInfoCheckState[0], featuresUsingReferCheckState[0], featuresUsingReferCheckState[1], featuresUsingReferCheckState[2], featuresUsingReferCheckState[3], 
                    featuresUsingReferCheckState[4], featuresUsingDownloadCheckState[0], featuresUsingDownloadCheckState[1], featuresUsingDownloadCheckState[2], featuresBillingCheckState[0],
                    featuresBillingCheckState[1], featuresBillingCheckState[2], featuresBillingCheckState[3], featuresContractCheckState[3], featuresSettingCheckState[0],
                    featuresSettingCheckState[1], featuresSettingCheckState[2], featuresSettingCheckState[3], featuresContractCheckState[0], false,
                    false, featuresContractCheckState[1], featuresContractCheckState[2], featuresHelpCheckState[0], featuresHelpCheckState[1],
                    featuresHelpCheckState[2], false, false, false, false,
                    false, false, false, false, false, false, false, false, false, false]
                    :
                    // 需要場所単位は配列が契約単位と異なるため注意
                    [featuresInfoCheckState[0], featuresUsingReferCheckState[0], featuresUsingReferCheckState[1], featuresUsingReferCheckState[2], featuresUsingReferCheckState[3], 
                    featuresUsingReferCheckState[4], featuresUsingDownloadCheckState[0], false, featuresUsingDownloadCheckState[1], false,
                    featuresBillingCheckState[0], featuresBillingCheckState[1], false, featuresContractCheckState[3], featuresSettingCheckState[0],
                    featuresSettingCheckState[1], featuresSettingCheckState[2], false, featuresContractCheckState[0], false,
                    false, featuresContractCheckState[1], featuresContractCheckState[2], featuresHelpCheckState[0], featuresHelpCheckState[1],
                    featuresHelpCheckState[2], false, false, false, false,
                    false, false, false, false, false, false, false, false, false, false]
        
        return booleanArray.map(value => value ? '1' : '0').join('');
    }
    
    // お客さま情報編集
    const updateCustomerAccount = async () => {
        const token = await getCurrentUserToken();
        const userAuthority = booleanArrayToString();
        
        axios.request({
            url: baseURL + "/update-customer-account",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'user_id' : userData.userId,
                'mail_address' : mailAddress,
                'mail_send_necessity_code' : mailSendCode,
                'valid_period_date' : validPeriodDate !== null? validPeriodDate.format("YYYY/MM/DD") : userData.validPeriodDate,
                'authority' : userAuthority
            },
        })
        .then((response) => {
            setIsLoading(true);
            const getData = async() => {
                try {
                    const _ = await getCustomerManagementData();
                    setIsLoading(false);
                }
                    catch(error){
                    console.log(error);
                }
            }
            getData();
            setEditSuccess(true);
        })
        .catch((error) => {
            console.log(error);
            setEditFailed(true);
        });
    }
    
    // 代理運用
    const insteadOperation = async () => {
        const token = await getCurrentUserToken();
        const customer_name = userData.userType === "契約単位"? userData.contractName: userData.requestPointName !== null? userData.requestPointName: "";
        const request_point_id = userData.userType === "需要場所単位"? userData.requestPointId: "";
        const userAuthority = booleanArrayToString();
        
        axios.request({
            url: baseURL + "/instead-operation",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'status' : 'add',
                'admin_id' : auth.username,
                'customer_name' : customer_name,
                'customer_user_id' : userData.userId,
                'contract_id' : userData.contractId,
                'request_point_id' : request_point_id,
                'authority' : userAuthority
            },
        })
        .then((response) => {
            if (response.status === 200) {
                auth.setUserUpdated(true);
                window.open('/user/top', '_blank');
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    // ID通知メール送信
    const sendIdNotificationMail = async () => {
        const token = await getCurrentUserToken();
        const userAuthority = booleanArrayToString();
        
        // データの更新を行う
        axios.request({
            url: baseURL + "/update-customer-account",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'user_id' : userData.userId,
                'mail_address' : mailAddress,
                'mail_send_necessity_code' : mailSendCode,
                'valid_period_date' : validPeriodDate !== null? validPeriodDate.format("YYYY/MM/DD") : userData.validPeriodDate,
                'authority' : userAuthority
            },
        })
        .then((response) => {
            setIsLoading(true);
            // 更新が成功した場合、ID通知メールの送信を行う
            axios.request({
                url: baseURL + "/send-mail",
                method: "post",
                headers: {
                    Authorization: `${token}`,
                },
                data: {
                    "insUpdKbn": "1",
                    "userId": userData.userId,
                    "caseNo": "4"
                }
            })
            .then((response) => {
                getCustomerManagementData();
                axios.request({
                    url: baseURL + "/send-mail",
                    method: "post",
                    headers: {
                        Authorization: `${token}`,
                    },
                    data: {
                        "mail_send_manage_no": response.data.data.mail_send_manage_no,
                        "caseNo": "2"
                    }
                })
                .then((response) => {
                    console.log(response);
                    setIsLoading(false);
                    setMailSuccess(true);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    setMailFailed(true);
                })
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                setMailFailed(true);
            })
        })
        .catch((error) => {
            console.log(error);
            setMailFailed(true);
        });
    }
    
    const handleClick = () => {
        setOpenCheckBox(!openCheckBox);
    };
    
    const handleOpenSendIdDialog = () => {
      setSendIdDialogOpen(true);
    };
    
    const handleCloseSendIdDialog = () => {
      setSendIdDialogOpen(false);
    };

    // 保存の際のメールアドレスの入力チェック
    const checkUpdateMailAddress = (): boolean => {
        // メール送信要否が"送信要"であった場合のみメールアドレスが入力されていないかチェックする
        if (mailSendCode === "1" && mailAddress === "") return setMailAddressError(ERROR_MESSAGE_15), false;
        if (mailAddress.length > 100) return setMailAddressError(ERROR_MESSAGE_16), false;
        if (mailAddress.match(/[^\x01-\x7E]/g) !== null) return setMailAddressError(ERROR_MESSAGE_17), false;
        return true;
    };
    
    // ID通知メール送信の際のメールアドレスの入力チェック
    const checkMailAddress = (): boolean => {
        // ID通知メール送信の場合は、メールアドレスの入力を絶対にチェックする
        if (mailAddress === "") return setMailAddressError(ERROR_MESSAGE_15), false;
        if (mailAddress.length > 100) return setMailAddressError(ERROR_MESSAGE_16), false;
        if (mailAddress.match(/[^\x01-\x7E]/g) !== null) return setMailAddressError(ERROR_MESSAGE_17), false;
        return true;
    };
    
    // ID通知メールを送信する関数
    const handleSendIdNotificationMail = () => {
      // ID通知メール送信処理を実行
      sendIdNotificationMail();
      handleCloseSendIdDialog();
    };
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "720px", 
                height: "100%", }}}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
            <IconButton
                onClick={() => handleClose()}
                style={{position: "absolute", top: 10, right: 10}}
                size="large"
            >
                <CloseIcon />
            </IconButton>
              
            <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>お客さま情報編集</Typography>
            <Spacer size={20} />
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>ユーザID</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{userData.userId}</StyledTypography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>ユーザ種別</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{userData.userType}</StyledTypography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>契約ID</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{userData.contractId}</StyledTypography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>契約名</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{userData.contractName}</StyledTypography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>需要場所ID</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.requestPointId}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>需要場所名</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.requestPointName}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>供給地点特定番号</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.supplyPointNo}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>料金プラン</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.feeMenuNamePrinting}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>契約容量</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.contractCapacity}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>供給開始日</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.supplyStartDate}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>契約終了日</StyledTypography>
                        {userData.userType === "需要場所単位"?
                            <StyledTypography sx={{ marginLeft: "10px" }}>{userData.contractEndDate !== "2099/12/31"? userData.contractEndDate : "-"}</StyledTypography>
                            : <StyledTypography sx={{ marginLeft: "10px" }}>-</StyledTypography>
                        }
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>ID発行通知</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{userData.idNoticeStatusCode === "1"? "通知済": "未通知"}</StyledTypography>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <StyledTypography>最終ログイン日時</StyledTypography>
                        <StyledTypography sx={{ marginLeft: "10px" }}>{(userData.lastLoginDatetime === null || typeof(userData.lastLoginDatetime) === "undefined")? "-": dayjs(userData.lastLoginDatetime).format("YYYY/MM/DD HH:mm:ss")}</StyledTypography>
                    </div>
                </Grid>
            </Grid>
            
            <Spacer size={10} />
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <StyledTypography>メール送信要否</StyledTypography>
                <FormControl sx={{ marginLeft: "10px" }}>
                    <RadioGroup row value={mailSendCode} onChange={(e) => setMailSendCode(e.target.value)}>
                        <FormControlLabel value="1" control={<Radio />} label="送信要" />
                        <FormControlLabel value="0" control={<Radio />} label="送信不要" />
                    </RadioGroup>
                </FormControl>
            </div>
            
            <Spacer size={10} />
            
            <StyledTypography>メールアドレス</StyledTypography>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                        value={mailAddress}
                        onChange={(e) => {
                            setMailAddress(e.target.value);
                            setMailAddressError('');
                        }}
                        size="small"
                        style={{ width: '300px', backgroundColor: "#FFFFFF" }} />
                    {userData.idNoticeStatusCode === "0"?
                        <StyledButton
                            style={{ height: '40px' }}
                            variant="outlined"
                            onClick={() => {
                                const chkMailAddress = checkMailAddress();
                                if (!chkMailAddress) return;
                                handleOpenSendIdDialog()}}
                        >ID通知メール送信</StyledButton> : <div />
                    }
                </div>
                <ErrorTypography variant="caption">{mailAddressError !== ""? mailAddressError : ""}</ErrorTypography>
            </div>
                
            <Spacer size={10} />
                
            <StyledTypography>有効期限</StyledTypography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                 value={validPeriodDate}
                 onChange={(newValue: Dayjs | null) => setValidPeriodDate(newValue)}
                 format="YYYY/MM/DD"
                 slotProps={{ textField: { size: 'small'}}}
                 sx={{ width: "150px", height: "40px" }}
                 views={['year', 'month', 'day']}
                />
            </LocalizationProvider>
              
            <Spacer size={20} />
            
            <ListItemButton sx={{ bgcolor: '#172B65', color: '#FFFFFF', '&:hover': { backgroundColor: '#172B65' } }} onClick={handleClick}>
                {openCheckBox ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary="利用可能機能" />
            </ListItemButton>
            {openCheckBox &&
                <Paper sx={{ backgroundColor: "#FFFFFF", height: userData.userType === "契約単位"? 1620 : 1480, padding: "0 40px" }} variant="outlined" square >
                    <Spacer size={10} />
                    <CheckboxGroup
                        title="お知らせ"
                        items={featuresInfo}
                        checkedState={featuresInfoCheckState}
                        setCheckedState={setFeaturesInfoCheckState}
                    />
                    <CheckboxGroup
                        title="使用実績照会（参照）"
                        items={featuresUsingRefer}
                        checkedState={featuresUsingReferCheckState}
                        setCheckedState={setFeaturesUsingReferCheckState}
                    />
                    <CheckboxGroup
                        title="使用実績照会（ダウンロード）"
                        items={featuresUsingDownload}
                        checkedState={featuresUsingDownloadCheckState}
                        setCheckedState={setFeaturesUsingDownloadCheckState}
                    />
                    <CheckboxGroup
                        title="請求関連書類照会"
                        items={featuresBilling}
                        checkedState={featuresBillingCheckState}
                        setCheckedState={setFeaturesBillingCheckState}
                    />
                    <CheckboxGroup
                        title="契約情報照会"
                        items={featuresContract}
                        checkedState={featuresContractCheckState}
                        setCheckedState={setFeaturesContractCheckState}
                    />
                    <CheckboxGroup
                        title="設定照会"
                        items={featuresSetting}
                        checkedState={featuresSettingCheckState}
                        setCheckedState={setFeaturesSettingCheckState}
                    />
                    <CheckboxGroup
                        title="ヘルプ"
                        items={featuresHelp}
                        checkedState={featuresHelpCheckState}
                        setCheckedState={setFeaturesHelpCheckState}
                    />
                </Paper>
            }
            
            <Spacer size={20} />
                
            <div style={{ display: "flex", alignItems: "center" }}>
                <CancelButton 
                    variant="outlined"
                    onClick={() => handleClose()}
                >キャンセル</CancelButton>
                <SaveButton 
                    onClick={() => {
                        const chkMailAddress = checkUpdateMailAddress();
                        if ( !chkMailAddress ) return;
                        updateCustomerAccount();
                        handleClose();}}
                >保存</SaveButton>
                
                <div style={{ marginLeft: "auto" }}>
                    <StyledButton 
                        variant="outlined"
                        onClick={() => {
                            insteadOperation();
                            handleClose();
                        }}
                    >代理運用</StyledButton>
                </div>
            </div>
            
            <Dialog
              open={sendIdDialogOpen}
              onClose={handleCloseSendIdDialog}
            >
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center' }}>
                        ID通知メールを送信します。<br />よろしいですか。
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <CancelButton
                        variant="outlined"
                        onClick={handleCloseSendIdDialog}
                    >キャンセル</CancelButton>
                    <SaveButton 
                        onClick={handleSendIdNotificationMail}
                    >送信</SaveButton>
                </DialogActions>
            </Dialog>
            
        </DialogContent>
    </Dialog>
  );
}