import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { useAuth } from '../../../utils/hooks/use-auth';
import { LocationInterface } from '../../interfaces/LocationInterface';
import { StyledTab } from '../../styles/style';

interface TabProps {
    initPage: string;
    pointID: string;
    inputValue: string;
    locationData: LocationInterface[];
};

export default function UsingAchievementTab(props: TabProps) {
  const { initPage, pointID, inputValue, locationData } = props;
  const navigate = useNavigate();
  const auth = useAuth();
  const [value, setValue] = React.useState(initPage);
  // tabの横の長さをタブの表示数で変更する
  const tabWidth = 100 - 20 * auth.userAuthority.substring(1, 6).split('').filter(char => char === '0').length;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    switch (newValue) {
        case "annual":
            navigate('/user/using-achievement/annual', { state : { pointID: pointID, inputValue: inputValue, locationData: locationData }});
            break;
        case "monthly":
            navigate('/user/using-achievement/monthly', { state : { pointID: pointID, inputValue: inputValue, locationData: locationData }});
            break;
        case "weekly":
            navigate('/user/using-achievement/weekly', { state : { pointID: pointID, inputValue: inputValue, locationData: locationData }});
            break;
        case "daily":
            navigate('/user/using-achievement/daily', { state : { pointID: pointID, inputValue: inputValue, locationData: locationData }});
            break;
        case "weekday":
            navigate('/user/using-achievement/weekday', { state : { pointID: pointID, inputValue: inputValue, locationData: locationData }});
            break;
        default:
            break;
    }
  };

  return (
    <Box sx={{ width: tabWidth.toString() + '%' }}>
      <Box sx={{ borderBottom: 2, borderColor: '#0098CB' }}>
        <Tabs 
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            sx={{ '& .MuiTab-root:not(:last-child)': { mr: 1 } }}
        >
            {auth.userAuthority[1] === "1"? <StyledTab label="年間" value="annual"/> : <div />}
            {auth.userAuthority[2] === "1"? <StyledTab label="月間" value="monthly"/> : <div />}
            {auth.userAuthority[3] === "1"? <StyledTab label="週間" value="weekly"/> : <div />}
            {auth.userAuthority[4] === "1"? <StyledTab label="日別" value="daily"/> : <div />}
            {auth.userAuthority[5] === "1"? <StyledTab label="曜日別" value="weekday"/> : <div />}
        </Tabs>
      </Box>
    </Box>
  );
}