import React, { useState, useEffect } from 'react';
import PrivateRoute from '../components/PrivateRoute';
import { useAuth } from '../../utils/hooks/use-auth';
import { useMultipleChecked } from "../../utils/hooks/useMultipleChecked"
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// custom components
import { Spacer } from '../../utils/components/Spacer';
import ContractInformationLinkBox from '../components/ContractInformation/ContractInformationLinkBox';
import ContractInformationTable from '../components/ContractInformation/ContractInformationTable';
import SearchBoxContractInformation from '../components/ContractInformation/SearchBoxContractInformation';
// custom hooks
import { useContractInformation } from "../hooks/useContractInformation";
// custom styled components
import { StyledDiv, StyledTypograpy } from '../styles/style';


const itemsPerPage: number = 20;

export function ContractInformation() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
   checked,
   toggleChecked,
   allCheck,
   clearCheck 
  } = useMultipleChecked<number>([...Array(itemsPerPage)].map((_, i) => i));
  
  const { 
    page,
    searchedData,
    curPageData,
    getDownloadList,
    setSearchConditionRequestPointName,
    requestPointNameList,
    setSearchConditionRequestPointId,
    requestPointIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionFeeMenuNamePrinting,
    feeMenuNamePrintingList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation
  } = useContractInformation();
  
  useEffect(() => {
    const getData = async() => {
      try {
        const _ = await getDownloadList();
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    getData();
  }, []);
  
  return (
    <PrivateRoute>
      <div>
        <StyledDiv>
          <Spacer size={50} />
　　        <StyledTypograpy variant="h5">契約情報</StyledTypograpy>
　　          {(!isLoading ?
　　            <div>
                  {auth.userAuthority.substring(18, 23) !== "00000"?
                    <div>
                      <ContractInformationLinkBox />
                      <Spacer size={20} />
                    </div> : <div />
                  }
                  {auth.userAuthority[13] === "1"?
                    <div>
                      <SearchBoxContractInformation 
                        setSearchConditionRequestPointName={setSearchConditionRequestPointName}
                        requestPointNameList={requestPointNameList}
                        setSearchConditionRequestPointId={setSearchConditionRequestPointId}
                        requestPointIdList={requestPointIdList}
                        setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
                        supplyPointNoList={supplyPointNoList}
                        setSearchConditionAddress={setSearchConditionAddress}
                        addressList={addressList}
                        setSearchConditionFeeMenuNamePrinting={setSearchConditionFeeMenuNamePrinting}
                        feeMenuNamePrintingList={feeMenuNamePrintingList}
                        setSearchConditionVoltageClass={setSearchConditionVoltageClass}
                        setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                        resetData={resetData} />
                      <Spacer size={30} />
                      <ContractInformationTable
                        data={curPageData}
                        toggleChecked={toggleChecked}
                        checkState={checked}
                        allCheck={allCheck}
                        clearCheck={clearCheck}
                        searchedData={searchedData}
                        totalDataNum={searchedData.length} 
                        page={page} 
                        pagenation={pagenation}
                      />
                    </div> : <div />
                  }
                </div>
              :
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
          )}
        </StyledDiv>
      </div>
    </PrivateRoute>
  );
}

