import React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAuth } from '../../utils/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import EneresIcon from '../../assets/img/common/Eneres_Secondary_Logo.png'
import Chip from '@mui/material/Chip';
import { StyledHeader, LogoutButton } from '../styles/kanriStyle';

export default function MenuHeader() {
  const auth = useAuth();
  const navigate = useNavigate();
  
  const onLogoutButtonClick = () => {
      localStorage.removeItem('token');
      auth.signOut();
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledHeader>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={EneresIcon} style={{ width: '150px' }} onClick={() => navigate({ pathname: '/kanri/menu' })}/>
            <Chip label="管理画面" sx={{ backgroundColor: "#FFFFFF", color: "#172B65", marginRight: "20px" }}/>
          </div>
          
          <div style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
            <Typography sx={{ fontWeight: "bold", marginRight: "30px" }} variant="subtitle1">{`${auth.adminName} さま`}</Typography>
            
            <LogoutButton
             variant="text"
             startIcon={<LogoutOutlinedIcon />}
             onClick={onLogoutButtonClick}>ログアウト</LogoutButton>
          </div>
        </Toolbar>
      </StyledHeader>
    </Box>
  );
}