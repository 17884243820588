import React from 'react';
import { StyledDiv, LoginButton } from '../styles/style';
import { useNavigate } from 'react-router-dom';
import { Spacer } from '../../utils/components/Spacer';

export function PasswordForgotSuccess(props: any) {
    const navigate = useNavigate();
    const navigateToLogin = function () {
        navigate('/signin');
    }
    return(
        <StyledDiv>
          <div style={{ width: "700px", margin: "30px auto 0 auto", fontSize: "20px", color: "#00506B" }}>
              <h3>お客さまページ パスワード再設定メール送信が完了しました</h3>
              ご登録いただいたメールアドレス宛にパスワード再設定メールを<br/>
              送信いたしましたので、ご確認お願い致します。<br/>
              <Spacer size={20} />
              ※メールが届かない方は、迷惑メールフォルダに振り分けられているか、<br/>
              ドメイン指定受信が設定されている可能性があります<br/>
              ご確認お願い致します。<br/>
              上記に該当しない場合、入力メールアドレスをご確認の上、<br/>
              再度お手続きをお願い致します。<br/>
              <div><LoginButton onClick={navigateToLogin}>ログイン画面へ</LoginButton></div>
          </div>
        </StyledDiv>
    )
}