import React, { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { Spacer } from '../../../utils/components/Spacer';
import { SearchItemsInterface } from '../../interfaces/InformationManagement';
import { StyledButton } from '../../styles/kanriStyle';

type Props = {
  setSearchItems: (setSearchItems: SearchItemsInterface) => void;
  getInformationManagement: () => void;
}

export default function SearchBoxInformationManagement(props: Props) {
  const [title, setTitle] = useState('');
  const [startDateHead, setStartDateHead] = useState<Dayjs | null>(null);
  const [startDateTail, setStartDateTail] = useState<Dayjs | null>(dayjs());
  const [endDateHead, setEndDateHead] = useState<Dayjs | null>(dayjs());
  const [endDateTail, setEndDateTail] = useState<Dayjs | null>(null);
  
  const { setSearchItems, getInformationManagement } = props;
  
  useEffect(() => {
    setSearchItems({
      title: title,
      startDateHead: startDateHead,
      startDateTail: startDateTail,
      endDateHead: endDateHead,
      endDateTail: endDateTail});
  }, [title, startDateHead, startDateTail, endDateHead, endDateTail])
  
  const onClickClearButton = () => {
    setTitle('');
    setStartDateHead(null);
    setStartDateTail(dayjs());
    setEndDateHead(dayjs());
    setEndDateTail(null);
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#172B65', color: '#FFFFFF', '&:hover': { backgroundColor: '#172B65' } }}>
        <ListItemText primary="検索条件" />
      </ListItemButton>
      <Paper sx={{ backgroundColor: "#C9CACA26", height: 260, padding: "0 40px" }} variant="outlined" square >
        <Spacer size={10} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="body1">件名</Typography>
            <TextField value={title} onChange={(e) => setTitle(e.target.value)} sx={{ backgroundColor: "#FFFFFF" }} size="small" fullWidth/>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">公開開始日</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={startDateHead}
                 format="YYYY/MM/DD HH:mm"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={(newValue: Dayjs | null) => setStartDateHead(newValue)}
                 views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
              <Typography variant="h5">~</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={startDateTail}
                 format="YYYY/MM/DD HH:mm"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={(newValue: Dayjs | null) => setStartDateTail(newValue)}
                 views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">公開終了日</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={endDateHead}
                 format="YYYY/MM/DD HH:mm"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={(newValue: Dayjs | null) => setEndDateHead(newValue)}
                 views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
              <Typography variant="h5">~</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                  value={endDateTail}
                  format="YYYY/MM/DD HH:mm"
                  ampm={false}
                  slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                  onChange={(newValue: Dayjs | null) => setEndDateTail(newValue)}
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <StyledButton
                onClick={() => {
                  getInformationManagement();
                }}>検索</StyledButton>
              <StyledButton onClick={onClickClearButton}>クリア</StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}