import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { UsingAchievementTableCell } from '../../styles/style';

interface Props {
    data: PowerData[];
};

interface PowerData {
    target_ym: string;
    daytime_use_power_kWh: number;
    nighttime_use_power_kWh: number;
};


export default function UsingAcievementTable(props: Props) {
  const { data } = props;
  const currentMonth = dayjs().format("YYYY/MM"); // 現在の年月を取得
    
  return (
    <TableContainer component={Paper} elevation={0} sx={{ width: 395, height: 378, border: '1px solid', borderColor: '#DFDFED' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>年月</TableCell>
            <UsingAchievementTableCell padding="none">全体(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">昼間(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">夜間(kWh)</UsingAchievementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const isFutureMonth = dayjs(row.target_ym).isAfter(currentMonth); // 今日の月より未来かどうかを判定
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#CBE8FA' : '#FFFFFF' }}
              >
                <TableCell>{dayjs(row.target_ym).format("YYYY/MM")}</TableCell>
                <TableCell align="right">{isFutureMonth ? "-" : (Number(row.daytime_use_power_kWh) + Number(row.nighttime_use_power_kWh)).toLocaleString()}</TableCell>
                <TableCell align="right">{isFutureMonth ? "-" : Number(row.daytime_use_power_kWh).toLocaleString()}</TableCell>
                <TableCell align="right">{isFutureMonth ? "-" : Number(row.nighttime_use_power_kWh).toLocaleString()}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}