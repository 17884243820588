import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface AutoCompleteProps {
  value: string[];
  setValue: (pointName: string[]) => void;
  requestList: string[];
  setSearchConditionRequest: (requestList: string[]) => void;
};

export default function AutoComplete(props: AutoCompleteProps) {
  const { value, setValue, requestList, setSearchConditionRequest } = props;
    
  return (
    <Box
      sx={{
          border: '1px solid #ced4da', // 枠線のスタイル
          borderRadius: '4px', // 枠線の角丸
          backgroundColor: "#FFFFFF", // 背景色
          '& .MuiAutocomplete-root': { // Autocompleteのスタイルを調整
            borderRadius: '4px', // Autocompleteの角丸をBoxと同じにする
          },
      }}
    >
      <Autocomplete
        multiple
        size="small"
        options={requestList}
        noOptionsText="対象なし"
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              size="small"
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => (
          <TextField 
            {...params}
            placeholder={value.length === 0 ? " - ": ""}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: { height: '40px', overflow: 'auto', overflowX: 'hidden', borderRadius: '4px' },
            }}
          />
        )}
        onChange = {(event, value) => {
          setValue([...value]);
          setSearchConditionRequest([...value]);
        }}
        value={value}
        sx={{ backgroundColor: "#FFFFFF" }}
      />
    </Box>
  );
}