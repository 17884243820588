import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import EditDialog from './EditDialog';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoginInformationManagementRecordInterface, LoginInformationManagementDialogInterface } from '../../interfaces/LoginInformationManagement';
import { StyledButton, StyledTableSortLabel } from '../../styles/kanriStyle';

interface Order {
  information_type: 'asc' | 'desc',
  information_title: 'asc' | 'desc',
  publicate_period_start_datetime: 'asc' | 'desc'
}

type OrderBy = keyof Order;

interface LoginInformationManagementTableProps {
  searchedData: LoginInformationManagementRecordInterface[];
  page: number;
  pagenation: (page: number) => void;
  getLoginInformationManagement: () => void;
  setEditSuccess: (openSuccess: boolean) => void;
  setEditFailed: (openFailed: boolean) => void;
}

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function LoginInformationTable(props: LoginInformationManagementTableProps) {
  const { searchedData, page, pagenation, getLoginInformationManagement, setEditSuccess, setEditFailed } = props;
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<Order>({
    information_type: 'asc',
    information_title: 'asc',
    publicate_period_start_datetime: 'asc',
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('publicate_period_start_datetime');
  const [infoData, setInfoData] = useState<LoginInformationManagementDialogInterface>({
    informationId: "",
    informationType: "",
    informationTitle: "",
    informationText: "",
    externalLinkUrl: "",
    publicatePeriodStartDatetime: "",
    publicatePeriodEndDatetime: "",
  });
  
   const handleOpen = (id: string, type: string, title: string, text: string, uri: string, start: string, end: string) => {
    const records = {
      informationId: id,
      informationType: type,
      informationTitle: title,
      informationText: text,
      externalLinkUrl: uri,
      publicatePeriodStartDatetime: start,
      publicatePeriodEndDatetime: end
    };
    setInfoData(records);
    setOpen(true);
  };
  
  const handleClose = () => setOpen(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  // カラムごとによる行の並び替え
  const compare = (a: LoginInformationManagementRecordInterface, b: LoginInformationManagementRecordInterface): number => {
    if (orderBy === 'information_type') {
      if (a.information_type < b.information_type) return order.information_type === 'asc' ? -1 : 1;
      if (a.information_type > b.information_type) return order.information_type === 'asc' ? 1 : -1;
    } else if (orderBy === 'information_title') {
      if (a.information_title < b.information_title) return order.information_title === 'asc' ? -1 : 1;
      if (a.information_title > b.information_title) return order.information_title === 'asc' ? 1 : -1;
    } else if (orderBy === 'publicate_period_start_datetime') {
      if (a.publicate_period_start_datetime < b.publicate_period_start_datetime) return order.publicate_period_start_datetime === 'asc' ? -1 : 1;
      if (a.publicate_period_start_datetime > b.publicate_period_start_datetime) return order.publicate_period_start_datetime === 'asc' ? 1 : -1;
      if (a.publicate_period_start_datetime === b.publicate_period_start_datetime) {
        if (a.publicate_period_end_datetime < b.publicate_period_end_datetime) return order.publicate_period_start_datetime === 'asc' ? -1 : 1;
        if (a.publicate_period_end_datetime > b.publicate_period_end_datetime) return order.publicate_period_start_datetime === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
    
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page)*itemsPerPage < searchedData.length ? (page)*itemsPerPage : searchedData.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'information_type'}
                  direction={order.information_type}
                  onClick={() => handleRequestSort('information_type')}>分類</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'information_title'}
                  direction={order.information_title}
                  onClick={() => handleRequestSort('information_title')}>件名</StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'publicate_period_start_datetime'}
                  direction={order.publicate_period_start_datetime}
                  onClick={() => handleRequestSort('publicate_period_start_datetime')}>公開期間</StyledTableSortLabel>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#D9E1FB' : '#FFFFFF' }}
              >
                <TableCell>{row.information_type === "0"? "通常": "重要なお知らせ"}</TableCell>
                <TableCell>{row.information_title}</TableCell>
                <TableCell>{`${row.publicate_period_start_datetime}~${row.publicate_period_end_datetime !== "2099/12/31 23:59"? row.publicate_period_end_datetime : ""}`}</TableCell>
                <TableCell>
                  <StyledButton
                    variant="outlined"
                    onClick={() => handleOpen(
                      row.information_id,
                      row.information_type,
                      row.information_title,
                      row.information_text,
                      row.external_link_url,
                      row.publicate_period_start_datetime,
                      row.publicate_period_end_datetime)}
                    >編集</StyledButton>
                  
                  <EditDialog
                      open={open}
                      getLoginInformationManagement={getLoginInformationManagement}
                      handleClose={handleClose}
                      infoData={infoData}
                      setEditSuccess={setEditSuccess}
                      setEditFailed={setEditFailed}/>
                      
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (searchedData.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}