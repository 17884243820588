import React, { useState, MouseEventHandler } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InsertChartOutlinedSharpIcon from '@mui/icons-material/InsertChartOutlinedSharp';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useAuth } from '../../utils/hooks/use-auth';
import { useNavigate} from 'react-router-dom';
import EneresIcon from '../../assets/img/common/Eneres_Secondary_Logo.png'
import Link from '@mui/material/Link';
import { getCustomerManualPdf } from '../../utils/functions';
import { StyledHeader, HeaderBox, HeaderPaper, LogoutButton } from '../styles/style';

interface HeaderItemProps {
  uri: string;
  screen: string;
}

const HeaderItem: React.FC<HeaderItemProps> = ({uri, screen}) => {
  const navigate = useNavigate();
  
  const handleClick: MouseEventHandler = (event) => {
    event.preventDefault();
    navigate(uri);
  }

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px", cursor: "pointer" }} onClick={handleClick}>
      <ArrowForwardIosIcon sx={{ fontSize: 10 }}/>
      <Typography sx={{ fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }} variant="subtitle1">{screen}</Typography>  
    </div>
  )
}

export default function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [usingAchievementOpen, setUsingAchievementOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  
  const handleUsingAchievementMouseOver = () => setUsingAchievementOpen(true);
  const handleUsingAchievementMouseLeave = () => setUsingAchievementOpen(false);
  const handleInvoiceMouseOver = () => setInvoiceOpen(true);
  const handleInvoiceMouseLeave = () => setInvoiceOpen(false);
  const handleSettingMouseOver = () => setSettingOpen(true);
  const handleSettingMouseLeave = () => setSettingOpen(false);
  const handleHelpMouseOver = () => setHelpOpen(true);
  const handleHelpMouseLeave = () => setHelpOpen(false);
  
  const onLogoutButtonClick = () => {
    localStorage.removeItem('token');
    auth.signOut();
  };
  
  return (
    <Box>
      <StyledHeader>
        <Toolbar>
          <div>
            <img src={EneresIcon} style={{ width: '150px' ,cursor: "pointer" }} onClick={() => navigate({ pathname: '/user/top' })}/>
          </div>
          {auth.userAuthority.substring(1, 9) !== "00000000"?
            <HeaderBox
              onMouseOver={handleUsingAchievementMouseOver}
              onMouseLeave={handleUsingAchievementMouseLeave}>
              <InsertChartOutlinedSharpIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "default" }} variant="subtitle1">使用実績</Typography>
              {usingAchievementOpen && (
                <HeaderPaper sx={{ width: auth.userAuthority.substring(1, 6) === "00000"? "200px" : auth.userAuthority.substring(6, 9) === "000"? "200px" : "320px" }} square={true}>
                  <div style={{ display: "flex" }}>
                    {auth.userAuthority.substring(1, 6) !== "00000"?
                      <div>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">実績データ</Typography>
                        {auth.userAuthority[1] === "1"? <HeaderItem uri="/user/using-achievement/annual" screen="年間"/> : <div />}
                        {auth.userAuthority[2] === "1"? <HeaderItem uri="/user/using-achievement/monthly" screen="月間"/> : <div />}
                        {auth.userAuthority[3] === "1"? <HeaderItem uri="/user/using-achievement/weekly" screen="週間"/> : <div />}
                        {auth.userAuthority[4] === "1"? <HeaderItem uri="/user/using-achievement/daily" screen="日別"/> : <div />}
                        {auth.userAuthority[5] === "1"? <HeaderItem uri="/user/using-achievement/weekday" screen="曜日別"/> : <div />}
                      </div> : <div />
                    }
                    {auth.userAuthority.substring(6, 9) !== "000"?
                      <div style={{ marginLeft: auth.userAuthority.substring(1, 6) !== "00000" ? "60px" : "0px" }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">ダウンロード</Typography>
                        {auth.userAuthority[8] === "1"? <HeaderItem uri="/user/using-achievement/download/monthly" screen="月別30分単位実績"/> : <div />}
                        {auth.userAuthority[7] === "1"? <HeaderItem uri="/user/using-achievement/download/day-and-night" screen="昼夜間電力量"/> : <div />}
                        {auth.userAuthority[6] === "1"? <HeaderItem uri="/user/using-achievement/download/report" screen="月次レポート"/> : <div />}
                      </div> : <div />
                    }
                  </div>
                </HeaderPaper>
              )}
            </HeaderBox> : <div />
          }
          {auth.userAuthority.substring(9, 13) !== "0000"?
            <HeaderBox
              onMouseOver={handleInvoiceMouseOver}
              onMouseLeave={handleInvoiceMouseLeave}>
              <CurrencyYenIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "default" }} variant="subtitle1">請求・明細</Typography>
              {invoiceOpen && (
                <HeaderPaper sx={{ width: auth.userAuthority.substring(9, 11) === "00"? "280px" : auth.userAuthority.substring(11, 13) === "00"? "150px" : "430px" }} square={true}>
                  <div style={{ display: "flex" }}>
                    {auth.userAuthority.substring(9, 11) !== "00"?
                      <div>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">帳票</Typography>
                        {auth.userAuthority[9] === "1"? <HeaderItem uri="/user/billing-document/invoice" screen="請求書"/> : <div />}
                        {auth.userAuthority[10] === "1"? <HeaderItem uri="/user/billing-document/specification" screen="明細書"/> : <div />}
                      </div> : <div />
                    }
                    {auth.userAuthority.substring(11, 13) !== "00"?
                      <div style={{ marginLeft: auth.userAuthority.substring(9, 11) !== "00" ? "60px" : "0px" }}>
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">ダウンロード</Typography>
                        {auth.userAuthority[11] === "1"? <HeaderItem uri="/user/billing-document/pdf-download" screen="請求書・明細書一括ダウンロード"/> : <div />}
                        {auth.userAuthority[12] === "1"? <HeaderItem uri="/user/billing-document/specificationlist-download" screen="明細一覧ダウンロード"/> : <div />}
                      </div> : <div />
                    }
                  </div>
                </HeaderPaper>
              )}
            </HeaderBox> : <div />
          }
          {auth.userAuthority[13] === "1" || auth.userAuthority.substring(18, 23) !== "00000"? 
            <HeaderBox onClick={() => navigate({ pathname: '/user/contract-information' })} style={{cursor: "pointer"}}>
              <DescriptionOutlinedIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">契約情報</Typography>
            </HeaderBox> : <div />
          }
          {auth.userAuthority.substring(14, 18) !== "0000"?
            <HeaderBox
              onMouseOver={handleSettingMouseOver}
              onMouseLeave={handleSettingMouseLeave}>
              <SettingsOutlinedIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "default" }} variant="subtitle1">設定</Typography>
              {settingOpen && (
                <HeaderPaper sx={{ width: "200px", paddingTop: 0 }} square={true}>
                  {auth.userAuthority[14] === "1"? <HeaderItem uri="/user/setting/electricity-alarm" screen="使用電力アラーム"/> : <div />}
                  {auth.userAuthority[15] === "1"? <HeaderItem uri="/user/setting/mail" screen="メールアドレス変更"/> : <div />}
                  {auth.userAuthority[16] === "1"? <HeaderItem uri="/user/setting/password" screen="パスワード変更"/> : <div />}
                  {auth.userAuthority[17] === "1"? <HeaderItem uri="/user/setting/billing-password" screen="需要場所ID通知"/> : <div />}
                </HeaderPaper>
              )}
            </HeaderBox> : <div />
          }
          {auth.userAuthority.substring(23, 26) !== "000"? 
            <HeaderBox
              onMouseOver={handleHelpMouseOver}
              onMouseLeave={handleHelpMouseLeave}>
              <HelpOutlineOutlinedIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "default" }} variant="subtitle1">ヘルプ</Typography>
              {helpOpen && (
                <HeaderPaper sx={{ width: "280px", paddingTop: 0 }} square={true}>
                  {auth.userAuthority[23] === "1"? 
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px" }}>
                      <ArrowForwardIosIcon sx={{ fontSize: 10, marginRight: 1 }}/>
                      <Link 
                        component="button" 
                        onClick={() => getCustomerManualPdf("エナリスお客さまサイト_操作マニュアル.pdf")} 
                        sx={{ color: "#333333" }} 
                        underline="none">
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">操作マニュアル</Typography>
                      </Link>
                    </div> : <div />}
                  {auth.userAuthority[24] === "1"?
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px" }}>
                      <ArrowForwardIosIcon sx={{ fontSize: 10, marginRight: 1 }}/>
                      <Link 
                        component="button" 
                        onClick={() => getCustomerManualPdf("EAサービスご利用の手引き(導入後).pdf")} 
                        sx={{ color: "#333333" }} 
                        underline="none">
                        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">エネルギーエージェントサービス　ご利用の手引き</Typography>
                      </Link>
                    </div> : <div />}
                  {auth.userAuthority[25] === "1"?
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px" }}>
                      <MailOutlineIcon sx={{ fontSize: 15, marginRight: 1 }}/>
                      <a href="mailto:ec_support@eneres.co.jp" style={{ textDecoration: 'none' }}>
                        <Typography sx={{ fontWeight: "bold", color: "#333333" }} variant="subtitle1">当サイトに関するお問い合わせ</Typography>
                      </a>
                    </div> : <div />}
                </HeaderPaper>
              )}
            </HeaderBox> : <div />
          }
          
          <div style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
            <Typography sx={{ fontWeight: "bold", marginRight: "30px" }} variant="subtitle1">{`${auth.name} さま`}</Typography>
            
            {typeof(auth.adminName) === "undefined"?
              <LogoutButton
                variant="text"
                startIcon={<LogoutOutlinedIcon />}
                onClick={onLogoutButtonClick}>ログアウト</LogoutButton>
              : <div />
            }
          </div>
        </Toolbar>
      </StyledHeader>
    </Box>
  );
}