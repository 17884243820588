import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import PrivateRoute from '../components/PrivateRoute';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Spacer } from '../../utils/components/Spacer';
import { LocationInterface } from '../interfaces/LocationInterface';
import { LocationState } from '../interfaces/UsingAchievement';
import SelectDemandLocation from '../components/SelectDemandLocation';
import UsingAchievementTab from '../components/Tab/UsingAchievementTab';
import UsingAchievementAnnualTable from '../components/UsingAchievementAnnual/UsingAchievementAnnualTable';
import UsingAchievementAnnualChart from '../components/UsingAchievementAnnual/UsingAchievementAnnualChart';
import { StyledDiv, StyledTypograpy } from '../styles/style';
import { getCurrentUserToken } from '../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

interface PowerData {
    target_ym: string;
    daytime_use_power_kWh: number;
    nighttime_use_power_kWh: number;
};

const sumPowerData = (powerData: PowerData[]): PowerData => {
  const target_ym = 'Total';
  const daytime_use_power_kWh = powerData.reduce((acc, item) => acc + Number(item.daytime_use_power_kWh), 0);
  const nighttime_use_power_kWh = powerData.reduce((acc, item) => acc + Number(item.nighttime_use_power_kWh), 0);
  return { target_ym, daytime_use_power_kWh, nighttime_use_power_kWh };
};


export function UsingAchievementAnnual() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation() as LocationState;
    const [res, setRes] = useState<PowerData[]>([]);
    const [targetYear, setTargetYear] = useState<Dayjs | null>(dayjs());
    const [inputValue, setInputValue] = useState('');
    const [pointID, setPointID] = useState('');
    const [locationData, setLocationData] = useState<LocationInterface[]>([]);
    const [totalData, setTotalData] = useState<PowerData>({target_ym: "", daytime_use_power_kWh: 0, nighttime_use_power_kWh: 0});
    
    // 需要場所データの取得
    const getDemandLocation = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/demand-location",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'contract_id': auth.contractID
            }
        })
        .then((response) => {
            const pointsSorted = response.data["data"].sort((a: LocationInterface, b: LocationInterface) => a.request_point_id.localeCompare(b.request_point_id));
            setPointID(pointsSorted[0]["request_point_id"]);
            setInputValue(pointsSorted[0]["request_point_name"]);
            setLocationData(pointsSorted);
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        if (typeof(auth.requestPointID) === "undefined") {
            // 使用実績画面が初めて開かれた場合、需要場所を取得する
            if (typeof(location.state?.locationData) === "undefined") {
                getDemandLocation();
            } else {
            // タブでの遷移だった場合、そのデータを引き継ぐ
                setPointID(location.state?.pointID);
                setInputValue(location.state?.inputValue);
                setLocationData(location.state?.locationData);
            }
        } else {
            setPointID(auth.requestPointID);
        }
    }, []);
    
    // 年間の使用実績データの取得
    const getUsingAchievementAnnualData = async (fiscalYear: string | null) => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/volume-usage/year",
            method: "post",
            headers: {
                Authorization: `${token}`,
            },
            data : {
                "year": fiscalYear,
                "request_point_id": pointID
            }
        })
        .then((response) => {
            setRes(response.data["data"]["data"]);
            if (fiscalYear === "") {
                setTargetYear(dayjs(response.data["data"]["year"]));
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
    
    useEffect(() => {
        if (pointID !== "") getUsingAchievementAnnualData("");
    }, [pointID]);
    
    useEffect(() => {
        setTotalData(sumPowerData(res));
    }, [res]);
    
    const callGetAnnualData = (newYear: Dayjs | null) => {
        const fiscalYear = newYear !== null? newYear.format("YYYY") : null;
        getUsingAchievementAnnualData(fiscalYear);
    };
    
    const handlePrevYear = () => {
        setTargetYear(targetYear !== null ? targetYear.add(-1, 'year') : null);
        callGetAnnualData(targetYear !== null ? targetYear.add(-1, 'year') : null);
    };
    
    const handleNextYear = () => {
        setTargetYear(targetYear !== null ? targetYear.add(1, 'year') : null);
        callGetAnnualData(targetYear !== null ? targetYear.add(1, 'year') : null);
    };
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={50} />
                <StyledTypograpy variant="h5">実績データ</StyledTypograpy>
                <Spacer size={20} />
                {typeof(auth.requestPointID) === "undefined"? (
                    <>
                        <SelectDemandLocation location={locationData} inputValue={inputValue} setInputValue={setInputValue} setPointID={setPointID}/>
                        <Spacer size={20} />
                    </>
                    ) : <div />
                }
                <UsingAchievementTab initPage={"annual"} pointID={pointID} inputValue={inputValue} locationData={locationData}/>
                <Spacer size={70} />
                
                <div style={{ display: 'flex' }}>
                    <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                <DateTimePicker
                                    value={targetYear}
                                    onChange={(newValue: Dayjs | null) => {
                                        setTargetYear(newValue);
                                        callGetAnnualData(newValue);
                                    }}
                                    format="YYYY"
                                    slotProps={{ textField: { size: 'small'}}}
                                    sx={{ width: "150px", height: "40px" }}
                                    views={['year']}
                                />
                            </LocalizationProvider>
                            <Button variant="text" startIcon={<ArrowBackIosIcon />} onClick={() => handlePrevYear()}>
                                前年度
                            </Button>
                            <Button variant="text" endIcon={<ArrowForwardIosIcon />} onClick={() => handleNextYear()}>
                                来年度
                            </Button>
                        </Stack>
                        
                        <Spacer size={20} />
                        
                        <Box sx={{ width: 395, display: 'flex', justifyContent: "space-between"}}>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>合計(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{(Number(totalData.daytime_use_power_kWh) + Number(totalData.nighttime_use_power_kWh)).toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>昼間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{Number(totalData.daytime_use_power_kWh).toLocaleString()}</Typography>
                            </div>
                            <div>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>夜間(kWh)</Typography>
                                <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "right" }}>{Number(totalData.nighttime_use_power_kWh).toLocaleString()}</Typography>
                            </div>
                        </Box>
                        
                        <Spacer size={20} />
                        
                        <UsingAchievementAnnualTable data={res}/>
                    </div>
                    
                    <Box sx={{ minWidth: "725px", width: "1250px", height: "530px" }}>
                        <UsingAchievementAnnualChart data={res}/>
                    </Box>
                </div>
                
                <Spacer size={40} />
                
                <div style={{ display: 'flex' }}>
                    <Typography>※</Typography>
                    <Typography>スマートメーターや通信機器の状態などで、「使用実績」に誤差が生まれる場合がございます。　実際の使用電力については、検針票・計算書にてご確認ください。</Typography>
                </div>
                {auth.userAuthority[14] === "1"? 
                    <div style={{ display: 'flex' }}>
                        <Typography>※</Typography>
                        <Typography>しきい値の変更は<span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0098CB' }} onClick={() => navigate("/user/setting/electricity-alarm")}>設定＞使用電力アラーム</span>から可能です。</Typography>
                    </div> : <div />
                }
            </StyledDiv>
        </PrivateRoute>
    );
}