import React ,{ useState } from 'react';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SettingTab from '../components/Tab/SettingTab';

import { StyledDiv, StyledTypograpy } from '../styles/style';
import SettingBillingPasswordTable from '../components/SettingBillingPasswordTable';
import { useSettingBillingPassword } from '../hooks/useSettingBillingPassword';
import SnackBar from '../../utils/components/SnackBar';
import { Box, CircularProgress } from '@mui/material';

export function SettingBillingPassword() {
    const { 
        page,
        data,
        curPageData,
        setData,
        pagenation,
        getSettingBillingPassword,
        totalDataNum,
        isLoading,
        isProcessing,
        sendIdNotificationMail,
        idPassCheck,
        mailSuccess,
        mailFailed,
        setMailSuccess,
        setMailFailed
    } = useSettingBillingPassword();
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={50} />
                <StyledTypograpy variant="h5">設定</StyledTypograpy>
                <Spacer size={20} />
                <SettingTab initPage={"billing-password"} />
                {isProcessing &&
                    <Box
                        style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255,255,255, 0.6)",
                            zIndex: "1000"
                        }}
                    >
                        <CircularProgress />
                    </Box>
                }
                <SettingBillingPasswordTable
                    data={data}
                    curPageData={curPageData}
                    setData={setData}
                    isLoading={isLoading}
                    page={page}
                    totalDataNum={totalDataNum}
                    pagenation={pagenation}
                    getBillingPasswordData={getSettingBillingPassword}
                    sendIdNotificationMail={sendIdNotificationMail}
                    idPassCheck={idPassCheck}
                />
                <SnackBar
                    successMessage="ID通知メールを送信しました"
                    openSuccess={mailSuccess}
                    setOpenSuccess={setMailSuccess}
                    failedMessage="ID通知メール送信が失敗しました"
                    openFailed={mailFailed}
                    setOpenFailed={setMailFailed}/>
                <Spacer size={10} />
            </StyledDiv>
        </PrivateRoute>
    );
}