import * as React from 'react';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

type Props = {
  searchData: (targetMonth: string) => void;
  targetDateList: string[],
  resetState: boolean,
  clearResetSatate: () => void;
}

export default function TargetMonth(props: Props) {
  const { searchData, targetDateList, resetState, clearResetSatate } = props;
  const [targetMonth, setTargetMonth] = React.useState("");
  
  const handleTargetMonthChange = (event: SelectChangeEvent) => {
    setTargetMonth(event.target.value as string);
  };
  
  React.useEffect(() => {
    setTargetMonth(`${targetDateList.length - 1}`);
  }, [targetDateList]);
  
  React.useEffect(() => {
    if (targetMonth === ""){
      searchData("");
    }
    else{
      searchData(targetDateList[Number(targetMonth)]);
    }
  }, [targetMonth]);

  React.useEffect(() => {
    if(resetState === true){
      setTargetMonth(`${targetDateList.length - 1}`);
      clearResetSatate();
    }
  }, [resetState]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <Typography className="c-search__select__title" variant="body1">対象年月</Typography>
        </Grid>
        <Grid item xs={1.5}>
          <FormControl size="small" fullWidth>
            <Select
              value={targetMonth}
              onChange={handleTargetMonthChange}
              MenuProps={{
                MenuListProps: {
                  style: {
                    maxHeight: "200px",
                    overflowY: "auto"
                  }
                }
              }}
            >
            {targetDateList.map((month, index) => (
                <MenuItem value={index}>{month}</MenuItem>
            ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}