import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import PrivateRoute from '../components/PrivateRoute';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TargetMonth from '../components/BillingPDFDownload/TargetMonth';
import SearchBoxPDFDownload from '../components/BillingPDFDownload/SearchBoxPDFDownload';
import { Spacer } from '../../utils/components/Spacer';
import PDFDownloadTable from '../components/BillingPDFDownload/PDFDownloadTable';
import BillingDownloadTab from '../components/Tab/BillingDownloadTab';
import { usePDFDownload } from "../hooks/usePDFDownload";
import { StyledDiv, StyledTypograpy, StyledFooter, StyledToolbar } from '../styles/style';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;

interface pdfFile {
  filename: string;
  blob: Blob;
}

export function BillingPDFDownload() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  
  const { 
    page,
    searchedData,
    getPDFDownloadData,
    nameList,
    setSearchConditionName,
    contractIdList,
    setSearchConditionContractId,
    supplyPointNoList,
    setSearchConditionSupplyPointNo,
    addressList,
    setSearchConditionAddress,
    setSearchConditionVoltageClass,
    setSearchConditionFiletype,
    resetData,
    pagenation,
    allCheck,
    singleCheck
  } = usePDFDownload();
  
  useEffect(() => {
    const getData = async() => {
      try {
        const _ = await getPDFDownloadData("", "");
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    getData();
  }, []);
  
  const onLambdaPdfButtonClick = async () => {
    setIsDownload(true);
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    // ダウンロード対象のファイル名を抽出
    const downloadFiles: any[] = [];
    const downloadFilePaths: string[] = [];
    const downloadFileNames: string[] = [];
    const targetYMs: string[] = [];
    searchedData.forEach((obj) => {
      if(obj.checked) {
        let downloadFile: {downloadFilePaths: string, downloadFileNames: string} = {
          "downloadFilePaths" : obj.system_file_name,
          "downloadFileNames": obj.download_file_name
        };
        downloadFilePaths.push(obj.system_file_name);
        downloadFileNames.push(obj.download_file_name);
        targetYMs.push(obj.target_ym);
        downloadFiles.push(downloadFile);
      }
    });
      
    if(downloadFiles.length === 0){
      window.alert("ダウンロード対象のファイルが存在しません")
      setIsDownload(false);
    }
    // 単一のpdfファイルをダウンロード
    else if(downloadFiles.length === 1){
      axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/pdf",
            Authorization: token,
          },
          data:{
            "downloadFiles": downloadFilePaths
          }
      })
      .then((response) => {
        const blob: Blob = toBlobPdf(response.data, "application/pdf");
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', downloadFileNames[0]);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownload(false);
      });
    }
    // zip形式のpdfファイル群をダウンロード
    else{
      const end = Math.max(...targetYMs.map(Number));
      const start = Math.min(...targetYMs.map(Number));
      
      if (downloadFiles.length <= 100) {
        axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/zip",
            Authorization: token,
          },
          data:{
            "downloadFiles": downloadFiles
          }
        })
        .then((response) => {
          const blob: Blob = toBlobZip(response.data);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${auth.contractID}_${start}-${end}.zip`);
          document.body.appendChild(link);
          link.click();
          setIsDownload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsDownload(false);
        });
      } else {
        // 1つのAPIが対処するファイル数をおよそ150件にする分割数の決定
        const segmentCount = downloadFiles.length / 100;
        
        // ダウンロードするファイル群をsegmentCountの数だけ等分する
        const chunkSize = Math.ceil(downloadFiles.length / segmentCount);
        const fileChunks = [];
        for (let i = 0; i < downloadFiles.length; i += chunkSize) {
          fileChunks.push(downloadFiles.slice(i, i + chunkSize));
        }
        
        // 新しいzipインスタンスを作成
        const finalZip = new JSZip();
        
        // 各チャンクごとにAPIリクエストを行い、結果を結合する
        try {
          const zipParts = await Promise.all(fileChunks.map(async (chunk, index) => {
            const response = await axios.request({
              url: baseURL + "/billing-inquery-invoice-download",
              method: "post",
              headers: { 
                Accept: "application/zip",
                Authorization: token,
              },
              data:{
                "downloadFiles": chunk
              }
            });
            
            // base64エンコードされたzipデータをBlobに変換
            const blob: Blob = toBlobZip(response.data);
      
            const zip = new JSZip();
            await zip.loadAsync(blob);
      
            const pdfFiles: Promise<pdfFile>[] = [];
            zip.forEach((relativePath, file) => {
              if (relativePath.endsWith('.pdf')) {
                pdfFiles.push(file.async('blob').then((blob) => ({
                  filename: relativePath,
                  blob: blob
                })));
              }
            });
            
            return await Promise.all(pdfFiles);
          }));
          
          zipParts.flat().forEach(({ filename, blob }) => {
            finalZip.file(filename, blob);
          });
        
          // 最終的なzipファイルを生成してダウンロード
          finalZip.generateAsync({ type: 'blob' }).then(function (content) {
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${auth.contractID}_${start}-${end}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          
        } catch (error) {
          console.error(error);
        } finally {
          setIsDownload(false);
        }
      }
    }
  };
  
  function toBlobPdf(base64: string, mime_ctype: string): Blob {
    // 日本語の文字化けに対処するためBOMを作成する。
    var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([bom, buffer.buffer], {
        type: mime_ctype,
    });
    return blob;
  };
  
  // base64エンコードされたzip形式のファイルをblob型に変換する
  function toBlobZip(base64: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'application/zip'
    });
    return blob;
  };
  
  return (
    <PrivateRoute>
      <StyledDiv>
        <Spacer size={50} />
        <StyledTypograpy variant="h5">データダウンロード</StyledTypograpy>
        <Spacer size={20} />
        <BillingDownloadTab initPage={"pdf-download"} />
        <Spacer size={20} />
        <TargetMonth searchedData={searchedData} getPDFDownloadData={getPDFDownloadData} setIsLoading={setIsLoading}/>
        <Spacer size={20} />
        {(!isLoading ?
          <>
            <SearchBoxPDFDownload 
              nameList={nameList}
              setSearchConditionName={setSearchConditionName}
              contractIdList={contractIdList}
              setSearchConditionContractId={setSearchConditionContractId}
              addressList={addressList}
              setSearchConditionAddress={setSearchConditionAddress}
              supplyPointNoList={supplyPointNoList}
              setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
              setSearchConditionVoltageClass={setSearchConditionVoltageClass}
              setSearchConditionFiletype={setSearchConditionFiletype}
              resetData={resetData} />
            <Spacer size={30} />
            <PDFDownloadTable
              allCheck={allCheck}
              singleCheck={singleCheck}
              searchedData={searchedData}
              page={page} 
              pagenation={pagenation}/>
          </>
          :
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </StyledDiv>
      <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
        <StyledToolbar>
          <StyledButton
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onLambdaPdfButtonClick}>PDFダウンロード</StyledButton>
          {isDownload && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </StyledToolbar>
      </StyledFooter>
    </PrivateRoute>
  );
}

/* Styles */
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  color: '#0098CB',
  fontWeight: 'bold',
  margin: 10,
  padding: 10,
  '&:hover': {
    backgroundColor: "#FFFFFF"
  }
}));
