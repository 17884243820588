import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const userPoolId: string = process.env.REACT_APP_USER_POOL_ID || "";
const clientId: string = process.env.REACT_APP_USER_WEB_CLIENT_ID || '';
const region: string = process.env.REACT_APP_REGION || '';

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
  Region: region
};

const userPool = new CognitoUserPool(poolData);

export function Verify() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(''); // passwordを宣言
  const [code, setCode] = useState('');

  const executeVerify = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      auth.signIn(username, password)
        .then(() => {
          navigate({ pathname: '/dashboard' });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  return (
    <div className="verify-formContainer">
      <form noValidate onSubmit={executeVerify}>
        <div>
          <label htmlFor="username">ユーザ名: </label>
          <input
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">パスワード: </label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="code">検証コード: </label>
          <input
            id="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <button type="submit" className="verify-button">確認</button>
      </form>
    </div>
  );
}