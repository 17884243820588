import React, { useState, useEffect }  from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SettingTab from '../components/Tab/SettingTab';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { SHA256 } from 'crypto-js';
import { StyledDiv, StyledTypograpy, SaveButton, SettingMailBox, SettingMailTextBox, ErrorTypography } from '../styles/style';
import { passwordPolicy } from '../../utils/functions';

export function SettingPassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [isPwValid, setIsPwValid] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [validateError, setValidateError] = useState('');
    
    const baseURL = process.env.REACT_APP_API_ENDPOINT;
    
    const auth = useAuth();
    const userId = typeof(auth.customerUserId) !== "undefined"? auth.customerUserId: auth.username;
    
    const ERR_MSG01 = "現在のパスワードを入力してください。";
    const ERR_MSG02 = "新しいパスワードを入力してください。";
    const ERR_MSG03 = "新しいパスワード(再入力)を入力してください。";
    const ERR_MSG04 = "新しいパスワードと新しいパスワード(再入力)は一致させてください。";
    const ERR_MSG05 = "新しいパスワードは現在のパスワードと異なるようにしてください。";
    const ERR_MSG06 = "パスワード制約を満たすようなパスワードを入力してください。";
    
    const resetPassword = async () => {
        clearMsg();
        setValidateError('');
        setIsSuccess(false);
        setOldPassword('');
        setNewPassword('');
        setNewConfirmPassword('');
    }
    const [errors, setErrors] = useState({
    "oldPasswordError": '',
      "passwordError" : '',
      "confirmPwError": '',
      "confirmCodeErr": '',
      "resetError"    : ''
    });
    
    function clearMsg() {
      setErrors({...errors,
      "oldPasswordError": '',
        "passwordError" : '',
        "confirmPwError": '',
        "confirmCodeErr": '',
        "resetError"    : ''
      });
    }
    const addResetRecord = async () => {
      axios.request({
        url: baseURL + "/password-reset-check",
        method: "post",
        headers: { 
          Accept: "application/json"
        },
        data: {
          'user_id': userId,
          'hashed_password': SHA256(newPassword).toString(),
          'operator': 'add'
        }
      }).then((response) => {
        
        if(response.data.result != true) {
          setErrors({...errors, resetError: "パスワード設定が失敗しました、時間を空けて再度設定いただくか、お問い合わせください。"})
        } else {
          setIsSuccess(true);
        }
      })
      .catch((error) => {
        
      });
    }
    const validatePassword = (v: string) => {
      if (v.length === 0) {
        setValidateError("");
        return false;
      }
      const numberRegex = /\d/;
      const specialCharRegex = passwordPolicy;
      const lowerCaseRegex = /[a-z]/;
      const fullWidthRegex = /[^\x00-\xff]/;
      setValidateError('');
      return (v.length >= 10 && v.length <= 50 && numberRegex.test(v) && specialCharRegex.test(v) && lowerCaseRegex.test(v) && !fullWidthRegex.test(v))
    }
    const handleSave = async () => {
      setIsSuccess(false);
      if (oldPassword === "") {
        setErrors({...errors, "oldPasswordError": ERR_MSG01})
        return;
      }
      if(newPassword === "") {
        setErrors({...errors, "passwordError": ERR_MSG02})
        return;
      }
      if(newConfirmPassword === "") {
        setErrors({...errors, "confirmPwError": ERR_MSG03})
        return;
      }
      if(newPassword != newConfirmPassword) {
        setErrors({...errors, "confirmPwError": ERR_MSG04});
        return;
      }
      if(newPassword == oldPassword) {
        setErrors({...errors, "passwordError": ERR_MSG05});
        return;
      }
      if(!validatePassword(newPassword)) {
        setErrors({...errors, "resetError": ERR_MSG06});
        return;
      }
      axios.request({
          url: baseURL + "/password-reset-check",
          method: "post",
          headers: { 
            Accept: "application/json"
          },
          data: {
            'user_id': userId,
            'hashed_password': SHA256(newPassword).toString()
          }
      })
      .then(async (response) => {
        if(response.data.result == false) {
          setErrors({...errors, resetError: "直近指定したパスワード3回分と同じものは使用できません"})
        } else {
          const result = await auth.changePassword(oldPassword, newPassword);
          if (result.success) {
            await addResetRecord();
          } else {
            setErrors({...errors, resetError: result.message})
          }
        }
      })
      .catch((error) => {});
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={50} />
                <StyledTypograpy variant="h5">設定</StyledTypograpy>
                <Spacer size={20} />
                
                <SettingTab initPage={"password"} />
                
                <Spacer size={40} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <StyledTypograpy>{isSuccess? '保存しました': ''}</StyledTypograpy>
                  <ErrorTypography variant="caption">{errors.resetError !== ""? errors.resetError : ""}</ErrorTypography>
                </div>
                <Spacer size={10} />
                
                
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    
                    <SettingMailBox>
                        <StyledTypograpy>現在のパスワード</StyledTypograpy>
                    </SettingMailBox>
                    
                    <SettingMailTextBox>
                        <TextField
                            type="password"
                            value={oldPassword} 
                            onChange={(e) => {
                                setOldPassword(e.target.value);
                                clearMsg();
                            }}
                            size="small"
                            style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                        
                        <ErrorTypography variant="caption">{errors.oldPasswordError !== ""? errors.oldPasswordError : ""}</ErrorTypography>
                    </SettingMailTextBox>
                    
                </div>
                
                <Spacer size={30}/>
                
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                
                    <SettingMailBox style={{ height: '140px' }}>
                        <StyledTypograpy>新しいパスワード</StyledTypograpy>
                    </SettingMailBox>
                    
                    <SettingMailTextBox style={{ height: '140px' }}>
                        <TextField
                            type="password"
                            value={newPassword} 
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                clearMsg();
                            }}
                            size="small"
                            style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                        <ErrorTypography variant="caption">{errors.passwordError !== ""? errors.passwordError : ""}</ErrorTypography>
                        <ErrorTypography variant="caption">{validateError !== ""? validateError : ""}</ErrorTypography>
                        <Spacer size={10}/>
                        <TextField
                            type="password"
                            value={newConfirmPassword} 
                            onChange={(e) => {
                                setNewConfirmPassword(e.target.value);
                                clearMsg();
                            }}
                            size="small"
                            style={{ width: '600px', backgroundColor: "#FFFFFF" }} />※確認のため、同じパスワードを2回入力してください。
                        
                        <ErrorTypography variant="caption">{errors.confirmPwError !== ""? errors.confirmPwError : ""}</ErrorTypography>
                    </SettingMailTextBox>
                    
                </div>
                
                <Spacer size={20}/>
                <div style={{ textAlign: 'right', paddingRight: '20%'}}>
                  <SaveButton
                      onClick={handleSave}>保存</SaveButton>
                  <SaveButton
                      onClick={resetPassword}>リセット</SaveButton>
                </div>
                <Spacer size={20}/>
                <div style={{ margin: '0 auto', width: '850px'}}>
                  ※パスワード制約は以下のとおりです <br/>
                  ・パスワードは、10～50文字にしてください<br/>
                  ・半角英字、半角数字、記号を1文字以上含むものにしてください<br/>
                  ・ユーザー名と同じものはパスワードに使用できません<br/>
                  ・直近指定したパスワード3回分と同じものは使用できません
                </div>
            </StyledDiv>
        </PrivateRoute>
    );
}