import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBox from '../components/CustomerManagement/SearchBoxCustomerManagement';
import CustomerManagementTable from '../components/CustomerManagement/CustomerManagementTable';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SnackBar from '../../utils/components/SnackBar';
import { useCustomerManagement } from "../hooks/useCustomerManagement";
import { StyledDiv, StyledTypography } from '../styles/kanriStyle';

export function CustomerManagement() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [editSuccess, setEditSuccess] = useState(false);
    const [editFailed, setEditFailed] = useState(false);
    const [mailSuccess, setMailSuccess] = useState(false);
    const [mailFailed, setMailFailed] = useState(false);
    
    const { 
        page,
        searchedData,
        curPageData,
        getCustomerManagementData,
        resetData,
        pagenation,
        setSearchConditionUserId,
        setSearchConditionContractId,
        setSearchConditionContractName,
        setSearchConditionRequestPointId,
        setSearchConditionRequestPointName,
        setSearchConditionSupplyDate,
        setSearchConditionUnit,
        setSearchConditionMailAddress,
        setSearchConditionIdStatus,
        setSearchConditionSendMail,
        searchData
    } = useCustomerManagement();
    
    useEffect(() => {
        const getData = async() => {
            try {
                const _ = await getCustomerManagementData();
                setIsLoading(false);
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
    }, []);
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={70} />
                <StyledTypography variant="h5">お客さま一覧照会</StyledTypography>
                <Spacer size={20} />
                
                <SearchBox 
                    setSearchConditionUserId={setSearchConditionUserId}
                    setSearchConditionContractId={setSearchConditionContractId}
                    setSearchConditionContractName={setSearchConditionContractName}
                    setSearchConditionRequestPointId={setSearchConditionRequestPointId}
                    setSearchConditionRequestPointName={setSearchConditionRequestPointName}
                    setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                    setSearchConditionUnit={setSearchConditionUnit}
                    setSearchConditionMailAddress={setSearchConditionMailAddress}
                    setSearchConditionIdStatus={setSearchConditionIdStatus}
                    setSearchConditionSendMail={setSearchConditionSendMail}
                    searchedData={searchedData}
                    searchData={searchData}
                    resetData={resetData}/>
                    
                <Spacer size={30} />
                
                {!isLoading?
                    <CustomerManagementTable
                        data={curPageData}
                        searchedData={searchedData}
                        totalDataNum={searchedData.length} 
                        page={page}
                        pagenation={pagenation}
                        getCustomerManagementData={getCustomerManagementData}
                        setIsLoading={setIsLoading}
                        setEditSuccess={setEditSuccess}
                        setEditFailed={setEditFailed}
                        setMailSuccess={setMailSuccess}
                        setMailFailed={setMailFailed}/>
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
                
                <SnackBar
                    successMessage="更新が成功しました"
                    openSuccess={editSuccess}
                    setOpenSuccess={setEditSuccess}
                    failedMessage="更新が失敗しました"
                    openFailed={editFailed}
                    setOpenFailed={setEditFailed}/>
                    
                <SnackBar
                    successMessage="メール送信を指示しました"
                    openSuccess={mailSuccess}
                    setOpenSuccess={setMailSuccess}
                    failedMessage="メール送信が失敗しました"
                    openFailed={mailFailed}
                    setOpenFailed={setMailFailed}/>
            </StyledDiv>
        </PrivateRoute>
    );
}