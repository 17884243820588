import React, { FC, useState, useEffect } from "react";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from './utils/hooks/use-auth';
import { SignIn } from './user/pages/SignIn';
import { ForgetPw } from './user/pages/ForgetPw';
import { ForgetPwConfirm } from './user/pages/ForgetPwConfirm';
import { FirstLogin } from './user/pages/FirstLogin'
import { PasswordResetSuccess } from './user/pages/PasswordResetSuccess'
import { PasswordForgotSuccess } from './user/pages/PasswordForgotSuccess'
import { SignUp } from './user/pages/SignUp';
import { Verify } from './user/pages/Verify';
import { BillingInvoice } from './user/pages/BillingInvoice';
import { BillingSpecification } from './user/pages/BillingSpecification';
import { BillingPDFDownload } from './user/pages/BillingPDFDownload';
import { BillingSpecificationListDownload } from './user/pages/BillingSpecificationListDownload';
import { Top } from './user/pages/Top';
import { Information } from './user/pages/Information';
import { UsingAchievementAnnual } from './user/pages/UsingAchievementAnnual';
import { UsingAchievementMonthly } from './user/pages/UsingAchievementMonthly';
import { UsingAchievementDaily } from './user/pages/UsingAchievementDaily';
import { UsingAchievementWeekly } from './user/pages/UsingAchievementWeekly';
import { UsingAchievementWeekday } from './user/pages/UsingAchievementWeekday';
import { UsingAchievementDownloadMonthly } from './user/pages/UsingAchievementDownloadMonthly';
import { UsingAchievementDownloadDayAndNight } from './user/pages/UsingAchievementDownloadDayAndNight';
import { UsingAchievementDownloadReport } from './user/pages/UsingAchievementDownloadReport';
import { ContractInformation } from './user/pages/ContractInformation';
import { SettingElectricityAlarm } from './user/pages/SettingElectricityAlarm';
import { SettingMail } from './user/pages/SettingMail';
import { SettingPassword } from './user/pages/SettingPassword';
import { SettingBillingPassword } from './user/pages/SettingBillingPassword';
import KanriSignIn  from './kanri/pages/SignIn';
import { Menu } from './kanri/pages/Menu';
import { CustomerManagement } from './kanri/pages/CustomerManagement';
import { LoginInformationManagement } from './kanri/pages/LoginInformationManagement';
import { ManagePersonAccount } from './kanri/pages/ManagePersonAccount';
import { InformationManagement } from './kanri/pages/InformationManagement';
import { InformationRegistration } from './kanri/pages/InformationRegistration';
import { ChangePassword } from './kanri/pages/ChangePassword';
import { BillingRelease } from './kanri/pages/BillingRelease';
import { Navigate } from 'react-router-dom';
import UserHeader from './user/components/Header';
import KanriHeader from './kanri/components/Header';
import KanriMenuHeader from './kanri/components/MenuHeader';
import UserFooter from './user/components/Footer';
import KanriFooter from './kanri/components/Footer';
import KanriMenuFooter from './kanri/components/MenuFooter';

// Headerの表示について判定する
interface UseLocationEffectProps {
  setIsNotHeader: React.Dispatch<React.SetStateAction<boolean>>; // Headerを表示するか否かの判定(true: 表示しない、false: 表示する)
  setIsUser: React.Dispatch<React.SetStateAction<boolean>>; // ユーザか管理者かを判定(true: ユーザ、false: 管理者)
  setIsMenu: React.Dispatch<React.SetStateAction<boolean>>; // 管理者のメニュー画面か否かの判定(true: メニュー画面、false: メニュー画面以外)
}

const UseLocationEffect: FC<UseLocationEffectProps> = ({ setIsNotHeader, setIsUser, setIsMenu }) => {
  const location = useLocation();

  useEffect(() => {
    setIsNotHeader(location.pathname.startsWith('/signin') || location.pathname.startsWith('/user/forgotpw') || location.pathname.startsWith('/user/forgotpw-confirm') || 
      location.pathname.startsWith('/user/first-login') || location.pathname.startsWith('/user/password-reset-success') ||
      location.pathname.startsWith('/user/password-forgot-success"') || location.pathname.startsWith('/kanri/signin'));
    setIsUser(location.pathname.startsWith('/user'));
    setIsMenu(location.pathname.startsWith('/kanri/menu'));
  }, [location, setIsNotHeader, setIsUser, setIsMenu]);

  return null;
};

function App() {
  const auth = useAuth();
  const [isNotHeader, setIsNotHeader] = useState(false);
  const [isUser, setIsUser] = useState(true);
  const [isMenu, setIsMenu] = useState(false);
  
  // ======================= セッションタイムアウトの設定 =================================
  // 1. 60分アプリケーションに動きがなかった場合、Cognitoからログアウトする
  const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || "",
    ClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID || "",
    Region: process.env.REACT_APP_REGION || "",
  };
  
  const userPool = new CognitoUserPool(poolData);
  let inactivityTimer: NodeJS.Timeout;
  
  // ユーザーの操作を監視する関数
  const startInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      // トークンを失効させる
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
          window.alert("セッションがタイムアウトしました。再度ログインしてください。");
          cognitoUser.signOut();
          window.location.reload();
        
      }
    }, 60 * 60 * 1000); // 60分後にタイムアウトする
  }
  
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    startInactivityTimer();
  }

  useEffect(() => {
    // ユーザーの操作を監視するイベントリスナーを設定する
    document.addEventListener('mousedown', resetInactivityTimer);
    document.addEventListener('mousemove', resetInactivityTimer);
    document.addEventListener('keydown', resetInactivityTimer);
    document.addEventListener('scroll', resetInactivityTimer);

    // コンポーネントがアンマウントされたときにイベントリスナーを解除する
    return () => {
      document.removeEventListener('mousedown', resetInactivityTimer);
      document.removeEventListener('mousemove', resetInactivityTimer);
      document.removeEventListener('keydown', resetInactivityTimer);
      document.removeEventListener('scroll', resetInactivityTimer);
      clearTimeout(inactivityTimer);
    };
  }, [userPool]);
  
  // 2. アプリを開いたときに、前回タブを消したときの時間から60分以上経っていたらログアウトする
  useEffect(() => {
    const SESSION_KEY = "myAppLastClosedTime";
    const lastClosedTime = parseInt(localStorage.getItem(SESSION_KEY) || "0", 10);
    const currentTime = new Date().getTime();
    if (lastClosedTime > 0 && (currentTime - lastClosedTime) > (60 * 60 * 1000)) {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.signOut();
      }
      localStorage.removeItem(SESSION_KEY);
    } else {
      localStorage.setItem(SESSION_KEY, currentTime.toString());
    }
  }, []);
  
  // ======================================================================================
  
  if (auth.isLoading) {
    return <div></div>;
  }

  return (
    <BrowserRouter>
      <UseLocationEffect setIsNotHeader={setIsNotHeader} setIsUser={setIsUser} setIsMenu={setIsMenu}/>
      {!auth.isAuthenticated || isNotHeader || isUser && typeof(auth.name) === "undefined"? <div /> :
        isUser ? <UserHeader /> : isMenu ? typeof(auth.adminName) !== "undefined"? <KanriMenuHeader />: <div /> : typeof(auth.adminName) !== "undefined" ? <KanriHeader />: <div />}
      <div style={{ minHeight: "100vh", position: "relative" }}> 
        <div style={{ paddingTop: 70, paddingBottom: 80 }}> 
          <Routes>
            <Route path="/" element={auth.isAuthenticated && typeof(auth.name) !== "undefined"? <Navigate to={"/user/top"} /> : <Navigate to={"/signin"} />} />
            <Route path="user/" element={auth.isAuthenticated && typeof(auth.name) !== "undefined"? <Navigate to={"/user/top"} /> : <Navigate to={"/signin"} />} />
            <Route path="signin" element={<SignIn />} />
            <Route path="user/forgotpw" element={<ForgetPw />} />
            <Route path="user/forgotpw-confirm" element={<ForgetPwConfirm />} />
            <Route path="user/first-login" element={<FirstLogin />} />
            <Route path="user/password-reset-success" element={<PasswordResetSuccess />} />
            <Route path="user/password-forgot-success" element={<PasswordForgotSuccess />} />
            <Route path="user/top" element={typeof(auth.name) !== "undefined" ? <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/information" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[0] === "1"? <Information /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/billing-document/invoice" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[9] === "1"? <BillingInvoice /> : <Top /> :<Navigate to={"/signin"} />} />
            <Route path="user/billing-document/specification" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[10] === "1"? <BillingSpecification /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/billing-document/pdf-download" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[11] === "1"? <BillingPDFDownload /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/billing-document/specificationlist-download" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[12] === "1"? <BillingSpecificationListDownload /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/annual" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[1] === "1"? <UsingAchievementAnnual /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/monthly" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[2] === "1"? <UsingAchievementMonthly /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/weekly" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[3] === "1"? <UsingAchievementWeekly /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/daily" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[4] === "1"? <UsingAchievementDaily /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/weekday" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[5] === "1"? <UsingAchievementWeekday /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/download/monthly" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[8] === "1"? <UsingAchievementDownloadMonthly /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/download/day-and-night" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[7] === "1"? <UsingAchievementDownloadDayAndNight /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/using-achievement/download/report" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[6] === "1"? <UsingAchievementDownloadReport /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/contract-information" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[13] === "1" || auth.userAuthority.substring(18, 23) !== "00000"? <ContractInformation /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/setting/electricity-alarm" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[14] === "1"? <SettingElectricityAlarm /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/setting/mail" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[15] === "1"? <SettingMail /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/setting/password" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[16] === "1"? <SettingPassword /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="user/setting/billing-password" element={typeof(auth.name) !== "undefined" ? auth.userAuthority[17] === "1"? <SettingBillingPassword /> : <Top /> : <Navigate to={"/signin"} />} />
            <Route path="kanri/" element={auth.isAuthenticated && typeof(auth.adminName) !== "undefined"? <Navigate to={"/kanri/menu"} /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/signin" element={<KanriSignIn />} />
            <Route path="kanri/menu" element={<Menu />} />
            <Route path="kanri/customer-management" element={typeof(auth.adminName) !== "undefined" ? <CustomerManagement /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/login-information-management" element={typeof(auth.adminName) !== "undefined" ? <LoginInformationManagement /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/manage-person-account" element={typeof(auth.adminName) !== "undefined" ? <ManagePersonAccount /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/information-management" element={typeof(auth.adminName) !== "undefined" ? <InformationManagement /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/information-registration" element={typeof(auth.adminName) !== "undefined" ? <InformationRegistration /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/change-password" element={typeof(auth.adminName) !== "undefined" ? <ChangePassword /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="kanri/billing-release" element={typeof(auth.adminName) !== "undefined" ? <BillingRelease /> : <Navigate to={"/kanri/signin"} />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="verification" element={<Verify />} />
            <Route path="*" element={<p>Page Not Found</p>} />
          </Routes>
        </div>
        
        {!auth.isAuthenticated || isUser && typeof(auth.name) === "undefined"? <KanriFooter />: isUser ? <UserFooter /> : isMenu ? <KanriMenuFooter />: <KanriFooter />}
        
      </div>
    </BrowserRouter>
  );
}

export default App;
