import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Spacer } from '../../../utils/components/Spacer';
import AutoComplete from '../../../utils/components/AutoComplete';

type Props = {
  invoiceNoList: string[],
  searchDataInvoiceNo: (invoiceNo: string[]) => void;
  searchDataFileType: (pdf?: boolean, csv?: boolean, xlsx?: boolean) => void;
  resetData: () => void;
}

export default function SearchBoxInvice(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [invoiceNo, setInvoiceNo] = React.useState<string[]>([]);
  // チェックボックス3つ分
  const [checkedPDF, setCheckedPDF] = React.useState(true);
  const [checkedCSV, setCheckedCSV] = React.useState(true);
  const [checkedXLSX, setCheckedXLSX] = React.useState(true);
  
  const { invoiceNoList, searchDataInvoiceNo, searchDataFileType, resetData } = props;
  
  React.useEffect(() => {
    searchDataFileType(checkedPDF, checkedCSV, checkedXLSX);
  }, [checkedPDF, checkedCSV, checkedXLSX])

  const handleClick = () => {
    setOpen(!open);
  };
  
  const handleChangePDF = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedPDF(event.target.checked);
  };
  const handleChangeCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedCSV(event.target.checked);
  };
  const handleChangeXLSX = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedXLSX(event.target.checked);
  };
  
  const resetConditionOnClick = () => {
    resetData();
    setInvoiceNo([]);
    setCheckedPDF(true);
    setCheckedCSV(true);
    setCheckedXLSX(true);
  };
  
  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#0099CC', color: '#ffffff' }} onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary="詳細絞り込み" />
      </ListItemButton>
      {open &&
        <Paper variant="outlined" square >
          <Spacer size={10} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={0.5}></Grid>
            <Grid item xs={5}>
              <Typography variant="body1">請求書番号</Typography>
              <AutoComplete
                value={invoiceNo}
                setValue={setInvoiceNo}
                requestList={invoiceNoList}
                setSearchConditionRequest={searchDataInvoiceNo}/>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">ダウンロード対象ファイル</Typography>
              <Stack direction="row" spacing={1}>
                <FormControlLabel control={<Checkbox checked={checkedPDF} onChange={handleChangePDF} />} label="請求書" />
                <FormControlLabel control={<Checkbox checked={checkedCSV} onChange={handleChangeCSV} />} label="明細一覧" />
                <FormControlLabel control={<Checkbox checked={checkedXLSX} onChange={handleChangeXLSX} />} label="清算金額内訳" />
              </Stack>
            </Grid>
            <Grid item xs={8.5}></Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={resetConditionOnClick}>検索条件をクリア</Button>
              </Box>
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
        </Paper>
      }
    </div>
  );
}