import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SettingBillingPasswordRecordInterface } from "../interfaces/SettingBillingPassword"

import { Spacer } from '../../utils/components/Spacer';
import { CancelButton, SaveButton } from '../styles/style';
import { Pagination, Typography, CircularProgress, TextField } from '@mui/material';
import { SettingBillingPasswordButton, StyledTableSortLabel, SettingIDNotificationSaveButton } from '../styles/style';
import SnackBar from '../../utils/components/SnackBar';
import { ERROR_MESSAGE_15, ERROR_MESSAGE_16, ERROR_MESSAGE_17 } from '../../utils/errorMessages';

interface SettingBillingPasswordTable {
  data: SettingBillingPasswordRecordInterface[];
  curPageData: SettingBillingPasswordRecordInterface[];
  setData: (data:SettingBillingPasswordRecordInterface[]) => void;
  isLoading: boolean;
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  getBillingPasswordData: () => void;
  idPassCheck: (userId: string, mailAddress: string) => void;
  sendIdNotificationMail: (userId: string) => void;
}

interface Order {
  request_point_name: 'asc' | 'desc';
  user_id: 'asc' | 'desc';
  request_point_id: 'asc' | 'desc';
  id_notice_status_code: 'asc' | 'desc';
  mail_address: 'asc' | 'desc';
}

type OrderBy = keyof Order;

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function SettingBillingPasswordTable(props: SettingBillingPasswordTable) {
    const [sendIdDialogOpen, setSendIdDialogOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMailAddress, setSelectedMailAddress] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [mailCheckSuccess, setMailCheckSuccess] = useState(false);
    const [mailCheckFailed, setMailCheckFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [order, setOrder] = React.useState<Order>({
      user_id: 'asc',
      request_point_name: 'asc',
      request_point_id: 'asc',
      id_notice_status_code: 'asc',
      mail_address: 'asc'
    });
    const [orderBy, setOrderBy] = React.useState<OrderBy>('request_point_id');
    const { data, setData, curPageData, isLoading, page, pagenation, sendIdNotificationMail, idPassCheck } = props;
    const changeUserMail = (index:number, mail_address:string) => {
      const newData = [...data];
      newData[index]['mail_address'] = mail_address;
      setData(newData);
    }
    const checkMailAddress = (mailAddress:string): boolean => {
      if (mailAddress === "") {
        setMailCheckFailed(true);
        setErrorMessage(ERROR_MESSAGE_15);
        return false;
      }
      if (mailAddress.length > 100){
        setMailCheckFailed(true);
        setErrorMessage(ERROR_MESSAGE_16);
        return false;
      }
      if (mailAddress.match(/^[^\x01-\x7E]*$/g) !== null){
        setMailCheckFailed(true);
        setErrorMessage(ERROR_MESSAGE_17);
        return false;
      }
      return true;
    }
    
    const handleRequestSort = (property: OrderBy) => {
      const isAsc = order[property] === 'asc';
      setOrderBy(property);
      setOrder((prevOrder) => ({
        ...prevOrder,
        [property]: isAsc ? 'desc' : 'asc',
      }));
    };
    
    const compare = (a: SettingBillingPasswordRecordInterface, b: SettingBillingPasswordRecordInterface): number => {
      if (orderBy === 'request_point_name') {
        if (a.request_point_name < b.request_point_name) {
          return order.request_point_name === 'asc' ? -1 : 1;
        }
        if (a.request_point_name > b.request_point_name) {
          return order.request_point_name === 'asc' ? 1 : -1;
        }
      } else if (orderBy === 'user_id') {
        if (a.user_id < b.user_id) {
          return order.user_id === 'asc' ? -1 : 1;
        }
        if (a.user_id > b.user_id) {
          return order.user_id === 'asc' ? 1 : -1;
        }
      } else if (orderBy === 'request_point_id') {
        if (a.request_point_id < b.request_point_id) {
          return order.request_point_id === 'asc' ? -1 : 1;
        }
        if (a.request_point_id > b.request_point_id) {
          return order.request_point_id === 'asc' ? 1 : -1;
        }
      } else if (orderBy === 'id_notice_status_code') {
        if (a.id_notice_status_code < b.id_notice_status_code) {
          return order.id_notice_status_code === 'asc' ? -1 : 1;
        }
        if (a.id_notice_status_code > b.id_notice_status_code) {
          return order.id_notice_status_code === 'asc' ? 1 : -1;
        }
      } else if (orderBy === 'mail_address') {
        if (a.mail_address < b.mail_address) {
          return order.mail_address === 'asc' ? -1 : 1;
        }
        if (a.mail_address > b.mail_address) {
          return order.mail_address === 'asc' ? 1 : -1;
        }
      }
      return 0;
    };
    
    const handleOpenSendIdDialog = (userId: string, mailAddress: string) => {
      //idPassCheck(selectedUserId, selectedMailAddress);
      setSelectedUserId(userId);
      setSelectedMailAddress(mailAddress);
      setSendIdDialogOpen(true);
    };
    
    const handleCloseSendIdDialog = () => {
      idPassCheck(selectedUserId, selectedMailAddress);
      setSendIdDialogOpen(false);
    };
    
    const handleConfirmSendMail = () => {
      idPassCheck(selectedUserId, selectedMailAddress);
      setOpenDialog(false);
    };
    
    const handleSendMailClick = (userId: string, mailAddress: string) => {
      setSelectedUserId(userId);
      setSelectedMailAddress(mailAddress);
      setOpenDialog(true);
    };
    
    return (
      <div>
        <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+curPageData.length} / ${data.length}件`}</Typography>
        {!isLoading ?
          <div>
          <TableContainer>
            <Table style={{ margin: '0 auto', width: '80%'}} sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <StyledTableSortLabel
                      active={orderBy === 'user_id'}
                      direction={order.user_id}
                      onClick={() => handleRequestSort('user_id')}>ユーザー名</StyledTableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <StyledTableSortLabel
                      active={orderBy === 'request_point_id'}
                      direction={order.request_point_id}
                      onClick={() => handleRequestSort('request_point_id')}>需要場所ID</StyledTableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <StyledTableSortLabel
                      active={orderBy === 'request_point_name'}
                      direction={order.request_point_name}
                      onClick={() => handleRequestSort('request_point_name')}>需要場所名</StyledTableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <StyledTableSortLabel
                      active={orderBy === 'id_notice_status_code'}
                      direction={order.id_notice_status_code}
                      onClick={() => handleRequestSort('id_notice_status_code')}>ID通知状況</StyledTableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <StyledTableSortLabel
                      active={orderBy === 'mail_address'}
                      direction={order.mail_address}
                      onClick={() => handleRequestSort('mail_address')}>メールアドレス</StyledTableSortLabel>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
                  <TableBody>
                    { data.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((item, index) => (
                        <TableRow>
                            <TableCell align="center">{item['user_id']}</TableCell>
                            <TableCell align="center">{item['request_point_id']}</TableCell>
                            <TableCell align="center">{item['request_point_name']? item['request_point_name']: ""}</TableCell>
                            <TableCell align="center">{item['id_notice_status_code'] == "1"? "通知済" : "未通知"}</TableCell>
                            <TableCell align="center">
                              <TextField 
                                value={item['mail_address']} 
                                onChange={(e) => {
                                    changeUserMail(index, e.target.value);
                                }}
                                size="small"
                                style={{ width: '200px', backgroundColor: "#FFFFFF" }} />
                            </TableCell>
                            <TableCell align="center">
                              <SettingBillingPasswordButton
                                variant="outlined"
                                onClick={() => {
                                  if(checkMailAddress(item['mail_address'])) {
                                    if(item['id_notice_status_code'] == "1") handleSendMailClick(item['user_id'], item['mail_address']);
//                                    else idPassCheck(item['user_id'], item['mail_address']);
                                    else handleOpenSendIdDialog(item['user_id'], item['mail_address']);                                    
                                  }
                                }}
                                >ID通知メール送信</SettingBillingPasswordButton>

                              <Dialog
                                open={sendIdDialogOpen}
                                onClose={() => setSendIdDialogOpen(false)}
                              >
                                <DialogTitle>ID通知メール送信</DialogTitle>
                                <DialogContent>
                                  <DialogContentText style={{ textAlign: 'center' }}>
                                      ID通知メールを送信します。よろしいですか。
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <SettingBillingPasswordButton variant="outlined" onClick={() => setSendIdDialogOpen(false)}>キャンセル</SettingBillingPasswordButton>
                                  <SettingIDNotificationSaveButton onClick={handleCloseSendIdDialog} autoFocus>はい</SettingIDNotificationSaveButton>
                                </DialogActions>
                              </Dialog>
                                
                              <Dialog
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                              >
                                <DialogTitle>ID通知メール送信</DialogTitle>
                                <DialogContent>
                                  <DialogContentText>ID通知済みですが、本当に再度通知を行いますか。</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <SettingBillingPasswordButton variant="outlined" onClick={() => setOpenDialog(false)}>キャンセル</SettingBillingPasswordButton>
                                  <SettingIDNotificationSaveButton onClick={handleConfirmSendMail} autoFocus>はい</SettingIDNotificationSaveButton>
                                </DialogActions>
                              </Dialog>
                            </TableCell>
                            
                        </TableRow>
                    )) }
                  </TableBody>
            </Table>
          </TableContainer>
          <>
            {
              (data.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
            }
          </>
          </div>
          :
          <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
            <CircularProgress />
          </div>
        }
        
        <Spacer size={10} />
        <ThemeProvider theme={pagenationTheme}>
            <Pagination 
              count={(data.length % itemsPerPage === 0) ? (Math.floor((data.length / itemsPerPage) + 1)-1) : (Math.floor((data.length / itemsPerPage) + 1))}
              variant="outlined" 
              shape="rounded" 
              onChange={(e: any, page: number) => {
                  pagenation(page);
                }
              } 
              page={page}
            />
        </ThemeProvider>
        <SnackBar
            successMessage=""
            openSuccess={mailCheckSuccess}
            setOpenSuccess={setMailCheckSuccess}
            failedMessage={errorMessage}
            openFailed={mailCheckFailed}
            setOpenFailed={setMailCheckFailed}/>
      </div>
    )
  
}