import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(AppBar)(({ theme }) => ({
    height: 52,
    position: 'absolute',
    top: 'auto',
    bottom: 0,
    width: "100%",
    backgroundColor: '#F2F2F2',
    color: '#808080',
    boxShadow: 'none',
}));

const FooterContent = styled(Container)(({ theme }) => ({
    maxWidth: '1660px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '0 30px',
}));

export default function Footer() {
    return (
        <StyledFooter>
            <FooterContent>
                <Typography variant="caption">Copyright © 2023 ENERES Co.,Ltd. All rights reserved.</Typography>
            </FooterContent>        
        </StyledFooter>
    );
}