import { useState } from "react";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { SpecificationListDownloadRecordInterface } from '../interfaces/SpecificationListDownload'
 
type useSpecificationListDownloadSearchRet<T> = {
  page: number,
  searchedData: T[],
  getSpecificationList:  (startDate: string | null, endDate: string | null) => Promise<T[]>;
  pagenation: (curPage: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, name: string) => void;
};

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;

// トークン取得
const getCurrentUserToken = async () => {
try {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
} catch (error) {
  console.log(error);
}
};

// メインフック
export function useSpecificationListDownload(): useSpecificationListDownloadSearchRet<SpecificationListDownloadRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [searchedData, setSearchedData] = useState<SpecificationListDownloadRecordInterface[]>([]);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  const pagenation = (curPage: number) => {
    setPage(curPage);
  }
  
  const allCheck = (state: boolean) => {
    const updateAllCheck = searchedData.map((obj) => ({ ...obj, checked: true }));
    
    const updateAllCancel = searchedData.map((obj) => ({ ...obj, checked: false }));
    
    if (state === true){
      setSearchedData(updateAllCheck);
    }
    else if (state === false){
      setSearchedData(updateAllCancel);
    }
  }
  
  const singleCheck = (target_ym: string, document_id: string) => {
    const updatedSingleCheck = searchedData.map((obj) => {
      if (obj["target_ym"] === target_ym && obj["document_id"] === document_id) {
        return { ...obj, checked: !obj["checked"]};
      } else {
        return obj;
      }
    });
    setSearchedData(updatedSingleCheck);
  }

  // 明細書を取得する　
  const getSpecificationList: (start: string | null, end: string | null) => Promise<SpecificationListDownloadRecordInterface[]> = async (start, end) => {
    const token = await getCurrentUserToken();
    const response = axios.request({
      url: baseURL + "/billing-inquery-specificationlist-download",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params : {
        "contractId" : auth.contractID,
        "startDate": start,
        "endDate": end
      }
    })
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i]["checked"] = false;
      }
      setSearchedData(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    getSpecificationList,
    pagenation,
    allCheck,
    singleCheck
  };
}
