import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useAuth } from '../../../utils/hooks/use-auth';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../../utils/components/Spacer';
import { StyledTypography, CancelButton, SaveButton, ErrorTypography, ModalBox } from '../../styles/kanriStyle';
import { LoginInformationManagementDialogInterface } from '../../interfaces/LoginInformationManagement';
import { ERROR_MESSAGE_22, ERROR_MESSAGE_23 } from '../../../utils/errorMessages';
import { getCurrentUserToken } from '../../../utils/functions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
interface Props {
    open: boolean;
    getLoginInformationManagement: () => void;
    handleClose: () => void;
    infoData: LoginInformationManagementDialogInterface;
    setEditSuccess: (editSuccess: boolean) => void;
    setEditFailed: (editFailed: boolean) => void;
};

export default function EditDialog(props: Props) {
    const { open, handleClose, infoData, getLoginInformationManagement, setEditSuccess, setEditFailed } = props;
    const auth = useAuth();
    const [informationId, setInformationId] = useState('');
    const [checkedType, setCheckedType] = useState(false);
    const [informationTitle, setInformationTitle] = useState('');
    const [informationText, setInformationText] = useState('');
    const [externalLinkUrl, setExternalLinkUrl] = useState('');
    const [start, setStart] = useState<Dayjs | null>(dayjs());
    const [end, setEnd] = useState<Dayjs | null>(dayjs());
    const [informationTitleError, setInformationTitleError] = useState('');
    const [informationTextError, setInformationTextError] = useState('');
    
    // Dialogの初期値を各stateに代入
    useEffect(() => {
        if (infoData.informationType !== "") {
            setInformationId(infoData.informationId);
            setCheckedType(infoData.informationType === "1"? true: false);
            setInformationTitle(infoData.informationTitle);
            setInformationText(infoData.informationText);
            setExternalLinkUrl(infoData.externalLinkUrl);
            setStart(dayjs(infoData.publicatePeriodStartDatetime));
            setEnd(dayjs(infoData.publicatePeriodEndDatetime));
        }
    }, [infoData]);
    
    const updateLoginInformationManagement = async () => {
        const token = await getCurrentUserToken();
        const informationType = checkedType? "1": "0";
        
        axios.request({
            url: baseURL + "/update-manage-login-before-info",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'operator' : 'update',
                'user_id': auth.username,
                'information_id' : informationId,
                'information_type' : informationType,
                'information_title' : informationTitle,
                'information_text' : informationText,
                'external_link_url' : externalLinkUrl,
                'publicate_period_start_datetime' : start !== null? start.format("YYYY/MM/DD HH:mm") : infoData.publicatePeriodStartDatetime,
                'publicate_period_end_datetime' : end !== null? end.format("YYYY/MM/DD HH:mm") : infoData.publicatePeriodEndDatetime,
            },
        })
        .then((response) => {
            console.log(response);
            getLoginInformationManagement();
            setEditSuccess(true);
        })
        .catch((error) => {
            console.log(error);
            setEditFailed(true);
        });
    }
    
    // 件名の入力チェック
    const checkInformationTitle = (): boolean => {
        if (informationTitle === "") return setInformationTitleError(ERROR_MESSAGE_22), false;
        return true;
    }
    
    // 内容の入力チェック
    const checkInformationText = (): boolean => {
        if (informationText === "") return setInformationTextError(ERROR_MESSAGE_23), false;
        return true;
    }
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "720px", 
                height: "100%", }}}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
            <IconButton
                onClick={() => handleClose()}
                style={{position: "absolute", top: 10, right: 10}}
                size="large"
            >
                <CloseIcon />
            </IconButton>
              
            <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>お知らせ編集（ログイン）</Typography>
            <Spacer size={20} />
            
            <ModalBox>
                <StyledTypography>分類</StyledTypography>
                <Stack direction="row" spacing={1}>
                    <FormControlLabel control={<Checkbox checked={checkedType} onChange={(event) => setCheckedType(event.target.checked)} />} label="重要なお知らせ" />
                </Stack>
                
                <StyledTypography>件名</StyledTypography>
                <TextField
                    value={informationTitle}
                    onChange={(e) => {
                        setInformationTitle(e.target.value);
                        setInformationTitleError("");
                    }}
                    size="small"
                    style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{informationTitleError !== ""? informationTitleError : ""}</ErrorTypography>
            
                <StyledTypography>内容</StyledTypography>
                <TextField
                    value={informationText}
                    onChange={(e) => {
                        setInformationText(e.target.value);
                        setInformationTextError("");
                    }}
                    multiline
                    rows={4}
                    style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{informationTextError !== ""? informationTextError : ""}</ErrorTypography>
                
                <StyledTypography>リンクURL</StyledTypography>
                <TextField
                    value={externalLinkUrl}
                    onChange={(e) => {
                        setExternalLinkUrl(e.target.value);
                    }}
                    size="small"
                    style={{ width: '600px', backgroundColor: "#FFFFFF" }} />
                
                <StyledTypography>公開期間</StyledTypography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                        <DateTimePicker
                            value={start}
                            onChange={(newValue: Dayjs | null) => setStart(newValue)}
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            slotProps={{ textField: { size: 'small'}}}
                            sx={{ width: "250px", height: "40px" }}
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                        />
                    </LocalizationProvider>
                    <Typography variant="h5">~</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                        <DateTimePicker
                            value={end}
                            onChange={(newValue: Dayjs | null) => setEnd(newValue)}
                            format="YYYY/MM/DD HH:mm"
                            ampm={false}
                            slotProps={{ textField: { size: 'small'}}}
                            sx={{ width: "250px", height: "40px" }}
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                        />
                    </LocalizationProvider>
                </Stack>
              
                <Spacer size={20} />
                
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CancelButton 
                        variant="outlined"
                        onClick={() => handleClose()}
                    >キャンセル</CancelButton>
                    <SaveButton 
                        onClick={() => {
                            const chkTitle = checkInformationTitle();
                            const chkText = checkInformationText();
                            if ( !chkTitle || !chkText ) return;
                            updateLoginInformationManagement();
                            handleClose();}}
                    >更新</SaveButton>
                </div>
            </ModalBox>
        </DialogContent>
    </Dialog>
  );
}