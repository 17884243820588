import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { InvoiceRecordInterface } from '../../interfaces/Invoice';
import { ERROR_MESSAGE_09, ERROR_MESSAGE_10, ERROR_MESSAGE_31 } from '../../../utils/errorMessages';
import { ErrorTypography } from '../../styles/style';

type Props = {
  searchedData: InvoiceRecordInterface[];
  getInvoiceData: (startDate: string | null, endDate: string | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export default function TargetMonth(props: Props) {
  const { searchedData, getInvoiceData, setIsLoading } = props;
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (searchedData.length > 0 && start === null && end === null) {
      setStart(dayjs(searchedData[0].target_ym))
      setEnd(dayjs(searchedData[0].target_ym)); 
    }
  }, [searchedData]);
  
  const callGetInvoice = async (start: Dayjs | null, end: Dayjs | null, flag: string) => {
    // 対象年月のチェック
    if(start === null || end === null) {
      setError(ERROR_MESSAGE_31);
      return;
    }
    if(flag === 'start' && start !== null && start.diff(end) > 0) {
      setError(ERROR_MESSAGE_09);
      return;
    };
    if(flag === 'end' && end !== null && end.diff(start) < 0) {
      setError(ERROR_MESSAGE_10);
      return;
    };
    
    try {
      setIsLoading(true);
      await getInvoiceData(
        start !== null ? start.format("YYYYMM") : null,
        end !== null ? end.format("YYYYMM") : null,
      );
    } catch (error) {
      console.error(error);
    } finally {
      // 処理が終了したらローディング状態を解除
      setIsLoading(false);
    }
  };

  const clearErrorMsg = () => {
    setError('');
  };

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <Typography variant="body1">対象年月</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={1} alignItems="center">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
              <DateTimePicker
                value={start}
                format="YYYY/MM"
                ampm={false}
                slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                views={['year', 'month']}
                onChange={(newValue: Dayjs | null) => {
                  clearErrorMsg();
                  setStart(newValue);
                }}
                onAccept={(newValue: Dayjs | null) => {
                  callGetInvoice(newValue, end, "start");
                }}
              />
            </LocalizationProvider>
            <Typography variant="h5">~</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
              <DateTimePicker
                value={end}
                format="YYYY/MM"
                ampm={false}
                slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                views={['year', 'month']}
                onChange={(newValue: Dayjs | null) => {
                  clearErrorMsg();
                  setEnd(newValue);
                }}
                onAccept={(newValue: Dayjs | null) => {
                  callGetInvoice(start, newValue, "end");
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Grid>
      </Grid>
      <ErrorTypography variant="caption">{error !== ""? error : ""}</ErrorTypography>
    </div>
  );
}