import React, { useState, useEffect, useRef } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import dayjs from "dayjs";
import { Spacer } from '../../../utils/components/Spacer';
import { SearchItemsInterface } from '../../interfaces/BillingRelease';
import { StyledButton } from '../../styles/kanriStyle';

type Props = {
  setSearchItems: (setSeachItems: SearchItemsInterface) => void;
  getBillingRelease: () => void;
}

const dates = Array.from({ length: 12 }, (_, i) => dayjs().subtract(i, 'month').format('YYYYMM'));

export default function SearchBoxBillingRelease(props: Props) {
  const [targetYM, setTargetYM] = useState(dayjs().format('YYYYMM'));
  const [userId, setUserId] = useState('');
  const [contractId, setContractId] = useState('');
  const [contractName, setContractName] = useState('');
  const [checkedOpen, setCheckedOpen] = useState(false);
  const [checkedNotOpen, setCheckedNotOpen] = useState(true);
  
  const { setSearchItems, getBillingRelease } = props;
  
  useEffect(() => {
    setSearchItems({targetYM: targetYM, userId: userId, contractId: contractId, contractName: contractName, publicateStatus: checkedOpen? '1' : '0'});
  }, [targetYM, userId, contractId, contractName, checkedOpen, checkedNotOpen])
  
  const handleNotOpenChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setCheckedNotOpen(event.target.checked);
    if (event.target.checked && checkedOpen) {
      setCheckedOpen(false);
    }
  };

  const handleOpenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedOpen(event.target.checked);
    if (event.target.checked && checkedNotOpen) {
      setCheckedNotOpen(false);
    }
  };
  
  const onClickClearButton = () => {
    setTargetYM(dayjs().format('YYYYMM'));
    setUserId('');
    setContractId('');
    setContractName('');
    setCheckedOpen(false);
    setCheckedNotOpen(true);
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#172B65', color: '#FFFFFF', '&:hover': { backgroundColor: '#172B65' } }}>
        <ListItemText primary="検索条件" />
      </ListItemButton>
      <Paper sx={{ backgroundColor: "#C9CACA26", height: 240, padding: "0 40px" }} variant="outlined" square >
        <Spacer size={10} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body1">ユーザID</Typography>
            <TextField value={userId} onChange={(e) => setUserId(e.target.value)} sx={{ backgroundColor: "#FFFFFF" }} size="small" fullWidth/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">契約ID</Typography>
            <TextField value={contractId} onChange={(e) => setContractId(e.target.value)} sx={{ backgroundColor: "#FFFFFF" }} size="small" fullWidth/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">契約名</Typography>
            <TextField value={contractName} onChange={(e) => setContractName(e.target.value)} sx={{ backgroundColor: "#FFFFFF" }} size="small" fullWidth/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">対象年月</Typography>
            <TextField
              select
              value={targetYM}
              onChange={(event) => setTargetYM(event.target.value)}
              sx={{ backgroundColor: "#FFFFFF" }} 
              size="small"
              fullWidth>
              {dates.map((date) => (
                <MenuItem key={date} value={date}>{date}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">公開ステータス</Typography>
            <Stack direction="row" spacing={1}>
              <FormControlLabel control={<Checkbox checked={checkedNotOpen} onChange={handleNotOpenChange} />} label="公開未済" />
              <FormControlLabel control={<Checkbox checked={checkedOpen} onChange={handleOpenChange} />} label="公開済" />
            </Stack>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <StyledButton onClick={getBillingRelease}>検索</StyledButton>
              <StyledButton onClick={onClickClearButton}>クリア</StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}