import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { AchievementDownloadMonthlyRecord, SearchCondition } from '../interfaces/AchievementDownloadMonthly'
 
type useInvoiceSearchRet<T> = {
  page: number,
  searchedData: T[],
  curPageData: T[],
  setCurPageData: (data: T[]) => void;
  getDownloadList:  () => Promise<T[]>;
  targetDateList: string[],
  setSearchConditionTargetDate: (startDate?: string, endDate?: string) => void;
  setSearchConditionRequestPointName: (requestPointName?: string[]) => void;
  requestPointNameList: string[];
  setSearchConditionRequestPointId: (requestPointId?: string[]) => void;
  requestPointIdList: string[];
  setSearchConditionSupplyPointNo: (supplyPointNo?: string[]) => void;
  supplyPointNoList: string[];
  setSearchConditionAddress: (address?: string[]) => void;
  addressList: string[];
  setSearchConditionFeeMenuNamePrinting: (feeMenuNamePrinting?: string[]) => void;
  feeMenuNamePrintingList: string[];
  setSearchConditionVoltageClass: (low?: boolean, high?: boolean, exHigh?: boolean) => void;
  setSearchConditionSupplyDate: (startHead: Date | null, startTail: Date | null, EndHead: Date | null, EndTail: Date | null) => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, requestPointId: string) => void;
  initTargetMonth: () => void;
  searchCondition: SearchCondition; // for dubug
};

// 検索条件の初期値
const initSerchVoltageClass = {
  exHigh: true,
  high: true,
  low: true
};
const initSupplyDate = {
  head: (new Date(1000, 1 ,15, 0, 0, 0)),
  tail: (new Date(9998, 12, 15, 0, 0, 0))
}
const initSerchCondition = {
  targetMonth: "",
  requestPointName: [],
  requestPointId: [],
  supplyPointNo: [],
  feeMenuNamePrinting: [],
  address: [],
  SearchVoltageClass: initSerchVoltageClass,
  supplyStartDate: initSupplyDate,
  supplyEndDate: initSupplyDate
}

const resetSearchCondition = {
  requestPointName: [],
  requestPointId: [],
  supplyPointNo: [],
  feeMenuNamePrinting: [],
  address: [],
  SearchVoltageClass: initSerchVoltageClass,
  supplyStartDate: initSupplyDate,
  supplyEndDate: initSupplyDate
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 5;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function useAchievementDonwloadMonthly(): useInvoiceSearchRet<AchievementDownloadMonthlyRecord> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<AchievementDownloadMonthlyRecord[]>([]);
  const [searchedData, setSearchedData] = useState<AchievementDownloadMonthlyRecord[]>([]);
  const [curPageData, setCurPageData] = useState<AchievementDownloadMonthlyRecord[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSerchCondition});
　const [targetDateList, setTargetDateList] = useState<string[]>([]);  // 対象年月
  const [requestPointNameList, setRequestPointNameList] = useState<string[]>([]);
  const [requestPointIdList, setRequestPointIdList] = useState<string[]>([]);
  const [supplyPointNoList, setSupplyPointNoList] = useState<string[]>([]);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [feeMenuNamePrintingList, setFeeMenuNamePrintingList] = useState<string[]>([]);
  const [supplyStartDate, setSupplyStartDate] = useState<Date | null>(null);
  const [supplyEndtDate, setSupplyEndDate] = useState<Date | null>(null);
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  
  useEffect(() => {
    if (defaultData.length > 0) searchData();
    getDropdownSearchList();
  }, [defaultData]);
  
  useEffect(() => {
    searchData();
    pagenation(1);
  }, [searchCondition]);
  
  const initTargetMonth = () => {
    const startDate = dayjs('201901');
    const endDate = dayjs();
    
    // 各月を格納する配列を初期化
    const monthsArray: string[] = [];
    
    // 開始日から終了日までループを回して、各月を配列に追加
    let currentDate = startDate;
    while (currentDate.isBefore(endDate, 'month') || currentDate.isSame(endDate, 'month')) {
      // フォーマット YYYY/MM で配列に追加
      monthsArray.push(currentDate.format('YYYY/MM'));
      // 次の月へ
      currentDate = currentDate.add(1, 'month');
    }
    
    setSearchCondition({...searchCondition, targetMonth: String(endDate.format('YYYYMM'))});
    setTargetDateList([...monthsArray]);
  }
  
  const setSearchConditionTargetDate = (month: string = "") => {
    if(month === ""){
      setSearchCondition({...searchCondition, targetMonth: dayjs().format('YYYYMM')});
    }
    else{
      setSearchCondition({...searchCondition, targetMonth: dayjs(month).format('YYYYMM')});
    }
  }
  
  const setSearchConditionRequestPointName = (requestPointName: string[] = []) => {
    if(requestPointName.length !== 0){
      setSearchCondition({...searchCondition, requestPointName: [...requestPointName]});
    }
    else{
      setSearchCondition({...searchCondition, requestPointName: []});
    }
  }

  const setSearchConditionRequestPointId = (requestPointId: string[] = []) => {
    if(requestPointId.length !== 0){
      setSearchCondition({...searchCondition, requestPointId: requestPointId});
    }
    else{
      setSearchCondition({...searchCondition, requestPointId: []});
    }
  }

  const setSearchConditionSupplyPointNo = (supplyPointNo: string[] = []) => {
    if(supplyPointNo.length !== 0){
      setSearchCondition({...searchCondition, supplyPointNo: supplyPointNo});
    }
    else{
      setSearchCondition({...searchCondition, supplyPointNo: []});
    }
  }
  
  const setSearchConditionAddress = (address: string[] = []) => {
    if(address.length !== 0){
      setSearchCondition({...searchCondition, address: address});
    }
    else{
      setSearchCondition({...searchCondition, address: []});
    }
  }
  
  const setSearchConditionFeeMenuNamePrinting = (feeMenuNamePrinting: string[] = []) => {
    if(feeMenuNamePrinting.length !== 0){
      setSearchCondition({...searchCondition, feeMenuNamePrinting: feeMenuNamePrinting});
    }
    else{
      setSearchCondition({...searchCondition, feeMenuNamePrinting: []});
    }
  }
  
  const setSearchConditionVoltageClass = (exHigh: boolean = false, high: boolean = false, low: boolean = false) => {
    const targetVoltageClass = {
      exHigh,
      high,
      low
    };
    setSearchCondition({...searchCondition, SearchVoltageClass: targetVoltageClass});
  }
  
  // const setSearchConditionSupplyDate = (startHead: Date|null = (new Date(1000, 1 ,15, 0, 0, 0)), startTail: Date|null = (new Date(9998, 12, 15, 0, 0, 0)), 
  //                                       EndHead: Date|null = (new Date(1000, 1 ,15, 0, 0, 0)), EndTail: Date|null = (new Date(9998, 12, 15, 0, 0, 0))) => {
  const setSearchConditionSupplyDate = (startHead: Date|null = null, startTail: Date|null = null, EndHead: Date|null = null, EndTail: Date|null = null ) => {
    setSearchCondition({...searchCondition, supplyStartDate: {head: startHead, tail: startTail}, supplyEndDate: {head: EndHead, tail: EndTail}});

    if(startHead !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, head: startHead}});
    }
    if(startTail !== null){
      setSearchCondition({...searchCondition, supplyStartDate: {...searchCondition.supplyStartDate, tail: startTail}});
    }
    if(EndHead !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, head: EndHead}});
    }
    if(EndTail !== null){
      setSearchCondition({...searchCondition, supplyEndDate: {...searchCondition.supplyEndDate, tail: EndTail}});
    }
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: AchievementDownloadMonthlyRecord[] = [];
    let searchedData2: AchievementDownloadMonthlyRecord[] = [];
  
    searchedData1 = defaultData;
  
    /*需要場所による検索処理*/
    if(searchCondition.requestPointName.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.requestPointName.indexOf(item.request_point_name) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*需要場所IDによる検索処理*/
    if(searchCondition.requestPointId.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.requestPointId.indexOf(item.request_point_id) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*住所による検索処理*/
    if(searchCondition.address.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.address.indexOf(item.address) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*供給地点番号による検索処理*/
    if(searchCondition.supplyPointNo.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.supplyPointNo.indexOf(item.supply_point_no) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*契約種別による検索処理*/
    if(searchCondition.feeMenuNamePrinting.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.feeMenuNamePrinting.indexOf(item.fee_menu_name_printing) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];

    /* ダウンロード対象ファイルによる検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.SearchVoltageClass.exHigh && item.voltage_class === "特高"){
        pushFlag = true;
      }
      else if(searchCondition.SearchVoltageClass.high && item.voltage_class === "高圧"){
        pushFlag = true;
      }
      else if(searchCondition.SearchVoltageClass.low && item.voltage_class === "低圧"){
        pushFlag = true;
      }
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    
    /* 供給開始日による検索処理 */
    searchedData1 = searchSupplyStartDate(searchCondition.supplyStartDate.head, searchCondition.supplyStartDate.tail);
    function searchSupplyStartDate(s: any, e: any){
      let tmpData: AchievementDownloadMonthlyRecord[] = [];
      searchedData1.forEach((obj, i) => {
        const dateParts = obj.supply_start_date.split("/");
        const y = parseInt(dateParts[0], 10);
        const m = parseInt(dateParts[1], 10) - 1; // 月は0から始まるため、1を引く
        const d = parseInt(dateParts[2], 10);
        const tmpDateObj: Date = new Date(y, m, d, 0, 0, 0);
        if(tmpDateObj >= (s as Date) && tmpDateObj <= (e as Date)) tmpData.push(searchedData1[i]);
      })
      return tmpData
    }
    
    /* 供給終了日による検索処理 */
    searchedData1 = searchSupplyEndDate(searchCondition.supplyEndDate.head, searchCondition.supplyEndDate.tail);
    function searchSupplyEndDate(s: any, e: any){
      let tmpData: AchievementDownloadMonthlyRecord[] = [];
      searchedData1.forEach((obj, i) => {
        const dateParts = obj.contract_end_date.split("/");
        const y = parseInt(dateParts[0], 10);
        const m = parseInt(dateParts[1], 10) - 1; // 月は0から始まるため、1を引く
        const d = parseInt(dateParts[2], 10);
        const tmpDateObj: Date = new Date(y, m, d, 0, 0, 0);
        if(tmpDateObj >= (s as Date) && tmpDateObj <= (e as Date)) tmpData.push(searchedData1[i]);
      })
      return tmpData
    }
    
    setSearchedData([...searchedData1]);
  }

  // 検索ボックスのプルダウンに表示するリストを作成する
  const getDropdownSearchList = () => {
    // setterは非同期のため一時配列を用意する
    let tmpTargetDateList: string[] = [];
    let TargetDateListForCompare: Date[] = [];
    let tmpRequestPointNameList: string[] = [];
    let tmpRequestPointIdList: string[] = [];
    let tmpSupplyPointNoList: string[] = [];
    let tmpAddressList: string[] = [];
    let tmpFeeMenuNamePrintingList: string[] = [];
    
    defaultData.forEach((obj: any, i: Number) => {
      const tmpRequestPointName: string = obj.request_point_name;
      if(tmpRequestPointNameList.indexOf(tmpRequestPointName) == -1){
        tmpRequestPointNameList.push(tmpRequestPointName);
      }
      const tmprequestPointId: string = obj.request_point_id;
      if(tmpRequestPointIdList.indexOf(tmprequestPointId) == -1){
        tmpRequestPointIdList.push(tmprequestPointId);
      }
      const tmpSupplyPointNo: string = obj.supply_point_no;
      if(tmpSupplyPointNoList.indexOf(tmpSupplyPointNo) == -1){
        tmpSupplyPointNoList.push(tmpSupplyPointNo);
      }
      const tmpAddress: string = obj.address;
      if(tmpAddressList.indexOf(tmpAddress) == -1){
        tmpAddressList.push(tmpAddress);
      }
      const tmpFeeMenuNamePrinting: string = obj.fee_menu_name_printing;
      if(tmpFeeMenuNamePrintingList.indexOf(tmpFeeMenuNamePrinting) == -1){
        tmpFeeMenuNamePrintingList.push(tmpFeeMenuNamePrinting);
      }
    });
    
    // 更新処理は最後に一括で代入する
    setRequestPointNameList(tmpRequestPointNameList);
    setRequestPointIdList(tmpRequestPointIdList);
    setSupplyPointNoList(tmpSupplyPointNoList);
    setAddressList(tmpAddressList);
    setFeeMenuNamePrintingList(tmpFeeMenuNamePrintingList);
  }
  
  const resetData = () => {
    setSearchedData([...defaultData]);
    setSearchCondition({...searchCondition, ...resetSearchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: AchievementDownloadMonthlyRecord[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }
  
  const allCheck = (state: boolean) => {
    const updateAllCheck = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: true }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj["request_point_id"] === sObj["request_point_id"]);
      return searchedObj ? { ...obj, checked: true } : obj;
    });
    
    const updateAllCancel = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: false }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj["request_point_id"] === sObj["request_point_id"]);
      return searchedObj ? { ...obj, checked: false } : obj;
    });
    
    if (state === true){
      setDefaultData(updateAllCheck);
    }
    else if (state === false){
      setDefaultData(updateAllCancel);
    }
  }
  
  const singleCheck = (target_ym: string, request_point_id: string) => {
    const updatedSingleCheck = defaultData.map((obj) => {
      if (obj["target_ym"] === target_ym && obj["request_point_id"] === request_point_id) {
        return { ...obj, checked: !obj["checked"]};
      } else {
        return obj;
      }
    });
    setDefaultData(updatedSingleCheck);
  }

  // サーバーからデータを取得する　
  const getDownloadList: () => Promise<AchievementDownloadMonthlyRecord[]> = async () => {
    const token = await getCurrentUserToken();
    const params: {[prop: string]: string} = {
      "contractId" : auth.contractID,
      "targetYm" : searchCondition.targetMonth
    }
    if(typeof(auth.requestPointID) !== "undefined") params["requestPointId"] = auth.requestPointID;
    
    const response = axios.request({
      url: baseURL + "/volume-usage/download-list",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params : params
    })
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i]["checked"] = false;
      }
      setDefaultData(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    curPageData,
    setCurPageData,
    getDownloadList,
    setSearchConditionTargetDate,
    targetDateList,
    setSearchConditionRequestPointName,
    requestPointNameList,
    setSearchConditionRequestPointId,
    requestPointIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionFeeMenuNamePrinting,
    feeMenuNamePrintingList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck,
    initTargetMonth,
    searchCondition // for debug
  };
}
