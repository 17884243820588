import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper, Typography, Divider, Box } from '@mui/material';

interface CheckboxGroupProps {
  title: string;
  items: { name: string }[];
  checkedState: boolean[];
  setCheckedState: (checked: boolean[]) => void;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ title, items, checkedState, setCheckedState }) => {
  
 // 全選択/全解除のハンドラー
  const handleToggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 全てを選択または全てを解除する新しい状態を設定
    setCheckedState(checkedState.map(() => event.target.checked));
  };

  // 個々のチェックボックスのハンドラー
  const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedCheckedState = checkedState.map((item, pos) => index === pos ? event.target.checked : item);
    setCheckedState(updatedCheckedState);
  };

  const allChecked = checkedState.every(Boolean);
  const indeterminate = checkedState.some(Boolean) && !allChecked;

  return (
    <Paper sx={{ p: 1, mb : 2 }}>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={allChecked}
          indeterminate={indeterminate}
          onChange={handleToggleAll}
        />
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Divider />
      <FormGroup>
        {items.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkedState[index]}
                onChange={handleChange(index)}
              />
            }
            label={item.name}
            sx={{ ml : 3 }}
          />
        ))}
      </FormGroup>
    </Paper>
  );
};