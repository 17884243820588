import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PrivateRoute from '../components/PrivateRoute';
import { StyledDiv, StyledContainer, MenuPaper, MenuPaperLow } from '../styles/kanriStyle';
import { toBlobPdf, getCustomerManualPdf } from '../../utils/functions';

export function Menu() {
    const navigate = useNavigate();
    const auth = useAuth();
    
    return (
        <PrivateRoute>
            <StyledContainer>
                <StyledDiv style={{ maxWidth: "1120px" }}>
                    <Grid container spacing={2} sx={{ margin: "50px 0", boxSizing: 'border-box', mr: -2, ml: -2}}>
                        {auth.adminAuthority[0] === "1"?
                            <Grid item xs={2.4}>
                                <MenuPaper onClick={() => navigate({ pathname: '/kanri/customer-management' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">お客さま管理</Typography>
                                </MenuPaper>
                            </Grid> : <div />}
                        {auth.adminAuthority[1] === "1"?
                            <Grid item xs={2.4}>
                                <MenuPaper onClick={() => navigate({ pathname: '/kanri/information-management' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">お知らせ管理</Typography>
                                </MenuPaper>
                            </Grid> : <div />}
                        {auth.adminAuthority[2] === "1"?
                            <Grid item xs={2.4}>
                                <MenuPaper onClick={() => navigate({ pathname: '/kanri/login-information-management' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">ログイン画面<br />お知らせ管理</Typography>
                                </MenuPaper>
                            </Grid> : <div />}
                        {auth.adminAuthority[3] === "1"?
                            <Grid item xs={2.4}>
                                <MenuPaper onClick={() => navigate({ pathname: '/kanri/manage-person-account' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">管理者アカウント管理</Typography>
                                </MenuPaper>
                            </Grid> : <div />}
                        {auth.adminAuthority[5] === "1"?
                            <Grid item xs={2.4}>
                                <MenuPaper onClick={() => navigate({ pathname: '/kanri/billing-release' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">請求公開</Typography>
                                </MenuPaper>
                            </Grid> : <div />}
                    </Grid>
                    {auth.adminAuthority === "000001" || auth.adminAuthority === "000000"? <div /> : 
                        <Box sx={{ borderBottom: "1px dashed #808080", width: "100%", mb: "25px" }} />
                    }
                    
                    <Grid container spacing={2} sx={{ mt: "25px", boxSizing: 'border-box', mr: -2, ml: -2}}>
                        {auth.adminAuthority[4] === "1"?
                            <Grid item xs={3}>
                                <MenuPaperLow onClick={() => navigate({ pathname: '/kanri/change-password' })}>
                                    <Typography sx={{ color: "#172B65", fontWeight: "bold" }} variant="subtitle1">パスワード変更</Typography>
                                </MenuPaperLow>
                            </Grid> : <div />}
                        <Grid item xs={3}>
                            <MenuPaperLow style={{ position: 'relative' }} onClick={(e: any) => (getCustomerManualPdf('エナリス管理者サイト_操作マニュアル.pdf'))}>
                                <FilterNoneOutlinedIcon style={{ position: 'absolute', top: 10, right: 10, fontSize: "15px", color: "#172B65" }}/>
                                <Typography sx={{ color: "#172B65", fontWeight: "bold", textAlign: "center" }} variant="subtitle1">操作マニュアル</Typography>
                            </MenuPaperLow>
                        </Grid>
                    </Grid>
                </StyledDiv>
            </StyledContainer>
        </PrivateRoute>
    );
}