import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../../utils/hooks/use-auth';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../../utils/components/Spacer';
import { 
    StyledTypograpy, 
    ElectricityAlarmModalBox,
    ElectricityAlarmCancelButton, 
    SaveButton,
    ErrorTypography } from '../../styles/style';
import { ElectricityAlarmUpdateInterface, ElectricityAlarmDialogInterface } from '../../interfaces/SettingElectricityAlarm';
import { ERROR_MESSAGE_12, ERROR_MESSAGE_13, ERROR_MESSAGE_14, ERROR_MESSAGE_15, ERROR_MESSAGE_16, ERROR_MESSAGE_17} from '../../../utils/errorMessages';
  
// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
interface Props {
    open: boolean;
    isDisabled: boolean;
    getSettingElecticityAlarmData: () => void;
    handleClose: () => void;
    userData: ElectricityAlarmDialogInterface[];
    setOpenSuccess: (openSuccess: boolean) => void;
    setOpenFailed: (openFailed: boolean) => void;
};

export default function SettingElectricityAlarmDialog(props: Props) {
    const { open, handleClose, userData, isDisabled, getSettingElecticityAlarmData, setOpenSuccess, setOpenFailed } = props;
    const auth = useAuth();
    const [validFlag, setValidFlag] = useState('');
    const [threshold, setThreshold] = useState('');
    const [noticeType, setNoticeType] = useState('');
    const [mailAddress, setMailAddress] = useState('');
    const [thresholdError, setThresholdError] = useState('');
    const [mailAddressError, setMailAddressError] = useState('');
    
    // Dialogの初期値を各stateに代入
    useEffect(() => {
        if (userData.length > 0) {
            setValidFlag(userData[0].usePowerAlarmValidFlag);
            setThreshold(isDisabled? "": userData[0].usePowerAlarmThreshold === ""? "": Math.floor(parseFloat(userData[0].usePowerAlarmThreshold)).toString());
            setNoticeType(userData[0].usePowerAlarmNoticeType);
            setMailAddress(userData[0].usePowerAlarmMailAddress);
        }
    }, [userData]);
    
    const updateElectricityArarm = async () => {
        const token = await getCurrentUserToken();
        const updateData: ElectricityAlarmUpdateInterface[] = [];
        // 個別設定
        if (!isDisabled) {
            for(let index = 0; index < userData.length; index++) {
                updateData.push({
                    "userId": userData[index].userId,
                    "requestPointId": userData[index].requestPointId,
                    "usePowerAlarmValidFlag": validFlag,
                    "usePowerAlarmNoticeType": noticeType,
                    "usePowerAlarmMailAddress": mailAddress,
                    "usePowerAlarmThreshold": threshold
                })
            }
        } else {
        // 一括設定
            for(let index = 0; index < userData.length; index++) {
                // 設計書に沿ってしきい値設定値を定義する
                const thresholdSettingValue = userData[index].contractPowerUsually !== ""? Math.floor(parseFloat(userData[index].contractPowerUsually)).toString()
                : userData[index].feeMenuBaseFeeConfirmValue !== ""? userData[index].capacityUnit === "KVA" || userData[index].capacityUnit === "kW"? Math.floor(parseFloat(userData[index].feeMenuBaseFeeConfirmValue)).toString()
                : userData[index].capacityUnit === "A" ? Math.floor(parseFloat(userData[index].feeMenuBaseFeeConfirmValue) / 10).toString()
                : "" : "";
                
                // 契約容量に値が入っている場合
                if (thresholdSettingValue !== "") {
                    let tmpThreshold = "";
                    // しきい値が設定されている場合、更新しない
                    if (userData[index].usePowerAlarmThreshold !== "") {
                        tmpThreshold = userData[index].usePowerAlarmThreshold;
                    } else {
                    // しきい値が設定されていない場合、契約容量をしきい値として設定する
                        tmpThreshold = thresholdSettingValue;
                    }
                    updateData.push({
                        "userId": userData[index].userId,
                        "requestPointId": userData[index].requestPointId,
                        "usePowerAlarmValidFlag": validFlag,
                        "usePowerAlarmNoticeType": noticeType,
                        "usePowerAlarmMailAddress": mailAddress,
                        "usePowerAlarmThreshold": Math.floor(parseFloat(tmpThreshold)).toString()
                    })
                } else {
                    let tmpVlidFlag = "";
                    // しきい値が設定されている場合、有効フラグを更新
                    if (userData[index].usePowerAlarmThreshold !== "") {
                        tmpVlidFlag = validFlag;
                    } else {
                    // しきい値が設定されていない場合、有効フラグを更新しない
                        tmpVlidFlag = userData[index].usePowerAlarmValidFlag;
                    }
                    // しきい値は更新しない
                    updateData.push({
                        "userId": userData[index].userId,
                        "requestPointId": userData[index].requestPointId,
                        "usePowerAlarmValidFlag": Math.floor(parseFloat(tmpVlidFlag)).toString(),
                        "usePowerAlarmNoticeType": noticeType,
                        "usePowerAlarmMailAddress": mailAddress,
                    })
                }
            }
        }
        
        axios.request({
            url: baseURL + "/update-setting-alarm",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            params : {
                "contractId" : auth.contractID
            },
            data : updateData,
        })
        .then((response) => {
            console.log(response);
            getSettingElecticityAlarmData();
            setOpenSuccess(true);
        })
        .catch((error) => {
            console.log(error);
            setOpenFailed(true);
        });
    }
    
    // しきい値の入力チェック
    const checkThreshold = (): boolean => {
        if (threshold === "") {
            setThresholdError(ERROR_MESSAGE_13);
            return false;
        };
        if (threshold.match(/^[0-9]+$/g) === null) {
            setThresholdError(ERROR_MESSAGE_12);
            return false;
        };
        if (threshold.length > 9) {
            setThresholdError(ERROR_MESSAGE_14);
            return false;
        };
        if (threshold.match(/^[^\x01-\x7E]*$/g) !== null) {
            setThresholdError(ERROR_MESSAGE_17);
            return false;
        };
        
        return true;
    }
    
    // メールアドレスの入力チェック
    const checkMailAddress = (): boolean => {
        if (mailAddress === "") {
            setMailAddressError(ERROR_MESSAGE_15);
            return false;
        };
        if (mailAddress.length > 100) {
            setMailAddressError(ERROR_MESSAGE_16);
            return false;
        };
        if (mailAddress.match(/^[^\x01-\x7E]*$/g) !== null) {
            setMailAddressError(ERROR_MESSAGE_17);
            return false;
        };
        
        return true;
    }
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "720px", 
                height: "100%", }}}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
            <IconButton
                onClick={() => {
                    handleClose();
                    setThresholdError("");
                    setMailAddressError("");
                }}
                style={{position: "absolute", top: 10, right: 10}}
                size="large"
            >
                <CloseIcon />
            </IconButton>
              
            <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>使用電力アラーム設定</Typography>
            
            <Spacer size={20} />
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography>選択中：</Typography>
                {userData.map((data, index) => (
                    <Typography>{index !== 0 ? index >= 6? ", ...": `, ${data.requestPointName}` : data.requestPointName}</Typography>
                ))}
            </div>
            
            <Spacer size={20} />
            
            <ElectricityAlarmModalBox>
                <StyledTypograpy>使用電力アラーム</StyledTypograpy>
                <FormControl>
                    <RadioGroup row value={validFlag} onChange={(e) => setValidFlag(e.target.value)}>
                        <FormControlLabel value="0" control={<Radio />} label="無効" />
                        <FormControlLabel value="1" control={<Radio />} label="有効" />
                    </RadioGroup>
                </FormControl>
              
                <Spacer size={20} />
              
                <StyledTypograpy>しきい値</StyledTypograpy>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField 
                        value={threshold}
                        onChange={(e) => {
                            setThreshold(e.target.value);
                            setThresholdError('');
                        }}
                        disabled={isDisabled}
                        size="small"
                        style={{ width: '120px', backgroundColor: "#FFFFFF" }} />
                    <Typography style={{ paddingLeft: "5px" }}>kW</Typography>
                </div>
                <ErrorTypography variant="caption">{!isDisabled? thresholdError !== ""? thresholdError : "": ""}</ErrorTypography>
                <StyledTypograpy variant="caption">{isDisabled? "※一括設定の時は入力できません" : ""}</StyledTypograpy>
              
                <Spacer size={20} />
              
                <StyledTypograpy>通知先メールアドレス</StyledTypograpy>
                <TextField
                    value={mailAddress}
                    onChange={(e) => {
                        setMailAddress(e.target.value);
                        setMailAddressError('');
                    }}
                    size="small"
                    style={{ width: '300px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{mailAddressError !== ""? mailAddressError : ""}</ErrorTypography>
              
                <Spacer size={20} />
              
                <StyledTypograpy>通知パターン</StyledTypograpy>
                <FormControl>
                    <RadioGroup row value={noticeType} onChange={(e) => setNoticeType(e.target.value)}>
                        <FormControlLabel value="1" control={<Radio />} label="初回" />
                        <FormControlLabel value="2" control={<Radio />} label="毎回" />
                    </RadioGroup>
                </FormControl>
              
                <Spacer size={20} />
              
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ElectricityAlarmCancelButton 
                        variant="outlined"
                        onClick={() => {
                            handleClose();
                            setThresholdError("");
                            setMailAddressError("");
                        }}>キャンセル</ElectricityAlarmCancelButton>
                    <SaveButton 
                        onClick={() => {
                            const chkThreshold = checkThreshold();
                            const chkMailAddress = checkMailAddress();
                            if (!isDisabled) {
                                if (!chkThreshold || !chkMailAddress) return;
                            } else {
                                if (!chkMailAddress) return;
                            }
                            updateElectricityArarm();
                            handleClose();}}
                            >保存</SaveButton>
                </div>
            </ElectricityAlarmModalBox>
        </DialogContent>
    </Dialog>
  );
}