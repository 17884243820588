import React, { useState, MouseEventHandler } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DehazeIcon from '@mui/icons-material/Dehaze';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuth } from '../../utils/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import EneresIcon from '../../assets/img/common/Eneres_Secondary_Logo.png'
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import { StyledHeader, HeaderBox, HeaderPaper, LogoutButton } from '../styles/kanriStyle';
import { getCustomerManualPdf } from '../../utils/functions';

interface HeaderItemProps {
  uri: string;
  screen: string;
}

const HeaderItem: React.FC<HeaderItemProps> = ({uri, screen}) => {
  const navigate = useNavigate();
  
  const handleClick: MouseEventHandler = (event) => {
    event.preventDefault();
    navigate(uri);
  }

  return (
    <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px" }} onClick={handleClick}>
      <ArrowForwardIosIcon sx={{ fontSize: 10 }}/>
      <Typography sx={{ fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }} variant="subtitle1">{screen}</Typography>  
    </div>
  )
}

export default function Header() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  
  const handleMenuMouseOver = () => setMenuOpen(true);
  const handleMenuMouseLeave = () => setMenuOpen(false);
  const handleHelpMouseOver = () => setHelpOpen(true);
  const handleHelpMouseLeave = () => setHelpOpen(false);
  
  const onLogoutButtonClick = () => {
    localStorage.removeItem('token');
    auth.signOut();
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <StyledHeader>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={EneresIcon} style={{ width: '150px' }} onClick={() => navigate({ pathname: '/kanri/menu' })}/>
            <Chip label="管理画面" sx={{ backgroundColor: "#FFFFFF", color: "#172B65", marginRight: "20px" }}/>
          </div>
          {auth.adminAuthority === "000001" || auth.adminAuthority === "000000"? <div /> :
            <HeaderBox
              onMouseOver={handleMenuMouseOver}
              onMouseLeave={handleMenuMouseLeave}>
              <DehazeIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "pointer" }} variant="subtitle1">メニュー</Typography>
              {menuOpen && (
                <HeaderPaper sx={{ width: "230px", paddingTop: 0 }} square={true}>
                  {auth.adminAuthority[0] === "1"? <HeaderItem uri="/kanri/customer-management" screen="お客さま管理"/>: <div />}
                  {auth.adminAuthority[1] === "1"? <HeaderItem uri="/kanri/information-management" screen="お知らせ管理"/>: <div />}
                  {auth.adminAuthority[2] === "1"? <HeaderItem uri="/kanri/login-information-management" screen="ログイン画面お知らせ管理"/>: <div />}
                  {auth.adminAuthority[3] === "1"? <HeaderItem uri="/kanri/manage-person-account" screen="管理者アカウント管理"/>: <div />}
                  {auth.adminAuthority[5] === "1"? <HeaderItem uri="/kanri/billing-release" screen="請求公開"/>: <div />}
                </HeaderPaper>
              )}
            </HeaderBox>
          }
          {auth.adminAuthority[4] === "1"?
            <HeaderBox onClick={() => navigate({ pathname: '/kanri/change-password' })}>
              <SettingsOutlinedIcon fontSize="small"/>
              <Typography sx={{ fontWeight: "bold", cursor: "pointer" }} variant="subtitle1">パスワード変更</Typography>
            </HeaderBox>: <div />}
          <HeaderBox
            onMouseOver={handleHelpMouseOver}
            onMouseLeave={handleHelpMouseLeave}>
            <HelpOutlineOutlinedIcon fontSize="small"/>
            <Typography sx={{ fontWeight: "bold", cursor: "pointer" }} variant="subtitle1">ヘルプ</Typography>
            {helpOpen && (
              <HeaderPaper sx={{ width: "280px", paddingTop: 0 }} square={true}>
                <div style={{ display: "flex", alignItems: "center", margin: "20px 0 0 10px" }}>
                  <ArrowForwardIosIcon sx={{ fontSize: 10, marginRight: 1 }}/>
                  <Link 
                    component="button" 
                    onClick={() => getCustomerManualPdf("エナリス管理者サイト_操作マニュアル.pdf")} 
                    sx={{ color: "#333333" }} 
                    underline="none">
                    <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">操作マニュアル</Typography>
                  </Link>
                </div>
              </HeaderPaper>
            )}
          </HeaderBox>
          
          <div style={{ display: "flex", alignItems: "center", marginLeft: 'auto' }}>
            <Typography sx={{ fontWeight: "bold", marginRight: "30px" }} variant="subtitle1">{`${auth.adminName} さま`}</Typography>
            
            <LogoutButton
             variant="text"
             startIcon={<LogoutOutlinedIcon />}
             onClick={onLogoutButtonClick}>ログアウト</LogoutButton>
          </div>
        </Toolbar>
      </StyledHeader>
    </Box>
  );
}

