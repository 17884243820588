import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { UsingAchievementTableCell } from '../../styles/style';

interface Props {
    data: WeekdayData[];
};

interface WeekdayData {
    aggregate_time: string;
    daytime_use_power_kWh: string;
    nighttime_use_power_kWh: string;
    total_use_power_kWh: string;
    week_of_day: string;
};

const day_of_week_ja = ["日", "月", "火", "水", "木", "金", "土"];

export default function UsingAcievementWeekdayTable(props: Props) {
  const { data } = props;
    
  return (
    <TableContainer component={Paper} elevation={0} sx={{ width: 395, height: 378, border: '1px solid', borderColor: '#DFDFED' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>曜日</TableCell>
            <UsingAchievementTableCell>全体(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell>昼間(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell>夜間(kWh)</UsingAchievementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 },
              backgroundColor: index % 2 === 1 ? '#CBE8FA' : '#FFFFFF' }}
            >
              <TableCell>{day_of_week_ja[Number(row.week_of_day)]}</TableCell>
              <TableCell align="right">{(Number(row.daytime_use_power_kWh) + Number(row.nighttime_use_power_kWh)).toLocaleString()}</TableCell>
              <TableCell align="right">{Number(row.daytime_use_power_kWh).toLocaleString()}</TableCell>
              <TableCell align="right">{Number(row.nighttime_use_power_kWh).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}