import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import TableSortLabel, { TableSortLabelProps } from '@mui/material/TableSortLabel';

export const StyledDiv = styled('div')(({ theme }) => ({
    width: "100%",
    maxWidth: "1660px",
    margin: "0 auto",
    padding: "0 30px 0 30px",
}));

export const StyledContainer = styled('div')(({ theme }) => ({
    position: "fixed",
    top: 52,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#F2F2F2",
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: "#333333",
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
}));

export const StyledHeader = styled(AppBar)(({ theme }) => ({
  height: 70,
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#172B65',
  color: '#FFF',
  boxShadow: 'none',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    width: "100%",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#172B65",
    borderColor: "#172B65",
    fontWeight: 'bold',
    margin: 10,
    padding: 10,
    '&:hover': {
        borderColor: "#172B65",
        backgroundColor: "#E6E6E6"
    },
}));

interface StyledTableSortLabelProps extends TableSortLabelProps {
  hideSortIcon?: boolean;
}

export const StyledTableSortLabel = styled(TableSortLabel)<StyledTableSortLabelProps>(({ theme, hideSortIcon }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: hideSortIcon ? 0 : 1,
    color: "#DADADA",
  },
}));

export const StyledFooter = styled(AppBar)(({ theme }) => ({
  height: 80,
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(23, 43, 101, 0.9)',
  opacity: 0.9,
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  margin: 'auto',
}));

export const ErrorTypography = styled(Typography)(({ theme }) => ({
    color: "#E2421F",
    fontFamily: "NotoSansJP-Bold",
    fontWeight: 700,
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    height: 70,
    position: "relative",
    padding: "0 20px",
    '&:hover': {
        color: "#172B65",
        backgroundColor: "#F3F3F7",
    }
}));

export const HeaderPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: "#F3F3F7",
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: 1,
    padding: "20px",
}));

export const FooterButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    color: '#172B65',
    fontWeight: 'bold',
    minWidth: "150px",
    margin: 10,
    padding: 10,
    '&:hover': {
        backgroundColor: "#E6E6E6"
    },
    '&:disabled': {
        backgroundColor: '#FFFFFF',
        color: '#172B65',
    },
}));

export const LogoutButton = styled(Button)(({ theme }) => ({
    color: '#333333',
    backgroundColor: '#C3D825',
    '&:hover': {
        backgroundColor: "#B0C321"
    }
}));

export const LoginButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        width: "100%",
        maxWidth: "320px",
        height: "50px",
        backgroundColor: "#00506B",
        fontFamily: "NotoSansJP-Bold",
        fontWeight: 700,
        textaAlign: "center",
        color: "#FFFFFF",
    },
    '&.Mui-focusVisible': {
        backgroundColor: "#00506B",
    },
}));

export const LoginLink = styled(Link)(({ theme }) => ({
    fontWeight: 400,
    color: "#00506B",
}));

export const LoginBox = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: "0 auto",
    width: "30%",
    backgroundColor: "#CCCCDB",
    minWidth: "480px",
    padding: "52px 0",
}));

export const MenuPaper = styled(Paper)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "160px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
}));

export const MenuPaperLow = styled(Paper)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
}));

export const PasswordBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "70px",
    width: "180px",
    textAlign: "center",
    justifyContent: "center",
    border: "1px solid #DFDFED",
    backgroundColor: "#D9E1FB",
}));

export const PasswordTextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "70px",
    width: "640px",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DFDFED",
    backgroundColor: "#FFFFFF",
}));

export const ManagePersonAccountTableButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#172B65",
    borderColor: "#172B65",
    fontWeight: 'bold',
    margin: 10,
    padding: 10,
    '&:hover': {
        borderColor: "#172B65",
        backgroundColor: "#E6E6E6"
    },
}));

export const ModalBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "500px",
    padding: "20px 40px",
    textAlign: "left",
    border: "1px solid #DFDFED",
    overflow: "hidden",
    overflowY: "auto"
}));

export const CancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#172B65",
    borderColor: "#172B65",
    fontWeight: 'bold',
    width: "160px",
    height: "44px",
    '&:hover': {
        borderColor: "#172B65",
        backgroundColor: "#E6E6E6"
    },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
    fontFamily: "NotoSansJP-Bold",
    backgroundColor: '#172B65',
    color: '#FFFFFF',
    width: "160px",
    height: "44px",
    marginLeft: "20px",
    '&:hover': {
        backgroundColor: "#172B65"
    },
    '&:disabled': {
        backgroundColor: '#172B65',
        color: '#FFFFFF',
        cursor: 'default',
        pointerEvents: 'none'
    },
}));