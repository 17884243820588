import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';

const PrivateRoute: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const currentRoute = useLocation().pathname;

  // ログインしていない場合には、/signinにリダイレクトする
  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  // ログインしている場合には、子要素をrenderする
  return <>{children}</>;
};

export default PrivateRoute;