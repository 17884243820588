import React, { useState, useEffect } from 'react';
import axios from "axios";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBox from '../components/InformationManagement/SearchBoxInformationManagement';
import CSVImportDialog from '../components/InformationManagement/CSVImportDialog';
import InformationManagementTable from '../components/InformationManagement/InformationManagementTable';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SnackBar from '../../utils/components/SnackBar';
import { SearchItemsInterface, InformationManagementRecordInterface } from '../interfaces/InformationManagement';
import { StyledButton, StyledDiv, StyledTypography } from '../styles/kanriStyle';
import { getCurrentUserToken } from '../../utils/functions';


const baseURL = process.env.REACT_APP_API_ENDPOINT;

export function InformationManagement() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchedData, setSearchedData] = useState<InformationManagementRecordInterface[]>([]);
    const [curPageData, setCurPageData] = useState<InformationManagementRecordInterface[]>([]);
    const [page, setPage] = useState<number>(1);
    const [openCSVImport, setOpenCSVImport] = useState(false);
    const [csvImportSuccess, setCSVImportSuccess] = useState(false);
    const [csvImportFailed, setCSVImportFailed] = useState(false);
    const [searchItems, setSearchItems] = useState<SearchItemsInterface>({
        title: "",
        startDateHead: null,
        startDateTail: dayjs(),
        endDateHead: dayjs(),
        endDateTail: null,
    });
    const [updateTarget, setUpdateTarget] = useState<InformationManagementRecordInterface>({
        information_title: "",
        publicate_period_start_datetime: "",
        publicate_period_end_datetime: ""
    });
    const [updateFlag, setUpdateFlag] = useState<boolean>(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [failedMsg, setFailedMsg] = useState('');
    
    const navigate = useNavigate();
    
    const itemsPerPage: number = 20;
    
    const pagenation = (curPage: number) => {
        const head = (curPage - 1) * itemsPerPage;
        const targetPage: InformationManagementRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
        setCurPageData([...targetPage]);
        setPage(curPage);
    }
    
    const handleOpenCSVImport = () => {
        setOpenCSVImport(true)
        console.log(updateTarget)
    };
    const handleCloseCSVImport = () => setOpenCSVImport(false);
    
    useEffect(() => {
        pagenation(1);
    }, [searchedData]);
    
    useEffect(() => {
        if(updateFlag === true){
            let data: any = {};
            data["state"] = {...updateTarget};
            data["state"]["flag"] = 2;
            navigate('/kanri/information-registration',
                data
            )
        }
    }, [updateFlag])
    
    useEffect(() => {
        // sessionStorageから登録成功フラグを取得
        if (sessionStorage.getItem('registrationSuccess')) {
            setRegistrationSuccess(true);
            setSuccessMsg("登録が成功しました");
            // フラグを使用した後はsessionStorageから削除
            sessionStorage.removeItem('registrationSuccess');
        }
        if (sessionStorage.getItem('registrationFailed')) {
            setRegistrationFailed(true);
            setFailedMsg("登録が失敗しました");
            sessionStorage.removeItem('registrationFailed');
        }
        if (sessionStorage.getItem('editSuccess')) {
            setRegistrationSuccess(true);
            setSuccessMsg("更新が成功しました");
            sessionStorage.removeItem('editSuccess');
        }
        if (sessionStorage.getItem('editFailed')) {
            setRegistrationFailed(true);
            setFailedMsg("更新が失敗しました")
            sessionStorage.removeItem('editFailed');
        }
        
        const getData = async() => {
            try {
                const _ = await getInformationManagement();
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
    }, [])
    // 検索条件に合致するログインお知らせデータの取得
    const getInformationManagement = async () => {
        const token = await getCurrentUserToken();
        
        axios.request({
            url: baseURL + "/get-manage-login-after-info",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
            params : {
                'title': searchItems.title,
                'start_datetime': [searchItems.startDateHead !== null? dayjs(searchItems.startDateHead).format("YYYY/MM/DD HH:mm") : dayjs("1900/01/01 00:00").format("YYYY/MM/DD HH:mm"),
                                    searchItems.startDateTail !== null? dayjs(searchItems.startDateTail).format("YYYY/MM/DD HH:mm") : dayjs("2099/12/31 23:59").format("YYYY/MM/DD HH:mm")],
                'end_datetime': [searchItems.endDateHead !== null? dayjs(searchItems.endDateHead).format("YYYY/MM/DD HH:mm") : dayjs("1900/01/01 00:00").format("YYYY/MM/DD HH:mm"),
                                searchItems.endDateTail !== null? dayjs(searchItems.endDateTail).format("YYYY/MM/DD HH:mm") : dayjs("2099/12/31 23:59").format("YYYY/MM/DD HH:mm")],
            },
            paramsSerializer: {
               indexes: null
            }
        })
        .then((response) => {
            console.log(response);
            setIsLoading(false);
            setSearchedData(response.data.data);
        })
        .catch((error) => {
            setIsLoading(false);
            console.log(error);
        });
    }
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={70} />
                <StyledTypography variant="h5">お知らせ管理</StyledTypography>
                
                <Spacer size={20} />
                
                <SearchBox 
                    setSearchItems={setSearchItems}
                    getInformationManagement={getInformationManagement}/>
                
                <Spacer size={20} />
                

                <Box display="flex" justifyContent="flex-end">
                    <StyledButton 
                        onClick={() => {
                            navigate('/kanri/information-registration',
                                {state: {flag: 1}}
                            );
                        }}
                        variant="outlined"
                    >新規登録</StyledButton>
                    <StyledButton 
                        onClick={handleOpenCSVImport}
                        variant="outlined"
                    >CSV登録</StyledButton>
                </Box>

                <CSVImportDialog
                  open={openCSVImport}
                  handleClose={handleCloseCSVImport}
                  setCSVImportSuccess={setCSVImportSuccess}
                  setCSVImportFailed={setCSVImportFailed}
                />
                
                { !isLoading?
                    <InformationManagementTable 
                        searchedData={searchedData}
                        page={page}
                        pagenation={pagenation}
                        getInformationManagement={getInformationManagement}
                        setEditSuccess={setCSVImportSuccess}
                        setEditFailed={setCSVImportFailed}
                        setUpdateTarget={setUpdateTarget}
                        setUpdateFlag={setUpdateFlag}/>
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                    
                }
                
                <SnackBar
                    successMessage="登録が成功しました"
                    openSuccess={csvImportSuccess}
                    setOpenSuccess={setCSVImportSuccess}
                    failedMessage="登録が失敗しました"
                    openFailed={csvImportFailed}
                    setOpenFailed={setCSVImportFailed}/>
                    
                <SnackBar
                    successMessage={successMsg}
                    openSuccess={registrationSuccess}
                    setOpenSuccess={setRegistrationSuccess}
                    failedMessage={failedMsg}
                    openFailed={registrationFailed}
                    setOpenFailed={setRegistrationFailed}/>
            </StyledDiv>
        </PrivateRoute>
    );
}