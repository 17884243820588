import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import { Spacer } from '../../utils/components/Spacer';
import { StyledDiv, StyledTypograpy, StyledTextField, LoginButton, LoginLink, LoginBox, LoginInformation, InformationTypograpy } from '../styles/style';
import EneresIcon from '../../assets/img/common/Eneres_Primary_Logo.png'
import { getCustomerManualPdf } from '../../utils/functions';

export function SignIn() {
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
  const [res, setRes] = React.useState([]);
  
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const errMsg01 = "ユーザー名を入力してください。";
  const errMsg02 = "パスワードを入力してください。";
  const errMsg03 = "指定されたユーザー名またはパスワードが正しくありません。";
  const errMsg04 = "パスワード入力回数の上限に達しました。時間をおいて再度お試しください。";
  const [errors, setErrors] = useState({
    "signinError": ''
  });
  
  function clearMsg() {
    setErrors({
      "signinError": ''
    });
  }
  
  // 画面初期処理
  useEffect(() => {
    // ログイン前のお知らせを取得する 
    getInfoBeforeLogin();
  }, []);
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
    
  const getInfoBeforeLogin = async () => {
    axios.request({
      url: baseURL + "/logininfo",
      method: "get",
    })
    .then((response) => {
      setRes(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  
  const executeSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsDisabled(true);
    //validation
    if(username === '') {
      setTimeout(() => {
        setIsDisabled(false);
      }, 1000);
      setErrors({...errors, signinError: errMsg01})
      return;
    }
    if(password === '') {
      setTimeout(() => {
        setIsDisabled(false);
      }, 1000);
      setErrors({...errors, signinError: errMsg02})
      return;
    }
    await axios.request({
      url: baseURL + "/login-check",
      method: "get",
      params: {
        'user_id': username
      }
    }).then((response) => {
      auth.setName(response.data.customer_name);
      if(response.data.login_flag) {
        auth.signIn(username, password).then((resp) => {
          if(resp.success) {
            axios.request({
              url: baseURL + "/update-login-time",
              method: "post",
              data: {
                  'table_name': 'user',
                  'user_id': username
              }
            })
            .then((response) => {
              if(response.data.success) {
                navigate({ pathname: '/user/top' });
              }
            }).catch((error) => {});
          } else {
            setTimeout(() => {
              setIsDisabled(false);
            }, 500);
            if(resp.message == '認証に失敗しました。') {
              setErrors({...errors, signinError: errMsg03})
            } else {
              setErrors({...errors, signinError: errMsg04})
            }
          }
        }).catch((error) => {
          console.log(error)
        })
      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 500);
        setErrors({...errors, signinError: errMsg03})
      }
    }).catch((error) => {});
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  return (
        <StyledDiv>
            <Spacer size={30} />
            
            <div style={{ textAlign: "center" }}>
                <img src={EneresIcon} style={{ width: '240px'}} />
                <StyledTypograpy>お客さまサイト</StyledTypograpy>
            </div>
          
            <Spacer size={20} />
          
            <div style={{ maxWidth: "1180px", display: "flex", margin: "0 auto" }}>
                <LoginBox>
                    <div style={{ maxWidth: "320px", margin: "0 auto"}}>
                      <form noValidate onSubmit={executeSignIn}>
                        <StyledTypograpy>ユーザー名</StyledTypograpy>
                        <StyledTextField
                            value={username}
                            size="small"
                            onChange={(e) => {setUsername(e.target.value); clearMsg()}}
                        />
                        
                        <Spacer size={20} />
                        
                        <StyledTypograpy>パスワード</StyledTypograpy>
                        <StyledTextField
                            value={password}
                            size="small"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(e) => {setPassword(e.target.value); clearMsg()}}
                            InputProps={{ 
                                endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}
                        />
                        
                        <Spacer size={20} />
                        
                        <Box sx={{ m: 1, position: 'relative' }}>
                          {!isDisabled?
                            <LoginButton type="submit" disabled={isDisabled}>ログイン</LoginButton>
                          : <LoginButton disabled={isDisabled}>ログイン中</LoginButton>
                          }
                          {isDisabled && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "#FFFFFF",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Box>
                        
                        <Spacer size={20} />
                        
                        <StyledTypograpy style={{ textAlign: "center" }}>{errors.signinError !== ""? errors.signinError: ""}</StyledTypograpy>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <LoginLink href="/user/forgotpw" underline="hover">パスワードをお忘れですか？</LoginLink>
                        </div>
                      </form>
                    </div>
                </LoginBox>
                
                <LoginInformation>
                    {Object.values(res).map((info) => {
                      return(
                        <div style={{ wordWrap: "break-word", padding: "25px 0", borderBottom: "1px solid #DFDFED" }}>
                            {info['information_type'] === '1'?
                              <div>
                                <InformationTypograpy style={{ color: "#F96030", fontWeight: 'bold'}}>⚠重要なお知らせ</InformationTypograpy>
                                <InformationTypograpy>{info['information_title']}</InformationTypograpy>
                              </div>
                            : 
                                <InformationTypograpy>{info['information_title']}</InformationTypograpy>
                            }
                            
                            <Spacer size={10} />
                            
                            <div style={{ fontWeight: 400, textAlign: "left" }}>
                              <Typography>{String(info['information_text']).split('\n').map((line) => 
                                        (<span>{line} <br/></span>)
                                    )}</Typography>
                              <Link sx={{ color: "#0099CC" }} href={info['external_link_url']} underline="hover">{info['external_link_url']}</Link>
                            </div>
                        </div>
                      )
                    })}
                </LoginInformation>
            </div>
            
            <Spacer size={50} />
            
            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
              <Link component="button" onClick={(e: any) => (getCustomerManualPdf('エナリスお客さまサイトログインマニュアル.pdf'))} sx={{ color: "#333333" }} underline="hover">ログイン操作マニュアル</Link>
              <FilterNoneOutlinedIcon sx={{ marginLeft: "5px", marginRight: "40px", color: "#333333", fontSize: 15 }} />
              <div>
                <Typography sx={{ color: "#333333", fontWeight: 'Bold' }}>当サイトに関するお問合せ<br/></Typography>
                <Typography sx={{ color: "#333333", marginLeft: '10px' }}>株式会社エナリス お客さまサイト窓口<br/>mail:ec_support@eneres.co.jp</Typography>
              </div>
            </div>
        </StyledDiv>
  );
}