import React, { useState, ChangeEvent, FormEvent, useEffect  } from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../../utils/components/Spacer';
import { useAuth } from '../../../utils/hooks/use-auth';
// Styles
import { ErrorTypography, SaveButton, StyledButton } from '../../styles/kanriStyle';
// Custom Util Modules
import { ERROR_MESSAGE_27, ERROR_MESSAGE_28 } from '../../../utils/errorMessages';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
interface Props {
    open: boolean;
    handleClose: () => void;
    setCSVImportSuccess: (CSVRegistrationSuccess: boolean) => void;
    setCSVImportFailed: (CSVRegistrationFailed: boolean) => void;
};

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

export default function CSVImportDialog(props: Props) {
    const { open, handleClose, setCSVImportSuccess, setCSVImportFailed } = props;
    
    const auth = useAuth();
    
    const [file, setFile] = useState<File | null>(null);
    const [errorFlag, setErrorFlag] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    
    useEffect(() => {
        setErrorMsg("");
    }, [open]);
    
    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;
        setFile(selectedFile);
    };
    
    const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
        const token = await getCurrentUserToken();
        event.preventDefault();
        
        if (file) {
            try {
                const size_MB = file.size / (1024 * 1024); 
                if (size_MB > 1){
                    setErrorFlag(true);
                    setErrorMsg(ERROR_MESSAGE_28);
                    return
                }
                
                const contents = await readFileContents(file);
                // ファイルを行ごとに分割するコード。CSVファイルの行は通常、CRLFで分割されるが、
                // フィールド内の改行は分割されないようにする必要がある。
                // そこで、ダブルクォートで囲まれたフィールド内のCRLFを一時的に置換する。
                const tempMarker = String.fromCharCode(65535); // 使用されない文字コードをマーカーとして使用
                let markedContents = contents.replace(/"([^"]*)"/g, function (match, p1) {
                return `"${p1.replace(/\r\n/g, tempMarker)}"`;
                });
                
                // 置換された内容を行で分割
                const records = markedContents.split('\r\n');
                
                // 分割されたフィールドを格納するための配列
                const strList: string[][] = [];

                // 各レコードを処理
                records.forEach((record) => {
                    // ダブルクォートで囲まれたフィールドを正規表現で分割
                    const fields = record.match(/(?:^|,)(\"(?:[^\"]+|\"\")*\"|[^,]*)/g)?.map(field =>
                        // ダブルクォートとフィールドの先頭のコンマを取り除き、
                        // エスケープされたダブルクォートとマーカーを元に戻す
                        field.trim().replace(/(?:^"|"$|^,)/g, '').replace(/""/g, '"').replace(new RegExp(tempMarker, 'g'), '\r\n')
                    );
                    
                    if (fields) {
                        strList.push(fields);
                    }
                });
                
                for(let i=0; i<strList.length; i++){
                    const row = strList[i];
                    
                    if(row.length !== 7){
                        setErrorFlag(true);
                        setErrorMsg(ERROR_MESSAGE_27);
                        return
                    }
                }
                
                const base64data = await getBase64Data(file);
                const response = await axios.request({
                    url: baseURL + "/upload-manage-login-after-info",
                    method: "post",
                    headers: { 
                        Authorization: `${token}`,
                    },
                    data : {
                        user_id: auth.username,
                        csv_content: base64data
                    }
                })
                setErrorFlag(false);
                setErrorMsg("");
                setCSVImportSuccess(true);
            } catch (error) {
                console.error(error);
                setCSVImportFailed(true);
            }
        }
        
        handleClose();
    };
    
    const readFileContents = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    resolve(reader.result);
                } else {
                    reject(new Error("Failed to read file"));
                }
            };
            reader.onerror = () => {
                reject(new Error("Failed to read file"));
            };
        });
    };

    const getBase64Data = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === "string") {
                const base64data = reader.result.split(",")[1];
                resolve(base64data);
                } else {
                    reject(new Error("Failed to read file"));
                }
            };
            reader.onerror = () => {
                reject(new Error("Failed to read file"));
            };
        });
    };
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "300px", 
                height: "100%", }}}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
            <IconButton
                onClick={() => handleClose()}
                style={{position: "absolute", top: 10, right: 10}}
                size="large"
            >
                <CloseIcon />
            </IconButton>
              
            <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>お知らせ一括登録</Typography>
            <Spacer size={20} />
            
            <div style={{ display: "flex", alignItems: "center" }}>
                <form onSubmit={handleFormSubmit}>
                    <input
                        type="file"
                        onChange={handleFileUpload}
                    />
                    <SaveButton 
                        type="submit"
                    >登録</SaveButton>
                </form>
            </div>
            <ErrorTypography variant="caption">{errorMsg}</ErrorTypography>

        </DialogContent>
    </Dialog>
  );
}