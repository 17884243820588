import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Spacer } from '../../../utils/components/Spacer';
import AutoComplete from '../../../utils/components/AutoComplete';

type Props = {
  nameList: string[];
  setSearchConditionName: (requestPointName?: string[]) => void;
  contractIdList: string[];
  setSearchConditionContractId: (requestPointId?: string[]) => void;
  supplyPointNoList: string[];
  setSearchConditionSupplyPointNo: (supplyPointNo?: string[]) => void;
  addressList: string[];
  setSearchConditionAddress: (address?: string[]) => void;
  setSearchConditionVoltageClass: (low?: boolean, high?: boolean, exHigh?: boolean) => void;
  setSearchConditionFiletype: (invoice?: boolean, specification?: boolean) => void;
  resetData: () => void;
}

export default function SearchBoxPDFDownload(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState<string[]>([]);
  const [contractId, setContractId] = React.useState<string[]>([]);
  const [address, setAddress] = React.useState<string[]>([]);
  const [supplyPointNo, setSupplyPointNo] = React.useState<string[]>([]);
  // 電圧区分チェックボックス3つ分
  const [checkedLow, setCheckedLow] = React.useState(true);
  const [checkedHigh, setCheckedHigh] = React.useState(true);
  const [checkedExHigh, setCheckedExHigh] = React.useState(true);
  // ファイル区分チェックボックス3つ分
  const [checkedInvoice, setCheckedInvoice] = React.useState(true);
  const [checkedSpecification, setCheckedSpecification] = React.useState(true);
  
  const {
    nameList, 
    setSearchConditionName,
    contractIdList,
    setSearchConditionContractId,
    addressList,
    setSearchConditionAddress,
    supplyPointNoList,
    setSearchConditionSupplyPointNo,
    setSearchConditionVoltageClass,
    setSearchConditionFiletype,
    resetData
  } = props;

  const handleClick = () => {
    setOpen(!open);
  };
  
  React.useEffect(() => {
    setSearchConditionVoltageClass(checkedExHigh, checkedHigh, checkedLow);
  }, [checkedExHigh, checkedHigh, checkedLow])

  React.useEffect(() => {
    setSearchConditionFiletype(checkedInvoice, checkedSpecification);
  }, [checkedInvoice, checkedSpecification])
  
  const handleChangeLow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedLow(event.target.checked);
  };
  const handleChangeHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedHigh(event.target.checked);
  };
  const handleChangeExHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedExHigh(event.target.checked);
  };
  
  const handleChangeInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedInvoice(event.target.checked);
  };
  const handleChangeSpecification = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSpecification(event.target.checked);
  };
  
  const resetConditionOnClick = () => {
    setName([]);
    setContractId([]);
    setAddress([]);
    setSupplyPointNo([]);
    setCheckedLow(true);
    setCheckedHigh(true);
    setCheckedExHigh(true);
    setCheckedInvoice(true);
    setCheckedSpecification(true);
    resetData();
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#0099CC', color: '#ffffff' }} onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary="詳細絞り込み" />
      </ListItemButton>
      {open &&
        <Paper variant="outlined" square >
          <Spacer size={10} />
          <Grid container spacing={2} alignItems="center">
            <>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={4}>
                <Typography variant="body1">種類</Typography>
                <Stack direction="row" spacing={1}>
                  <FormControlLabel control={<Checkbox checked={checkedInvoice} onChange={handleChangeInvoice} />} label="請求書" />
                  <FormControlLabel control={<Checkbox checked={checkedSpecification} onChange={handleChangeSpecification} />} label="明細書" />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">電圧区分</Typography>
                <Stack direction="row" spacing={1}>
                  <FormControlLabel control={<Checkbox checked={checkedExHigh} onChange={handleChangeExHigh} />} label="特高" />
                  <FormControlLabel control={<Checkbox checked={checkedHigh} onChange={handleChangeHigh} />} label="高圧" />
                  <FormControlLabel control={<Checkbox checked={checkedLow} onChange={handleChangeLow} />} label="低圧" />
                </Stack>
              </Grid>
              <Grid item xs={3.5} />
            </>
            <>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={8}>
                <Typography variant="body1">名称</Typography>
                <AutoComplete
                  value={name}
                  setValue={setName}
                  requestList={nameList}
                  setSearchConditionRequest={setSearchConditionName}/>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">契約番号</Typography>
                <AutoComplete
                  value={contractId}
                  setValue={setContractId}
                  requestList={contractIdList}
                  setSearchConditionRequest={setSearchConditionContractId}/>
              </Grid>
              <Grid item xs={0.5}></Grid>
            </>
            <>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={8}>
                <Typography variant="body1">住所</Typography>
                <AutoComplete
                  value={address}
                  setValue={setAddress}
                  requestList={addressList}
                  setSearchConditionRequest={setSearchConditionAddress}/>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">供給地点特定番号</Typography>
                <AutoComplete
                  value={supplyPointNo}
                  setValue={setSupplyPointNo}
                  requestList={supplyPointNoList}
                  setSearchConditionRequest={setSearchConditionSupplyPointNo}/>
              </Grid>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={0.5}></Grid>
            </>
            <>
              <Grid item xs={8.5}></Grid>
              <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={resetConditionOnClick}>検索条件をクリア</Button>
                </Box>
              </Grid>
              <Grid item xs={0.5}></Grid>
            </>
          </Grid>
        </Paper>
      }
    </div>
  );
}