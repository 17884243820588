export const ERROR_MESSAGE_01 = "ユーザ名を入力してください";
export const ERROR_MESSAGE_02 = "パスワードを入力してください";
export const ERROR_MESSAGE_03 = "現在のパスワードを入力してください";
export const ERROR_MESSAGE_04 = "新しいパスワードを入力してください";
export const ERROR_MESSAGE_05 = "新しいパスワード（再入力）を入力してください";
export const ERROR_MESSAGE_06 = "新しいパスワードと新しいパスワード（再入力）が異なります";
export const ERROR_MESSAGE_07 = "現在のパスワードと新しいパスワードが同じです";
export const ERROR_MESSAGE_08 = "ユーザ名をパスワードに使用することはできません";
export const ERROR_MESSAGE_09 = "期間の始まりは終わりより前の日付を指定してください";
export const ERROR_MESSAGE_10 = "期間の終わりは始まりより後の日付を指定してください";
export const ERROR_MESSAGE_11 = "メールアドレスを最低1つは入力してください";
export const ERROR_MESSAGE_12 = "数字を入力してください";
export const ERROR_MESSAGE_13 = "しきい値を入力してください";
export const ERROR_MESSAGE_14 = "9桁までのしきい値を入力してください";
export const ERROR_MESSAGE_15 = "メールアドレスを入力してください";
export const ERROR_MESSAGE_16 = "100文字以下のメールアドレスを入力してください";
export const ERROR_MESSAGE_17 = "半角で入力してください";
export const ERROR_MESSAGE_18 = "設定したい項目を選択してください";
export const ERROR_MESSAGE_19 = "50文字以下のパスワードを入力してください";
export const ERROR_MESSAGE_20 = "備考は100文字までです";
export const ERROR_MESSAGE_21 = "100文字以下の名称を入力してください";
export const ERROR_MESSAGE_22 = "件名を入力してください";
export const ERROR_MESSAGE_23 = "内容を入力してください";
export const ERROR_MESSAGE_24 = "公開開始日を入力してください";
export const ERROR_MESSAGE_25 = "公開終了日を入力してください";
export const ERROR_MESSAGE_26 = "添付ファイルはPDFファイル、zipファイルのいずれかとしてください。";
export const ERROR_MESSAGE_27 = "CSVファイルのフォーマットに誤りがあります。";
export const ERROR_MESSAGE_28 = "CSVファイルのサイズは1MBまでとしてください。";
export const ERROR_MESSAGE_29 = "件名は200文字までにしてください。";
export const ERROR_MESSAGE_30 = "内容は5000文字までにしてください。";
export const ERROR_MESSAGE_31 = "対象年月を入力してください。";
export const ERROR_MESSAGE_32 = "対象年月の期間を1年以内にしてください。";
export const ERROR_MESSAGE_33 = "120MB以上のファイルはアップロードできません";
export const ERROR_MESSAGE_0201_01 = "ユーザIDを入力してください。";
export const ERROR_MESSAGE_0201_02 = "パスワードを入力してください。";
export const ERROR_MESSAGE_0201_03 = "指定されたユーザー名またはパスワードが正しくありません。";
export const ERROR_MESSAGE_0201_04 = "パスワード入力回数の上限に達しました。時間をおいて再度お試しください。";