import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// custom components
import { Spacer } from '../../../utils/components/Spacer';
import SearchBoxInformationRegistration from '../../components/InformationRegistration/SearchBoxInformationRegistration'
import InformationRegistrationTable from '../../components/InformationRegistration/InformationRegistrationTable';
// styles
import { StyledButton } from '../../styles/kanriStyle';
// interfaces
import { InformationRegistrationRecordInterface } from '../../interfaces/InformationRegistration'
  
interface Props {
    open: boolean;
    isLoading: boolean;
    page: number,
    curPageData: InformationRegistrationRecordInterface[],
    searchedData: InformationRegistrationRecordInterface[];
    setSearchConditionUserId: (userId?: string) => void;
    setSearchConditionContractId: (contractId?: string) => void;
    setSearchConditionContractName: (contractName?: string) => void;
    setSearchConditionRequestPointId: (requestPointId?: string) => void;
    setSearchConditionRequestPointName: (requestPointName?: string) => void;
    setSearchConditionSupplyDate: (startHead: Dayjs | null, startTail: Dayjs | null, EndHead: Dayjs | null, EndTail: Dayjs | null) => void;
    setSearchConditionUnit: (contractUnit?: boolean, requestPointUnit?: boolean) => void;
    handleClose: () => void;
    searchData: () => void;
    resetData: () => void;
    pagenation: (curPage: number) => void;
    allCheck: (state: boolean) => void;
    singleCheck: (contract_id: string, request_point_id: string, user_type_code: string) => void;
};

export default function RegistrationDialog(props: Props) {
    const {
        open, 
        isLoading,
        page,
        curPageData,
        searchedData,
        setSearchConditionUserId,
        setSearchConditionContractId,
        setSearchConditionContractName,
        setSearchConditionRequestPointId,
        setSearchConditionRequestPointName,
        setSearchConditionSupplyDate,
        setSearchConditionUnit,
        handleClose,
        searchData,
        resetData,
        pagenation,
        allCheck,
        singleCheck
        } = props;
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            maxWidth={'xl'}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
              
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>配信対象者選択</Typography>
                    
                    <StyledButton
                        variant="outlined"
                        sx={{ marginRight: 0 }}
                        onClick={handleClose}
                    >閉じる</StyledButton>
                </Box>
                
                <Spacer size={20} />
                
                <SearchBoxInformationRegistration
                    setSearchConditionUserId={setSearchConditionUserId}
                    setSearchConditionContractId={setSearchConditionContractId}
                    setSearchConditionContractName={setSearchConditionContractName}
                    setSearchConditionRequestPointId={setSearchConditionRequestPointId}
                    setSearchConditionRequestPointName={setSearchConditionRequestPointName}
                    setSearchConditionSupplyDate={setSearchConditionSupplyDate}
                    setSearchConditionUnit={setSearchConditionUnit}
                    searchedData={searchedData}
                    searchData={searchData}
                    resetData={resetData}
                />
                
                {!isLoading?
                    <InformationRegistrationTable
                        data={curPageData}
                        searchedData={searchedData}
                        totalDataNum={searchedData.length}
                        page={page}
                        pagenation={pagenation}
                        allCheck={allCheck}
                        singleCheck={singleCheck}
                    />
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
            
            </DialogContent>
    </Dialog>
  );
}