import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import PrivateRoute from '../components/PrivateRoute';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TargetMonthSpecificationList from '../components/BillingSpecificationListDownload/TargetMonthSpecificationList';
import { Spacer } from '../../utils/components/Spacer';
import BillingDownloadTab from '../components/Tab/BillingDownloadTab';
import SpecificationListDownloadTable from '../components/BillingSpecificationListDownload/SpecificationListDownloadTable';
import { useSpecificationListDownload } from "../hooks/useSpecificationListDownload";
import { StyledDiv, StyledTypograpy, StyledFooter, StyledToolbar } from '../styles/style';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;

interface CsvFile {
  filename: string;
  blob: Blob;
}

export function BillingSpecificationListDownload() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  
  const { 
    page,
    searchedData,
    getSpecificationList,
    allCheck,
    singleCheck,
    pagenation
  } = useSpecificationListDownload();
  
  useEffect(() => {
    const getData = async() => {
      try {
        const _ = await getSpecificationList("", "");
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    getData();
  }, []);
  
  const onSpecificationDownloadButtonClick = async () => {
    setIsDownload(true);
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    // ダウンロード対象のファイル名を抽出
    const downloadFiles: string[] = [];
    const targetYMs: string[] = [];
    searchedData.forEach((obj) => {
      if(obj.checked) {
        downloadFiles.push(obj.system_file_name);
        targetYMs.push(obj.target_ym);
      }
    });
    
    if(downloadFiles.length === 0){
      window.alert("ダウンロード対象のファイルが存在しません");
      setIsDownload(false);
    }
    // 単一のcsvファイルをダウンロード
    else if(downloadFiles.length === 1){
      const end = Math.max(...targetYMs.map(Number));
      const start = Math.min(...targetYMs.map(Number));
      
      axios.request({
          url: baseURL + "/billing-inquery-invoice-download",
          method: "post",
          headers: { 
            Accept: "application/csv",
            Authorization: token,
          },
          data:{
            "downloadFiles": downloadFiles,
            "specification": ""
          }
      })
      .then((response) => {
        const blob: Blob = toBlobCsv(response.data, "application/csv");
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `明細一覧_${auth.contractID}_${start}_${end}.csv`);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownload(false);
      });
    }
    // zip形式のcsvファイル群をダウンロード
    else{
      const end = Math.max(...targetYMs.map(Number));
      const start = Math.min(...targetYMs.map(Number));
      
      axios.request({
        url: baseURL + "/billing-inquery-invoice-download",
        method: "post",
        headers: { 
          Accept: "application/zip",
          Authorization: token,
        },
        data:{
          "downloadFiles": downloadFiles,
          "specification": "",
          "start": start,
          "end": end
        }
      })
      .then((response) => {
        const blob: Blob = toBlobZip(response.data);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${auth.contractID}_${start}-${end}.zip`);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        alert("ダウンロード可能なサイズ上限を超えたため、ダウンロード対象数を減らしてください")
        setIsDownload(false);
      });
    }
  };

  function toBlobCsv(base64: string, mime_ctype: string): Blob {
    const byteArray = new Uint8Array(atob(base64).split('').map(function (char) {
      return char.charCodeAt(0);
    }));

    var bin = atob(base64);
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([byteArray], {
        type: mime_ctype,
    });
    return blob;
  };  

  // base64エンコードされたzip形式のファイルをblob型に変換する
  function toBlobZip(base64: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'application/zip'
    });
    return blob;
  };
  
  return (
    <PrivateRoute>
      <StyledDiv>
        <Spacer size={50} />
        <StyledTypograpy variant="h5">データダウンロード</StyledTypograpy>
        <Spacer size={20} />
        <BillingDownloadTab initPage={"specificationlist-download"} />
        <Spacer size={20} />
        <TargetMonthSpecificationList searchedData={searchedData} getSpecificationList={getSpecificationList} setIsLoading={setIsLoading}/>
        <Spacer size={20} />
        {( 
          typeof(auth.requestPointID) !== "undefined" ? 
            <p style={{textAlign: 'center'}}>対象なし</p> :
          !isLoading ? 
            <>
              <SpecificationListDownloadTable
                allCheck={allCheck}
                singleCheck={singleCheck}
                searchedData={searchedData}
                page={page} 
                pagenation={pagenation}/>
            </>
          :
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
        )}
      </StyledDiv>
      <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
        <StyledToolbar>
          <StyledButton
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onSpecificationDownloadButtonClick}>明細一覧ダウンロード</StyledButton>
          {isDownload && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </StyledToolbar>
      </StyledFooter>
    </PrivateRoute>
  );
}

/* Styles */
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  color: '#0098CB',
  fontWeight: 'bold',
  margin: 10,
  padding: 10,
  '&:hover': {
    backgroundColor: "#FFFFFF"
  }
}));
