import React, { useState, useEffect } from 'react';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import RegistrationDialog from '../components/ManagePersonAccount/RegistrationDialog';
import SearchBox from '../components/ManagePersonAccount/SearchBoxManagePersonAccount';
import ManagePersonAccountTable from '../components/ManagePersonAccount/ManagePersonAccountTable';
import PrivateRoute from '../components/PrivateRoute';
import { Spacer } from '../../utils/components/Spacer';
import SnackBar from '../../utils/components/SnackBar';
import { useManagePersonAccount } from "../hooks/useManagePersonAccount";
import { toBlobCsv, getCurrentUserToken } from '../../utils/functions';
import { StyledDiv, StyledTypography, StyledButton, StyledFooter, StyledToolbar, FooterButton } from '../styles/kanriStyle';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export function ManagePersonAccount() {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailed, setUpdateFailed] = useState(false);
    const { 
        page,
        searchedData,
        curPageData,
        getManagePersonAccountData,
        setSearchConditionRequestName,
        requestNameList,
        setSearchConditionRequestUserId,
        requestUserIdList,
        setSearchConditionAuthority,
        resetData,
        pagenation,
    } = useManagePersonAccount();
    
    useEffect(() => {
        const getData = async() => {
            try {
                const _ = await getManagePersonAccountData();
                setTimeout(() => { setIsLoading(false) }, 1000)
            }
                catch(error){
                console.log(error);
            }
        }
        getData();
    }, []);
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    // 採番ルールに沿ったアカウントIDの発番
    const createAccountID = (): string => {
        const consonants = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z'];
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        
        // ランダムな文字列を生成するための関数定義
        const getRandomChar = (arr: any[]): string => arr[Math.floor(Math.random() * arr.length)];
        
        // ランダムな3文字の大文字の子音を生成
        let id = '';
        for(let i = 0; i < 3; i++) {
            id += getRandomChar(consonants);
        }
        // アカウント種別番号（'09'）を4, 5文字目に追加
        id += '09';
        
        // ランダムな数字を6文字目から13文字目まで追加
        for(let i = 0; i < 8; i++) {
            id += getRandomChar(numbers);
        }
        return id;
    }
    
    const onClickCSVDownloadButton = async () => {
        const token = await getCurrentUserToken();
        axios.request({
            url: baseURL + "/get-manage-account-csv-download",
            method: "get",
            headers: { 
                Authorization: `${token}`,
            },
        })
        .then((response) => {
            const blob: Blob = toBlobCsv(response.data.data, "application/csv");
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${auth.username}_${dayjs().format("YYYYMMDDHHmmss")}.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    return (
        <PrivateRoute>
            <StyledDiv>
                <Spacer size={70} />
                <div style={{ display: "flex" }}>
                    <StyledTypography variant="h5">管理者アカウント一覧照会</StyledTypography>
                    <StyledButton 
                        onClick={handleOpen}
                        sx={{ marginLeft: "auto" }}>新規登録</StyledButton>
                    
                    <RegistrationDialog
                      open={open}
                      accountId={createAccountID()}
                      getManagePersonAccountData={getManagePersonAccountData}
                      handleClose={handleClose}
                      setOpenSuccess={setRegistrationSuccess}
                      setOpenFailed={setRegistrationFailed}/>
                </div>
                <Spacer size={20} />
                
                <SearchBox 
                    setSearchConditionRequestName={setSearchConditionRequestName}
                    requestNameList={requestNameList}
                    setSearchConditionRequestUserId={setSearchConditionRequestUserId}
                    requestUserIdList={requestUserIdList}
                    setSearchConditionAuthority={setSearchConditionAuthority}
                    resetData={resetData}/>
                    
                <Spacer size={30} />
                
                { !isLoading? 
                    <ManagePersonAccountTable
                        data={curPageData}
                        searchedData={searchedData}
                        totalDataNum={searchedData.length} 
                        page={page}
                        pagenation={pagenation}
                        getManagePersonAccountData={getManagePersonAccountData}
                        setOpenSuccess={setUpdateSuccess}
                        setOpenFailed={setUpdateFailed}/>
                    :
                    <div style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
                        <CircularProgress />
                    </div>
                }
                
            </StyledDiv>
            
            <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
                <StyledToolbar>
                    <FooterButton
                    variant="outlined"
                    startIcon={<SimCardDownloadOutlinedIcon />}
                    onClick={onClickCSVDownloadButton}>CSV出力</FooterButton>
                </StyledToolbar>
            </StyledFooter>
            
            <SnackBar
                successMessage="登録が完了しました"
                openSuccess={registrationSuccess}
                setOpenSuccess={setRegistrationSuccess}
                failedMessage="登録が失敗しました"
                openFailed={registrationFailed}
                setOpenFailed={setRegistrationFailed}/>
            
            <SnackBar
                successMessage="編集が完了しました"
                openSuccess={updateSuccess}
                setOpenSuccess={setUpdateSuccess}
                failedMessage="編集が失敗しました"
                openFailed={updateFailed}
                setOpenFailed={setUpdateFailed}/>
        </PrivateRoute>
    );
}