import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Auth } from 'aws-amplify';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../../utils/components/Spacer';
import { StyledTypography, CancelButton, SaveButton, ErrorTypography, ModalBox } from '../../styles/kanriStyle';
import { ERROR_MESSAGE_02, ERROR_MESSAGE_15, ERROR_MESSAGE_16, ERROR_MESSAGE_17, ERROR_MESSAGE_19, ERROR_MESSAGE_20, ERROR_MESSAGE_21 } from '../../../utils/errorMessages';
  
// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
interface Props {
    open: boolean;
    accountId: string;
    getManagePersonAccountData: () => void;
    handleClose: () => void;
    setOpenSuccess: (openSuccess: boolean) => void;
    setOpenFailed: (openFailed: boolean) => void;
};

export default function RegistrationDialog(props: Props) {
    const { open, accountId, handleClose, getManagePersonAccountData, setOpenSuccess, setOpenFailed } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [checkedCustomer, setCheckedCustomer] = useState(true);
    const [checkedInfo, setCheckedInfo] = useState(true);
    const [checkedLoginInfo, setCheckedLoginInfo] = useState(true);
    const [checkedAccount, setCheckedAccount] = useState(true);
    const [checkedChangePassword, setCheckedChangePassword] = useState(true);
    const [checkedBillingRelease, setCheckedBillingRelease] = useState(true);
    const [start, setStart] = useState<Dayjs | null>(dayjs());
    const [end, setEnd] = useState<Dayjs | null>(dayjs("2099/12/31"));
    const [mailAddress, setMailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [remarks, setRemarks] = useState('');
    const [nameError, setNameError] = useState('');
    const [mailAddressError, setMailAddressError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [remarksError, setRemarksError] = useState('');
    
    useEffect(() => {
        setUserId(accountId);
        setName('');
        setCheckedCustomer(true);
        setCheckedInfo(true);
        setCheckedLoginInfo(true);
        setCheckedAccount(true);
        setCheckedChangePassword(true);
        setCheckedBillingRelease(true);
        setStart(dayjs());
        setEnd(dayjs("2099/12/31"));
        setMailAddress('');
        setPassword('');
        setRemarks('');
        setNameError('');
        setMailAddressError('');
        setPasswordError('');
        setRemarksError('');
    }, [accountId])
    
    const updateManagePersonAccount = async () => {
        const token = await getCurrentUserToken();
        const authority = (checkedCustomer? "1" : "0") + 
            (checkedInfo? "1" : "0") + (checkedLoginInfo? "1" : "0") +
            (checkedAccount? "1" : "0") + (checkedChangePassword? "1" : "0") +
            (checkedBillingRelease? "1" : "0");
        
        axios.request({
            url: baseURL + "/update-manage-account",
            method: "post",
            headers: { 
                Authorization: `${token}`,
            },
            data : {
                'operator' : 'insert',
                'user_id' : userId,
                'name' : name,
                'mail_address' : mailAddress,
                'password' : password,
                'authority' : authority,
                'valid_period_start_date' : start !== null? start.format("YYYY/MM/DD") : dayjs().format("YYYY/MM/DD"),
                'valid_period_end_date' : end !== null? end.format("YYYY/MM/DD") : dayjs("2099/12/31").format("YYYY/MM/DD"),
                'remarks' : remarks
            },
        })
        .then((response) => {
            console.log(response);
            getManagePersonAccountData();
            setOpenSuccess(true);
        })
        .catch((error) => {
            console.log(error);
            setOpenFailed(true);
        });
    }
    
    // 名称の入力チェック
    const checkName = (): boolean => {
        if (name.length > 100) return setNameError(ERROR_MESSAGE_21), false;
        return true;
    }
    
    // メールアドレスの入力チェック
    const checkMailAddress = (): boolean => {
        if (mailAddress === "") return setMailAddressError(ERROR_MESSAGE_15), false;
        if (mailAddress.length > 100) return setMailAddressError(ERROR_MESSAGE_16), false;
        if (mailAddress.match(/^[^\x01-\x7E]*$/g) !== null) return setMailAddressError(ERROR_MESSAGE_17), false;
        return true;
    }
    
    // パスワードの入力チェック
    const checkPassword = (): boolean => {
        if (password === "") return setPasswordError(ERROR_MESSAGE_02), false;
        if (password.length > 50) return setPasswordError(ERROR_MESSAGE_19), false;
        if (password.match(/^[^\x01-\x7E]*$/g) !== null) return setPasswordError(ERROR_MESSAGE_17), false;
        return true;
    }
    
    // 備考の入力チェック
    const checkRemarks = (): boolean => {
        if (remarks.length > 100) return setRemarksError(ERROR_MESSAGE_20), false;
        return true;
    }
    
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "720px", 
                height: "100%", }}}
        >
            <DialogContent style={{ padding: "40px 80px" }}>
            <IconButton
                onClick={() => handleClose()}
                style={{position: "absolute", top: 10, right: 10}}
                size="large"
            >
                <CloseIcon />
            </IconButton>
              
            <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>管理者アカウント登録</Typography>
            <Spacer size={20} />
            <div style={{ display: "flex", alignItems: "center" }}>
                <StyledTypography>アカウントID</StyledTypography>
                <StyledTypography sx={{ marginLeft: "10px" }}>{userId}</StyledTypography>
            </div>
            <Spacer size={10} />
            
            <ModalBox>
                <StyledTypography>名称</StyledTypography>
                <TextField
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setNameError("");
                    }}
                    size="small"
                    style={{ width: '300px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{nameError !== ""? nameError : ""}</ErrorTypography>
                
                <StyledTypography>メールアドレス</StyledTypography>
                <TextField
                    value={mailAddress}
                    onChange={(e) => {
                        setMailAddress(e.target.value);
                        setMailAddressError("");
                    }}
                    size="small"
                    style={{ width: '300px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{mailAddressError !== ""? mailAddressError : ""}</ErrorTypography>
                
                <StyledTypography>パスワード</StyledTypography>
                <TextField
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError("");
                    }}
                    type={password === ""? "text" : showPassword? "text" : "password"}
                    size="small"
                    style={{ width: '300px', backgroundColor: "#FFFFFF" }}
                    InputProps={{ 
                        endAdornment: <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        </InputAdornment>
                    }}/>
                <ErrorTypography variant="caption">{passwordError !== ""? passwordError : ""}</ErrorTypography>
                
                <StyledTypography>権限</StyledTypography>
                <Stack direction="row" spacing={1}>
                    <FormControlLabel control={<Checkbox checked={checkedCustomer} onChange={(event) => setCheckedCustomer(event.target.checked)} />} label="お客さま管理" />
                    <FormControlLabel control={<Checkbox checked={checkedInfo} onChange={(event) => setCheckedInfo(event.target.checked)} />} label="お知らせ管理" />
                    <FormControlLabel control={<Checkbox checked={checkedLoginInfo} onChange={(event) => setCheckedLoginInfo(event.target.checked)} />} label="ログインお知らせ管理" />
                </Stack>
                <Stack direction="row" spacing={1}>
                    <FormControlLabel control={<Checkbox checked={checkedAccount} onChange={(event) => setCheckedAccount(event.target.checked)} />} label="アカウント管理" />
                    <FormControlLabel control={<Checkbox checked={checkedChangePassword} onChange={(event) => setCheckedChangePassword(event.target.checked)} />} label="パスワード変更" />
                    <FormControlLabel control={<Checkbox checked={checkedBillingRelease} onChange={(event) => setCheckedBillingRelease(event.target.checked)} />} label="請求公開" />
                </Stack>
                
                <StyledTypography>有効期限</StyledTypography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                        <DateTimePicker
                         value={start}
                         onChange={(newValue: Dayjs | null) => setStart(newValue)}
                         format="YYYY/MM/DD"
                         slotProps={{ textField: { size: 'small'}}}
                         sx={{ width: "150px", height: "40px" }}
                         views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                    <Typography variant="h5">~</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                        <DateTimePicker
                         value={end}
                         onChange={(newValue: Dayjs | null) => setEnd(newValue)}
                         format="YYYY/MM/DD"
                         slotProps={{ textField: { size: 'small'}}}
                         sx={{ width: "150px", height: "40px" }}
                         views={['year', 'month', 'day']}
                        />
                    </LocalizationProvider>
                </Stack>
                
                <StyledTypography>備考</StyledTypography>
                <TextField
                    value={remarks}
                    onChange={(e) => {
                        setRemarks(e.target.value);
                        setRemarksError("");
                    }}
                    size="small"
                    style={{ width: '300px', backgroundColor: "#FFFFFF" }} />
                <ErrorTypography variant="caption">{remarksError !== ""? remarksError : ""}</ErrorTypography>
              
                <Spacer size={20} />
                
                <div style={{ display: "flex", alignItems: "center" }}>
                    <CancelButton 
                        variant="outlined"
                        onClick={() => handleClose()}>キャンセル</CancelButton>
                    <SaveButton 
                        onClick={() => {
                            const chkName = checkName();
                            const chkMailAddress = checkMailAddress();
                            const chkPassword = checkPassword();
                            const chkRemarks = checkRemarks();
                            if ( !chkName || !chkMailAddress || !chkPassword || !chkRemarks ) return;
                            updateManagePersonAccount();
                            handleClose();}}
                            >登録</SaveButton>
                </div>
            </ModalBox>
        </DialogContent>
    </Dialog>
  );
}