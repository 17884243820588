import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import PrivateRoute from '../components/PrivateRoute';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../utils/hooks/use-auth';
import axios from "axios";
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import TargetMonth from '../components/utils/TargetMonth';
import SearchBoxAchievementDownload from '../components/SearchBox';
import { Spacer } from '../../utils/components/Spacer';
import UsingAchievementDownloadMonthlyTable from '../components/Download/UsingAchievementDownloadMonthlyTable';
import AchievementDownloadTab from '../components/Tab/AchievementDownloadTab';
import { useAchievementDonwloadMonthly } from "../hooks/useAchievementDonwloadMonthly";
import { StyledDiv, StyledTypograpy, StyledFooter, StyledToolbar } from '../styles/style';

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const downloadFileLength = 100;

interface CsvFile {
  filename: string;
  blob: Blob;
}

export function UsingAchievementDownloadMonthly() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  
  const { 
    page,
    searchedData,
    getDownloadList,
    targetDateList,
    setSearchConditionTargetDate,
    setSearchConditionRequestPointName,
    requestPointNameList,
    setSearchConditionRequestPointId,
    requestPointIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionFeeMenuNamePrinting,
    feeMenuNamePrintingList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck,
    initTargetMonth,
    searchCondition
  } = useAchievementDonwloadMonthly();
  
  useEffect(() => {
    setIsLoading(true);
    const getData = async() => {
      try {
        const _ = await getDownloadList();
        setIsLoading(false);
      }
      catch(error){
        console.log(error);
      }
    }
    if (searchCondition.targetMonth !== "") getData();
  }, [searchCondition.targetMonth]);
  
  useEffect(() => {
    initTargetMonth();
  }, []);
  
  const onLambdaCsvButtonClick = async () => {
    setIsDownload(true);
    localStorage.removeItem('token');
    const token = await getCurrentUserToken();
    // ダウンロード対象のファイル名を抽出
    const downloadFiles: Object[] = [];
    const targetYMs: string[] = [];
    searchedData.filter(obj => obj.checked).map((obj) => {
      let donwloadFile = {
        requestPointId : obj.request_point_id,
        targetYm : obj.target_ym,
        customerId : obj.customer_no,
        supplyPointNo : obj.supply_point_no,
        requestPointName : obj.request_point_name
      };
      downloadFiles.push(donwloadFile);
      targetYMs.push(obj.target_ym);
    });
      
    if(downloadFiles.length === 0){
      window.alert("ダウンロード対象のファイルが存在しません");
      setIsDownload(false);
    }
    // 単一のcsvファイルをダウンロード
    else if(downloadFiles.length === 1){
      axios.request({
          url: baseURL + "/volume-usage/download-month",
          method: "post",
          headers: { 
            Accept: "application/csv",
            Authorization: token,
          },
          data: downloadFiles,
      })
      .then((response) => {
        // const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
        const blob: Blob = toBlobCsv(response.data.data, "application/csv");
        const url = window.URL.createObjectURL(blob);
        // window.open(url, "_blank")  // pdfを別タブで表示
        const link = document.createElement('a');
        const filename = response.data.filename;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setIsDownload(false);
      })
      .catch((error) => {
        console.log(error);
        setIsDownload(false);
      });
    }
    // zip形式のCsvファイル群をダウンロード
    else{
      const end = Math.max(...targetYMs.map(Number));
      const start = Math.min(...targetYMs.map(Number));
      
      if (downloadFiles.length <= downloadFileLength) {
        axios.request({
          url: baseURL + "/volume-usage/download-month",
          method: "post",
          headers: { 
            Accept: "application/zip",
            Authorization: token,
          },
          data: downloadFiles
        })
        .then((response) => {
          const blob: Blob = toBlobZip(response.data.data);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const downloadFileName = typeof(auth.requestPointID) === "undefined" ?
            `${start}-${end}_${auth.contractID}_${auth.name}.zip`
            : `${start}-${end}_${auth.requestPointID}_${auth.name}.zip`;
          link.setAttribute('download', downloadFileName);
          document.body.appendChild(link);
          link.click();
          setIsDownload(false);
        })
        .catch((error) => {
          console.log(error);
          setIsDownload(false);
        });
      } else {
        // 1つのAPIが対処するファイル数をおよそ150件にする分割数の決定
        const segmentCount = downloadFiles.length / downloadFileLength;
        
        // ダウンロードするファイル群をsegmentCountの数だけ等分する
        const chunkSize = Math.ceil(downloadFiles.length / segmentCount);
        const fileChunks = [];
        for (let i = 0; i < downloadFiles.length; i += chunkSize) {
          fileChunks.push(downloadFiles.slice(i, i + chunkSize));
        }
        
        // 新しいzipインスタンスを作成
        const finalZip = new JSZip();
        
         // 各チャンクごとにAPIリクエストを行い、結果を結合する
        try {
          const zipParts = await Promise.all(fileChunks.map(async (chunk, index) => {
            const response = await axios.request({
              url: baseURL + "/volume-usage/download-month",
              method: "post",
              headers: {
                Accept: "application/zip",
                Authorization: token,
              },
              data: chunk
            });
            
            // base64エンコードされたzipデータをBlobに変換
            const blob: Blob = toBlobZip(response.data.data);
      
            const zip = new JSZip();
            await zip.loadAsync(blob);
      
            const csvFiles: Promise<CsvFile>[] = [];
            zip.forEach((relativePath, file) => {
              if (relativePath.endsWith('.csv')) {
                csvFiles.push(file.async('blob').then((blob) => ({
                  filename: relativePath,
                  blob: blob
                })));
              }
            });
            
            return await Promise.all(csvFiles);
          }));
          
          zipParts.flat().forEach(({ filename, blob }) => {
            finalZip.file(filename, blob);
          });
        
          // 最終的なzipファイルを生成してダウンロード
          finalZip.generateAsync({ type: 'blob' }).then(function (content) {
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            const downloadFileName = typeof(auth.requestPointID) === "undefined" ?
            `${start}-${end}_${auth.contractID}_${auth.name}.zip`
            : `${start}-${end}_${auth.requestPointID}_${auth.name}.zip`;
            link.setAttribute('download', downloadFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          
        } catch (error) {
          console.error(error);
        } finally {
          setIsDownload(false);
        }
      }
    }
  };
  
  function toBlobCsv(base64: string, mime_ctype: string): Blob {
    const byteArray = new Uint8Array(atob(base64).split('').map(function (char) {
      return char.charCodeAt(0);
    }));

    var bin = atob(base64);
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    var blob = new Blob([byteArray], {
        type: mime_ctype,
    });
    return blob;
  };
  
  // base64エンコードされたzip形式のファイルをblob型に変換する
  function toBlobZip(base64: string): Blob {
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const blob = new Blob([buffer.buffer], {
      type: 'application/zip'
    });
    return blob;
  };
  
  return (
    <PrivateRoute>
      <StyledDiv>
      <Spacer size={50} />
        <StyledTypograpy variant="h5">データダウンロード</StyledTypograpy>
        <Spacer size={20} />
        <AchievementDownloadTab initPage={"monthly"} />
        <Spacer size={20} />
        <TargetMonth searchData={setSearchConditionTargetDate} targetDateList={targetDateList} resetState={resetState} clearResetSatate={clearResetSatate} />
        <Spacer size={20} />
        {(!isLoading ?
          <>
            <SearchBoxAchievementDownload 
              setSearchConditionRequestPointName={setSearchConditionRequestPointName}
              requestPointNameList={requestPointNameList}
              setSearchConditionRequestPointId={setSearchConditionRequestPointId}
              requestPointIdList={requestPointIdList}
              setSearchConditionSupplyPointNo={setSearchConditionSupplyPointNo}
              supplyPointNoList={supplyPointNoList}
              setSearchConditionAddress={setSearchConditionAddress}
              addressList={addressList}
              setSearchConditionFeeMenuNamePrinting={setSearchConditionFeeMenuNamePrinting}
              feeMenuNamePrintingList={feeMenuNamePrintingList}
              setSearchConditionVoltageClass={setSearchConditionVoltageClass}
              setSearchConditionSupplyDate={setSearchConditionSupplyDate}
              resetData={resetData} />
            <Spacer size={30} />
            <UsingAchievementDownloadMonthlyTable
              allCheck={allCheck}
              singleCheck={singleCheck}
              searchedData={searchedData}
              page={page} 
              pagenation={pagenation}/>
          </>
          :
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}
      </StyledDiv>
      <StyledFooter sx={{ top: 'auto', bottom: 0 }}>
        <StyledToolbar>
          <StyledButton
            variant="outlined"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={onLambdaCsvButtonClick}>CSVダウンロード</StyledButton>
          {isDownload && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </StyledToolbar>
      </StyledFooter>
    </PrivateRoute>
  );
}

/* Styles */
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: '#0098CB',
  fontWeight: 'bold',
  margin: 10,
  padding: 10,
  '&:hover': {
    backgroundColor: "#FFFFFF"
  }
}));
