import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import { useAuth } from "../../utils/hooks/use-auth";
import { SHA256 } from 'crypto-js'
import axios from 'axios';
import { StyledDiv, StyledTypograpy, StyledTextField, ForgotPasswordForm, ForgotPasswordMain, LoginButton } from '../styles/style';
import { Spacer } from '../../utils/components/Spacer';
import { toBlobPdf, getCustomerManualPdf, passwordPolicy } from '../../utils/functions';

export function ForgetPwConfirm(props: any){
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  
  const [showFlg, setShowFlg] = useState(true);
  
  const [userId, setUserId] = useState('');
  const [tmpPassword, setTmpPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPwValid, setIsPwValid] = useState(true);
  const [validateError, setValidateError] = useState('');
  
  useEffect(()=> {
    const param = new URLSearchParams(location.search);
    let param_url = param.get('param');
    if(param_url) {
      console.log(param_url)
      axios.request({
          url: baseURL + "/first-login-decode",
          method: "post",
          headers: { 
            Accept: "application/json"
          },
          data: {
            'url': param_url,
            'operator': 'forgot-password'
          }
      })
      .then(async (response) => {
        if(response.status == 200) {
          setUserId(response.data["user_id"]);
          setTmpPassword(response.data["tmp_password"]);
        }
      }).catch(error => {
        setShowFlg(false);
      });
      
    }
  }, []);
  
  const [errors, setErrors] = useState({
    "userError"    : '',
    "passwordError" : '',
    "confirmPwError": '',
    "confirmCodeErr": '',
    "resetError"    : ''
  });
  
  function clearMsg() {
    setErrors({...errors,
      "userError"    : '',
      "passwordError" : '',
      "confirmPwError": '',
      "confirmCodeErr": '',
      "resetError"    : ''
    });
  }
  const handlePwChange = (event: any) => {
    const value = event.target.value;
    setNewPassword(value);
    clearMsg();
  }
  const handleRePwChange = (event: any) => {
    const value = event.target.value;
    setConfirmPassword(value);
    clearMsg();
  }
  
  const addResetRecord = async () => {
    axios.request({
      url: baseURL + "/password-reset-check",
      method: "post",
      headers: { 
        Accept: "application/json"
      },
      data: {
        'user_id': userId,
        'hashed_password': SHA256(newPassword).toString(),
        'operator': 'add'
      }
    }).then((response) => {
      console.log(response.data)
      if(response.data.result == true) {
        navigate('/user/password-reset-success');
      } else {
        setErrors({...errors, resetError: "リセットが失敗しました、再設定お願いします。"})
      }
      
    })
    .catch((error) => {});
  }
  
  const validatePassword = (v: string) => {
    
    const numberRegex = /\d/;
    const specialCharRegex = passwordPolicy;
    const lowerCaseRegex = /[a-z]/;
    const fullWidthRegex = /[^\x00-\xff]/;
    return (v.length >= 10 && v.length <= 50 && numberRegex.test(v) && specialCharRegex.test(v) && lowerCaseRegex.test(v) && !fullWidthRegex.test(v))
  }
  const errMsg01 = "ユーザー名を入力してください。";
  const errMsg02 = "パスワードを入力してください。";
  const errMsg03 = "パスワード(再入力)を入力してください。";
  const errMsg04 = "パスワードと合致させてください。";
  const errMsg05 = "ユーザー名をパスワードに設定不可";
  const errMsg06 = "パスワード制約を満たすようなパスワードを入力してください。";
  
  const updatePassword = async function() {
    //validation
    if(userId == '') {
      setErrors({...errors, userError: errMsg01})
      return;
    }
    if(newPassword == '') {
      setErrors({...errors, passwordError: errMsg02})
      return;
    }
    if(confirmPassword == '') {
      setErrors({...errors, confirmPwError: errMsg03})
      return;
    }
    if(newPassword == userId) {
      setErrors({...errors, passwordError: errMsg05})
      return;
    }
    if(!validatePassword(newPassword)) {
      setErrors({...errors, resetError: errMsg06})
      return;
    }
    if(newPassword != confirmPassword) {
      setErrors({...errors, confirmPwError: errMsg04})
      return;
    }
    axios.request({
        url: baseURL + "/password-reset-check",
        method: "post",
        headers: { 
          Accept: "application/json"
        },
        data: {
          'user_id': userId,
          'hashed_password': SHA256(newPassword).toString()
        }
    })
    .then(async (response) => {
      if(response.data.result == false) {
        setErrors({...errors, resetError: errMsg06})
      } else {
        // 履歴に追加
        const user = await auth.signIn(userId, tmpPassword);
        axios.request({
            url: baseURL + "/update-change-password",
            method: "post",
            headers: { 
              Accept: "application/json"
            },
            data: {
              'user_id': userId,
              'password': newPassword
            }
        })
        .then(async (response) => {
          auth.signOut();
          await addResetRecord();
        }).catch(error => {
          setErrors({...errors, resetError: 'パスワードの変更に失敗しました。'});
        });
      }
    })
    .catch((error) => {});
    
  }
  
  return(
    <StyledDiv>
      <div style={{ width: "500px", margin: "20px auto 0 auto", fontSize: "20px", color: "#00506B" }}>
        新しく設定するパスワードを入力後、「変更内容を保存」をクリックしてください
      </div>
      <ForgotPasswordMain style = {{ marginTop: "40px" }}>
        <ForgotPasswordForm>
          <div className="err-msg" style = {{ width: "300px" }}>
            {errors.resetError != ""? <div>{errors.resetError}</div>: ""}
          </div>
          <div>
            <StyledTypograpy>ユーザー名</StyledTypograpy>
            <StyledTypograpy style = {{ width: "300px" }}>{userId}</StyledTypograpy>
            <Spacer size={10} />
            <div className="err-msg">
              {errors.userError != ""? <div>{errors.userError}</div>: ""}
            </div>
          </div>
          <div>
            <StyledTypograpy>新しいパスワード</StyledTypograpy>
            <StyledTextField
              id="new_password"
              type="password"
              value={newPassword}
              onChange={handlePwChange}
            />
            <Spacer size={10} />
            <div className="err-msg">
              {validateError != ""? <div>{validateError}</div>: ""}
              {errors.passwordError != ""? <div>{errors.passwordError}</div>: ""}
            </div>
          </div>
          <div>
            <StyledTypograpy>新しいパスワード(再入力)</StyledTypograpy>
            <StyledTextField
              id="reconfirm_password"
              type="password"
              value={confirmPassword}
              onChange={handleRePwChange}
            />
            <Spacer size={10} />
            <div className="err-msg">
              {errors.confirmPwError != ""? <div>{errors.confirmPwError}</div>: ""}
            </div>
          </div>
          <Spacer size={20} />
          <LoginButton onClick={updatePassword}>変更内容を保存する</LoginButton>
        </ForgotPasswordForm>
      </ForgotPasswordMain>
      <div style = {{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
        ※　パスワードは以下の制約があります <br/>
        文字数制限：10～50文字<br/>
        入力制限：<br/>
        ・半角英字、半角数字、記号を1文字以上含む<br/>
        ・旧パスワードと新パスワードは同じになりません<br/>
        ・ユーザー名をパスワードに設定不可<br/>
        変更時制限：<br/>
        ・直近指定したパスワード3回分と同じ値は設定できません
      </div>
      <Spacer size={50} />
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
            <Link component="button" onClick={(e: any) => (getCustomerManualPdf('エナリスお客さまサイト_操作マニュアル.pdf'))} sx={{ color: "#333333" }} underline="hover">ログイン操作マニュアル</Link>
            <FilterNoneOutlinedIcon sx={{ marginLeft: "5px", marginRight: "40px", color: "#333333", fontSize: 15 }} />
            <div>
              <Typography sx={{ color: "#333333", fontWeight: 'Bold' }}>当サイトに関するお問合せ<br/></Typography>
              <Typography sx={{ color: "#333333", marginLeft: '10px' }}>株式会社エナリス お客さまサイト窓口<br/>mail:ec_support@eneres.co.jp</Typography>
            </div>
      </div>
    </StyledDiv>
  )
}
