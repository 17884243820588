import * as React from 'react';
// MUI components
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// interfaces
import { ContractInformation, ContractInformationRecord } from '../../interfaces/ContractInformation'
// styled custom components
import { StyledTableSortLabel } from '../../styles/style';


interface Order {
  request_point_name: 'asc' | 'desc';
  request_point_id: 'asc' | 'desc';
  supply_point_no: 'asc' | 'desc';
  voltage_class: 'asc' | 'desc';
  fee_menu_name_printing: 'asc' | 'desc';
  address: 'asc' | 'desc';
  supply_start_date: 'asc' | 'desc';
  contract_end_date: 'asc' | 'desc';
  valid_start_date: 'asc' | 'desc';
  valid_end_date: 'asc' | 'desc';
  contract_capacity: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface contractInformationTable {
  data: ContractInformationRecord[];
  toggleChecked: (index: number) => void;
  searchedData: ContractInformationRecord[];
  totalDataNum: number;
  page: number;
  pagenation: (page: number) => void;
  checkState: number[];
  allCheck: () => void;
  clearCheck: () => void;
}

const itemsPerPage: number = 20;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function ContractInformationTable(props: contractInformationTable) {
  const { data, searchedData, toggleChecked, checkState, allCheck, clearCheck, totalDataNum, page, pagenation } = props;
  const [order, setOrder] = React.useState<Order>({ 
    request_point_name: 'asc',
    request_point_id: 'asc',
    supply_point_no: 'asc',
    voltage_class: 'asc',
    fee_menu_name_printing: 'asc',
    address: 'asc',
    supply_start_date: 'asc',
    contract_end_date: 'asc',
    valid_start_date: 'asc',
    valid_end_date: 'asc',
    contract_capacity: 'asc'
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('request_point_id');
  const [checkboxBulk, setCheckboxBulk] = React.useState<boolean>(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxBulk(event.target.checked);
    if(event.target.checked){
      allCheck();
    }
    else{
      clearCheck();
    }
  };
  
  interface VoltageMap {
    [key: string]: number;
  }
  
  const voltageMap: VoltageMap = {
    '低圧': 0,
    '高圧': 1,
    '特高': 2
  };

  // カラムごとによる行の並び替え
  const compare = (a: ContractInformationRecord, b: ContractInformationRecord): number => {
    if (orderBy === 'request_point_name') {
      if (a.request_point_name < b.request_point_name) {
        return order.request_point_name === 'asc' ? -1 : 1;
      }
      if (a.request_point_name > b.request_point_name) {
        return order.request_point_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'request_point_id') {
      if (a.request_point_id < b.request_point_id) {
        return order.request_point_id === 'asc' ? -1 : 1;
      }
      if (a.request_point_id > b.request_point_id) {
        return order.request_point_id === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'voltage_class') {
      if (voltageMap[a.voltage_class] < voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? -1 : 1;
      }
      if (voltageMap[a.voltage_class] > voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_point_no') {
      if (a.supply_point_no < b.supply_point_no) {
        return order.supply_point_no === 'asc' ? -1 : 1;
      }
      if (a.supply_point_no > b.supply_point_no) {
        return order.supply_point_no === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'fee_menu_name_printing') {
      if (a.fee_menu_name_printing < b.fee_menu_name_printing) {
        return order.fee_menu_name_printing === 'asc' ? -1 : 1;
      }
      if (a.fee_menu_name_printing > b.fee_menu_name_printing) {
        return order.fee_menu_name_printing === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_capacity') {
      const contract_power_a = a.contract_power_usually !== ""? a.contract_power_usually + a.capacity_unit : a.fee_menu_base_fee_confirm_value !== ""? a.fee_menu_base_fee_confirm_value + a.capacity_unit : "";
      const contract_power_b = b.contract_power_usually !== ""? b.contract_power_usually + b.capacity_unit : b.fee_menu_base_fee_confirm_value !== ""? b.fee_menu_base_fee_confirm_value + b.capacity_unit : "";
      
      if (contract_power_a < contract_power_b) {
        return order.contract_capacity === 'asc' ? -1 : 1;
      }
      if (contract_power_a > contract_power_b) {
        return order.contract_capacity === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'address') {
      if (a.address < b.address) {
        return order.address === 'asc' ? -1 : 1;
      }
      if (a.address > b.address) {
        return order.address === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_start_date') {
      if (a.supply_start_date < b.supply_start_date) {
        return order.supply_start_date === 'asc' ? -1 : 1;
      }
      if (a.supply_start_date > b.supply_start_date) {
        return order.supply_start_date === 'asc' ? 1 : -1;
      }
      if (a.supply_start_date === b.supply_start_date) {
        if (a.contract_end_date < b.contract_end_date) {
          return order.contract_end_date === 'asc' ? -1 : 1;
        }
        if (a.contract_end_date > b.contract_end_date) {
          return order.contract_end_date === 'asc' ? 1 : -1;
        }
      }
    } else if (orderBy === 'valid_start_date') {
      if (a.valid_start_date < b.valid_start_date) {
        return order.valid_start_date === 'asc' ? -1 : 1;
      }
      if (a.valid_start_date > b.valid_start_date) {
        return order.valid_start_date === 'asc' ? 1 : -1;
      }
      if (a.valid_start_date === b.valid_start_date) {
        if (a.valid_end_date < b.valid_end_date) {
          return order.valid_end_date === 'asc' ? -1 : 1;
        }
        if (a.valid_end_date > b.valid_end_date) {
          return order.valid_end_date === 'asc' ? 1 : -1;
        }
      }
    }
    return 0;
  };
  
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page-1)*itemsPerPage+data.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_name'}
                  direction={order.request_point_name}
                  onClick={() => handleRequestSort('request_point_name')}
                >
                  需要場所
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'request_point_id'}
                  direction={order.request_point_id}
                  onClick={() => handleRequestSort('request_point_id')}
                >
                  需要場所ID
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_point_no'}
                  direction={order.supply_point_no}
                  onClick={() => handleRequestSort('supply_point_no')}
                >
                  供給地点特定番号
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'voltage_class'}
                  direction={order.voltage_class}
                  onClick={() => handleRequestSort('voltage_class')}
                >
                  電圧区分
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'fee_menu_name_printing'}
                  direction={order.fee_menu_name_printing}
                  onClick={() => handleRequestSort('fee_menu_name_printing')}
                >
                  契約種別名
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_capacity'}
                  direction={order.contract_capacity}
                  onClick={() => handleRequestSort('contract_capacity')}
                >
                  契約容量
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'address'}
                  direction={order.address}
                  onClick={() => handleRequestSort('address')}
                >
                  住所
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_start_date'}
                  direction={order.supply_start_date}
                  onClick={() => handleRequestSort('supply_start_date')}
                >
                  供給期間
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'valid_start_date'}
                  direction={order.valid_start_date}
                  onClick={() => handleRequestSort('valid_start_date')}
                >
                  契約期間
                </StyledTableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#ABE1FA' : '#ffffff' }}
              >
                <TableCell>{row.request_point_name}</TableCell>
                <TableCell>{row.request_point_id}</TableCell>
                <TableCell>{row.supply_point_no}</TableCell>
                <TableCell>{row.voltage_class}</TableCell>
                <TableCell>{row.fee_menu_name_printing}</TableCell>
                <TableCell>{row.contract_power_usually !== ""? Math.floor(parseFloat(row.contract_power_usually)).toString() + row.capacity_unit
                : row.fee_menu_base_fee_confirm_value !== ""? Math.floor(parseFloat(row.fee_menu_base_fee_confirm_value)).toString() + row.capacity_unit : ""}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{`${dayjs(row.supply_start_date).format("YYYY/MM/DD")}~${dayjs(row.contract_end_date).format("YYYY/MM/DD") !== "2099/12/31"? dayjs(row.contract_end_date).format("YYYY/MM/DD") : ""}`}</TableCell>
                <TableCell>{`${dayjs(row.valid_start_date).format("YYYY/MM/DD")}~${dayjs(row.valid_end_date).format("YYYY/MM/DD") !== "2099/12/31"? dayjs(row.valid_end_date).format("YYYY/MM/DD") : ""}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (data.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              clearCheck();
              setCheckboxBulk(false);
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}