import React from 'react';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';
import { Spacer } from '../../utils/components/Spacer';
import { NewsDateTypograpy, NewsModalBox } from '../../user/styles/style';

interface InformationDialogProps {
    open: boolean;
    onClose: () => void;
    infoDate: string;
    infoTitle: string;
    infoText: string;
    infoAttach: string;
    onDownload: (event: any) => void; 
}

export default function InformationDialog(props: InformationDialogProps) {
    const { open, onClose, infoDate, infoTitle, infoText, infoAttach, onDownload } = props
    
    // URLを検出してLinkに変換する関数
    const renderTextWithLinks = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                return <Link key={index} href={part} target="_blank" rel="noopener noreferrer" sx={{ color: 'blue' }}>{part}</Link>;
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };
    
    return (
        <Dialog 
            open={open}
            onClose={onClose}
            BackdropProps={{ style: { opacity: 0.5 }}}
            PaperProps={{ 
                style: {
                maxWidth: "910px", 
                width: "100%", 
                maxHeight: "720px", 
                height: "100%", 
            }}}
        >
        <DialogContent style={{ padding: "40px 80px" }}>
        <IconButton onClick={onClose} style={{position: "absolute", top: 10, right: 10}} size="large">
            <CloseIcon />
        </IconButton>
        
        <NewsDateTypograpy>{infoDate}</NewsDateTypograpy>
        <Typography variant="h6" style={{ color: "#00506B", fontWeight: "bold" }}>{infoTitle}</Typography>
        
        <Spacer size={20} />
        
        {infoAttach &&
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link component="button" underline="hover" onClick={onDownload} sx={{ color: "#333333"}}>
                    {infoAttach}
                </Link>
                <FilterNoneOutlinedIcon sx={{ color: "#333333", fontSize: 15, marginLeft: "5px" }} />
            </div>
        }
        
        <Spacer size={20} />
        
        <NewsModalBox>
        <div style={{ wordWrap: "break-word", width: "100%", padding: "0 0 5px 0" }}>
            {infoText.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                    {renderTextWithLinks(line)}
                     <br />
                </React.Fragment>
            ))}
        </div>
        </NewsModalBox>
        </DialogContent>
        </Dialog>
    );
}
