import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useAuth } from '../../../utils/hooks/use-auth';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Spacer } from '../../../utils/components/Spacer';
import { StyledButton } from '../../styles/kanriStyle';
// interfaces
import { InformationRegistrationRecordInterface, InformationRegistrationJsonToCsvInterface, SearchCondition } from '../../interfaces/InformationRegistration'

type Props = {
  setSearchConditionUserId: (userId?: string) => void;
  setSearchConditionContractId: (contractId?: string) => void;
  setSearchConditionContractName: (contractName?: string) => void;
  setSearchConditionRequestPointId: (requestPointId?: string) => void;
  setSearchConditionRequestPointName: (requestPointName?: string) => void;
  setSearchConditionSupplyDate: (startHead: Dayjs|null, startTail: Dayjs|null, EndHead: Dayjs|null, EndTail: Dayjs|null) => void;
  setSearchConditionUnit: (contractUnit?: boolean, requestPointUnit?: boolean) => void;
  searchedData: InformationRegistrationRecordInterface[];
  searchData: () => void;
  resetData: () => void;
}

interface Field {
  label: string;
  value: keyof InformationRegistrationJsonToCsvInterface;
}

const fields: Field[] = [
  { label: '契約ID', value: 'contract_id' },
  { label: '契約名', value: 'contract_name' },
  { label: '需要場所ID', value: 'request_point_id' },
  { label: '需要場所名', value: 'request_point_name' },
  { label: '供給地点特定番号', value: 'supply_point_no' },
  { label: '供給開始日', value: 'supply_start_date' },
  { label: '供給終了日', value: 'contract_end_date' },
  { label: '種別(料金プラン)', value: 'fee_menu_name_printing' },
  { label: '契約容量', value: 'contract_power_usually' },
  { label: 'ユーザID', value: 'user_id' },
  { label: 'アカウント種別', value: 'user_type' }
];

export default function SearchBoxCustomerManagement(props: Props) {
  const auth = useAuth();
  const [userId, setUserId] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [contractName, setContractName] = useState<string>('');
  const [requestPointId, setRequestPointId] = useState<string>('');
  const [requestPointName, setRequestPointName] = useState<string>('');
  const [startDateHead, setStartDateHead] = useState<Dayjs | null>(null);
  const [startDateTail, setStartDateTail] = useState<Dayjs | null>(null);
  const [endDateHead, setEndDateHead] = useState<Dayjs | null>(dayjs());
  const [endDateTail, setEndDateTail] = useState<Dayjs | null>(null);
  const [checkedContractUnit, setCheckedContractUnit] = useState(true);
  const [checkedRequestPointUnit, setCheckedRequestPointUnit] = useState(true);
  
  const {
    setSearchConditionUserId,
    setSearchConditionContractId,
    setSearchConditionContractName,
    setSearchConditionRequestPointId,
    setSearchConditionRequestPointName,
    setSearchConditionSupplyDate,
    setSearchConditionUnit,
    searchedData,
    searchData,
    resetData
  } = props;
  
  useEffect(() => {
    setSearchConditionUnit(checkedContractUnit, checkedRequestPointUnit);
  }, [checkedContractUnit, checkedRequestPointUnit])
  
  useEffect(() => {
    searchData();
  }, [])
  
  const handleChangeSupplyStartHead = (newValue: Dayjs| null) => {
     setStartDateHead(newValue);
     setSearchConditionSupplyDate(newValue, null, null, null);
  };
  const handleChangeSupplyStartTail = (newValue: Dayjs | null) => {
     setStartDateTail(newValue);
     setSearchConditionSupplyDate(null, newValue, null, null);
  };
  const handleChangeSupplyEndHead = (newValue: Dayjs | null) => {
     setEndDateHead(newValue);
     setSearchConditionSupplyDate(null, null, newValue, null);
  };
  const handleChangeSupplyEndTail = (newValue: Dayjs | null) => {
     setEndDateTail(newValue);
     setSearchConditionSupplyDate(null, null, null, newValue);
  };
  
  const handleClear = () => {
    setUserId("");
    setContractId("");
    setContractName("");
    setRequestPointId("");
    setRequestPointName("");
    setStartDateHead(null);
    setStartDateTail(null);
    setEndDateHead(dayjs());
    setEndDateTail(null);
    setCheckedContractUnit(true);
    setCheckedRequestPointUnit(true);
    resetData();
  }
  
  useEffect(() => {
    handleClear();
  }, [])
  
  const convertJsonToCsv = (data: InformationRegistrationJsonToCsvInterface[]) => {
    const csvRows = [];
    const headers = fields.map(field => field.label);
    csvRows.push(headers.join(','));

    for (const row of data) {
      if (row.request_point_id) {
        row.user_type = '需要場所単位';
      } else {
        row.user_type = '契約単位';
      }
      
      const values = fields.map(field => {
        const escaped = ('' + row[field.value]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  const csv = convertJsonToCsv(searchedData);
  
  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#172B65', color: '#FFFFFF', '&:hover': { backgroundColor: '#172B65' } }}>
        <ListItemText primary="検索条件" />
      </ListItemButton>
      <Paper sx={{ height: 350, padding: "0 40px" }} variant="outlined" square >
        <Spacer size={10} />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="body1">ユーザID</Typography>
            <TextField 
              value={userId}
              size="small" 
              fullWidth
              onChange={(e) => {
                setUserId(e.target.value);
                setSearchConditionUserId(e.target.value)
              }}/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">契約ID</Typography>
            <TextField 
              value={contractId}
              size="small" 
              fullWidth
              onChange={(e) => {
                setContractId(e.target.value);
                setSearchConditionContractId(e.target.value)
              }}/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">契約名</Typography>
            <TextField
              value={contractName}
              size="small"
              fullWidth
              onChange={(e) => {
                setContractName(e.target.value);
                setSearchConditionContractName(e.target.value)
              }}/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">需要場所ID</Typography>
            <TextField
              value={requestPointId}
              size="small"
              fullWidth
              onChange={(e) => {
                setRequestPointId(e.target.value);
                setSearchConditionRequestPointId(e.target.value)
              }}/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">需要場所名</Typography>
            <TextField
              value={requestPointName}
              size="small"
              fullWidth
              onChange={(e) => {
                setRequestPointName(e.target.value);
                setSearchConditionRequestPointName(e.target.value)
              }}/>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography variant="body1">供給開始日</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={startDateHead}
                 format="YYYY/MM/DD"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={handleChangeSupplyStartHead}
                 views={['year', 'month', 'day']}
                />
              </LocalizationProvider>
              <Typography variant="h5">~</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={startDateTail}
                 format="YYYY/MM/DD"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={handleChangeSupplyStartTail}
                 views={['year', 'month', 'day']}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">供給終了日</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                 value={endDateHead}
                 format="YYYY/MM/DD"
                 ampm={false}
                 slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                 onChange={handleChangeSupplyEndHead}
                 views={['year', 'month', 'day']}
                />
              </LocalizationProvider>
              <Typography variant="h5">~</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                <DateTimePicker
                  value={endDateTail}
                  format="YYYY/MM/DD"
                  ampm={false}
                  slotProps={{ textField: { size: 'small', style: { backgroundColor: '#FFFFFF' }}}}
                  onChange={handleChangeSupplyEndTail}
                  views={['year', 'month', 'day']}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={3}>
            <Typography variant="body1">ユーザ種別</Typography>
            <Stack direction="row" spacing={1}>
              <FormControlLabel control={<Checkbox checked={checkedContractUnit} onChange={(e) => setCheckedContractUnit(e.target.checked)} />} label="契約単位" />
              <FormControlLabel control={<Checkbox checked={checkedRequestPointUnit} onChange={(e) => setCheckedRequestPointUnit(e.target.checked)} />} label="需要場所単位" />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              <StyledButton variant="outlined" onClick={searchData}>検索</StyledButton>
              <StyledButton variant="outlined" onClick={handleClear}>クリア</StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}