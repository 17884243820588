import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { UsingAchievementTableCell } from '../../styles/style';

interface Props {
    data: MonthlyData[];
    supplyStartDate: string;
    contractEndDate: string;
};

interface MonthlyData {
    acquisition_date: string;
    daytime_use_power_kWh: string;
    nighttime_use_power_kWh: string;
    total_use_power_kWh: string;
};


export default function UsingAcievementTable(props: Props) {
  const { data, supplyStartDate, contractEndDate } = props;
  const today = dayjs().format("YYYY/MM/DD"); // 現在の年月を取得
    
  return (
    <TableContainer component={Paper} elevation={0} sx={{ width: 395, height: 378, border: '1px solid', borderColor: '#DFDFED' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>日</TableCell>
            <UsingAchievementTableCell padding="none">全体(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">昼間(kWh)</UsingAchievementTableCell>
            <UsingAchievementTableCell padding="none">夜間(kWh)</UsingAchievementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const isFutureDate = dayjs(row.acquisition_date).isAfter(today);
            // supplyStartDateとcontractEndDateの間にあるかどうか
            const withinContractPeriod = dayjs(row.acquisition_date).isAfter(dayjs(supplyStartDate).subtract(1, 'day')) &&
                                          dayjs(row.acquisition_date).isBefore(dayjs(contractEndDate).add(1, 'day'));
            return (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#CBE8FA' : '#FFFFFF' }}
              >
                <TableCell>{dayjs(row.acquisition_date).format("YYYY/MM/DD")}</TableCell>
                <TableCell align="right">{(!withinContractPeriod || isFutureDate || row.total_use_power_kWh === "") ? "-" : (Number(row.daytime_use_power_kWh) + Number(row.nighttime_use_power_kWh)).toLocaleString()}</TableCell>
                <TableCell align="right">{(!withinContractPeriod || isFutureDate || row.daytime_use_power_kWh === "") ? "-" : Number(row.daytime_use_power_kWh).toLocaleString()}</TableCell>
                <TableCell align="right">{(!withinContractPeriod || isFutureDate || row.nighttime_use_power_kWh === "") ? "-" : Number(row.nighttime_use_power_kWh).toLocaleString()}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}