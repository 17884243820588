import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// interface
import { AchievementDownloadDayAndNightRecord } from '../../interfaces/AchievementDownloadDayAndNight'
// customhook
import { useAuth } from '../../../utils/hooks/use-auth';
// custom styled component
import { StyledTableSortLabel } from '../../styles/style';


interface Order {
  target_ym: 'asc' | 'desc';
  name: 'asc' | 'desc';
  contract_id: 'asc' | 'desc';
  supply_point_no: 'asc' | 'desc';
  voltage_class: 'asc' | 'desc';
  contract_type_name: 'asc' | 'desc';
  address: 'asc' | 'desc';
  supply_start_date: 'asc' | 'desc';
  contract_end_date: 'asc' | 'desc';
}

type OrderBy = keyof Order;

interface UsingAchievementDownloadDayAndNightTable {
  searchedData: AchievementDownloadDayAndNightRecord[];
  page: number;
  pagenation: (page: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, name: string) => void;
}

const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 5;

const pagenationTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: ({theme}) =>
          theme.unstable_sx({
            display: "flex",
            justifyContent: "center"
          }),
        },
      },
    },
  },
);

export default function UsingAchievementDownloadDayAndNightTable(props: UsingAchievementDownloadDayAndNightTable) {
  const auth = useAuth();
  const {searchedData, singleCheck, allCheck, page, pagenation} = props;
  const [order, setOrder] = React.useState<Order>({ 
    target_ym: 'desc',
    name: 'asc',
    contract_id: 'asc',
    supply_point_no: 'asc',
    voltage_class: 'asc',
    contract_type_name: 'asc',
    address: 'asc',
    supply_start_date: 'asc',
    contract_end_date: 'asc',
  });
  const [orderBy, setOrderBy] = React.useState<OrderBy>('target_ym');
  const [checkboxBulk, setCheckboxBulk] = React.useState<boolean>(false);
  
  const handleRequestSort = (property: OrderBy) => {
    const isAsc = order[property] === 'asc';
    setOrderBy(property);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [property]: isAsc ? 'desc' : 'asc',
    }));
  };
  
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxBulk(event.target.checked);
    allCheck(event.target.checked);
  };

  interface VoltageMap {
    [key: string]: number;
  }
  
  const voltageMap: VoltageMap = {
    '低圧': 0,
    '高圧': 1,
    '特高': 2
  };

  const compare = (a: AchievementDownloadDayAndNightRecord, b: AchievementDownloadDayAndNightRecord): number => {
    if (orderBy === 'target_ym') {
      if (a.target_ym < b.target_ym) {
        return order.target_ym === 'asc' ? -1 : 1;
      }
      if (a.target_ym > b.target_ym) {
        return order.target_ym === 'asc' ? 1 : -1;
      }
      if (a.target_ym === b.target_ym) {
        if (a.contract_id < b.contract_id) {
          return order.contract_id === 'asc' ? -1 : 1;
        }
        if (a.contract_id > b.contract_id) {
          return order.contract_id === 'asc' ? 1 : -1;
        }
      }
    } 
    if (orderBy === 'name') {
      if (a.name < b.name) {
        return order.name === 'asc' ? -1 : 1;
      }
      if (a.name > b.name) {
        return order.name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_id') {
      if (a.contract_id < b.contract_id) {
        return order.contract_id === 'asc' ? -1 : 1;
      }
      if (a.contract_id > b.contract_id) {
        return order.contract_id === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'voltage_class') {
      if (voltageMap[a.voltage_class] < voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? -1 : 1;
      }
      if (voltageMap[a.voltage_class] > voltageMap[b.voltage_class]) {
        return order.voltage_class === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_point_no') {
      if (a.supply_point_no < b.supply_point_no) {
        return order.supply_point_no === 'asc' ? -1 : 1;
      }
      if (a.supply_point_no > b.supply_point_no) {
        return order.supply_point_no === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_type_name') {
      if (a.contract_type_name < b.contract_type_name) {
        return order.contract_type_name === 'asc' ? -1 : 1;
      }
      if (a.contract_type_name > b.contract_type_name) {
        return order.contract_type_name === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'address') {
      if (a.address < b.address) {
        return order.address === 'asc' ? -1 : 1;
      }
      if (a.address > b.address) {
        return order.address === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'supply_start_date') {
      if (a.supply_start_date < b.supply_start_date) {
        return order.supply_start_date === 'asc' ? -1 : 1;
      }
      if (a.supply_start_date > b.supply_start_date) {
        return order.supply_start_date === 'asc' ? 1 : -1;
      }
    } else if (orderBy === 'contract_end_date') {
      if (a.contract_end_date < b.contract_end_date) {
        return order.contract_end_date === 'asc' ? -1 : 1;
      }
      if (a.contract_end_date > b.contract_end_date) {
        return order.contract_end_date === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
  
  const replaceContractEndDateForPrinting = (contractEndDate : string) => {
    if(contractEndDate != null){
      const splitedDate = contractEndDate.split("/");
      const y: number = Number(splitedDate[0]);
      const m: number = Number(splitedDate[1]);
      const d: number = Number(splitedDate[2].slice(0, 2));
      
      return(`${String(y)}/${String(m)}/${String(d)}`);
    }
    else{
      return contractEndDate;
    }
  }
  
  return (
    <div>
      <Typography variant="h6">{`${(page-1)*itemsPerPage+1}-${(page)*itemsPerPage < searchedData.length? (page)*itemsPerPage : searchedData.length} / ${searchedData.length}件`}</Typography>
      <TableContainer >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox checked={checkboxBulk} onChange={handleChangeCheckbox} />
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'target_ym'}
                  direction={order.target_ym}
                  onClick={() => handleRequestSort('target_ym')}
                >
                  対象年月
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'name'}
                  direction={order.name}
                  onClick={() => handleRequestSort('name')}
                >
                  名称
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_id'}
                  direction={order.contract_id}
                  onClick={() => handleRequestSort('contract_id')}
                >
                  契約番号
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'voltage_class'}
                  direction={order.voltage_class}
                  onClick={() => handleRequestSort('voltage_class')}
                >
                  電圧区分
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_type_name'}
                  direction={order.contract_type_name}
                  onClick={() => handleRequestSort('contract_type_name')}
                >
                  契約種別名
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'address'}
                  direction={order.address}
                  onClick={() => handleRequestSort('address')}
                >
                  住所
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'supply_start_date'}
                  direction={order.supply_start_date}
                  onClick={() => handleRequestSort('supply_start_date')}
                >
                  供給開始日
                </StyledTableSortLabel>
              </TableCell>
              <TableCell>
                <StyledTableSortLabel
                  active={orderBy === 'contract_end_date'}
                  direction={order.contract_end_date}
                  onClick={() => handleRequestSort('contract_end_date')}
                >
                  供給終了日
                </StyledTableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedData.sort(compare).slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage).map((row, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: index % 2 === 1 ? '#ABE1FA' : '#ffffff' }}
              >
                <TableCell>
                  <Checkbox
                    onChange={() => singleCheck(row.target_ym, row.contract_id)}
                    checked={row.checked} />
                </TableCell>
                <TableCell>{row.target_ym}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.contract_id}</TableCell>
                <TableCell>{row.voltage_class}</TableCell>
                <TableCell>{row.contract_type_name}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.supply_start_date}</TableCell>
                <TableCell>{row.contract_end_date !== "2099/12/31"? replaceContractEndDateForPrinting(row.contract_end_date) : ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <>
        {
          (searchedData.length === 0) ? <p style={{textAlign: 'center'}}>データがありません</p> : <></>
        }
      </>
      <ThemeProvider theme={pagenationTheme}>
        <Pagination 
          count={(searchedData.length % itemsPerPage === 0) ? (Math.floor((searchedData.length / itemsPerPage) + 1)-1) : (Math.floor((searchedData.length / itemsPerPage) + 1))}
          variant="outlined" 
          shape="rounded" 
          onChange={(e, page) => {
              pagenation(page);
            }
          } 
          page={page}
        />
      </ThemeProvider>
    </div>
  );
}