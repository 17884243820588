import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '../../utils/components/Spacer';
import { LocationInterface } from '../interfaces/LocationInterface';
import { StyledTypograpy, UsingAchievementDialogBox } from '../styles/style';

interface SelectDemandLocationProps {
    location: LocationInterface[];
    inputValue: string;
    setInputValue: (inputValue: string) => void;
    setPointID: (pointID: string) => void;
}

export default function SelectDemandLocation(props: SelectDemandLocationProps) {
    const { location, inputValue, setInputValue, setPointID } = props;
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    
    // 需要場所のキーワード検索の結果によって表示するデータを制限する。
    const filteredLocation = location.filter((item) => 
        item.request_point_name.includes(search) || 
        item.request_point_id.includes(search) ||
        item.supply_point_no.includes(search) || 
        item.fee_menu_name_printing.includes(search) ||
        item.prefecture_name.includes(search) ||
        item.address.includes(search)
    );
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleItemClick = (value: string, id: string) => {
        setInputValue(value);
        setPointID(id);
        setSearch('');
        handleClose();
    };
    
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <StyledTypograpy variant="body1">需要場所名</StyledTypograpy>
            <TextField
                value={inputValue}
                onClick={handleOpen} 
                size="small"
                style={{ width: "100%", maxWidth: "684px" }}
                InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}/>
            <Dialog 
                open={open}
                onClose={handleClose}
                PaperProps={{ 
                    style: {
                        maxWidth: "910px", 
                        width: "100%", 
                        maxHeight: "720px", 
                        height: "100%", }}}
            >
                <DialogContent style={{ padding: "40px 80px" }}>
                    <IconButton
                        onClick={handleClose}
                        style={{position: "absolute", top: 10, right: 10}}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    
                    <Typography variant="h6" style={{ color: "#054D8F", fontWeight: "bold" }}>詳細検索</Typography>
                    <UsingAchievementDialogBox>
                        <StyledTypograpy variant="body1">キーワード検索</StyledTypograpy>
                        <TextField value={search} onChange={(e) => setSearch(e.target.value)} size="small" style={{ width: '100%', maxWidth: '684px', backgroundColor: "#FFFFFF" }} />
                    </UsingAchievementDialogBox>
                    <Spacer size={20} />
                    
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>検索結果</Typography>
                    <List sx={{ width: "100%", height: "400px", overflowY: "scroll" }}>
                        {filteredLocation.map((loc) => (
                            <ListItemButton 
                                sx={{ borderTop: "1px solid #E0E0E0", '&:hover': {backgroundColor: "#F2FAFC"} }} 
                                onClick={() => handleItemClick(loc.request_point_name, loc.request_point_id)}>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography>{loc.request_point_name}</Typography>
                                            <div>
                                                需要場所ID：{loc.request_point_id}　供給地点番号：{loc.supply_point_no}
                                            </div>
                                            <div>
                                                契約種別名（電圧）：{loc.fee_menu_name_printing}
                                            </div>
                                            <div>
                                                住所：{loc.prefecture_name + loc.address}
                                            </div>
                                        </React.Fragment>
                                    } 
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </Stack>
    );
}