import * as React from 'react';
// MUI components
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Spacer } from '../../../utils/components/Spacer';
import AutoComplete from '../../../utils/components/AutoComplete';

type Props = {
  setSearchConditionRequestPointName: (requestPointName?: string[]) => void;
  requestPointNameList: string[];
  setSearchConditionRequestPointId: (requestPointId?: string[]) => void;
  requestPointIdList: string[];
  setSearchConditionSupplyPointNo: (supplyPointNo?: string[]) => void;
  supplyPointNoList: string[];
  setSearchConditionAddress: (address?: string[]) => void;
  addressList: string[];
  setSearchConditionFeeMenuNamePrinting: (feeMenuNamePrinting?: string[]) => void;
  setSearchConditionVoltageClass: (low?: boolean, high?: boolean, exHigh?: boolean) => void;
  setSearchConditionSupplyDate: (startHead: Date|null, startTail: Date|null, EndHead: Date|null, EndTail: Date|null) => void;
  feeMenuNamePrintingList: string[];
  resetData: () => void;
}

export default function SearchBoxContractInformation(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [location, setLocation] = React.useState<Array<string>>([]);
  const [locationId, setLocationId] = React.useState<Array<string>>([]);
  const [address, setAdress] = React.useState<Array<string>>([]);
  const [num, setNum] = React.useState<Array<string>>([]);
  const [category, setCategory] = React.useState<Array<string>>([]);
  // チェックボックス3つ分
  const [checkedLow, setCheckedLow] = React.useState(true);
  const [checkedHigh, setCheckedHigh] = React.useState(true);
  const [checkedExHigh, setCheckedExHigh] = React.useState(true);
  // カレンダー検索ボックス
  const [supplyStartDateHead, setSupplyStartDateHead] = React.useState<Date | null>(null);
  const [supplyStartDateTail, setSupplyStartDateTail] = React.useState<Date | null>(null);
  const [supplyEndDateHead, setSupplyEndDateHead] = React.useState<Date | null>(null);
  const [supplyEndDateTail, setSupplyEndDateTail] = React.useState<Date | null>(null);
  
  const {
    setSearchConditionRequestPointName,
    requestPointNameList, 
    setSearchConditionRequestPointId,
    requestPointIdList,
    setSearchConditionSupplyPointNo,
    supplyPointNoList,
    setSearchConditionAddress,
    addressList,
    setSearchConditionFeeMenuNamePrinting,
    feeMenuNamePrintingList,
    setSearchConditionVoltageClass,
    setSearchConditionSupplyDate,
    resetData
  } = props;

  const handleClick = () => {
    setOpen(!open);
  };
  
  React.useEffect(() => {
    setSearchConditionVoltageClass(checkedExHigh, checkedHigh, checkedLow);
  }, [checkedExHigh, checkedHigh, checkedLow])
  
  const handleChangeLow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedLow(event.target.checked);
  };
  const handleChangeHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedHigh(event.target.checked);
  };
  const handleChangeExHigh = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedExHigh(event.target.checked);
  };
  
  const handleChangeSupplyStartHead = (newValue: Date | null) => {
     setSupplyStartDateHead(newValue);
     setSearchConditionSupplyDate(newValue, null, null, null);
  };
  const handleChangeSupplyStartTail = (newValue: Date | null) => {
     setSupplyStartDateTail(newValue);
     setSearchConditionSupplyDate(null, newValue, null, null);
  };
  const handleChangeSupplyEndHead = (newValue: Date | null) => {
     setSupplyEndDateHead(newValue);
     setSearchConditionSupplyDate(null, null, newValue, null);
  };
  const handleChangeSupplyEndTail = (newValue: Date | null) => {
     setSupplyEndDateTail(newValue);
     setSearchConditionSupplyDate(null, null, null, newValue);
  };
  
  const resetConditionOnClick = () => {
    setLocation([]);
    setLocationId([]);
    setAdress([]);
    setNum([]);
    setCategory([]);
    setCheckedLow(true);
    setCheckedHigh(true);
    setCheckedExHigh(true);
    setSupplyStartDateHead(null);
    setSupplyStartDateTail(null);
    setSupplyEndDateHead(null);
    setSupplyEndDateTail(null);
    resetData();
  };

  return (
    <div>
      <ListItemButton sx={{ bgcolor: '#0099CC', color: '#ffffff' }} onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
        <ListItemText primary="詳細絞り込み" />
      </ListItemButton>
      {open &&
        <Paper variant="outlined" square >
          <Spacer size={10} />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8}>
              <Typography variant="body1">需要場所</Typography>
              <AutoComplete
                value={location}
                setValue={setLocation}
                requestList={requestPointNameList}
                setSearchConditionRequest={setSearchConditionRequestPointName}/>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">需要場所ID</Typography>
              <AutoComplete
                value={locationId}
                setValue={setLocationId}
                requestList={requestPointIdList}
                setSearchConditionRequest={setSearchConditionRequestPointId}/>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8}>
              <Typography variant="body1">住所</Typography>
              <AutoComplete
                value={address}
                setValue={setAdress}
                requestList={addressList}
                setSearchConditionRequest={setSearchConditionAddress}/>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">供給地点特定番号</Typography>
              <AutoComplete
                value={num}
                setValue={setNum}
                requestList={supplyPointNoList}
                setSearchConditionRequest={setSearchConditionSupplyPointNo}/>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={8}>
              <Typography variant="body1">契約種別名</Typography>
              <AutoComplete
                value={category}
                setValue={setCategory}
                requestList={feeMenuNamePrintingList}
                setSearchConditionRequest={setSearchConditionFeeMenuNamePrinting}/>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1">電圧区分</Typography>
              <Stack direction="row" spacing={1}>
                <FormControlLabel control={<Checkbox checked={checkedExHigh} onChange={handleChangeExHigh} />} label="特高" />
                <FormControlLabel control={<Checkbox checked={checkedHigh} onChange={handleChangeHigh} />} label="高圧" />
                <FormControlLabel control={<Checkbox checked={checkedLow} onChange={handleChangeLow} />} label="低圧" />
              </Stack>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={4}>
              <Typography variant="body1">供給開始日</Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DateTimePicker
                     value={supplyStartDateHead}
                     format="YYYY/MM/DD"
                     slotProps={{ textField: { size: 'small'}}}
                     onChange={handleChangeSupplyStartHead}
                     views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1">~</Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DateTimePicker
                     value={supplyStartDateTail}
                     format="YYYY/MM/DD"
                     slotProps={{ textField: { size: 'small'}}}
                     onChange={handleChangeSupplyStartTail}
                     views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1">供給終了日</Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DateTimePicker
                     value={supplyEndDateHead}
                     format="YYYY/MM/DD"
                     slotProps={{ textField: { size: 'small'}}}
                     onChange={handleChangeSupplyEndHead}
                     views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1">~</Typography>
                </Grid>
                <Grid item xs={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                    <DateTimePicker
                     value={supplyEndDateTail}
                     format="YYYY/MM/DD"
                     slotProps={{ textField: { size: 'small'}}}
                     onChange={handleChangeSupplyEndTail}
                     views={['year', 'month', 'day']}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8.5}></Grid>
            <Grid item xs={3}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={resetConditionOnClick}>検索条件をクリア</Button>
              </Box>
            </Grid>
            <Grid item xs={0.5}></Grid>
          </Grid>
        </Paper>
      }
    </div>
  );
}

