import * as React from 'react';
import { useAuth } from '../../../utils/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { TabProps } from '../../../utils/interfaces/TabProps';
import { StyledTab } from '../../styles/style';

export default function AchievementDownloadTab(props: TabProps) {
    const auth = useAuth();
    const navigate = useNavigate();
    const [value, setValue] = React.useState(props.initPage);
    // tabの横の長さをタブの表示数で変更する
    const tabWidth = 60 - 20 * auth.userAuthority.substring(6, 9).split('').filter(char => char === '0').length;

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        switch (newValue) {
            case "monthly":
                navigate('/user/using-achievement/download/monthly');
                break;
            case "day-and-night":
                navigate('/user/using-achievement/download/day-and-night');
                break;
            case "report":
                navigate('/user/using-achievement/download/report');
                break;
            default:
                break;
        }
    };

    return (
        <Box sx={{ width: '100%', borderBottom: 2, borderColor: '#0098CB' }}>
            <Box sx={{ width: tabWidth.toString() + "%" }}>
                <Tabs 
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="tab"
                    sx={{ '& .MuiTab-root:not(:last-child)': { mr: 1 } }}
                >
                    {auth.userAuthority[8] === "1"? <StyledTab label="月別30分単位実績" value="monthly"/> : <div />}
                    {auth.userAuthority[7] === "1"? <StyledTab label="昼夜間電力量" value="day-and-night"/> : <div />}
                    {auth.userAuthority[6] === "1"? <StyledTab label="月次レポート" value="report"/> : <div />}
                </Tabs>
            </Box>
        </Box>
    );
}