import Amplify from 'aws-amplify';

const awsconfig = {
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_USER_Identity_POOL_ID, 
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_REGION, 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
    },
    API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_REGION
      }
    ]
  },
  Logging: {
    logGroupName: process.env.REACT_APP_Log_Group_Name,
    logStreamName: `${new Date().toISOString().substring(0, 10)}`, // 日付毎にログストリームを作成,
    region: process.env.REACT_APP_REGION
  }
};

Amplify.configure(awsconfig);

export default awsconfig;