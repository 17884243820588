import { useState, useEffect } from "react";
import axios from "axios";
import { Auth } from 'aws-amplify';
import { useAuth } from '../../utils/hooks/use-auth';
import { PDFDownloadRecordInterface, SearchCondition } from '../interfaces/PDFDownload'
 
type usePDFDownloadSearchRet<T> = {
  page: number,
  searchedData: T[],
  curPageData: T[],
  setCurPageData: (data: T[]) => void;
  getPDFDownloadData:  (startDate: string | null, endDate: string | null) => Promise<T[]>;
  targetDateList: string[],
  nameList: string[];
  setSearchConditionName: (name?: string[]) => void;
  contractIdList: string[];
  setSearchConditionContractId: (contractId?: string[]) => void;
  supplyPointNoList: string[];
  setSearchConditionSupplyPointNo: (supplyPointNo?: string[]) => void;
  addressList: string[];
  setSearchConditionAddress: (address?: string[]) => void;
  setSearchConditionVoltageClass: (low?: boolean, high?: boolean, exHigh?: boolean) => void;
  setSearchConditionFiletype: (invoice?: boolean, specification?: boolean) => void;
  resetState: boolean;
  resetData: () => void;
  clearResetSatate: () => void;
  pagenation: (curPage: number) => void;
  allCheck: (state: boolean) => void;
  singleCheck: (target_ym: string, contractId: string) => void;
};

// 検索条件の初期値
const initSerchVoltageClass = {
  exHigh: true,
  high: true,
  low: true
};
const initFileType = {
  invoice: true,
  specification: true
}
const initSerchCondition = {
  searchFiletype: initFileType,
  searchVoltageClass: initSerchVoltageClass,
  name: [],
  contractId: [],
  address: [],
  supplyPointNo: [],
}

// APIリクエストのベースURL
const baseURL = process.env.REACT_APP_API_ENDPOINT;
// 1ページ当たりに表示するデータ件数
const itemsPerPage: number = Number(process.env.REACT_APP_INVOICE_ITEMS_PER_PAGE) || 5;

// トークン取得
const getCurrentUserToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.log(error);
  }
};

// メインフック
export function usePDFDownload(): usePDFDownloadSearchRet<PDFDownloadRecordInterface> {
  const auth = useAuth();
  // データを格納する変数
  const [defaultData, setDefaultData] = useState<PDFDownloadRecordInterface[]>([]);
  const [searchedData, setSearchedData] = useState<PDFDownloadRecordInterface[]>([]);
  const [curPageData, setCurPageData] = useState<PDFDownloadRecordInterface[]>([]);
  // 検索条件を格納する変数
　const [searchCondition, setSearchCondition] = useState<SearchCondition>({...initSerchCondition});
　const [targetDateList, setTargetDateList] = useState<string[]>([]);  // 対象年月
  const [nameList, setNameList] = useState<string[]>([]);
  const [contractIdList, setContractIdList] = useState<string[]>([]);
  const [addressList, setAddressList] = useState<string[]>([]);
  const [supplyPointNoList, setSupplyPointNoList] = useState<string[]>([]);
  // 検索条件の初期化を制御する変数
  const [resetState, setResetState] = useState<boolean>(false);
  // ページ番号を保持する変数
  const [page, setPage] = useState<number>(1);
  
  
  useEffect(() => {
    if (defaultData.length > 0) searchData();
  }, [defaultData]);
  
  useEffect(() => {
    pagenation(1);
    searchData();
  }, [searchCondition]);
  
  const setSearchConditionName = (name: string[] = []) => {
    if(name.length !== 0){
      setSearchCondition({...searchCondition, name: [...name]});
    }
    else{
      setSearchCondition({...searchCondition, name: []});
    }
  }

  const setSearchConditionContractId = (contractId: string[] = []) => {
    if(contractId.length !== 0){
      setSearchCondition({...searchCondition, contractId: [...contractId]});
    }
    else{
      setSearchCondition({...searchCondition, contractId: []});
    }
  }
  
  const setSearchConditionAddress = (address: string[] = []) => {
    if(address.length !== 0){
      setSearchCondition({...searchCondition, address: [...address]});
    }
    else{
      setSearchCondition({...searchCondition, address: []});
    }
  }
  
  const setSearchConditionSupplyPointNo = (supplyPointNo: string[] = []) => {
    if(supplyPointNo.length !== 0){
      setSearchCondition({...searchCondition, supplyPointNo: [...supplyPointNo]});
    }
    else{
      setSearchCondition({...searchCondition, supplyPointNo: []});
    }
  }
  
  const setSearchConditionVoltageClass = (exHigh: boolean = false, high: boolean = false, low: boolean = false) => {
    const targetVoltageClass = {
      exHigh,
      high,
      low
    };
    setSearchCondition({...searchCondition, searchVoltageClass: targetVoltageClass});
  }
  
  const setSearchConditionFiletype = (invoice: boolean = false, specification: boolean = false) => {
    const targetFiletype = {
      invoice,
      specification
    };
    setSearchCondition({...searchCondition, searchFiletype: targetFiletype});
  }
  
  // 検索処理のメイン関数
  const searchData = () => {
    let searchedData1: PDFDownloadRecordInterface[] = [];
    let searchedData2: PDFDownloadRecordInterface[] = [];
  
    searchedData1 = defaultData;
  
    /*名称による検索処理*/
    if(searchCondition.name.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.name.indexOf(item.name) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*契約IDによる検索処理*/
    if(searchCondition.contractId.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.contractId.indexOf(item.contract_id) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*住所による検索処理*/
    if(searchCondition.address.length !== 0){
      searchedData1.forEach((item, index) => {
        if(searchCondition.address.indexOf(item.address) !== -1){
          searchedData2.push(searchedData1[index]);
        }
      });
    }
    else{
      searchedData2 = [...searchedData1];
    }
    searchedData1 = [];
    
    /*供給地点番号による検索処理*/
    if(searchCondition.supplyPointNo.length !== 0){
      searchedData2.forEach((item, index) => {
        if(searchCondition.supplyPointNo.indexOf(item.supply_point_no) !== -1){
          searchedData1.push(searchedData2[index]);
        }
      });
    }
    else{
      searchedData1 = [...searchedData2];
    }
    searchedData2 = [];
    
    /*種類による検索処理*/
    searchedData1.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchFiletype.invoice && item.file_type === "請求書"){
        pushFlag = true;
      }
      else if(searchCondition.searchFiletype.specification && item.file_type === "明細書"){
        pushFlag = true;
      }
      if (pushFlag) searchedData2.push(searchedData1[index]);
    });
    searchedData1 = [];

    /* ダウンロード対象ファイルによる検索処理 */
    searchedData2.forEach((item, index) => {
      let pushFlag = false;
      if(searchCondition.searchVoltageClass.exHigh && item.voltage_class === "特高"){
        pushFlag = true;
      }
      else if(searchCondition.searchVoltageClass.high && item.voltage_class === "高圧"){
        pushFlag = true;
      }
      else if(searchCondition.searchVoltageClass.low && item.voltage_class === "低圧"){
        pushFlag = true;
      }
      else if(searchCondition.searchVoltageClass.exHigh && searchCondition.searchVoltageClass.high && searchCondition.searchVoltageClass.low && item.voltage_class == null){
        pushFlag = true;
      }
      if (pushFlag) searchedData1.push(searchedData2[index]);
    });
    
    setSearchedData([...searchedData1]);
  }

  // 検索ボックスのプルダウンに表示するリストを作成する
  const getDropdownSearchList = (responseData: PDFDownloadRecordInterface[]) => {
    // setterは非同期のため一時配列を用意する
    let tmpNameList: string[] = [];
    let tmpContractIdList: string[] = [];
    let tmpSupplyPointNoList: string[] = [];
    let tmpAddressList: string[] = [];
    
    responseData.forEach((obj: any, i: Number) => {
      const tmpName: string = obj.name;
      if(tmpNameList.indexOf(tmpName) == -1){
        tmpNameList.push(tmpName);
      }
      const tmpContractId: string = obj.contract_id;
      if(tmpContractIdList.indexOf(tmpContractId) == -1){
        tmpContractIdList.push(tmpContractId);
      }
      if(obj.supply_point_no != null){
        const tmpSupplyPointNo: string = obj.supply_point_no;
        if(tmpSupplyPointNoList.indexOf(tmpSupplyPointNo) == -1){
          tmpSupplyPointNoList.push(tmpSupplyPointNo);
        }
      }
      if(obj.address != null){
        const tmpAddress: string = obj.address;
        if(tmpAddressList.indexOf(tmpAddress) == -1){
          tmpAddressList.push(tmpAddress);
        }
      }
    });
    
    // 更新処理は最後に一括で代入する
    setNameList(tmpNameList);
    setContractIdList(tmpContractIdList);
    setSupplyPointNoList(tmpSupplyPointNoList);
    setAddressList(tmpAddressList);
  }
  
  const resetData = () => {
    setSearchedData([...defaultData]);
    setSearchCondition({...searchCondition, ...initSerchCondition});
    setResetState(true);
  }
  
  const clearResetSatate = () => {
    setResetState(false);
  }
  
  const pagenation = (curPage: number) => {
    const head = (curPage - 1) * itemsPerPage;
    const targetPage: PDFDownloadRecordInterface[] = searchedData.slice(head, (head + itemsPerPage));
    setCurPageData([...targetPage]);
    setPage(curPage);
  }
  
  const allCheck = (state: boolean) => {
    const updateAllCheck = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: true }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj["contract_id"] === sObj["contract_id"]);
      return searchedObj ? { ...obj, checked: true } : obj;
    });
    
    const updateAllCancel = searchedData.length === defaultData.length?
    defaultData.map((obj) => ({ ...obj, checked: false }))
    : defaultData.map((obj) => {
      const searchedObj = searchedData.find((sObj) => obj["target_ym"] === sObj["target_ym"] && obj["contract_id"] === sObj["contract_id"]);
      return searchedObj ? { ...obj, checked: false } : obj;
    });
    
    if (state === true){
      setDefaultData(updateAllCheck);
    }
    else if (state === false){
      setDefaultData(updateAllCancel);
    }
  }
  
  const singleCheck = (target_ym: string, contract_id: string) => {
    const updatedSingleCheck = defaultData.map((obj) => {
      if (obj["target_ym"] === target_ym && obj["contract_id"] === contract_id) {
        return { ...obj, checked: !obj["checked"]};
      } else {
        return obj;
      }
    });
    setDefaultData(updatedSingleCheck);
  }

  // 明細書を取得する　
  const getPDFDownloadData: (start: string | null, end: string | null) => Promise<PDFDownloadRecordInterface[]> = async (start, end) => {
    const token = await getCurrentUserToken();
    let params: { [key: string]: string | null } = {
      "contractId" : auth.contractID,
      "startDate": start,
      "endDate": end
    }
    if(typeof(auth.requestPointID) !== "undefined") params["requestPointId"] = auth.requestPointID;
    
    const response = axios.request({
      url: baseURL + "/billing-inquery-pdfs-download",
      method: "get",
      headers: { 
        Authorization: `${token}`,
      },
      params : params
    })
    .then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i]["checked"] = false;
      }
      setDefaultData(response.data);
      getDropdownSearchList(response.data)
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
    
    return response
  };

  return {
    page,
    searchedData,
    curPageData,
    setCurPageData,
    getPDFDownloadData,
    targetDateList,
    nameList,
    setSearchConditionName,
    contractIdList,
    setSearchConditionContractId,
    supplyPointNoList,
    setSearchConditionSupplyPointNo,
    addressList,
    setSearchConditionAddress,
    setSearchConditionVoltageClass,
    setSearchConditionFiletype,
    resetState,
    resetData,
    clearResetSatate,
    pagenation,
    allCheck,
    singleCheck
  };
}
