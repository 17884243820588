import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    data: DailyData[];
    threshold: number;
    supplyStartDate: string;
    contractEndDate: string;
    targetDay: Dayjs | null;
};

interface DailyData {
    time: string;
    daytime_use_power_kWh: number;
    nighttime_use_power_kWh: number;
    exceed: number;
};

interface CustomizedAxisTickProps {
  x?: number;
  y?: number;
  index?: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: { name: string, value: number }[];
  label?: string;
}

const CustomizedAxisTick = ({x = 0, y = 0, index = 0}: CustomizedAxisTickProps) => {
  // 偶数本目のグラフのみティックに数値を表示
  const value = (index % 2 === 1) ? (index + 1) / 2 : '';

  return (
    <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
      {value}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <Box className="custom-tooltip" sx={{ padding: 1, borderRadius: 1, color: "#FFFFFF", backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
        <Typography variant="h6" style={{ display: "flex", justifyContent: "center" }}>{`${label}`}</Typography>
        {payload[0].value > 0 && (
          <div>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <Box sx={{backgroundColor: '#FCD983', width: '10px', height: '10px', mr: 1}}></Box>
              <Typography>{`${payload[0].name} : ${(Number(payload[0].value) + Number(payload[2].value)).toLocaleString()} kWh`}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <Box sx={{backgroundColor: '#FCB097', width: '10px', height: '10px', mr: 1}}></Box>
              <Typography>{`${payload[2].name} : ${Number(payload[2].value).toLocaleString()} kWh`}</Typography>
            </div>
          </div>
        )}
        {payload[1].value > 0 && (
          <div>
            <div style={{ display: 'flex', alignItems: "center"}}>
              <Box sx={{backgroundColor: '#A5BCE8', width: '10px', height: '10px', mr: 1}}></Box>
              <Typography>{`${payload[1].name} : ${(Number(payload[1].value) + Number(payload[2].value)).toLocaleString()} kWh`}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <Box sx={{backgroundColor: '#FCB097', width: '10px', height: '10px', mr: 1}}></Box>
              <Typography>{`${payload[2].name} : ${Number(payload[2].value).toLocaleString()} kWh`}</Typography>
            </div>
          </div>
        )}
      </Box>
    );
  }

  return null;
};

export default function UsingAchievementDailyChart(props: Props) {
    const { data, threshold, supplyStartDate, contractEndDate, targetDay } = props;
    
    const processedData = data.map((entry) => {
      const date = dayjs(targetDay);
      const start = dayjs(supplyStartDate);
      const end = dayjs(contractEndDate);
      // 対象日が供給開始日と契約終了日の間にあるかチェック
      const isInPeriod = date.isAfter(start.subtract(1, 'day')) && date.isBefore(end.add(1, 'day'));
      
      return {
        ...entry,
        daytime_use_power_kWh: isInPeriod ? Number(entry.daytime_use_power_kWh) !== -1 ? entry.daytime_use_power_kWh : 0 : 0,
        nighttime_use_power_kWh: isInPeriod ? Number(entry.nighttime_use_power_kWh) !== -1 ? entry.nighttime_use_power_kWh : 0 : 0,
        exceed: isInPeriod ? entry.exceed : 0
      };
    });
  
    return (
      <ResponsiveContainer width="100%" height="95%">
        <BarChart
          width={500}
          height={300}
          data={processedData}
          margin={{
            top: 0,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="0" />
          <XAxis dataKey="time" interval={0} tick={<CustomizedAxisTick />}>
            <Label value="時刻" offset={-15} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: '電力(kWh)', angle: -90, position: 'insideLeft' }}/>
          <Tooltip content={<CustomTooltip /> } cursor={false}/>
          <Legend 
            verticalAlign="top" 
            align="center" 
            height={36} 
            formatter={(value) => <span style={{ color: "#000000" }}>{value}</span>}/>
          <Bar name="昼間" dataKey="daytime_use_power_kWh" stackId="a" fill="#FCD983" />
          <Bar name="夜間" dataKey="nighttime_use_power_kWh" stackId="a" fill="#A5BCE8" />
          <Bar name="超過" dataKey="exceed" stackId="a" fill="#FCB097" />
          {threshold !== 0 ? <ReferenceLine y={threshold} stroke="red" /> : <div />}
        </BarChart>
      </ResponsiveContainer>
    );
}
