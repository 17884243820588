import * as React from 'react';
import { useAuth } from '../../../utils/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { TabProps } from '../../../utils/interfaces/TabProps';
import { StyledTab } from '../../styles/style';

export default function SettingTab(props: TabProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(props.initPage);
  // tabの横の長さをタブの表示数で変更する
  const tabWidth = 80 - 20 * auth.userAuthority.substring(14, 18).split('').filter(char => char === '0').length;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    switch (newValue) {
        case "electricity-alarm":
            navigate('/user/setting/electricity-alarm');
            break;
        case "mail":
            navigate('/user/setting/mail');
            break;
        case "password":
            navigate('/user/setting/password');
            break;
        case "billing-password":
            navigate('/user/setting/billing-password');
            break;
        default:
            break;
    }
  };

  return (
    <Box sx={{ width: '100%', borderBottom: 2, borderColor: '#0098CB' }}>
      <Box sx={{ width: tabWidth.toString() + "%" }}>
        <Tabs 
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          sx={{ '& .MuiTab-root:not(:last-child)': { mr: 1 } }}
        >
          {auth.userAuthority[14] === "1"? <StyledTab label="使用電力アラーム" value="electricity-alarm"/> : <div />}
          {auth.userAuthority[15] === "1"? <StyledTab label="メール" value="mail"/> : <div />}
          {auth.userAuthority[16] === "1"? <StyledTab label="パスワード" value="password"/> : <div />}
          {auth.userAuthority[17] === "1"? <StyledTab label="需要場所ID通知" value="billing-password"/> : <div />}
        </Tabs>
      </Box>
    </Box>
  );
}