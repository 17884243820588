import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import React, { createContext, useContext, useEffect, useState } from 'react';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  contractID: string;
  requestPointID: string;
  signUp: (username: string, email: string, password: string) => Promise<Result>;
  confirmSignUp: (verificationCode: string) => Promise<Result>;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => void;
  forgotPassword: (username: string) => Promise<Result>;
  forgotPasswordConfirm: (username: string, code: string, password:string) => Promise<Result>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<Result>;
  name: string;
  customerUserId: string;
  adminName: string;
  adminAuthority: string;
  setName: (name: string) => void;
  setCustomerUserId: (customerUserId: string) => void;
  setAdminName: (adminName: string) => void;
  setAdminAuthority: (adminAuthority: string) => void;
  userAuthority: string;
  setUserAuthority: (userAuthority: string) => void;
  userUpdated: boolean;
  setUserUpdated: (userUpdated: boolean) => void;
}

interface Result {
  success: boolean;
  message: string;
}

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [name, setName] = useState('');
  const [customerUserId, setCustomerUserId] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminAuthority, setAdminAuthority] = useState('');
  const [username, setUsername] = useState('');
  const [userAuthority, setUserAuthority] = useState('');
  const [password, setPassword] = useState('');
  const [contractID, setContractID] = useState('');
  const [requestPointID, setRequestPointID] = useState('');
  const [userUpdated, setUserUpdated] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((result) => {
        console.log(result)
        setContractID(result.attributes['custom:contract_id'])
        setRequestPointID(result.attributes['custom:request_point_id'])
        setName(result.attributes['custom:customer_name']);
        setCustomerUserId(result.attributes['custom:customer_user_id']);
        setAdminAuthority(result.attributes['custom:admin_authority']);
        setAdminName(result.attributes['custom:admin_name']);
        setUsername(result.username);
        setUserAuthority(result.attributes['custom:user_authority']);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setName('');
        setCustomerUserId('');
        setUsername('');
        setUserAuthority('');
        setContractID('');
        setAdminAuthority('');
        setAdminName('');
        setRequestPointID('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, [userUpdated]);

  const signUp = async (username: string, email: string, password: string) => {
    try {
      await Auth.signUp({ username, password });
      setUsername(username);
      setPassword(password);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const confirmSignUp = async (verificationCode: string) => {
    try {
      await Auth.confirmSignUp(username, verificationCode);
      const result = await signIn(username, password);
      setPassword('');
      return result;
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signIn = async (username: string, password: string) => {
    try {
      const user = await Auth.signIn(username, password);
      console.log(user)
      setContractID(user.attributes['custom:contract_id']);
      setRequestPointID(user.attributes['custom:request_point_id']);
      setName(user.attributes['custom:customer_name']);
      setCustomerUserId(user.attributes['custom:customer_user_id']);
      setUsername(user.username);
      setUserAuthority(user.attributes['custom:user_authority']);
      setAdminName(user.attributes['custom:admin_name']);
      setAdminAuthority(user.attributes['custom:admin_authority']);
      setIsAuthenticated(true);
  
      return { success: true, message: ''};
    } catch (error) {
      console.error(error);
      let msg = '認証に失敗しました。';
      if(typeof error === 'object' && error != null) {
        const err = error as Error;
        if('message' in err) {
          const errMsg = err.message;
          if(errMsg === 'Password attempts exceeded') {
            msg = 'パスワード入力回数の上限に達しました';
          }
        }
      }
      return {
        success: false,
        message: msg,
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername('');
      setUserAuthority('');
      setName('');
      setCustomerUserId('');
      setAdminName('');
      setAdminAuthority('');
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };
  
  const forgotPassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '確認コードの送信に失敗しました。',
      };
    }
  }
  const forgotPasswordConfirm = async (username: string, code: string, password:string) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'パスワードの再設定に失敗しました。',
      };
    }
  }
  
  const changePassword = async (currentPassword: string, newPassword: string) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, currentPassword, newPassword);
      return {success: true, message: ''};
    } catch (error) {
      if(typeof error === 'object' && error != null) {
        const err = error as Error;
        if('message' in err) {
          const errMsg = err.message;
          if(errMsg === 'Incorrect username or password.') {
            return {
              success: false,
              message: '現在のパスワードが正しくありません。',
            };
          }
        }
      }
      return {
        success: false,
        message: 'パスワードの変更に失敗しました。',
      };
    };
  };
  
  return {
    isLoading,
    isAuthenticated,
    username,
    contractID,
    requestPointID,
    signUp,
    confirmSignUp,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordConfirm,
    changePassword,
    name,
    customerUserId,
    adminName,
    adminAuthority,
    setName,
    setCustomerUserId,
    setAdminName,
    setAdminAuthority,
    userAuthority,
    setUserAuthority,
    userUpdated,
    setUserUpdated
  };
};
